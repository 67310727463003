import * as React from 'react';

function SvgCompetitionInsightsMapReset(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 14 14"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      ref={svgRef}
      {...props}
    >
      <path d="M14 0L0 6l6 2 2 6 6-14z" />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgCompetitionInsightsMapReset);
export default ForwardRef;
