import cx from 'classnames';
import React, { FC, useState } from 'react';
import styled from 'styled-components';

import { Spinner } from '@css/op-components';

import ElemeIcon from '../../common/svgs/ElemeIcon.svg';
import MeituanIcon from '../../common/svgs/MeituanIcon.svg';
import UnAuthorizedIcon from '../../common/svgs/UnAuthorizedIcon.svg';
import { MeituanOfoType } from '../../hooks/useOfoAuth';
import useSearchParams from '../../hooks/useSearchParams';
import { Button } from '../ui';
import { Mask } from './Mask';
import { Modal } from './Modal';
import { ModalHeader } from './ModalHeader';
import { OFO_NAMES, Ofo } from './constant';
import { PrepMode } from './constant';
import { openNativeOfoAuth } from './openNativeOfoAuth';
import fromEntries from 'fromentries';

export const OFO_ICONS: Record<Ofo, React.ElementType> = {
  meituan: MeituanIcon,
  eleme: ElemeIcon,
};

type OfoCardProps = {
  ofo: Ofo;
  isAuthorized: boolean;
  loading: boolean;
  ofoStoreName?: string;
  meituanOfoType?: MeituanOfoType;
  mode: PrepMode;
  minute: number;
  onClickSetPrepTime: (ofoName: Ofo, minute: number) => void;
  onClickSetMode: (ofoName: Ofo, mode: PrepMode) => void;
};

export const OfoAuthorizationCard: FC<OfoCardProps> = ({
  ofo,
  isAuthorized,
  loading,
  minute,
  mode,
  onClickSetMode,
  onClickSetPrepTime,
}) => {
  const OfoIcon = OFO_ICONS[ofo];
  const [maskOpen, setMaskOpen] = useState(false);
  const [adjustPrepTimeModalOpen, setAdjustPrepTimeModalOpen] = useState(false);
  const [adjustModeModalOpen, setAdjustModeModalOpen] = useState(false);
  const [bottom, setBottom] = useState(0);
  const { storeId } = useSearchParams();

  return (
    <OfoCardWrapper>
      <Mask open={maskOpen} />
      <OfoNameWrapper>
        <OfoIcon />
        <OfoNameTextWrapper>
          <OfoName>{`${OFO_NAMES[ofo]}出餐设置`}</OfoName>
          {ofo === 'meituan' ? <OfoTip>仅支持手动批量出餐</OfoTip> : <OfoTip>仅支持自动出餐</OfoTip>}
        </OfoNameTextWrapper>
      </OfoNameWrapper>
      <OfoCardActions>
        {loading ? (
          <ActionRow>
            <HorizCenterDiv>
              <Spinner size="small" color="gray" />
            </HorizCenterDiv>
          </ActionRow>
        ) : !isAuthorized ? (
          <ActionRow>
            <OfoAuthorizationStatus isAuthorized={isAuthorized}>
              <UnAuthorizedIcon />
              未授权
            </OfoAuthorizationStatus>
            <AuthorizationAction
              onClick={() => {
                openNativeOfoAuth({
                  ofo,
                  storeId,
                  productName: 'xcd',
                  callback: window.location.pathname,
                  callbackParams: fromEntries(new URLSearchParams(window.location.search)),
                });
              }}
            >
              去授权
            </AuthorizationAction>
          </ActionRow>
        ) : (
          <>
            <ActionRow
              onClick={() => {
                // setAdjustModeModalOpen(true);
              }}
            >
              <ActionName>出餐模式</ActionName>
              <ActionWrapper>{mode === PrepMode.auto ? '自动出餐' : '手动批量出餐'}</ActionWrapper>
            </ActionRow>
            <ActionRow onClick={() => setAdjustPrepTimeModalOpen(true)}>
              <ActionName>自动出餐时长设置</ActionName>
              <AuthorizationAction>{minute}</AuthorizationAction>
            </ActionRow>
          </>
        )}
      </OfoCardActions>

      <Modal
        onOpen={() => {
          setMaskOpen(true);
        }}
        style={{
          position: 'fixed',
          bottom,
        }}
        open={adjustPrepTimeModalOpen}
        bottom
        onClose={() => {
          setAdjustPrepTimeModalOpen(false);
          setMaskOpen(false);
        }}
        CustomHeader={() => (
          <ModalHeader title={`${OFO_NAMES[ofo]}出餐时长设置`} handleClose={() => setAdjustPrepTimeModalOpen(false)} />
        )}
      >
        <div className="minute-input-modal__body">
          <div className="minute-input-modal__body__input">
            <label className="minute-input-modal__body__input__label">请输入出餐时长（分钟）</label>
            <input
              autoFocus
              className="minute-input-modal__body__input__input"
              value={minute}
              onFocus={() => {
                setBottom(() => {
                  setTimeout(() => {
                    window.scrollTo(0, 0);
                    document.body.scrollTop = 0;
                  }, 100);
                  return 220;
                });
              }}
              onChange={(e) => {
                onClickSetPrepTime(ofo, Number(e.target.value));
              }}
              type="number"
              inputMode="decimal"
              pattern="\d*"
              min={0}
            />
          </div>
          <div className="minute-input-modal__body__btn-wrapper">
            <Button small fill onClick={() => onClickSetPrepTime(ofo, 5)}>
              5分钟
            </Button>
            <Button small fill onClick={() => onClickSetPrepTime(ofo, 10)}>
              10分钟
            </Button>
            <Button small fill onClick={() => onClickSetPrepTime(ofo, 20)}>
              20分钟
            </Button>
            <Button small fill onClick={() => onClickSetPrepTime(ofo, 30)}>
              30分钟
            </Button>
          </div>
        </div>
      </Modal>

      <Modal
        onOpen={() => {
          setMaskOpen(true);
        }}
        open={adjustModeModalOpen}
        bottom
        onClose={() => {
          setAdjustModeModalOpen(false);
          setMaskOpen(false);
        }}
        CustomHeader={() => (
          <ModalHeader title={`${OFO_NAMES[ofo]}出餐模式`} handleClose={() => setAdjustModeModalOpen(false)} />
        )}
      >
        <div className="mode-adjust-modal__body">
          <div className="mode-adjust-modal__body__input">
            <div
              className={cx('mode-adjust-modal__body__input__item', {
                disabled: ofo === 'meituan',
              })}
              onClick={() => {
                if (ofo === 'meituan') return;
                onClickSetMode(ofo, PrepMode.auto);
              }}
            >
              <div className="mode-adjust-modal__body__input__item__container">
                <label className="mode-adjust-modal__body__input__item__title">自动出餐</label>
                <label className="mode-adjust-modal__body__input__item__sub-title">
                  {ofo === 'meituan' ? '由于平台规则限制，暂不支持' : '系统将按照你设置的时长自动出餐'}
                </label>
              </div>
              <input type="radio" name="mode" value="auto" checked={mode === PrepMode.auto} />
            </div>
            <div
              className="mode-adjust-modal__body__input__item"
              onClick={() => {
                onClickSetMode(ofo, PrepMode.manual);
              }}
            >
              <div className="mode-adjust-modal__body__input__item__container">
                <label className="mode-adjust-modal__body__input__item__title">手动批量出餐</label>
                <label className="mode-adjust-modal__body__input__item__sub-title">
                  系统将按照你的设置定时汇总待出餐订单列表，你可以手动点击批量出餐
                </label>
              </div>
              <input type="radio" name="mode" value="manual" checked={mode === PrepMode.manual} />
            </div>
          </div>
        </div>
      </Modal>
    </OfoCardWrapper>
  );
};

const HorizCenterDiv = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const ActionName = styled.span`
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
`;

const OfoCardWrapper = styled.div`
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 12px;
`;
const OfoNameWrapper = styled.div`
  display: flex;
  padding: 20px;
  align-items: center;
`;

const OfoName = styled.div`
  font-weight: 500;
`;

const OfoNameTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 8px;
`;

const OfoTip = styled.div`
  font-size: 12px;
  color: #8f8f8f;
`;

const OfoCardActions = styled.div`
  display: flex;
  padding: 0 20px;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  justify-content: space-between;
  font-size: 14px;
  color: #525252;
  flex-direction: column;
  // set a divider between items except last item with css
  > div:not(:last-child) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  }
`;

const OfoAuthorizationStatus = styled.span<{ isAuthorized: boolean }>`
  color: ${({ isAuthorized }) => (isAuthorized ? '#39A652' : 'inherit')};
  display: flex;
  align-items: center;
  > svg {
    margin-right: 4px;
  }
`;

const AuthorizationAction = styled.span`
  align-self: center;
  position: relative;
  margin-right: 18px;
  :after {
    position: absolute;
    content: '';
    right: -18px;
    top: 7px;
    width: 5px;
    height: 5px;
    border-top: 2px solid #525252;
    border-right: 2px solid #525252;
    transform: rotate(45deg);
  }
`;

const ActionWrapper = styled.span`
  align-self: center;
  position: relative;
  margin-right: 18px;
  :after {
    position: absolute;
    content: '';
    right: -18px;
    top: 7px;
    width: 5px;
    height: 5px;
    border-top: 2px solid white;
    border-right: 2px solid white;
    transform: rotate(45deg);
  }
`;

const ActionRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 64px;
  align-items: center;
`;
