import * as React from 'react';

function SvgOrders(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <rect width={24} height={24} rx={6} fill="#DA252F" />
      <path
        d="M20.252 7.38a2.357 2.357 0 01-2.357 2.357H6.109a2.357 2.357 0 110-4.714h11.786a2.357 2.357 0 012.357 2.357zM19.073 12.979c0-.814-.66-1.474-1.474-1.474H6.403a1.473 1.473 0 000 2.947H17.6c.813 0 1.473-.66 1.473-1.473zM17.894 17.693c0-.814-.66-1.474-1.474-1.474H7.581a1.473 1.473 0 000 2.947h8.84c.813 0 1.473-.66 1.473-1.473z"
        fill="#fff"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgOrders);
export default ForwardRef;
