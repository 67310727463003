import React, { useEffect, useState, forwardRef } from 'react';
import { FocusScope, OverlayContainer, usePreventScroll } from 'react-aria';
import { isIOS } from 'react-device-detect';
import { Transition } from 'react-transition-group';
import { TransitionStatus } from 'react-transition-group/Transition';
import styled, { css, CSSProperties } from 'styled-components';

import { stopPropagation, zIndex as zIndexFn } from '../../../theme/utilities';

export interface OverlayProps {
  children?: React.ReactNode | ((status: TransitionStatus) => React.ReactNode);
  isVisible?: boolean;
  isDismissable?: boolean;
  transitionMs?: number;
  zIndex?: number;
}

const StyledContainer = styled.div<{ zIndex: number }>`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;
  z-index: ${(props) => props.zIndex};
`;

const StyledBackground = styled.div<{ opacity: number; opacityTransitionMs: number }>`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  ${(props) =>
    props.opacityTransitionMs !== undefined &&
    css`
      transition: opacity ${props.opacityTransitionMs}ms;
    `}
  opacity: ${(props) => props.opacity};
  background-color: ${(props) => props.theme.colors.transparentBlack20};
`;

const StyledOuterScrollContainer = styled.div<{ height: CSSProperties['height'] }>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  height: ${(props) => props.height};
  overflow-y: auto;
`;

const StyledInnerScrollContainer = styled.div`
  flex-shrink: 0;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const PreventScroll: React.FC<{}> = () => {
  // Prevent scrolling the page beneath the overlay while open
  usePreventScroll();
  return null;
};

/**
 * Modal overlay that fills the screen with the children and a transparent background.
 *
 * Optionally supports the same API as react-transition-group's Transition for its children,
 * passing an animationState given a transitionMs.
 *
 * @param isVisible Whether to show the overlay.
 * @param transitionMs Duration of background opacity transition and of proxied animationState transition.
 * @returns
 */
export const Overlay = forwardRef<HTMLDivElement, OverlayProps>(
  (
    { children, isVisible = true, isDismissable = false, transitionMs = 0, zIndex = zIndexFn('modalsDialogWindows') },
    ref
  ) => {
    // If a modal contains an input field, focusing the input in iOS and Android will cause the keyboard to appear.
    // On Android, the keyboard subtracts from the viewport height and so the container will automatically resize,
    // keeping the modal centered. The iOS developers felt the animation performance might be poor if the page height
    // were animated, since it would cause layout computations at each frame, so the keyboard is implemented as an
    // overlay and page’s content is animated up by the same amount. This change in height isn't consistently available
    // across iOS versions and devices via typical methods such as innerHeight; but is available in a fully supported
    // but lightly documented Visual Viewport API.
    //
    // References:
    // * https://blog.opendigerati.com/the-eccentric-ways-of-ios-safari-with-the-keyboard-b5aa3f34228d
    // * https://tkte.ch/2019/09/23/iOS-VisualViewport.html
    // * https://developer.mozilla.org/en-US/docs/Web/API/Visual_Viewport_API)
    const [height, setHeight] = useState<CSSProperties['height']>('100%');

    useEffect(() => {
      if (!window.visualViewport || !isIOS) {
        return;
      }

      const onVisualViewportChange = (event: Event) => {
        const viewport = event.target as VisualViewport;
        setHeight(`${viewport.height}px`);
      };

      window.visualViewport.addEventListener('resize', onVisualViewportChange);
      window.visualViewport.addEventListener('scroll', onVisualViewportChange);

      return () => {
        window.visualViewport?.removeEventListener('resize', onVisualViewportChange);
        window.visualViewport?.removeEventListener('scroll', onVisualViewportChange);
      };
    }, [height]);

    return (
      <>
        <Transition in={isVisible} timeout={transitionMs} mountOnEnter unmountOnExit appear>
          {(animationState: TransitionStatus) => {
            const isEnter = animationState === 'entering' || animationState === 'entered';
            return (
              <OverlayContainer>
                <StyledContainer ref={ref} zIndex={zIndex} onClick={stopPropagation()}>
                  <StyledBackground opacity={isEnter ? 1 : 0} opacityTransitionMs={transitionMs} />
                  <StyledOuterScrollContainer height={height}>
                    <StyledInnerScrollContainer
                      onPointerDown={(e) => {
                        // prevent restore focus when clicked on overlay
                        if (!isDismissable && e.target === e.currentTarget) {
                          e.preventDefault();
                        }
                      }}
                    >
                      <FocusScope contain restoreFocus autoFocus>
                        {children instanceof Function ? children(animationState) : children}
                      </FocusScope>
                    </StyledInnerScrollContainer>
                  </StyledOuterScrollContainer>
                </StyledContainer>
              </OverlayContainer>
            );
          }}
        </Transition>
        {isVisible && <PreventScroll />}
      </>
    );
  }
);
