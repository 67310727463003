import * as React from 'react';

function SvgCullinanArrowUpRight(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 20 20"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      ref={svgRef}
      {...props}
    >
      <path d="M14.596 5.4l-7.5.004v1.999l4.086-.003-6.485 6.485 1.413 1.413 6.485-6.486-.002 4.088h1.999l.004-7.5z" />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgCullinanArrowUpRight);
export default ForwardRef;
