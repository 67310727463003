import * as React from 'react';

function SvgCullinanSparkle(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 20 20"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      ref={svgRef}
      {...props}
    >
      <path d="M12 4l-2 5-5 2 5 2 2 5 2-5 5-2-5-2-2-5zM3.5 6.5l1 2.5 1-2.5 2.5-1-2.5-1-1-2.5-1 2.5-2.5 1 2.5 1zM5.25 15l-.75-2-.75 2-1.75.5 1.75.75L4.5 18l.75-1.75L7 15.5 5.25 15z" />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgCullinanSparkle);
export default ForwardRef;
