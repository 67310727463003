import * as React from 'react';

function SvgPlus(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      ref={svgRef}
      {...props}
    >
      <path
        d="M11.95 1.121a1 1 0 00-1 1v8.829H2.12a1 1 0 100 2h8.829v8.828a1 1 0 102 0V12.95h8.828a1 1 0 100-2H12.95V2.12a1 1 0 00-1-1z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgPlus);
export default ForwardRef;
