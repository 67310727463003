import * as React from 'react';

function SvgMinus(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 8 2"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path d="M0 1h8" stroke="currentColor" strokeLinejoin="round" />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgMinus);
export default ForwardRef;
