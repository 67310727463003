import * as React from 'react';

function SvgDirectToCustomers(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <rect width={24} height={24} rx={6} fill="#C5C948" />
      <path
        d="M5.864 13.682a4.91 4.91 0 014.909-4.91h2.454a4.91 4.91 0 014.91 4.91c0 .678-.55 1.227-1.228 1.227H7.091c-.678 0-1.227-.55-1.227-1.227zM19.5 17.636c0-.753-.61-1.363-1.364-1.363H5.864a1.364 1.364 0 100 2.727h12.272c.754 0 1.364-.61 1.364-1.364z"
        fill="#fff"
      />
      <rect x={10.295} y={4} width={3.409} height={3.409} rx={1.705} fill="#fff" />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgDirectToCustomers);
export default ForwardRef;
