import { RemoteContent } from '@/features/cms/components/remote-content';

export function VasBundleOperateContent() {
  return (
    <div className="max-h-screen overflow-auto">
      <RemoteContent contentKey={['iap', 'vasbundleoperate']} slot="headerImages" />
      <RemoteContent contentKey={['iap', 'vasbundleoperate']} slot="detailImages" />
    </div>
  );
}
