import * as React from 'react';

function SvgPaperclip(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.094 4.006a3.466 3.466 0 114.883 4.92l-6.754 6.705a4.824 4.824 0 01-6.797-6.848L7.742 4.5l1.057 1.064-4.317 4.285a3.324 3.324 0 104.684 4.718l6.755-6.704a1.966 1.966 0 10-2.77-2.791l-6.273 6.226a.608.608 0 00.857.863l6.272-6.226 1.057 1.064-6.273 6.227a2.108 2.108 0 01-2.97-2.992l6.273-6.227z"
        fill="#112232"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgPaperclip);
export default ForwardRef;
