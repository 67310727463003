import React, { FC } from 'react';

const UnAuthorizedIcon: FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M7.33325 4.66667L8.66659 4.66667V6H7.33325V4.66667ZM7.33325 7.33334H8.66659L8.66659 11.3333H7.33325L7.33325 7.33334ZM7.99992 1.33334C4.31992 1.33334 1.33325 4.32 1.33325 8C1.33325 11.68 4.31992 14.6667 7.99992 14.6667C11.6799 14.6667 14.6666 11.68 14.6666 8C14.6666 4.32 11.6799 1.33334 7.99992 1.33334ZM7.99992 13.3333C5.05992 13.3333 2.66659 10.94 2.66659 8C2.66659 5.06 5.05992 2.66667 7.99992 2.66667C10.9399 2.66667 13.3333 5.06 13.3333 8C13.3333 10.94 10.9399 13.3333 7.99992 13.3333Z"
        fill="#525252"
      />
    </svg>
  );
};

export default UnAuthorizedIcon;
