import { useOrgId } from '@/hooks/use-org-id';
import { fetchVasStoreId } from '@/hooks/use-vas-store-id';
import { urqlClient } from '@/http-clients';
import { createLogger } from '@/logger';
import { queryClient } from '@/query-client';
import type { Money } from '@css/currency';
import { moneyToNumber } from '@css/currency';
import gql from 'graphql-tag';
import { createQuery } from 'react-query-kit';
import { ProductPair } from './use-pay';

import { z } from 'zod';
import { addBreadcrumb, captureException } from '@sentry/browser';
import { AmountError } from '@/errors';
import { capturePaymentException } from '@/sentry/capture';

const amountHookParamsSchema = z.object({
  planName: z.string().refine((val) => val !== 'undefined' && val.length > 3, {
    message: 'invalid plan name',
  }),
  couponCode: z
    .string()
    .optional()
    .transform((val) => {
      return typeof val === 'undefined' || val === 'undefined' ? '' : val;
    }),
});

const amountToPayParamsSchema = amountHookParamsSchema.extend({
  // TODO: orgId and storeId uuid validation
  orgId: z.string().min(1, { message: 'invalid org id' }),
  storeId: z.string().min(1, { message: 'invalid store id' }),
});

const logger = createLogger('use-amount');

const amountQuery = gql`
  query fetchAmountToPayByPlan($planName: String!, $couponCode: String, $orgId: ID!, $storeId: ID!) {
    amountToPayByPlan(
      input: {
        planName: $planName
        couponCode: $couponCode
        payerOrganizationId: $orgId
        planAssociatedEntity: { entityId: $storeId, entityType: STORE }
      }
    ) {
      amount {
        currencyCode
        units
        nanos
      }
    }
  }
`;

async function bootstrapParams({ planName, couponCode }: ProductPair) {
  const orgId = await queryClient.fetchQuery({ queryKey: useOrgId.getKey(), queryFn: useOrgId.queryFn });
  const storeId = await fetchVasStoreId();

  const params = {
    planName,
    couponCode,
    orgId,
    storeId,
  };

  const parseResult = amountToPayParamsSchema.safeParse(params);

  if (couponCode === 'undefined') {
    captureException('CouponId is [undefined]');
  }

  if (!parseResult.success) {
    logger.error(params, `invalid params: \n${JSON.stringify(parseResult.error)}`);
    throw parseResult.error;
  }

  return params;
}

interface AmountToPayResponse {
  amountToPayByPlan: {
    amount: Money;
  };
}

export async function queryAmount({ planName, couponCode }: ProductPair) {
  const params = await bootstrapParams({ planName, couponCode });
  const res = await urqlClient.query<AmountToPayResponse>(amountQuery, params).toPromise();

  if (res.error) {
    throw new AmountError('query amount server error', res.error, params);
  }

  if (typeof res.data?.amountToPayByPlan?.amount === 'undefined') {
    throw new AmountError('amount is undefined', new Error('amount is undefined'), params);
  }

  const price = moneyToNumber(res.data.amountToPayByPlan.amount);

  if (price < 0) {
    addBreadcrumb({
      type: 'error',
      message: 'Price is less than 0',
      data: {
        price,
      },
    });
    capturePaymentException(new AmountError('Price is less than 0', new Error('negative price'), params));
    return 0;
  }

  return price;
}

export const useAmount = createQuery<number, ProductPair, Error>({
  primaryKey: 'use-amount',
  queryFn: async function ({ queryKey: [, { planName, couponCode }] }) {
    return await queryAmount({ planName, couponCode });
  },
  enabled(data, variables) {
    return amountHookParamsSchema.safeParse(variables).success;
  },
});
