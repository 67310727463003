import { gql } from '@urql/core';

export const queryVasBundle = gql`
  query vasBundle($productName: String!, $entityId: ID!) {
    vasBundle(input: { productName: $productName, entityId: $entityId, entityType: STORE }) {
      bundle {
        shouldRenew
        name
        title
        description
        status
        subscriptionEndTime
        points {
          id
          name
          enabled
          title
          subtitle
          deepLink
        }
      }
    }
  }
`;
