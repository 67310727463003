import { cva } from 'class-variance-authority';

export const button = cva('rounded-lg text-center inline-block', {
  variants: {
    intent: {
      primary: 'bg-primary text-white',
      fire: 'bg-[#ff8219] text-white !rounded-full text-sm flex justify-center items-baseline px-12 py-2',
      secondary: 'text-black',
      outlinePrimary: 'text-primary border border-primary',
      outlineSecondary: 'text-black border border-[#00000029]',
    },
    size: {
      full: 'block w-full p-2',
      large: 'text-lg p-4',
      auto: 'px-4 py-2',
    },
    disabled: {
      true: 'curshor-not-allowed',
    },
  },
});
