import * as React from 'react';

function SvgAppIconLocations(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M18.636 16.648c.262.186.338.308.358.352-.02.044-.096.166-.358.352-.382.271-1 .556-1.852.812C15.09 18.672 12.692 19 10 19s-5.09-.328-6.784-.836c-.853-.256-1.47-.541-1.852-.812-.262-.186-.338-.308-.358-.352.02-.044.096-.166.358-.352.382-.27 1-.555 1.852-.811.661-.199 1.43-.37 2.28-.504a36.619 36.619 0 01-.772-.882C1.888 14.981 0 15.924 0 17.001c0 1.656 4.477 3 10 3s10-1.344 10-3c0-1.077-1.888-2.02-4.724-2.55-.258.305-.517.6-.772.882.85.134 1.619.305 2.28.504.853.256 1.47.54 1.852.811z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 7a2 2 0 11-4 0 2 2 0 014 0zm-1 0a1 1 0 11-2 0 1 1 0 012 0z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.646 16.408C12.38 14.78 17 10.141 17 7A7 7 0 103 7c0 3.141 4.62 7.78 6.354 9.408a.94.94 0 001.292 0zm4.714-7.296c.428-.84.64-1.553.64-2.112A6 6 0 004 7c0 .559.212 1.273.64 2.112.42.825 1.012 1.694 1.675 2.543 1.306 1.673 2.826 3.179 3.685 3.988.858-.81 2.379-2.316 3.685-3.988.663-.849 1.254-1.718 1.675-2.543z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgAppIconLocations);
export default ForwardRef;
