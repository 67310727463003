import retry, { Options } from 'async-retry';

import { RetryOptions } from '../../api/types';
import { httpStatusRetryable } from '../../api/utils';
import { HTTPRequestError } from '../errors/error';
import { FetchFn } from '../types';

interface RetryingFetchOpts {
  delegate: FetchFn;
  retryOpts?: RetryOptions;
}

/**
 * Wraps a FetchFn and adds retry logic.
 */
export function createRetryingFetch(opts: RetryingFetchOpts): FetchFn {
  const retryOpts: Options = {
    retries: 0, // no retry by default, override in retryOpts
    factor: 2,
    minTimeout: 100,
    randomize: true,
    ...opts.retryOpts,
    // Never allow anyone to set forever in async-retry, even if they bypass our type checks
    forever: false,
  };

  return async (input: string, init?: RequestInit): Promise<Response> => {
    const body = await retry(async (bail) => {
      const response = await opts.delegate(input, init);
      if (response.ok) {
        return response;
      }

      const err = new HTTPRequestError(await response.text(), response.status, response.statusText, response);

      if (!httpStatusRetryable(response.status)) {
        bail(err);
        throw Error('Unreachable');
      }

      throw err;
    }, retryOpts);

    return body;
  };
}
