import * as React from 'react';

function SvgCullinanOnlineOrdering(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg
      viewBox="-2 -2 20 18"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      ref={svgRef}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 0H1L0 5h2v8.992h12V5h2l-1-5zM3.5 5h9v7.492H10v-4.99H6v4.99H3.5V5z"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgCullinanOnlineOrdering);
export default ForwardRef;
