import LoadingOverlay from '@/common/components/LoadingOverlay/LoadingOverlay';
import { spawnHttpClient } from '@/http-clients';
import { createQuery } from 'react-query-kit';

type RemoteContentSpec =
  | {
      category: 'iap';
      content: {
        headerImages: Array<string>;
        detailImages: Array<string>;
      };
    }
  | {
      category: 'upsell';
      content: {
        isNewVersion: Array<string>;
        positiveButton: Array<string>;
        carouselImages: Array<string>;
      };
    };

type TCategory = RemoteContentSpec['category'];

export type GetRemoteContentByCategory<TCat extends TCategory> = Extract<
  RemoteContentSpec,
  { category: TCat }
> extends {
  content: infer Content;
}
  ? Content
  : never;

export async function fetchRemoteContent<TCat extends TCategory>({
  category,
  productName,
}: {
  category: TCat;
  productName: string;
}) {
  const res = await spawnHttpClient({}).get<GetRemoteContentByCategory<TCat>>({
    path: `/lutrahub/ops/remote_content/${category}/${productName}`,
    method: 'GET',
    requestParams: {},
  });

  return res;
}

export const useRemoteContent = createQuery<
  RemoteContentSpec['content'],
  {
    category: TCategory;
    productName: string;
  }
>({
  primaryKey: 'use-remote-content',
  queryFn: async function ({ queryKey: [, { category, productName }] }) {
    const res = await fetchRemoteContent<typeof category>({ category, productName });
    return res;
  },
});

export function RemoteContent<TCat extends TCategory>(
  props: Extract<RemoteContentSpec, { category: TCat }> extends {
    content: infer Content;
  }
    ? {
        contentKey: [TCat, string];
        slot: keyof Content;
      }
    : never
) {
  const { contentKey, slot } = props;

  const { data, isLoading } = useRemoteContent({
    variables: {
      category: contentKey[0],
      productName: contentKey[1],
    },
  });

  if (isLoading) {
    return <LoadingOverlay />;
  }

  const content = data?.[slot] ?? [];

  return (
    <div>
      {content.map((image, index) => (
        <img key={index} alt="" src={image} className="block w-full" />
      ))}
    </div>
  );
}

export function RemotePositiveButtonText({
  category = 'upsell',
  productName,
  fallback,
}: {
  category?: TCategory;
  productName: string;
  fallback: string;
}) {
  const remoteContent = useRemoteContent<GetRemoteContentByCategory<'upsell'>>({
    variables: {
      category,
      productName,
    },
  });
  return (
    <span className="font-bold text-2xl">
      {remoteContent.data?.positiveButton?.[0] ? remoteContent.data.positiveButton?.[0] : fallback}
    </span>
  );
}

export function HideForUpsellNewVersion({
  category = 'upsell',
  productName,
  children,
}: {
  category?: TCategory;
  productName: string;
  children: React.ReactNode;
}) {
  const remoteContent = useRemoteContent<GetRemoteContentByCategory<'upsell'>>({
    variables: {
      category,
      productName,
    },
    suspense: true,
  });

  if (remoteContent.data?.isNewVersion[0] === 'yes') {
    return null;
  }

  return <>{children}</>;
}
