import * as React from 'react';

function SvgRoundCheckbox(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <circle cx={12} cy={12} r={12} fill="#FF5530" />
      <mask
        id="round-checkbox_svg__a"
        maskUnits="userSpaceOnUse"
        x={7.454}
        y={6.527}
        width={9.899}
        height={9.899}
        fill="#000"
      >
        <path fill="#fff" d="M7.454 6.527h9.899v9.899H7.454z" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.278 14.776l4.334-4.334-.707-.707-3.627 3.627-1.794-1.793-.707.707 2.5 2.5h.001z"
        />
      </mask>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.278 14.776l4.334-4.334-.707-.707-3.627 3.627-1.794-1.793-.707.707 2.5 2.5h.001z"
        fill="#fff"
      />
      <path
        d="M15.612 10.442l.354.354.354-.354-.354-.353-.354.353zm-4.334 4.334l-.353.354.353.353.354-.353-.354-.354zm3.627-5.04l.354-.354-.354-.354-.353.354.353.353zm-3.627 3.626l-.353.354.353.354.354-.354-.354-.354zM9.484 11.57l.354-.354-.354-.354-.353.354.353.354zm-.707.707l-.353-.354-.354.354.354.353.353-.353zm2.5 2.5l-.353.354.354.353.353-.353-.353-.354zm.001 0l.354-.354-.354-.353-.353.353.353.354zm3.98-4.687l-4.333 4.334.707.707 4.334-4.334-.707-.707zm-.706 0l.707.707.707-.707-.707-.707-.707.707zm-2.92 3.627l3.627-3.627-.707-.707-3.627 3.627.707.707zM9.13 11.922l1.794 1.794.707-.707-1.794-1.794-.707.707zm0 .707l.707-.707-.707-.707-.707.707.707.707zm2.5 1.794l-2.5-2.501-.707.707 2.5 2.5.707-.706zm-.706 0l.706.707-.706-.708zm.707 0l-.707.707.707-.707z"
        fill="#fff"
        mask="url(#round-checkbox_svg__a)"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgRoundCheckbox);
export default ForwardRef;
