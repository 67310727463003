import { fetchVasStoreId } from '@/hooks/use-vas-store-id';
import { useMutation } from '@tanstack/react-query';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Transition, TransitionStatus } from 'react-transition-group';
import styled from 'styled-components';
import loadingImage from '../../assets/otter_meituan_bridge.png';
import headerImage from '../../assets/otter_meituan_unbind.png';
import { spawnHttpClient } from '../../http-clients';
import { trackEvent } from '../../mixpanel';

export const wait = (delay: number) => new Promise((resolve) => setTimeout(resolve, delay));

type ButtonVariant = 'primary' | 'secondary' | 'danger';
const buttonColorMap: Record<ButtonVariant, { background: string; color: string; border: string }> = {
  primary: {
    background: '#e06e4d',
    color: '#fff',
    border: '#e06e4d',
  },
  secondary: {
    background: '#fff',
    color: '#141414',
    border: 'rgba(0, 0, 0, 0.16)',
  },
  danger: {
    background: '#fff',
    color: '#DA252F',
    border: 'transparent',
  },
};

const Wrapper = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

const Overlay = styled.div`
  position: absolute;
  background: rgba(0, 0, 0, 0.5);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

const Container = styled.div`
  position: relative;
  margin: 16px;
  border-radius: 16px;
  background: #fff;
  z-index: 2;
  overflow: hidden;
  width: 100%;
`;

const Title = styled.div`
  font-weight: 500;
  font-size: 22px;
  margin-bottom: 12px;
`;

const Paragraph = styled.div`
  font-size: 16px;
  font-weight: 400;
  color: #525252;
  margin-bottom: 20px;
`;

export const Button = styled.div<{ variant?: ButtonVariant }>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  background: ${(props) => buttonColorMap[props.variant ?? 'primary'].background};
  color: ${(props) => buttonColorMap[props.variant ?? 'primary'].color};
  border: 1px solid ${(props) => buttonColorMap[props.variant ?? 'primary'].border};
  width: 100%;
  height: 44px;

  & + & {
    margin-top: 10px;
  }
`;

const Header = styled.div`
  background: #f5f5f5;
  img {
    margin: 0 auto;
  }
`;

const Body = styled.div`
  padding: 24px 16px 12px;
  text-align: center;
`;

const LoadingOverlay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #fff;
`;

const LoadingHeader = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 60px;
  padding: 0 16px;
`;

const CloseButton = styled.div`
  height: 20px;
  width: 20px;
  cursor: pointer;
`;

const LoadingTitle = styled.div`
  margin: 40px 0 24px;
  font-size: 32px;
  font-weight: 500;
`;

const LoadingSubtitle = styled.div`
  font-size: 16px;
  font-weight: 400;
  text-align: center;
`;

const duration = 300;
const loadingDuration = 450;
const loadingDefaultStyle = {
  transition: `transform ${loadingDuration}ms cubic-bezier(.36,.66,.04,1)`,
  transform: 'translateY(100%)',
};
const loadingTransitionStyles: Record<TransitionStatus, any> = {
  entering: { transform: 'translateY(0)' },
  entered: { transform: 'translateY(0)' },
  exiting: { transform: 'translateY(100%)' },
  exited: { transform: 'translateY(100%)' },
  unmounted: { transform: 'translateY(100%)' },
};
const overlayDefaultStyle = {
  transition: `opacity ${duration}ms ease`,
  opacity: 0,
};
const overlayTransitionStyles: Record<TransitionStatus, any> = {
  entering: { opacity: 1 },
  entered: { opacity: 1 },
  exiting: { opacity: 0 },
  exited: { opacity: 0 },
  unmounted: { opacity: 0 },
};
const containerDefaultStyle = {
  transition: `opacity ${duration}ms ease, transform ${duration}ms ease`,
  opacity: 0,
  transform: 'translateY(20px)',
};
const containerTransitionStyles: Record<TransitionStatus, any> = {
  entering: { opacity: 1, transform: 'translateY(0)' },
  entered: { opacity: 1, transform: 'translateY(0)' },
  exiting: { opacity: 0, transform: 'translateY(20px)' },
  exited: { opacity: 0, transform: 'translateY(20px)' },
  unmounted: { opacity: 0, transform: 'translateY(20px)' },
};

const UnbindModal: React.FC<{ onClose: () => void }> = ({ onClose }) => {
  const { mutate } = useMutation(
    async function () {
      const storeId = await fetchVasStoreId();

      const res = await spawnHttpClient({}).get<{
        storeId: string;
        unbindLink: string;
      }>({
        path: `/stores/${storeId}/unbind_link`,
        method: 'POST',
        requestParams: {},
      });

      return res.unbindLink;
    },
    {
      mutationKey: ['get-unbind-link-mutation'],
      onSuccess: function (unbindLink) {
        trackEvent('unbind_meituan_callback_loaded', {
          page_name: 'WEB',
        });
        window.location.href = unbindLink;
      },
    }
  );

  const [hasError, setHasError] = useState(false);
  const [uiState, setUIState] = useState<'init' | 'confirm' | 'loading'>('init');
  const [isExiting, setIsExiting] = useState(false);

  const isLoading = uiState === 'loading';
  const isConfirm = uiState === 'confirm';
  const isInit = uiState === 'init';

  const isMountedRef = useRef(true);
  useEffect(
    () => () => {
      isMountedRef.current = false;
    },
    []
  );

  const handleClose = () => {
    setIsExiting(true);
    setTimeout(onClose, duration);
  };

  const handleUnbind = useCallback(async () => {
    try {
      setHasError(false);
      setUIState('loading');
      trackEvent('unbind_meituan_clicked', {
        page_name: 'WEB',
      });
      mutate();
    } catch (err) {
      console.error(err);
      setHasError(true);
    }
  }, []);

  return (
    <>
      <Transition in={isLoading && !isExiting} timeout={loadingDuration} mountOnEnter unmountOnExit appear>
        {(state) => (
          <LoadingOverlay
            style={{
              ...loadingDefaultStyle,
              ...loadingTransitionStyles[state],
            }}
          >
            <LoadingHeader>
              <CloseButton onClick={handleClose}>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M15.8337 5.34175L14.6587 4.16675L10.0003 8.82508L5.34199 4.16675L4.16699 5.34175L8.82533 10.0001L4.16699 14.6584L5.34199 15.8334L10.0003 11.1751L14.6587 15.8334L15.8337 14.6584L11.1753 10.0001L15.8337 5.34175Z"
                    fill="black"
                  />
                </svg>
              </CloseButton>
            </LoadingHeader>
            <div style={{ padding: 16 }}>
              <LoadingTitle>{hasError ? '网络连接失败' : '正在跳转至第三方页面'}</LoadingTitle>
              <img src={loadingImage} style={{ display: 'block', maxWidth: '100%', marginBottom: 24 }} />
              {hasError ? (
                <Button variant="secondary" onClick={handleUnbind}>
                  重试
                </Button>
              ) : (
                <LoadingSubtitle>正在加载第三方页面...</LoadingSubtitle>
              )}
            </div>
          </LoadingOverlay>
        )}
      </Transition>
      <Transition in={isConfirm && !isExiting} timeout={duration} mountOnEnter unmountOnExit appear>
        {(state) => (
          <Wrapper>
            <Overlay
              style={{
                ...overlayDefaultStyle,
                ...overlayTransitionStyles[state],
              }}
              onClick={handleClose}
            />
            <Container
              style={{
                ...containerDefaultStyle,
                ...containerTransitionStyles[state],
              }}
            >
              <Body>
                <Title>确认解除授权？</Title>
                <Paragraph>解除授权后，删除的历史数据将无法恢复</Paragraph>
                <Button onClick={handleClose}>暂不解除</Button>
                <Button variant="danger" onClick={handleUnbind}>
                  确认解除
                </Button>
              </Body>
            </Container>
          </Wrapper>
        )}
      </Transition>
      <Transition in={isInit && !isExiting} timeout={duration} mountOnEnter unmountOnExit appear>
        {(state) => (
          <Wrapper>
            <Overlay
              style={{
                ...overlayDefaultStyle,
                ...overlayTransitionStyles[state],
              }}
              onClick={handleClose}
            />
            <Container
              style={{
                ...containerDefaultStyle,
                ...containerTransitionStyles[state],
              }}
            >
              <Header>
                <img src={headerImage} style={{ display: 'block', maxWidth: '100%' }} />
              </Header>
              <Body>
                <Title>解除授权</Title>
                <Paragraph>
                  点击“解除授权”将解除美团非接单授权，并删除水獭掌柜中的美团店铺相关历史数据，你将无法使用需要美团非接单授权的水獭掌柜服务，是否确认解除授权？
                </Paragraph>
                <Button onClick={handleClose}>保留授权</Button>
                <Button variant="danger" onClick={() => setUIState('confirm')}>
                  解除授权
                </Button>
              </Body>
            </Container>
          </Wrapper>
        )}
      </Transition>
    </>
  );
};

export default UnbindModal;
