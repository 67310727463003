import { gql } from 'graphql-request';
import { urqlClient } from '@/http-clients';
import { createQuery } from 'react-query-kit';

const appUserRecallLogicQuery = gql`
  query appUserRecallLogic($storeId: String!, $productName: String!, $checkTime: Datetime!) {
    appUserRecallLogic(input: { storeId: $storeId, productName: $productName, checkTime: $checkTime }) {
      productName
      recallLogic
    }
  }
`;

export enum RecallLogic {
  RECALL_CASE_NOT_ELIGIBLE = 'RECALL_CASE_NOT_ELIGIBLE', //001用户没有过期
  RECALL_CASE_SIGN_AND_PAY = 'RECALL_CASE_SIGN_AND_PAY', //001用户过期两周内
  RECALL_CASE_PACKAGE_SELL = 'RECALL_CASE_PACKAGE_SELL', //001用户过期两周-四周
  RECALL_CASE_001_COUPON = 'RECALL_CASE_001_COUPON', //001用户过期四周-六周
  RECALL_CASE_NOT_TARGET_USER = 'RECALL_CASE_NOT_TARGET_USER', //非001用户
  RECALL_CASE_NEW_USER = 'RECALL_CASE_NEW_USER', //新用户
}

export const useAppUserRecallLogic = createQuery<
  RecallLogic | undefined,
  { storeId: string; productName: string },
  Error
>({
  primaryKey: 'app-user-recall-logic',
  queryFn: async ({ queryKey: [, { storeId, productName }] }) => {
    const res = await urqlClient
      .query<{
        appUserRecallLogic: {
          productName: string;
          recallLogic: RecallLogic;
        };
      }>(appUserRecallLogicQuery, {
        storeId,
        productName,
        checkTime: new Date(),
      })
      .toPromise();

    return res.data?.appUserRecallLogic.recallLogic;
    // return RecallLogic.RECALL_CASE_PACKAGE_SELL;
  },
});
