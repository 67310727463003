import React from 'react';

import { RemoteContent } from '@/features/cms/components/remote-content';
import { getTrackingName } from '../product-registry';
import orderPrepBanner from './assets/orderprep_banner.webp';
import orderPrepDetail from './assets/orderprep_detail.webp';
import printerTemplateBanner from './assets/printer-template-banner.webp';
import promotionBanner from './assets/promotion_banner.jpg';
import promotionDetail from './assets/promotion_detail.jpg';
import Printer4GPlan from './customizedComponents/Printer4GPlan';
import { VideoContainer } from './customizedComponents/xcd';
import { page as cpcPage } from './products/cpc';

type HeroDefinition =
  | {
      background: string;
      bannerPic?: string;
      detailPic?: string;
      introVideo?: string;
    }
  | {
      CustomComponent?: React.ComponentType<any>;
    };

// TODO: 把这里和 product registry 重合的部分移除掉
export interface PageDefinition {
  /**
   * some of the iap pages is in other projects,
   * but meanwhile it's required to render the intro popup in /am/privileges-list page.
   * so we need placeholders in pages array for displaying intro only
   */
  pending?: {
    CustomComponent: React.ComponentType<any>;
  };
  isPlaceholder?: boolean;
  slug: string;
  hero: HeroDefinition;
  detail: {
    imageUrl?: string;
    CustomComponent?: React.ComponentType<any>;
  };
  planProductName: string;
  title?: string;
  /**
   * check this document https://csscompany.atlassian.net/wiki/spaces/CNENG/pages/2094825952/Appendix#product_name-%2F-product_type-table
   */
  trackingProductName: string;
  sectionTitle?: string;
  planSelectorShown?: boolean;
}

const baasPage: PageDefinition = {
  slug: 'BAAS',
  hero: {
    CustomComponent: () => <RemoteContent contentKey={['iap', 'BAAS']} slot="headerImages" />,
  },
  detail: {
    CustomComponent: () => <RemoteContent contentKey={['iap', 'BAAS']} slot="detailImages" />,
  },
  planProductName: 'BAAS',
  title: '外卖监控',
  trackingProductName: 'STOREMONITOR',
};

const vasBundleOrderPage: PageDefinition = {
  slug: 'vasbundleorder',
  hero: {
    CustomComponent: () => <RemoteContent contentKey={['iap', 'vasbundleorder']} slot="headerImages" />,
  },
  detail: {
    CustomComponent: () => <RemoteContent contentKey={['iap', 'vasbundleorder']} slot="detailImages" />,
  },
  planProductName: 'vasbundleorder',
  title: '接单大师',
  trackingProductName: getTrackingName('vasbundleorder'),
};

const vasBundleOperatePage: PageDefinition = {
  slug: 'vasbundleoperate',
  hero: {
    CustomComponent: () => <RemoteContent contentKey={['iap', 'vasbundleoperate']} slot="headerImages" />,
  },
  detail: {
    CustomComponent: () => <RemoteContent contentKey={['iap', 'vasbundleoperate']} slot="detailImages" />,
  },
  planProductName: 'vasbundleoperate',
  title: '运营大师',
  trackingProductName: getTrackingName('vasbundleoperate'),
};

export interface OfoAuthSetting {
  meituanAuthType: 'meituan' | 'meituan-admin';
  disableCancel?: boolean;
  nextUrl?: string;
  title?: string;
  hide?: string;
}

export const pages: Array<PageDefinition> = [
  vasBundleOrderPage,
  vasBundleOperatePage,
  {
    slug: 'ordermissing',
    hero: {
      CustomComponent: () => <RemoteContent contentKey={['iap', 'ordermissing']} slot="headerImages" />,
    },
    detail: {
      CustomComponent: () => <RemoteContent contentKey={['iap', 'ordermissing']} slot="detailImages" />,
    },
    planProductName: 'ordermissing',
    trackingProductName: getTrackingName('ordermissing'),
    title: '漏单保护',
  },
  {
    slug: 'orderprep', // '一键出餐'
    hero: {
      background: '#E95058',
      bannerPic: orderPrepBanner,
    },
    detail: {
      imageUrl: orderPrepDetail,
    },
    planProductName: 'orderprep',
    title: '一键出餐',
    trackingProductName: 'ONE_CLICK_BATCHPREP',
  },
  baasPage,
  cpcPage,
  {
    ...baasPage,
    slug: 'smart_monitoring',
  },
  {
    ...baasPage,
    slug: 'storemonitor',
  },
  {
    slug: 'smart_promotion', // 智能满减
    hero: {
      background: '#FCC73A',
      bannerPic: promotionBanner,
    },
    detail: {
      imageUrl: promotionDetail,
    },
    planProductName: 'smartsale',
    trackingProductName: 'SMART_PROMOTION',
  },
  {
    slug: 'qrco', // 扫码点餐
    sectionTitle: '选择您的购买方案',
    hero: {
      CustomComponent: () => <RemoteContent contentKey={['iap', 'qrco']} slot="headerImages" />,
    },
    detail: {
      CustomComponent: () => <RemoteContent contentKey={['iap', 'qrco']} slot="detailImages" />,
    },
    planProductName: 'qrco',
    title: '扫码点餐 ',
    trackingProductName: 'QRCO',
  },
  {
    title: '收银点餐系统',
    slug: 'ordercashier',
    sectionTitle: '选择您的购买方案',
    planProductName: 'ordercashier',
    trackingProductName: 'ORDERCASHIER',
    hero: {
      CustomComponent: () => <RemoteContent contentKey={['iap', 'ordercashier']} slot="headerImages" />,
    },
    detail: {
      CustomComponent: () => <RemoteContent contentKey={['iap', 'ordercashier']} slot="detailImages" />,
    },
  },
  {
    title: '发券宝',
    slug: 'xcd',
    planProductName: 'xcd',
    trackingProductName: 'XCD',
    hero: {
      CustomComponent: () => <RemoteContent contentKey={['iap', 'xcd']} slot="headerImages" />,
    },
    detail: {
      CustomComponent: () => (
        <>
          <RemoteContent contentKey={['iap', 'xcd']} slot="detailImages" />
          <VideoContainer videoLink="https://static.csschina.com/xcd/1028_XCD_P0_750x1146_compressed.mp4#t=0.01" />
        </>
      ),
    },
  },
  {
    title: '菜品打印排序',
    slug: 'printsegment',
    planProductName: 'printsegment',
    trackingProductName: 'PRINTER_SECTION_TICKET',
    hero: {
      CustomComponent: () => <RemoteContent contentKey={['iap', 'printsegment']} slot="headerImages" />,
    },
    detail: {
      CustomComponent: () => <RemoteContent contentKey={['iap', 'printsegment']} slot="detailImages" />,
    },
  },
  {
    title: '评价回复',
    slug: 'reviewmgmt',
    planProductName: 'reviewmgmt',
    trackingProductName: 'COMMENT_HANDLING',
    hero: {
      CustomComponent: () => <RemoteContent contentKey={['iap', 'reviewmgmt']} slot="headerImages" />,
    },
    detail: {
      CustomComponent: () => <RemoteContent contentKey={['iap', 'reviewmgmt']} slot="detailImages" />,
    },
  },
  {
    isPlaceholder: true,
    title: '小票高级定制',
    slug: 'printtemplate',
    planProductName: 'printtemplate',
    trackingProductName: 'TBD',
    hero: {
      background: '#fff',
      bannerPic: printerTemplateBanner,
    },
    detail: {
      CustomComponent: () => null,
    },
  },
  {
    title: '云打印机4G数据',
    slug: 'printer-4g',
    planProductName: 'printersim',
    trackingProductName: 'PRINTER_SIM',
    hero: {
      CustomComponent: Printer4GPlan,
    },
    detail: {},
    planSelectorShown: false,
  },
  buildPageDefinition('vasbundleprotection', '守护宝'),
  {
    title: '品牌正餐',
    slug: 'advance-template-branded-dinner',
    trackingProductName: 'ADVANCE_TEMPLATE_BRANDED_DINNER',
  },
  {
    slug: 'advance-template-tea',
    trackingProductName: 'ADVANCE_TEMPLATE_TEA',
    title: '连锁茶饮',
  },
  {
    slug: 'advance-template-spicy-hot-pot',
    trackingProductName: 'ADVANCE_TEMPLATE_SPICY_HOT_POT',
    title: '麻辣烫',
  },
].map((page) => ({
  ...page,
  defaultCallbackUrl: `${window.location.origin}/iap/${page.slug}/callback`,
}));

/***
 * @deprecated use getCallbackByProduct instead.
 */
export function getCallbackUrlByProduct(productName: string): string {
  return `${window.location.origin}/iap/${productName}/callback`;
}

function buildPageDefinition(productName: string, title: string, trackingName?: string) {
  return {
    slug: productName,
    hero: {
      CustomComponent: () => <RemoteContent contentKey={['iap', productName]} slot="headerImages" />,
    },
    detail: {
      CustomComponent: () => <RemoteContent contentKey={['iap', productName]} slot="detailImages" />,
    },
    planProductName: productName,
    title: title,
    trackingProductName: trackingName ?? productName.toUpperCase(),
  };
}
