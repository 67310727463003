import * as React from 'react';

function SvgCreditCard(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M29.347 26.667H6.611c-.767 0-1.277-.604-1.277-1.294l.042-14.745c0-.777.596-1.294 1.277-1.294H29.39c.767 0 1.278.603 1.278 1.294l-.043 14.745c0 .69-.596 1.294-1.277 1.294z"
        fill="#1F2B3D"
      />
      <path d="M30.667 12.957H5.333v3.624h25.334v-3.624z" fill="#B5B5B5" />
      <path
        d="M25.37 22.667H2.63c-.778 0-1.296-.605-1.296-1.297V6.716c0-.778.604-1.383 1.296-1.383h22.74c.778 0 1.297.605 1.297 1.297v14.654c0 .778-.519 1.383-1.297 1.383z"
        fill="#DBDBDB"
      />
      <path
        d="M19.969 10.251c0-.517.258-.949.604-1.294-.346-.259-.69-.431-1.122-.431a1.73 1.73 0 00-1.725 1.725 1.73 1.73 0 001.725 1.726c.431 0 .863-.173 1.122-.431-.432-.346-.604-.777-.604-1.295z"
        fill="#fff"
      />
      <path
        d="M21.694 8.526c-.431 0-.862.172-1.121.431.345.345.604.777.604 1.294 0 .518-.26.95-.604 1.295.345.258.69.43 1.121.43a1.73 1.73 0 001.726-1.725 1.73 1.73 0 00-1.726-1.725z"
        fill="#1F2B3D"
      />
      <path
        d="M21.177 10.252c0-.518-.26-.95-.604-1.294-.345.345-.604.776-.604 1.294 0 .517.259.949.604 1.294.345-.345.604-.777.604-1.294z"
        fill="#B5B5B5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.44 16.667h19.262V16H4.44v.667zm0 2.666h8.886v-.666H4.439v.666z"
        fill="#B5B5B5"
      />
      <path
        d="M8.235 11.804H5.302a.68.68 0 01-.69-.69V9.302a.68.68 0 01.69-.69h2.933a.68.68 0 01.69.69V11.2c0 .345-.345.604-.69.604z"
        fill="#fff"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgCreditCard);
export default ForwardRef;
