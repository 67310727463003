import * as React from 'react';

function SvgCircleCheckmark(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 22 22"
      fill="currentcolor"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <g filter="url(#circle-checkmark_svg__filter0_d_3012_87319)">
        <circle cx={11} cy={10} r={10} />
      </g>
      <path
        d="M10.695 12.409l-.415.424-.708-.736.412-.421.711.733zm-1.407-1.451l-.714.73-1.988-2.05.711-.732 1.99 2.052zm2.107.736l-.71-.733 4.021-4.107.708.736-4.019 4.104z"
        fill="#FCFCFC"
        stroke="#FCFCFC"
      />
      <defs>
        <filter
          id="circle-checkmark_svg__filter0_d_3012_87319"
          x={0}
          y={0}
          width={22}
          height={22}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dy={1} />
          <feGaussianBlur stdDeviation={0.5} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.02 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_3012_87319" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow_3012_87319" result="shape" />
        </filter>
      </defs>
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgCircleCheckmark);
export default ForwardRef;
