export type ElementType =
  | 'elementsAndComponents'
  | 'elementAndComponentDropDowns'
  | 'popOvers'
  | 'secondaryNavigation'
  | 'headerFooter'
  | 'bannerNotifications'
  | 'primaryNavigation'
  | 'fullScreenFeatures'
  | 'specialCases'
  | 'modalsDialogWindows'
  | 'notifications';

const elementTypeOrder: Record<ElementType, number> = {
  elementsAndComponents: 0,
  elementAndComponentDropDowns: 1,
  secondaryNavigation: 2,
  headerFooter: 3,
  bannerNotifications: 4,
  primaryNavigation: 4,
  fullScreenFeatures: 6,
  specialCases: 7,
  // Popovers need to have the same z-index as modals/dialogs.
  // If both are portalled to the document.body, then popovers will need
  // the same z-index in order to be able to be rendered inside a modal,
  // otherwise the modal will always be on top of popover.
  popOvers: 8,
  modalsDialogWindows: 8,
  notifications: 9,
};
/**
 * inspired by this article:  https://medium.com/science-journal/z-index-organization-in-css-5913fd4c25c9
 * @param {ElementType} elementType the type of UI element you are generating a z-index for.
 * @param major increments in multiples of 100. Clamped at 9.
 * @param minor increments in multiples of 10. Clamped at 9.
 * @param single increments in multiples of 1. Clamped at 9
 */
export const zIndex = (
  elementType: ElementType,
  major: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 = 0,
  minor: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 = 0,
  single: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 = 0
): number => {
  return elementTypeOrder[elementType] * 1000 + major * 100 + minor * 10 + single;
};
