import * as React from 'react';

function SvgDry(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 22 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M11 0a1 1 0 00-1 1v.272C4.932 1.775.906 6.054.832 11.243c-.001.11.055.25.132.328.178.18.502.171.67-.018.562-.63 1.288-.977 2.045-.977.798 0 1.554.381 2.127 1.074a.408.408 0 00.627 0c.573-.693 1.329-1.074 2.127-1.074.51 0 1.002.157 1.44.451V20.5a.5.5 0 01-1 0V20a1 1 0 10-2 0v.5a2.5 2.5 0 005 0v-9.472a2.57 2.57 0 011.441-.452c.798 0 1.554.381 2.128 1.074a.408.408 0 00.626 0c.574-.693 1.33-1.074 2.128-1.074.756 0 1.482.347 2.044.976.17.19.493.198.67.018a.51.51 0 00.132-.328c-.073-5.189-4.1-9.468-9.169-9.97V1a1 1 0 00-1-1z"
        fill="#707070"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgDry);
export default ForwardRef;
