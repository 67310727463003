import * as React from 'react';

function SvgFudieStreetView(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 9.333a3.333 3.333 0 100-6.666 3.333 3.333 0 000 6.666zm-3.627 2.22a1 1 0 00-.373.78V16h4.667C15.4 16 16 15.4 16 14.667V10.68a6.032 6.032 0 00-2-.347c-1.353 0-2.62.467-3.627 1.22zM10.16 4a4.31 4.31 0 00.78 5.06l-6.547 6.547a1.33 1.33 0 01-.393-.94V5.333C4 4.6 4.6 4 5.333 4h4.827z"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgFudieStreetView);
export default ForwardRef;
