import qs from 'qs';

export function getUtmTags() {
  const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true }) as Record<string, string>;
  const result: Record<string, string> = {};

  for (const key in queryParams) {
    if (key.startsWith('utm_')) {
      result[key] = queryParams[key];
    }
  }

  return result;
}
