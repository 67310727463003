import * as React from 'react';

function SvgCloudKitchen(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <mask id="cloud-kitchen_svg__a" fill="#fff">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.692 21.954a2.308 2.308 0 01.923-4.523v-.046a2.77 2.77 0 012.846-2.769 5.54 5.54 0 0110.4-1.59A4.615 4.615 0 1122.384 22H6.691v-.045z"
        />
      </mask>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.692 21.954a2.308 2.308 0 01.923-4.523v-.046a2.77 2.77 0 012.846-2.769 5.54 5.54 0 0110.4-1.59A4.615 4.615 0 1122.384 22H6.691v-.045z"
        fill="currentColor"
      />
      <path
        d="M6.692 21.954h1v-.818l-.801-.162-.2.98zm.923-4.523l-.199.98 1.22.248-.02-1.244-1 .016zm2.846-2.815l-.027 1 .87.024.143-.858-.986-.166zm10.4-1.59l-.892.455.397.778.824-.288-.33-.944zM6.69 22h-1v1h1v-1zm.2-1.026a1.308 1.308 0 01-1.045-1.282h-2a3.309 3.309 0 002.647 3.242l.398-1.96zm-1.045-1.282c0-.722.585-1.307 1.307-1.307v-2a3.308 3.308 0 00-3.307 3.307h2zm1.307-1.307c.091 0 .18.009.263.026l.398-1.96a3.315 3.315 0 00-.66-.066v2zm1.462-.97v-.03h-2v.062l2-.032zm0-.03c0-.978.792-1.77 1.77-1.77v-2a3.77 3.77 0 00-3.77 3.77h2zm1.77-1.77l.049.001.054-2h-.104v2zm1.062-.833A4.54 4.54 0 0115.923 11V9a6.54 6.54 0 00-6.449 5.451l1.973.33zM15.923 11a4.538 4.538 0 014.046 2.48l1.782-.907A6.538 6.538 0 0015.923 9v2zm5.267 2.97a3.61 3.61 0 011.194-.2v-2c-.648 0-1.272.11-1.854.313l.66 1.888zm1.194-.2A3.615 3.615 0 0126 17.384h2a5.615 5.615 0 00-5.616-5.616v2zM26 17.384A3.615 3.615 0 0122.384 21v2A5.615 5.615 0 0028 17.385h-2zM22.384 21H7.154v2h15.23v-2zm-15.23 0h-.462v2h.461v-2zm.538 1v-.046h-2V22h2z"
        fill="currentColor"
        mask="url(#cloud-kitchen_svg__a)"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgCloudKitchen);
export default ForwardRef;
