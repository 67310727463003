import * as React from 'react';

function SvgCullinanMenu(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path d="M10.027 5l-7-2v12l7 2 7-2V3l-7 2zm6 9.246l-6 1.727V6.035l3-.855c1.012-.288 2.024-.575 3-.854v9.92zm-1-6.302l-4 1.056v-.944l4-1.056v.944zm-4 3.056l4-1.056V9l-4 1.056V11zm4 .944l-4 1.056v-.944l4-1.056v.944z" />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgCullinanMenu);
export default ForwardRef;
