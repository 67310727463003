import { button } from '@/cva/button';
import { cva, cx } from 'class-variance-authority';
import React from 'react';

export interface AlertProps {
  title: React.ReactNode;
  description?: React.ReactNode;
  confirmButton?: React.ReactNode;
  cancelButton?: React.ReactNode;
}

export function AlertContent({ title, description, confirmButton, cancelButton }: AlertProps) {
  return (
    <div className="bg-white rounded-[16px] overflow-hidden m-4 flex flex-col gap-[8px] pt-[24px] pb-[4px] w-full z-20">
      {title}
      {description}
      <div className="flex flex-row justify-between px-[16px] py-[16px] w-full gap-[10px] h-[72px]">
        {cancelButton}
        {confirmButton}
      </div>
    </div>
  );
}

export const defaultAlertTitle = cva('text-center text-2xl font-bold mx-[20px]');

export function DefaultAlertDescription({ description }: { description: string }) {
  return <div className="text-center text-lg">{description}</div>;
}

export function DefaultAlertButton({
  title,
  style = 'primary',
  onClick,
}: {
  title: string;
  style: 'primary' | 'outlineSecondary';
  onClick: () => void;
}) {
  return (
    <button
      className={button({ intent: style, size: 'full', className: 'flex items-center justify-center py-[15px]' })}
      onClick={onClick}
    >
      <span className="text-lg text-center">{title}</span>
    </button>
  );
}
