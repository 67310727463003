import * as React from 'react';

function SvgCullinanConnections(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path fillRule="evenodd" clipRule="evenodd" d="M13 17v-6h2v6h-2z" />
      <path fillRule="evenodd" clipRule="evenodd" d="M11 13h6v2h-6v-2z" />
      <path d="M3 3h6v6H3V3zM3 11h6v6H3v-6zM11 3h6v6h-6V3z" />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgCullinanConnections);
export default ForwardRef;
