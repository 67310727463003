import { Carousel } from '../FeatureUnlockedPage/carousel';
import { Agreements } from '@/common/components/agreements';
import { getCallbackByProduct, getTrackingName, ProductSlug } from '@/product-registry';
import { useParams } from 'react-router';
import { PrintTestAndKnowMoreButton } from '../FeatureUnlockedPage/components';
import useSearchParams from '@/hooks/useSearchParams';
import { getFeatureBySlug } from '../FeatureUnlockedPage/features';
import { FireButton } from '@/features/upsell/components/fire-button';
import { LaunchPaymentButton } from '@/next/components/launch-payment-button';
import { RenewButton } from '@/features/payment/components/renew-button';
import { cssTracking } from '@/tracking/css-tracking';
import { useEffect, useMemo } from 'react';
import { trackEvent } from '@/mixpanel';
import { UpsellPayMode, useUpsellPayMode } from '@/bnm-common/hooks/useUpsellPayMode';
import {
  GetRemoteContentByCategory,
  HideForUpsellNewVersion,
  RemotePositiveButtonText,
  useRemoteContent,
} from '@/features/cms/components/remote-content';

function commonDescription(isSignContract: boolean) {
  return (
    <p>
      {isSignContract ? (
        '外卖运营爆单工具，5大功能助力外卖运营增收提效。'
      ) : (
        <>
          <div>【双11限时福利】</div>
          <div>外卖运营爆单工具，5大功能助力外卖运营增收提效。</div>
        </>
      )}
    </p>
  );
}

export default function VasBundleOperateUpsellPage() {
  const { productName } = useParams<{ productName: ProductSlug }>();

  const feature = getFeatureBySlug(productName);

  const { storeId, couponId, callbackUrl: callbackUrlFromQS } = useSearchParams();
  useEffect(() => {
    trackEvent('upselling_landing_page_loaded', {
      page_name: 'UPSELLING_PAGE',
      // TODO: 需要在这里处理支持多个产品的逻辑
      product_list: [getTrackingName(productName)],
    });

    cssTracking('page_loaded', {
      url: window.location.href,
    });
  }, []);

  const { payMode } = useUpsellPayMode();
  const isSignContract = useMemo(() => payMode === 'sign-contract', [payMode]);
  const remoteContent = useRemoteContent<GetRemoteContentByCategory<'upsell'>>({
    variables: { category: 'upsell', productName },
  });
  const { isFreeSign, isNewVersion } = useMemo(() => {
    const buttonContent = remoteContent.data?.positiveButton?.[0] ?? '免费试用7天';
    const isNew = remoteContent.data?.isNewVersion[0] === 'yes';
    return { isFreeSign: buttonContent.includes('免费试用'), isNewVersion: isNew };
  }, [remoteContent]);

  if (!feature) {
    return null;
  }
  const searchParams = new URLSearchParams({
    paymentType: 'plan',
    paymentTypeValue: feature.purchasePlan,
    entityId: storeId,
    entityType: 'STORE',
    productName: getTrackingName(productName),
  });
  const callbackUrl =
    callbackUrlFromQS ??
    getCallbackByProduct({
      authorizedOfos: {},
      storeId: '',
    })(productName);
  searchParams.set('callbackUrl', callbackUrl);
  if (couponId) {
    searchParams.set('couponCode', couponId);
  }

  return (
    <section className="h-screen mx-auto grid grid-rows-footer-page pb-safe">
      <HideForUpsellNewVersion productName={'vasbundleoperate'}>
        <div className="max-h-screen overflow-auto p-4">
          <h1 className="font-bold text-2xl">
            <span className="text-primary">运营大师</span>
            服务特权已激活
          </h1>
          <h1 className="font-bold text-2xl ">
            <span className="text-primary">{isSignContract ? '0元畅享7天' : '1元试用全部功能7天'}</span> 原价<s>￥99</s>
          </h1>
          {commonDescription(isSignContract)}
          <div className="bg-neutral-100 flex flex-col justify-center rounded-lg pb-2">
            <Carousel />
            <FireButton productName={productName} />
          </div>
        </div>
      </HideForUpsellNewVersion>
      {isNewVersion && (
        <div className="flex flex-col pl-4 pr-4">
          <Carousel />
        </div>
      )}
      <footer className="p-4 text-md border-t border-neutral-400 w-full bg-white flex flex-col gap-2 ">
        <Agreements />
        <HideForUpsellNewVersion productName={'vasbundleoperate'}>
          <div>
            {isSignContract ? (
              <RenewButton
                productName={productName}
                planName={'vas.bundle.operate.recurring.p1m.store.chn.v0'}
                children="免费试用7天"
              />
            ) : (
              <LaunchPaymentButton planName={feature.purchasePlan} couponCode={couponId}>
                <span className="font-bold text-2xl">{'确认协议并开通'}</span>
              </LaunchPaymentButton>
            )}
          </div>
        </HideForUpsellNewVersion>
        {isNewVersion && (
          <div>
            {isFreeSign ? (
              <RenewButton
                productName={productName}
                planName={'vas.bundle.operate.recurring.p1m.store.chn.v0'}
                children={<RemotePositiveButtonText fallback="免费试用7天" productName="vasbundleoperate" />}
              />
            ) : (
              <LaunchPaymentButton planName={feature.purchasePlan} couponCode={couponId}>
                <span className="font-bold text-2xl">
                  <RemotePositiveButtonText fallback="确认协议并开通" productName="vasbundleoperate" />
                </span>
              </LaunchPaymentButton>
            )}
          </div>
        )}
        <PrintTestAndKnowMoreButton />
      </footer>
    </section>
  );
}
