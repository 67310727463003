import * as React from 'react';

function SvgLightning(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path d="M11 2l-6 9h4v7l6-9h-4V2z" fill="currentColor" />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgLightning);
export default ForwardRef;
