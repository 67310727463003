export function secondsToHours(timeInSeconds: number) {
  const hours = timeInSeconds / 3600;

  // Check if the hours value is an integer
  if (Number.isInteger(hours)) {
    return hours;
  } else {
    return parseFloat(hours.toFixed(1));
  }
}
