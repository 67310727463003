import * as React from 'react';

function SvgMaximize(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M4.286 19.286H0V30h10.714v-4.286H4.286v-6.428zM0 10.714h4.286V4.286h6.428V0H0v10.714zm25.714 15h-6.428V30H30V19.286h-4.286v6.428zM19.286 0v4.286h6.428v6.428H30V0H19.286z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgMaximize);
export default ForwardRef;
