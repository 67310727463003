import styled from 'styled-components';
import cardBg from '../../assets/printer-4g-card.png';

export const Container = styled.div`
  padding: 16px;
`;
export const CardOuter = styled.div`
  position: relative;
  padding: 1px;
  border-radius: 12px;
  background: radial-gradient(
    132% 279.12% at -3.35% -2%,
    #f8f8f8 0%,
    rgba(173, 173, 173, 0.697917) 58.54%,
    rgba(201, 201, 201, 0.171522) 84.9%,
    #ffffff 100%
  );
  box-shadow: 0px 10px 26px rgba(0, 0, 0, 0.1);
`;
export const CardShadow = styled.div`
  position: absolute;
  top: 0;
  right: 42px;
  bottom: 1px;
  left: 42px;
  z-index: 0;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.15);
`;
export const Card = styled.div`
  position: relative;
  display: flex;
  gap: 32px;
  padding: 20px 16px;
  border-radius: 11px;
  background: radial-gradient(155.28% 250.82% at 12.97% 19.25%, #ffffff 0%, #ededed 67.19%, #f3f3f3 100%);
  z-index: 1;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: url(${cardBg});
    background-size: 100% 100%;
    pointer-events: none;
    z-index: 0;
  }
`;
export const CardLeft = styled.div`
  position: relative;
  flex-grow: 1;
  z-index: 1;
`;
export const CardRight = styled.div`
  position: relative;
  display: flex;
  align-items: flex-end;
  flex-grow: 0;
  flex-shrink: 0;
  z-index: 1;
`;
export const Title = styled.div`
  margin: 12px 0 16px;
  color: #525252;
  font-weight: 500;
  font-size: 22px;
`;
export const Subtitle = styled.div`
  color: #000000;
  font-weight: 400;
  font-size: 10px;
`;
export const CurrencySymbol = styled.div`
  margin-right: 10px;
  color: #e06e4d;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
`;
export const Price = styled.div`
  color: #e06e4d;
  font-weight: 600;
  font-size: 48px;
  line-height: 40px;
`;
export const TagOuter = styled.div`
  position: absolute;
  background: radial-gradient(85.95% 2013.44% at 11.16% 34%, #cecece 0%, #ffffff 100%);
  padding: 1px;
  top: 0;
  right: 0;
  z-index: 2;
  border-bottom-left-radius: 12px;
  border-top-right-radius: 12px;
`;
export const Tag = styled.div`
  background: radial-gradient(94.58% 1042.48% at -2.41% 8%, #e6e6e6 0%, #cecece 41.15%, #eaeaea 100%);
  padding: 6px 16px;
  border-bottom-left-radius: 11px;
  border-top-right-radius: 11px;

  div {
    background: radial-gradient(114.29% 726.79% at 10.81% 0%, #8a8a8a 0%, #525252 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
  }
`;
export const HintTitle = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: #525252;
  margin-bottom: 8px;
`;
export const HintImage = styled.img`
  max-width: 100%;
`;
