import * as React from 'react';

function SvgRotateLeft(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path d="M11.297 14a4 4 0 10-4-4h2l-3 4-3-4h2a6 6 0 116 6v-2z" />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgRotateLeft);
export default ForwardRef;
