import * as React from 'react';

function SvgPointOfSales(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <rect width={24} height={24} rx={6} fill="#E03975" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.6 13.8l-.001-.96v-1.68c0-2.016 0-3.024.392-3.794a3.6 3.6 0 011.573-1.573C6.334 5.4 7.342 5.4 9.36 5.4h5.28c2.016 0 3.024 0 3.794.393a3.6 3.6 0 011.573 1.573c.393.77.393 1.778.393 3.794v1.68c0 .35 0 .668-.002.96H3.6zm.09 1.8h16.617c-.057.41-.15.74-.3 1.035a3.6 3.6 0 01-1.574 1.573c-.77.393-1.778.393-3.794.393h-5.28c-2.017 0-3.025 0-3.795-.393a3.6 3.6 0 01-1.573-1.573c-.15-.296-.243-.626-.3-1.035z"
        fill="#fff"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgPointOfSales);
export default ForwardRef;
