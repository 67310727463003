import * as React from 'react';

function SvgDoneAll(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.24 5.59L11.66 16.17 7.48 12l-1.41 1.41L11.66 19l12-12-1.42-1.41zM18 7l-1.41-1.41-6.34 6.34 1.41 1.41L18 7zM6 19L.41 13.41 1.83 12l5.58 5.59L6 19z"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgDoneAll);
export default ForwardRef;
