import { queryClient } from '@/query-client';

import {
  isCouponAppliableToProduct,
  isUpsellCoupon,
  useCouponsNg,
} from '@/features/coupon/hooks/use-redeemable-coupons';
import { routeUpsellByCoupon } from './helper';

export async function buildUpsellCouponRule(productName: string) {
  const coupons = await queryClient.fetchQuery({
    queryKey: useCouponsNg.getKey({
      status: 'REDEEMABLE',
    }),
    queryFn: useCouponsNg.queryFn,
  });

  const upsellCoupon = coupons.find(
    (coupon) => isUpsellCoupon(coupon) && isCouponAppliableToProduct(coupon, productName)
  );
  return {
    checker: function () {
      return typeof upsellCoupon === 'undefined';
    },
    otherwise: function () {
      if (typeof upsellCoupon === 'undefined') {
        throw new Error('upsell coupon not found');
      }
      window.location.replace(routeUpsellByCoupon(upsellCoupon, productName));
    },
  };
}
