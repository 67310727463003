import {
  buildClaimCouponRule,
  buildEntitlementRule,
  buildFinalRule,
  buildOfoCheckRule,
  buildUpsellCouponRule,
  ruleExecutor,
} from '../rules';

export async function vasbundleorder() {
  const productName = 'vasbundleorder';
  const rules = [
    buildClaimCouponRule(productName),
    buildUpsellCouponRule(productName),
    buildOfoCheckRule((ofo) => Boolean(ofo.eleme || ofo.meituan), productName),
    buildEntitlementRule(productName, 'vas.bundle.order', 'STORE'),
    buildFinalRule(() => window.location.replace('/bundle/vasbundleorder')),
  ];

  await ruleExecutor(rules);
}
