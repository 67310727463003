import * as React from 'react';

function SvgAppIconBrands(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.258 11.506a2.49 2.49 0 10-2.491-4.314 2.49 2.49 0 002.49 4.314zm-.5-.866a1.49 1.49 0 10-1.491-2.582 1.49 1.49 0 001.49 2.582z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 10c0 .183-.024.36-.07.528a1.988 1.988 0 01-.924 2.246l-12.05 6.957a1.988 1.988 0 01-2.714-.728L.267 12.118a1.988 1.988 0 01.727-2.715L2 8.823V2a2 2 0 012-2h14a2 2 0 012 2v8zM3 8.245l10.043-5.799a1.987 1.987 0 012.715.728L19 8.789V2a1 1 0 00-1-1H4a1 1 0 00-1 1v6.245zm-1.867 3.373a.988.988 0 01.361-1.35l12.05-6.956a.988.988 0 011.348.362l3.975 6.885a.988.988 0 01-.361 1.349l-12.05 6.957a.988.988 0 01-1.348-.362l-3.975-6.885z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgAppIconBrands);
export default ForwardRef;
