import * as React from 'react';

function SvgCullinanCheckSemiActive(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path fillRule="evenodd" clipRule="evenodd" d="M15 11H5V9h10v2z" fill="currentColor" />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgCullinanCheckSemiActive);
export default ForwardRef;
