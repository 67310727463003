import { useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';

import { spawnHttpClient } from '@/http-clients';

type SubscriptionAccess = Array<{
  id: string;
  startTime: string;
  endTime: string;
  nextRenewalTime: string;
  entity: {
    id: string;
  };
  feature: string;
  product: string;
  status: string;
}>;

type XcdSubscriptionsResponse = {
  data: {
    lutrahubProductAccess: {
      productAccess: SubscriptionAccess;
    };
  };
  errors: { message: string }[];
};

const useXCDSubscriptionsQuery = (storeId: string): UseQueryResult<SubscriptionAccess> => {
  return useQuery<XcdSubscriptionsResponse, unknown, SubscriptionAccess, string[]>(
    ['xcd-subscriptions-data'],
    async function () {
      return await spawnHttpClient({})
        .get<XcdSubscriptionsResponse>({
          path: `/graphql`,
          method: 'POST',
          requestParams: {
            data: {
              query: XcdSubscriptions,
              variables: {
                input: {
                  storeId,
                  // product: 'xcd',
                },
              },
            },
          },
        })
        .then((res) => {
          if (res.errors?.length > 0) {
            throw new Error(res.errors[0].message);
          }
          return res;
        });
    },
    {
      enabled: !!storeId,
      staleTime: 0,
      select: (res) => res?.data?.lutrahubProductAccess.productAccess,
    }
  );
};

const useXCDSubscriptions = (storeId: string) => {
  const { data, isLoading } = useXCDSubscriptionsQuery(storeId);
  if (!data)
    return {
      isLoading,
      ecdData: undefined,
      mcdData: undefined,
    };
  const ecdData = data.find((o) => o.feature === 'ecd');
  const mcdData = data.find((o) => o.feature === 'mcd');
  return {
    isLoading,
    ecdData,
    mcdData,
  };
};
export default useXCDSubscriptions;

const XcdSubscriptions = `query subscriptions($input: QueryLutrahubProductAccessInput) {
    lutrahubProductAccess(input: $input) {
      productAccess {
        id
        product
        feature
        status
        startTime
        endTime
        nextRenewalTime
        entity {
          ... on Organization {
            id
            name
        }
        ... on Brand {
            id
            name
            organizations {
              edges {
                node {
                  id
                  name
              }
            }
          }
        }
        ... on Store {
            id
            facilityV2 {
              name
              address {
                addressOne
            }
          }
          brand {
              id
              name
          }
          organization {
              id
              name
          }
        }
      }
    }
  }
}`;
