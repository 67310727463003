import { destr } from 'destr';
import { Message, logLevels } from 'roarr';
import SlsTracker from '@aliyun-sls/web-track-browser/dist/web-track-browser.es';

type RoarrBuiltinContext = {
  logLevel: number;
};

type ApplicationContext = {
  application: 'lutrahub-pages';
  mode: 'staging' | 'production' | 'development';
};

const aliyunTracker = new SlsTracker({
  host: 'cn-shanghai.log.aliyuncs.com',
  project: 'android-mobile',
  logstore: 'mobile-logstore',
  time: 10,
  count: 10,
  topic: 'lutrahub-pages',
  source: 'web',
});

export const aliyunTransport = (message: string) => {
  const payload = destr<Message<RoarrBuiltinContext & ApplicationContext>>(message);

  if (payload.context.logLevel >= logLevels.warn) {
    aliyunTracker.send(payload);
  }
};
