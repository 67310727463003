import { urqlClient } from '@/http-clients';
import { gql } from 'graphql-request';
import { PURCHASE_POLLING_LIMIT } from '@/constant';
import delay from 'delay';
import retry, { AbortError } from 'p-retry';
import { createQuery } from 'react-query-kit';

const query = gql`
  query purchaseResult($recordId: ID!) {
    paymentCentralPaymentRecordById(input: { recordId: $recordId }) {
      state
    }
  }
`;

interface PurchasePollingResult {
  state: string;
  result: PurchaseResult;
}

export const usePurchaseResult = createQuery<PurchasePollingResult, { recordId: string }, Error>({
  primaryKey: 'purchase-result-polling',
  queryFn: async function ({ queryKey: [, { recordId }] }) {
    return pollPurchaseResult(recordId);
  },
  retry: 1,
  enabled(_data, variables) {
    return variables.recordId.length > 1;
  },
});

async function pollPurchaseResult(recordId: string): Promise<PurchasePollingResult> {
  const run = async () => {
    const serverSaid = await urqlClient
      .query<{
        paymentCentralPaymentRecordById: {
          state: string;
        };
      }>(
        query,
        { recordId },
        {
          requestPolicy: 'network-only',
        }
      )
      .toPromise();

    const DELAY_DURATION = import.meta.env.MODE === 'integration' ? 5000 : 1000;
    await delay(DELAY_DURATION);

    const state = serverSaid.data?.paymentCentralPaymentRecordById?.state ?? '';
    const result = triagePaymentResult(state);
    if (result === 'success') {
      return {
        state,
        result,
      };
    } else if (serverSaid.error || result === 'failed') {
      console.log(serverSaid);
      throw new AbortError(serverSaid.error ?? '支付失败');
    } else {
      return Promise.reject(new Error('keep trying'));
    }
  };

  return await retry(run, { retries: PURCHASE_POLLING_LIMIT });
}

type PurchaseResult = 'success' | 'failed' | 'pending' | 'unknown';
function triagePaymentResult(state: string): PurchaseResult {
  switch (state) {
    case 'PAYMENT_STATE_PAID':
      return 'success';
    case 'PAYMENT_STATE_CANCELED':
    case 'PAYMENT_STATE_CANCEL_FAILED':
    case 'PAYMENT_STATE_CANCEL_IN_PROGRESS':
    case 'PAYMENT_STATE_FAILED':
    case 'PAYMENT_STATE_INCOMPLETE':
      return 'failed';
    case 'PAYMENT_STATE_IN_PROGRESS':
    case 'PAYMENT_STATE_OPEN':
      return 'pending';
    default:
      return 'unknown';
  }
}
