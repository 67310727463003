import * as React from 'react';

function SvgCloudKitchens(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <rect width={24} height={24} rx={6} fill="#2B403A" />
      <path
        d="M20.612 7.19h-1.777l-4.143 4.343V7.19h-1.414v9.462h1.414v-3.216l1.364-1.452 3.23 4.668H21l-3.98-5.682 3.592-3.78zM7.606 8.314c1.377 0 2.49.8 2.654 1.902h1.476c-.262-1.84-1.965-3.141-4.117-3.141C5.215 7.075 3 8.665 3 11.919c0 3.241 2.19 4.843 4.619 4.843 2.19 0 3.88-1.301 4.08-3.154h-1.414c-.15 1.102-1.277 1.903-2.679 1.903-1.84 0-3.167-1.252-3.167-3.592.013-2.341 1.327-3.605 3.167-3.605z"
        fill="#F8F6EB"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgCloudKitchens);
export default ForwardRef;
