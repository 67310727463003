import * as React from 'react';

function SvgCullinanOrders(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 20 20"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      ref={svgRef}
      {...props}
    >
      <path fillRule="evenodd" clipRule="evenodd" d="M4 14V6h2V2h8v4h2v8H4zM8 4h4v2H8V4z" />
      <path d="M4 16h12v2H4v-2z" />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgCullinanOrders);
export default ForwardRef;
