import * as React from 'react';

function SvgCirclePlus(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="1em" height="1em" ref={svgRef} {...props}>
      <path
        fill="currentColor"
        d="M14.57.28l1.22.33 1.17.46 1.1.57 1.03.68.95.77.87.87.77.95.68 1.03.57 1.1.46 1.17.33 1.22.21 1.26L24 12l-.07 1.31-.21 1.26-.33 1.22-.46 1.17-.57 1.1-.68 1.03-.77.95-.87.87-.95.77-1.03.68-1.1.57-1.17.46-1.22.33-1.26.21L12 24l-1.31-.07-1.26-.21-1.22-.33-1.17-.46-1.1-.57-1.03-.68-.95-.77-.87-.87-.77-.95-.68-1.03-.57-1.1-.46-1.17-.33-1.22-.21-1.26L0 12l.07-1.31.21-1.26.33-1.22.46-1.17.57-1.1.68-1.03.77-.95.87-.87.95-.77 1.03-.68 1.1-.57L8.21.61 9.43.28l1.26-.21L12 0l1.31.07 1.26.21zm-2.94 11.35H6v.75h5.63V18h.75v-5.62H18v-.75h-5.62V6h-.75v5.63z"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgCirclePlus);
export default ForwardRef;
