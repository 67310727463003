/**
 * this file should be in sync with https://gitlab.csschina.com/css/baas/blob/master/src/mobileRouter/hooks/useOfoAuth.ts
 */
import { useMemo, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';

import { spawnGraphqlClient } from '../http-clients';
import { queryStoreLink } from '../query/storeLinks';
import { fetchVasStoreId } from './use-vas-store-id';

export const OFO_NAMES = { meituan: '美团', eleme: '饿了么' };
export type Ofo = keyof typeof OFO_NAMES;
export enum MeituanOfoType {
  meituan = 'meituan' /* 美团接单 */,
  meituanAdmin = 'meituan-admin' /* 美团非接单 */,
}

const JUDGE_AUTHORIZED_KEYWORDS = [
  'CONNECTION_STATE_ACTIVE',
  'CONNECTION_STATE_INACTIVE',
  'CONNECTION_STATE_TEMPORARILY_DISABLED',
];

function getElementJudgeAccountTypes() {
  return [
    /** 饿了么smb授权 */
    'eleme',
    /** 饿了么ka授权 */
    'eleme-branch',
    'eleme-ka',
  ];
}

function getMeituanJudgeAccountTypes(meituanOfoType: MeituanOfoType) {
  // 非接单授权判断meituan-admin，接单授权判断meituan
  return [meituanOfoType];
}

function getElementJudgeConnectionType() {
  // 饿了么统一判断CONNECTION_TYPE_INSIGHTS
  return 'CONNECTION_TYPE_INSIGHTS';
}

function getMeituanJudgeConnectionType(meituanOfoType: MeituanOfoType) {
  // 美团非接单判断CONNECTION_TYPE_INSIGHTS，接单判断CONNECTION_TYPE_ORDER_SOURCE
  return meituanOfoType === MeituanOfoType.meituan ? 'CONNECTION_TYPE_ORDER_SOURCE' : 'CONNECTION_TYPE_INSIGHTS';
}

export interface StoreOfoAuthorizationProps {
  storeId?: string;
  meituanOfoType?: MeituanOfoType;
}

export interface StoreOfoAuthorization {
  ofo: Ofo;
  isAuthorized: boolean;
  loading: boolean;
}

export interface StoreOfoAuthorization {
  ofo: Ofo;
  isAuthorized: boolean;
  loading: boolean;
}

/***
 * @deprecated please switch to useStoreLinks in use-store-links.ts
 */
export const useStoreOfoAuthorizations = ({
  meituanOfoType = MeituanOfoType.meituanAdmin,
}: StoreOfoAuthorizationProps | undefined = {}): {
  ofoAuthorizations: StoreOfoAuthorization[];
  loading: boolean;
  error?: boolean;
} => {
  const client = spawnGraphqlClient();

  const {
    isLoading: loading,
    isError: error,
    data,
  } = useQuery(
    ['ofo-authorization'],

    async function () {
      const storeId = await fetchVasStoreId();
      if (!storeId) {
        return;
      }

      console.log('call');
      const res = await client.request(queryStoreLink, {
        storeId,
      });
      return res;
    },
    {
      refetchOnWindowFocus: true,
    }
  );

  const storeLinks = useMemo(() => {
    return (data?.storeById?.storeLinksV2?.edges ?? []) as any[];
  }, [data]);

  const ofoAuthorizations = useMemo<StoreOfoAuthorization[]>(
    () =>
      Object.keys(OFO_NAMES).map((ofo) => {
        const isAuthorized = !!storeLinks.find((storeLink) => {
          const isValidOfoSlug = ofo === storeLink?.node?.ofoSlug;
          console.log('store', storeLink, isValidOfoSlug);
          if (!isValidOfoSlug) return false;

          const judgeAccountConnection = storeLink?.node?.accountConnectionsV2?.edges?.find((edge: any) => {
            const accountType =
              ofo === 'meituan' ? getMeituanJudgeConnectionType(meituanOfoType) : getElementJudgeConnectionType();
            return edge?.node?.type === accountType;
          });
          const isValidAccountConnection = (type: string) => {
            const accountTypes =
              ofo === 'meituan' ? getMeituanJudgeAccountTypes(meituanOfoType) : getElementJudgeAccountTypes();
            console.log('account types', accountTypes, type);
            return accountTypes.includes(type);
          };
          console.log('connection', judgeAccountConnection, judgeAccountConnection?.node?.connectionStatus);

          const isAuthorizedConnectionStatus = (status: string) => JUDGE_AUTHORIZED_KEYWORDS.includes(status);
          return (
            isValidAccountConnection(judgeAccountConnection?.node?.account?.accountType?.slug) &&
            isAuthorizedConnectionStatus(judgeAccountConnection?.node?.connectionStatus ?? '')
          );
        });
        console.log('is auth', isAuthorized);
        return {
          ofo: ofo as Ofo,
          isAuthorized,
          loading,
        };
      }),
    [storeLinks, loading, meituanOfoType]
  );

  useEffect(() => {
    console.log('ofoAuthorizations', ofoAuthorizations);
  }, [ofoAuthorizations]);

  return {
    loading,
    error,
    ofoAuthorizations,
  };
};
