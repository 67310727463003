import React, { FC } from 'react';

const MeituanIcon: FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_773_71206)">
        <circle cx="20" cy="20" r="20" fill="white" />
        <circle cx="20" cy="20" r="19.5" stroke="black" strokeOpacity="0.12" />
        <path
          d="M29.7386 29.0086H29.738C29.7181 29.0134 28.5578 29.2716 27.8097 27.8828C27.0552 26.481 27.0897 24.0332 27.0897 24.0332C27.0897 24.0332 27.1059 22.4605 27.8097 21.2517C28.4996 20.0666 29.7984 20.3107 29.8491 20.3204H29.8496L29.8507 20.3215C34.001 24.7683 29.7386 29.0086 29.7386 29.0086Z"
          fill="#060203"
        />
        <path
          d="M27.8099 27.8827C27.0554 26.481 27.0899 24.0331 27.0899 24.0331C27.0899 24.0331 27.106 22.4605 27.8099 21.2517C28.4997 20.0666 29.7985 20.3107 29.8492 20.3204C29.8136 20.2816 29.7764 20.2423 29.7387 20.2035C28.787 19.2189 27.6595 18.4493 26.4787 17.8478L28.2981 11.2928V11.2917L28.3639 11.0573C28.4447 10.7317 28.4878 10.3911 28.4878 10.0403C28.4878 7.71694 26.6043 5.83337 24.2809 5.83337C22.2066 5.83337 20.4831 7.33376 20.1371 9.30841L18.9692 15.7918C18.6324 15.7589 18.3397 15.7368 18.1015 15.7223L17.1304 9.58381C16.8059 7.45987 14.9719 5.83337 12.7575 5.83337C10.314 5.83337 8.33337 7.81395 8.33337 10.2575C8.33337 10.6498 8.38457 11.0298 8.47996 11.3919L8.48212 11.3995L8.56565 11.6765V11.6786L9.65484 15.289C10.9062 19.443 11.6386 23.7356 11.8359 28.0697L11.9809 31.2575C11.9809 31.2575 12.4643 32.9465 15.8245 34.4976C18.0724 35.5356 19.9501 35.3734 20.9476 35.1519C21.1724 35.1023 21.3885 35.0214 21.59 34.9142C21.7911 34.8064 21.9781 34.6711 22.1446 34.5121C22.3575 34.309 22.4615 34.0163 22.4249 33.7242L22.3241 32.9201C27.0198 32.4507 29.6967 29.0619 29.7382 29.0091C29.7182 29.0134 28.5579 29.2716 27.8099 27.8827ZM17.1336 22.6508C17.1336 21.3293 17.7916 20.2574 18.6038 20.2574C19.416 20.2574 20.074 21.3293 20.074 22.6508C20.074 23.9722 19.4155 25.0447 18.6038 25.0447C17.7922 25.0447 17.1336 23.9728 17.1336 22.6508ZM23.3599 29.7997C23.3297 29.8164 22.7967 30.1026 22.0023 30.1026C21.7183 30.1026 21.4009 30.066 21.0614 29.9673C19.5884 29.5389 18.7089 28.1533 18.6723 28.0945C18.5462 27.8924 18.6076 27.6267 18.8097 27.5006C19.0113 27.3745 19.2769 27.4354 19.4031 27.637C19.4149 27.6553 20.1538 28.8054 21.3023 29.1395C22.2357 29.4111 22.9185 29.0576 22.9471 29.0425C23.1556 28.9283 23.4165 29.0048 23.5313 29.2134C23.6455 29.4219 23.5685 29.6849 23.3599 29.7997Z"
          fill="#F4C91C"
        />
        <path
          d="M20.0741 22.6506C20.0741 23.9726 19.4155 25.0445 18.6039 25.0445C17.7922 25.0445 17.1337 23.9726 17.1337 22.6506C17.1337 21.3286 17.7917 20.2572 18.6039 20.2572C19.416 20.2572 20.0741 21.3291 20.0741 22.6506Z"
          fill="#231815"
        />
        <path
          d="M29.8512 20.3211C29.8512 20.3211 29.8505 20.3211 29.8497 20.3203C29.8505 20.3211 29.8512 20.3211 29.8512 20.3211Z"
          fill="#060203"
        />
        <path
          d="M23.3598 29.7997C23.3297 29.8164 22.7966 30.1026 22.0023 30.1026C21.7182 30.1026 21.4008 30.0659 21.0613 29.9673C19.5884 29.5389 18.7088 28.1533 18.6722 28.0945C18.5461 27.8924 18.6075 27.6267 18.8096 27.5006C19.0112 27.3745 19.2769 27.4354 19.403 27.637C19.4148 27.6553 20.1537 28.8054 21.3022 29.1395C22.2356 29.4111 22.9184 29.0576 22.947 29.0425C23.1556 28.9282 23.4164 29.0048 23.5312 29.2133C23.6455 29.4219 23.5684 29.6849 23.3598 29.7997Z"
          fill="#231815"
        />
        <path
          d="M29.7386 29.0086H29.738C29.7181 29.0134 28.5578 29.2716 27.8097 27.8828C27.0552 26.481 27.0897 24.0332 27.0897 24.0332C27.0897 24.0332 27.1059 22.4605 27.8097 21.2517C28.4996 20.0666 29.7984 20.3107 29.8491 20.3204H29.8496L29.8507 20.3215C34.001 24.7683 29.7386 29.0086 29.7386 29.0086Z"
          fill="#060203"
        />
        <path
          d="M27.8099 27.8827C27.0554 26.481 27.0899 24.0331 27.0899 24.0331C27.0899 24.0331 27.106 22.4605 27.8099 21.2517C28.4997 20.0666 29.7985 20.3107 29.8492 20.3204C29.8136 20.2816 29.7764 20.2423 29.7387 20.2035C28.787 19.2189 27.6595 18.4493 26.4787 17.8478L28.2981 11.2928V11.2917L28.3639 11.0573C28.4447 10.7317 28.4878 10.3911 28.4878 10.0403C28.4878 7.71694 26.6043 5.83337 24.2809 5.83337C22.2066 5.83337 20.4831 7.33376 20.1371 9.30841L18.9692 15.7918C18.6324 15.7589 18.3397 15.7368 18.1015 15.7223L17.1304 9.58381C16.8059 7.45987 14.9719 5.83337 12.7575 5.83337C10.314 5.83337 8.33337 7.81395 8.33337 10.2575C8.33337 10.6498 8.38457 11.0298 8.47996 11.3919L8.48212 11.3995L8.56565 11.6765V11.6786L9.65484 15.289C10.9062 19.443 11.6386 23.7356 11.8359 28.0697L11.9809 31.2575C11.9809 31.2575 12.4643 32.9465 15.8245 34.4976C18.0724 35.5356 19.9501 35.3734 20.9476 35.1519C21.1724 35.1023 21.3885 35.0214 21.59 34.9142C21.7911 34.8064 21.9781 34.6711 22.1446 34.5121C22.3575 34.309 22.4615 34.0163 22.4249 33.7242L22.3241 32.9201C27.0198 32.4507 29.6967 29.0619 29.7382 29.0091C29.7182 29.0134 28.5579 29.2716 27.8099 27.8827ZM17.1336 22.6508C17.1336 21.3293 17.7916 20.2574 18.6038 20.2574C19.416 20.2574 20.074 21.3293 20.074 22.6508C20.074 23.9722 19.4155 25.0447 18.6038 25.0447C17.7922 25.0447 17.1336 23.9728 17.1336 22.6508ZM23.3599 29.7997C23.3297 29.8164 22.7967 30.1026 22.0023 30.1026C21.7183 30.1026 21.4009 30.066 21.0614 29.9673C19.5884 29.5389 18.7089 28.1533 18.6723 28.0945C18.5462 27.8924 18.6076 27.6267 18.8097 27.5006C19.0113 27.3745 19.2769 27.4354 19.4031 27.637C19.4149 27.6553 20.1538 28.8054 21.3023 29.1395C22.2357 29.4111 22.9185 29.0576 22.9471 29.0425C23.1556 28.9283 23.4165 29.0048 23.5313 29.2134C23.6455 29.4219 23.5685 29.6849 23.3599 29.7997Z"
          fill="#F4C91C"
        />
        <path
          d="M20.0741 22.6506C20.0741 23.9726 19.4155 25.0445 18.6039 25.0445C17.7922 25.0445 17.1337 23.9726 17.1337 22.6506C17.1337 21.3286 17.7917 20.2572 18.6039 20.2572C19.416 20.2572 20.0741 21.3291 20.0741 22.6506Z"
          fill="#231815"
        />
        <path
          d="M29.8512 20.3211C29.8512 20.3211 29.8505 20.3211 29.8497 20.3203C29.8505 20.3211 29.8512 20.3211 29.8512 20.3211Z"
          fill="#060203"
        />
        <path
          d="M23.3598 29.7997C23.3297 29.8164 22.7966 30.1026 22.0023 30.1026C21.7182 30.1026 21.4008 30.0659 21.0613 29.9673C19.5884 29.5389 18.7088 28.1533 18.6722 28.0945C18.5461 27.8924 18.6075 27.6267 18.8096 27.5006C19.0112 27.3745 19.2769 27.4354 19.403 27.637C19.4148 27.6553 20.1537 28.8054 21.3022 29.1395C22.2356 29.4111 22.9184 29.0576 22.947 29.0425C23.1556 28.9282 23.4164 29.0048 23.5312 29.2133C23.6455 29.4219 23.5684 29.6849 23.3598 29.7997Z"
          fill="#231815"
        />
      </g>
      <defs>
        <clipPath id="clip0_773_71206">
          <rect width="40" height="40" rx="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default MeituanIcon;
