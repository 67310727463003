import { fetchVasStoreId } from '@/hooks/use-vas-store-id';
import { urqlClient } from '@/http-clients';
import { gql } from '@urql/core';
import { createQuery } from 'react-query-kit';

const query = gql`
  query GetVasBundleUsageStats($storeId: String!, $from: Datetime!, $to: Datetime!) {
    getVasBundleUsageStats(input: { storeIds: [$storeId], timeRange: { from: $from, to: $to } }) {
      stats {
        storeId
        details {
          featureName
          count
        }
      }
    }
  }
`;

/***
 * @description 用于统计 fromTime 到 toTime 之间，接单大师的使用次数。当前只用了 自动出餐（一键备餐）的使用次数
 */
export const useStats = createQuery<number, { fromTime: string; toTime: string }, Error>({
  primaryKey: 'vas-bundle-stats',
  queryFn: async ({ queryKey }) => {
    const [, { fromTime, toTime }] = queryKey;

    const storeId = await fetchVasStoreId();

    const res = await urqlClient
      .query<{
        getVasBundleUsageStats: {
          stats: [
            {
              details: [
                {
                  featureName: string;
                  count: number;
                }
              ];
            }
          ];
        };
      }>(query, { storeId, from: fromTime, to: toTime })
      .toPromise();

    const target =
      res.data?.getVasBundleUsageStats.stats[0].details.find((detail) => detail.featureName === '自动出餐')?.count ?? 0;

    return target;
  },
});
