import * as React from 'react';

function SvgAnalytics(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <rect width={24} height={24} rx={6} fill="#1C69E8" />
      <rect x={9.955} y={10.056} width={4.091} height={9.068} rx={2.045} fill="#fff" />
      <rect x={15.41} y={4.875} width={4.091} height={14.25} rx={2.045} fill="#fff" />
      <rect x={4.5} y={15.239} width={4.091} height={3.886} rx={1.943} fill="#fff" />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgAnalytics);
export default ForwardRef;
