import styled, { css } from 'styled-components';

import { Intent } from '../../types';

export const Label = styled.label<{ intent?: Intent }>`
  font-weight: 600;
  font-size: ${(props) => props.theme.fontSizes.label};
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: ${(props) => props.theme.colors.grey};
  display: block;

  ${(props) =>
    props.intent === 'error' &&
    css`
      color: ${(props) => props.theme.colors.red};
    `}
`;
