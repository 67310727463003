import * as React from 'react';

function SvgRepeat(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 4C5.792 4 4 5.793 4 8h2l-2.667 2.667L.666 8h2a5.332 5.332 0 015.333-5.333c1.047 0 2.02.306 2.84.826l-.973.974A3.914 3.914 0 007.999 4zm2 4l2.666-2.667L15.333 8h-2a5.332 5.332 0 01-5.334 5.333 5.287 5.287 0 01-2.84-.826l.974-.974c.553.3 1.193.467 1.866.467 2.207 0 4-1.793 4-4h-2z"
        fill="#1F2B3D"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgRepeat);
export default ForwardRef;
