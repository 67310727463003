import { JSBBase } from './base';
import type { JSBIBase } from './base';
import debugFactory from 'debug';

const debug = debugFactory('jsbi:v1');

type PaymentMethod =
  | 'PAYMENT_METHOD_TYPE_ALIPAY_MINIAPP'
  | 'PAYMENT_METHOD_TYPE_ALI_PAY'
  | 'PAYMENT_METHOD_TYPE_WECHAT_PAY'
  | 'PAYMENT_METHOD_TYPE_FREE_PASS';

type V1Methods = {
  getUserToken(): Promise<string | undefined>;
  getFacilityId(): Promise<string | undefined>;
  getVasStoreId(): Promise<string | undefined>;
  getNotificationPermission(): Promise<boolean | undefined>;
  getUserId(): Promise<string | undefined>;
  downloadBase64Image(base64: string): Promise<void> | undefined;
  launchPayment(
    paymentMethodType: PaymentMethod,
    payload: string,
    isRecurring?: boolean,
    paymentRecordId?: string
  ): void;
  downloadImageFromUrl(url: string): Promise<void> | undefined;
  hideWebViewControl(control: string[]): Promise<void> | undefined;
};

export type JSBI = JSBIBase<V1Methods>;

declare global {
  interface Window {
    __jsbi__: JSBI;
  }
}

export class JSBClient extends JSBBase implements V1Methods {
  public bridgeVersion: '1' = '1';
  protected jsbi: JSBI = window.__jsbi__;

  constructor() {
    super({ bridgeVersion: '1' });
  }

  getUserToken() {
    return window.__jsbi__?.getUserToken();
  }

  getFacilityId() {
    return window.__jsbi__?.getFacilityId();
  }

  getVasStoreId() {
    return window.__jsbi__?.getVasStoreId();
  }

  getNotificationPermission() {
    return window.__jsbi__?.getNotificationPermission();
  }

  getUserId() {
    return window.__jsbi__?.getUserId();
  }

  downloadImageFromUrl(url: string) {
    return window.__jsbi__?.downloadImageFromUrl(url);
  }

  downloadBase64Image(base64: string) {
    return window.__jsbi__?.downloadBase64Image(base64);
  }

  launchPayment(paymentMethodType: PaymentMethod, payload: string, isRecurring?: boolean, paymentRecordId?: string) {
    debug('calling launchPayment with %s, %s, %s', paymentMethodType, payload, isRecurring, paymentRecordId);
    return window.__jsbi__?.launchPayment(paymentMethodType, payload, isRecurring, paymentRecordId);
  }

  hideWebViewControl(control: string[]): Promise<void> | undefined {
    debug('calling hideWebViewControl with %j', control);
    return window.__jsbi__?.hideWebViewControl(control);
  }
}
