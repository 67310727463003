import React, { useReducer } from 'react';

import { DisplayPlan, EnrichedFlatValueCoupon } from './types';
import { useCoupons } from '@/features/coupon/hooks/use-redeemable-coupons';
import { findBestCouponForPlan } from './utils';

interface IapState {
  planToCouponMapping: Record<string, EnrichedFlatValueCoupon>;
  plans?: Array<DisplayPlan>;
  selectedPlan?: DisplayPlan;
  selectedCoupon?: EnrichedFlatValueCoupon;
  addedCouponCode?: string;
  paymentButtonDisabled: boolean;
  additionalSearchParams: Record<string, string>;
}

export interface IapMethods {
  setPlans: (plans: Array<DisplayPlan>) => void;
  setSelectedPlan: (plan: DisplayPlan) => void;
  setSelectedCoupon: (coupon: EnrichedFlatValueCoupon | undefined) => void;
  setAddedCouponCode: (couponCode: string) => void;
  setPaymentButtonDisabled: (disabled: boolean) => void;
  setAdditionalSearchParams: (params: Record<string, string>) => void;

  selectPlan: (planName: string) => void;
}

type IapContext = IapState & IapMethods;

/**
 * @deprecated please switch to zustand
 */
export const IapContext = React.createContext<IapContext>({
  planToCouponMapping: {},
  setSelectedPlan: () => {},
  setSelectedCoupon: () => {},
  setPlans: () => {},
  setAddedCouponCode: () => {},
  paymentButtonDisabled: false,
  setPaymentButtonDisabled: () => {},
  additionalSearchParams: {},
  setAdditionalSearchParams: () => {},
  selectPlan: () => {},
});

type IapAction<TType, TPayload> = { type: TType; payload: TPayload };

type IapReducerActions =
  | IapAction<'setAddedCouponCode', string>
  | IapAction<'setAdditionalSearchParams', Record<string, string>>
  | IapAction<'setPaymentButtonDisabled', boolean>
  | IapAction<'setPlans', Array<DisplayPlan>>
  | IapAction<'setSelectedCoupon', EnrichedFlatValueCoupon>
  | IapAction<'setSelectedPlan', DisplayPlan>
  | IapAction<
      'updatePlanToCouponMapping',
      {
        planName: string;
        coupon: EnrichedFlatValueCoupon;
      }
    >;

function reducer(state: IapState, action: IapReducerActions) {
  const { type, payload } = action;

  if (type === 'setAddedCouponCode') {
    return {
      ...state,
      addedCouponCode: payload,
    };
  }

  if (type === 'setAdditionalSearchParams') {
    return {
      ...state,
      additionalSearchParams: payload,
    };
  }

  if (type === 'setPaymentButtonDisabled') {
    return {
      ...state,
      paymentButtonDisabled: payload,
    };
  }

  if (type === 'setPlans') {
    return {
      ...state,
      plans: payload,
    };
  }

  if (type === 'setSelectedCoupon') {
    return {
      ...state,
      selectedCoupon: payload,
    };
  }

  if (type === 'setSelectedPlan') {
    return {
      ...state,
      selectedPlan: payload,
    };
  }

  if (type === 'updatePlanToCouponMapping') {
    return {
      ...state,
      planToCouponMapping: {
        ...state.planToCouponMapping,
        [String(payload.planName)]: payload.coupon,
      },
    };
  }

  return state;
}

const initialState: IapState = {
  planToCouponMapping: {},
  paymentButtonDisabled: false,
  additionalSearchParams: {},
};

export function IapProvider({ children }: { children: React.ReactNode }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  /**
   * @deprecated use selectPlan instead
   */
  function setSelectedPlan(plan: DisplayPlan) {
    return dispatch({ type: 'setSelectedPlan', payload: plan });
  }

  const { data: coupons } = useCoupons('REDEEMABLE');

  function setSelectedCoupon(coupon?: EnrichedFlatValueCoupon) {
    if (typeof coupon !== 'undefined') {
      dispatch({ type: 'setSelectedCoupon', payload: coupon });
      const { applicablePlans } = coupon;
      if (applicablePlans.length === 1) {
        dispatch({
          type: 'updatePlanToCouponMapping',
          payload: {
            planName: applicablePlans[0],
            coupon,
          },
        });
      }
    }
  }

  function selectPlan(planName: string) {
    const plan = state.plans?.find((plan) => plan.name === planName);

    if (plan) {
      const userSelectedCoupon = state.planToCouponMapping[planName];
      dispatch({ type: 'setSelectedPlan', payload: plan });
      if (!userSelectedCoupon) {
        setSelectedCoupon(findBestCouponForPlan(plan, coupons));
      }
    }
  }

  return (
    <IapContext.Provider
      value={{
        ...state,
        setAddedCouponCode: (couponCode: string) => dispatch({ type: 'setAddedCouponCode', payload: couponCode }),
        setAdditionalSearchParams: (params: Record<string, string>) =>
          dispatch({ type: 'setAdditionalSearchParams', payload: params }),
        setPaymentButtonDisabled: (disabled: boolean) =>
          dispatch({ type: 'setPaymentButtonDisabled', payload: disabled }),
        setPlans: (plans: Array<DisplayPlan>) => dispatch({ type: 'setPlans', payload: plans }),
        setSelectedCoupon,
        setSelectedPlan,
        selectPlan,
      }}
    >
      {children}
    </IapContext.Provider>
  );
}
