import { ReactComponent as CheckedIcon } from '@/assets/checked.svg';
import { ReactComponent as UncheckedIcon } from '@/assets/unchecked.svg';
import { ReactComponent as AdvancedBadge } from '@/assets/advanced-badge.svg';
import { useSelectedTemplateId, useChooseTemplate, useSingleTemplate } from '@/pages/PrinterTemplate/useTemplate';
import { cva } from 'class-variance-authority';
import { usePrintQueues } from '@/pages/PrinterTemplate/use-print-queue';
import { useCallback, useEffect, useState } from 'react';
import { queryClient } from '@/query-client';
import { Popup } from '@/common/components/popup';
import { AlertContent, DefaultAlertButton, defaultAlertTitle } from '@/common/components/alert-content';
import toast from 'react-hot-toast';
import { useBundleStatus } from '@/bundle/components/use-bundle';
import { isVersionBefore } from '@/utils/cmpVersions';
import UpgradeModal from '@/common/components/UpgradeModal';
import { useHistory, useLocation, useParams } from 'react-router';
import { trackEvent } from '@/mixpanel';
import { useClaimEventCouponMutation } from '@/pages/PrinterTemplate/useClaimCoupon';
import { useAdLandingURL } from '@/pages/PrinterTemplate/useAd';
import { SuccessEventType } from '@/pages/PrinterTemplate/types';
import { Experiment, useExperiment } from '@/features/experiment/components/experiment';
import { useEntitlement } from '@/hooks/use-entitlement';
import { usePlanSelector } from '@/store/plan-slice';
import { usePlan, useProduct } from '@/iap/use-product';
import { useCoupons } from '@/features/coupon/hooks/use-redeemable-coupons';
import { moneyToNumber } from '@css/currency';
import {
  templateNameToFeatureMap,
  templateNameToProductNameMap,
} from '@/pages/PrinterTemplate/advanced-template-iap-map';

interface PrintTemplateCardProps {
  templateId: string;
  sectionLayout: 'vertical' | 'horizontal' | 'horizontal-height';
}

const templateCard = cva('bg-white h-full flex flex-col');
const templateAvatar = cva('block mx-auto z-10 drop-shadow-md top-4 absolute left-1/2 -translate-x-1/2', {
  variants: {
    sectionLayout: {
      vertical: 'max-w-[200px]',
      horizontal: 'max-w-[150px]',
      'horizontal-height': 'max-w-[150px]',
    },
  },
});
function templateBannerWithId({ templateId }: { templateId: string }) {
  return `https://otter-public.oss-cn-shanghai.aliyuncs.com/print-template/${templateId}.png`;
}

export function PrintTemplateCard({ templateId, sectionLayout = 'vertical' }: PrintTemplateCardProps) {
  const advancedTemplateExperimentQuery = useExperiment({ variables: { name: 'sales_plan_advance_template' } });
  const advancedTemplateVariant = advancedTemplateExperimentQuery.data?.variant ?? 'default';

  const history = useHistory();
  const location = useLocation();
  const selectedTemplateQuery = useSelectedTemplateId({ suspense: true });
  const selectedTemplateId = selectedTemplateQuery.data;
  const templateQuery = useSingleTemplate({ variables: { templateId } });
  const bundleStatusQuery = useBundleStatus({
    variables: {
      productName: 'vasbundleorder',
    },
    suspense: true,
  });
  const vasBundleOrderNotEntitled = bundleStatusQuery.data === 'UNKNOWN' || bundleStatusQuery.data === 'EXPIRED';
  console.log(vasBundleOrderNotEntitled);

  const isOldVersion = isVersionBefore({ iOS: '4.2.9', android: '4.5.0' });

  const [needUpgradeModelShown, setNeedUpgradeModelShown] = useState(false);
  const template = templateQuery.data;

  const productName = templateNameToProductNameMap[template?.templateName ?? ''];
  const featureName = templateNameToFeatureMap[template?.templateName ?? ''];

  const advancedTemplateEntitlementQuery = useEntitlement({
    variables: { feature: featureName, entityType: 'STORE' },
    enabled: typeof featureName !== 'undefined',
  });

  const title = template?.templateName ?? '';
  const isSelected = selectedTemplateId === templateId;

  const printQueuesQuery = usePrintQueues({ suspense: true });
  const printQueueIds = printQueuesQuery.data?.printQueueMappings.map((queue) => queue.queueId) ?? [];
  const havePrinter = printQueueIds.length > 0;
  const [popupContent, setPopupContent] = useState<React.ReactNode>(null);
  const editTemplateDeeplink = `otter://print/template_preview?print_queue_id=${printQueueIds[0]}&template_id=${templateId}`;

  const handleClick = useCallback(() => {
    if (isOldVersion) {
      setNeedUpgradeModelShown(true);
      return;
    }
    if (window.location.pathname === 'bundle/vasbundleorder/setting') {
      return;
    }
    trackEvent('printer_template_click', {
      template_name: template?.templateName,
      template_type: template?.isAdvanced ? 'advanced' : 'normal',
      source: location.pathname,
    });
    if (template?.isAdvanced ?? false) {
      if (advancedTemplateVariant === 'A') {
        // TODO: check entitlement
        // if vasbundleorder is entitled, advacend templates are all entitled
        if (typeof productName === 'undefined') {
          toast.error('未知模板类型');
          return;
        }
        const entitled = advancedTemplateEntitlementQuery.data ?? false;
        if (!entitled) {
          const urlParams = new URLSearchParams();
          urlParams.set('purchase_origin', 'advanced_template_preview');
          history.push({
            ...location,
            pathname: `/printer/advanced-template/iap/${templateId}/${productName}`,
            search: urlParams.toString(),
          });
          return;
        }
      } else {
        if (vasBundleOrderNotEntitled && (template?.isAdvanced ?? false)) {
          if (typeof productName === 'undefined') {
            toast.error('未知模板类型');
            return;
          }
          const urlParams = new URLSearchParams();
          urlParams.set('purchase_origin', 'advanced_template_preview');
          history.push({
            ...location,
            pathname: `/printer/advanced-template/iap/${templateId}/vasbundleorder`,
            search: urlParams.toString(),
          });
          return;
        }
      }
    }

    if (havePrinter) {
      window.location.href = editTemplateDeeplink;
    } else {
      setPopupContent(<BindPrinterPopupContent handleClose={() => setPopupContent(null)} />);
    }
  }, [
    printQueuesQuery.data,
    bundleStatusQuery.data,
    advancedTemplateEntitlementQuery.data,
    advancedTemplateExperimentQuery.data,
  ]);

  const handleSelect = useCallback(() => {
    if (template?.isAdvanced ?? false) {
      if (advancedTemplateVariant === 'A') {
        if (typeof productName === 'undefined') {
          toast.error('未知模板类型');
          return;
        }
        const entitled = advancedTemplateEntitlementQuery.data ?? false;
        if (!entitled) {
          const urlParams = new URLSearchParams();
          urlParams.set('purchase_origin', 'advanced_template_preview');
          history.push({
            ...location,
            pathname: `/printer/advanced-template/iap/${templateId}/${productName}`,
            search: urlParams.toString(),
          });
          return;
        }
      } else {
        if (vasBundleOrderNotEntitled && (template?.isAdvanced ?? false)) {
          const urlParams = new URLSearchParams(window.location.search);
          urlParams.set('utm_source', 'advanced_template');
          urlParams.set('purchase_origin', 'advanced_template');
          history.push({
            ...location,
            pathname: '/landing/vasbundleorder',
            search: urlParams.toString(),
          });
          return;
        }
      }
    }

    if (isOldVersion) {
      setNeedUpgradeModelShown(true);
      return;
    }
    trackEvent('printer_template_select', {
      type: template?.templateName,
      template_type: template?.isAdvanced ? 'advanced' : 'normal',
      source: location.pathname,
    });

    setPopupContent(
      <SwitchTemplatePopupContent
        templateId={templateId}
        handleClose={() => {
          setPopupContent(null);
        }}
      />
    );
  }, [
    printQueuesQuery.data,
    bundleStatusQuery.data,
    advancedTemplateExperimentQuery.data,
    advancedTemplateExperimentQuery.data,
  ]);

  return (
    <section data-testid="print-template-card" className={templateCard()}>
      <div
        className="relative min-h-[131px] overflow-hidden flex-grow bg-[#f5f5f5] shadow-template-card-inner-shadow"
        onClick={handleClick}
      >
        {template?.isAdvanced ? <div className={bgBox({ sectionLayout })}></div> : null}
        <img
          src={templateBannerWithId({ templateId: templateId })}
          alt=""
          className={templateAvatar({ sectionLayout })}
        />

        {sectionLayout === 'vertical' ? (
          <>
            <AdvancedBadge className="absolute right-2 top-2 z-10" />
            <div className="absolute left-1/2 -translate-x-1/2 z-10 bottom-[18px] px-2 py-1 rounded-full bg-slate-200 ">
              <p className="text-[12px] font-normal">点击预览小票</p>
            </div>
          </>
        ) : null}
      </div>

      <div
        className={templateTitleWrapper({
          selected: isSelected,
        })}
      >
        <div className="py-2">
          <CurrentTemplateIndicator templateId={templateId} handleClick={handleSelect} />
        </div>
        <div className="flex-grow self-stretch flex items-center" onClick={handleSelect}>
          <p
            className={templateTitle({
              selected: isSelected,
            })}
          >
            {title}
          </p>
        </div>
        {/* TODO: add abtest for price tag */}
        {sectionLayout === 'vertical' ? (
          <Experiment
            name="sales_plan_advance_template"
            variantMap={{ A: () => <PriceTag templateId={templateId} />, B: () => null, default: () => null }}
          />
        ) : null}
      </div>
      <Popup isOpen={Boolean(popupContent)}>{popupContent}</Popup>
      {needUpgradeModelShown && (
        <UpgradeModal
          androidMin={'4.5.0'}
          iosMin={'4.2.9'}
          onClose={() => {
            setNeedUpgradeModelShown(false);
          }}
          message={'高级小票模版需更新水獭掌柜App后使用'}
        />
      )}
    </section>
  );
}

function PriceTag({ templateId }: { templateId: string }) {
  const useSingleTemplateQuery = useSingleTemplate({ variables: { templateId } });
  const template = useSingleTemplateQuery.data;
  const productName = templateNameToProductNameMap[template?.templateName ?? ''];
  const featureName = templateNameToFeatureMap[template?.templateName ?? ''];
  const entitlementQuery = useEntitlement({
    variables: { feature: featureName, entityType: 'STORE' },
    enabled: typeof featureName !== 'undefined' && template?.isAdvanced,
  });
  const entitled = entitlementQuery.data ?? false;
  const init = usePlanSelector((state) => state.init);
  const couponQuery = useCoupons('REDEEMABLE');
  const productQuery = useProduct(productName);
  const currentPlan = usePlanSelector((state) => state.currentPlan);
  const usePlanQuery = usePlan(productName, currentPlan);
  const plan = usePlanQuery.data;
  useEffect(
    function () {
      if (couponQuery.isSuccess && productQuery.isSuccess) {
        const recommendedPlanName = productQuery.data.find((plan) => plan.isRecommended)?.name;
        init(productQuery.data, couponQuery.data, recommendedPlanName);
      }
    },
    [couponQuery.isSuccess, productQuery.isSuccess]
  );
  if (!template?.isAdvanced || entitled) {
    return null;
  }
  return (
    <p className="text-[14px]">
      <span className="line-through text-gray-500 font-normal">￥{moneyToNumber(plan?.basePrice)}</span> ￥
      {moneyToNumber(plan?.unitPrice)}
    </p>
  );
}

const templateTitleWrapper = cva('px-2 m-2 flex flex-row gap-2 items-center', {
  variants: {
    selected: {
      true: 'rounded-lg bg-print-template-indicator',
    },
  },
});
const templateTitle = cva('text-[14px] font-semibold  text-[#525252]', {
  variants: {
    selected: { true: 'text-black' },
  },
});

const bgBox = cva('absolute z-0 bottom-0 left-1/2 -translate-x-1/2 rounded-t-[56px] min-h-[180px]', {
  variants: {
    sectionLayout: {
      vertical: 'bg-print-template-bgbox w-[282px]',
      horizontal: '',
      'horizontal-height': 'bg-print-template-bgbox w-[282px]',
    },
  },
});

function CurrentTemplateIndicator({
  templateId,
  handleClick,
}: {
  templateId: string;
  handleClick: (templateId: string) => void;
}) {
  const currentTemplateQuery = useSelectedTemplateId();
  const currentTemplate = currentTemplateQuery.data;
  const isSelected = currentTemplate === templateId;

  return (
    <>
      {isSelected ? (
        <CheckedIcon className="flex-shrink-0 cursor-pointer" />
      ) : (
        <UncheckedIcon
          className="flex-shrink-0 cursor-pointer"
          onClick={(e) => {
            e.stopPropagation();
            handleClick(templateId);
          }}
        />
      )}
    </>
  );
}

function BindPrinterPopupContent({ handleClose }: { handleClose: () => void }) {
  return (
    <AlertContent
      title={<div className={defaultAlertTitle()}>需要先绑定打印机才能编辑模板，是否立即绑定？</div>}
      confirmButton={
        <DefaultAlertButton
          title="去绑定"
          style="primary"
          onClick={() => {
            handleClose();
            window.location.href = `otter://printer`;
          }}
        />
      }
      cancelButton={
        <DefaultAlertButton
          title="取消"
          style="outlineSecondary"
          onClick={() => {
            handleClose();
          }}
        />
      }
    />
  );
}

function SwitchTemplatePopupContent({ templateId, handleClose }: { templateId: string; handleClose: () => void }) {
  const { bundleId } = useParams<{ bundleId: string }>();
  const useChooseTemplateMutation = useChooseTemplate({
    onSuccess: () => {
      queryClient.setQueryData(useSelectedTemplateId.getKey(), templateId);
      if (window.location.pathname.includes('bundle/vasbundleorder')) {
        void queryClient.invalidateQueries(['bundle-detail', bundleId]);
        void queryClient.invalidateQueries(['feature-point', 'ADVANCED_TEMPLATE']);
      }
      handleClose();
    },
  });

  return (
    <AlertContent
      title={<div className={defaultAlertTitle()}>确认切换模版？</div>}
      confirmButton={
        <DefaultAlertButton
          title="更换"
          style="primary"
          onClick={async () => {
            console.log('templateId', templateId);
            await toast.promise(useChooseTemplateMutation.mutateAsync({ templateId }), {
              loading: '选择中',
              success: '模板更换成功',
              error: '模板更换失败，请重试',
            });
          }}
        />
      }
      cancelButton={
        <DefaultAlertButton
          title="取消"
          style="outlineSecondary"
          onClick={() => {
            handleClose();
          }}
        />
      }
    />
  );
}
