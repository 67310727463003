import { useEffect, useState } from 'react';
import { Transition } from 'react-transition-group';
import simNumberLocation from '../../assets/sim-number-location.png';
import { Spinner } from '@css/op-components';
import { Button } from '../../common/components/UnbindModal';
import { trackEvent } from '../../mixpanel';
import {
  Container,
  Header,
  Title,
  Hint,
  Input,
  ErrorMessage,
  duration,
  Modal,
  ModalOverlay,
  overlayDefaultStyle,
  overlayTransitionStyles,
  ModalContent,
  loadingDefaultStyle,
  loadingTransitionStyles,
  ModalHeader,
  CloseButton,
  ModalBody,
  ModalTitle,
  Loader,
} from './printer-sim-iccid-validator-ui';
import { Formik, useField, useFormikContext } from 'formik';
import useSearchParams from '@/hooks/useSearchParams';
import { z } from 'zod';
import { checkSimIccid } from '@/hooks/useIsSimIccidValid';
import { useSim } from '@/store/sim-slice';

export function PrinterSimCardNoValidator() {
  const [hintModalShown, setHintModalShown] = useState(false);
  const { simIccid: simcard, queueId } = useSearchParams();

  return (
    <Formik
      validateOnBlur
      validateOnMount
      initialValues={{
        simcard,
        queueId,
      }}
      onSubmit={() => {}}
    >
      {() => (
        <>
          <Container>
            <Header>
              <Title>
                打印机流量卡号<sup>*</sup>
              </Title>
              <Hint
                onClick={() => {
                  setHintModalShown(true);
                  trackEvent('sim_card_location_hint_clicked', { from: 'printer_sim_card_no_validator' });
                }}
              >
                在哪里找到流量卡号
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M6.47003 4L5.53003 4.94L8.58336 8L5.53003 11.06L6.47003 12L10.47 8L6.47003 4Z"
                    fill="#525252"
                  />
                </svg>
              </Hint>
            </Header>
            <IccidInput />
          </Container>
          <Transition in={hintModalShown} timeout={duration} mountOnEnter unmountOnExit appear>
            {(state) => (
              <Modal>
                <ModalOverlay
                  onClick={() => setHintModalShown(false)}
                  style={{
                    ...overlayDefaultStyle,
                    ...overlayTransitionStyles[state],
                  }}
                />
                <ModalContent
                  style={{
                    ...loadingDefaultStyle,
                    ...loadingTransitionStyles[state],
                  }}
                >
                  <ModalHeader>
                    <CloseButton onClick={() => setHintModalShown(false)}>
                      <svg width="24" height="24" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M15.8337 5.34175L14.6587 4.16675L10.0003 8.82508L5.34199 4.16675L4.16699 5.34175L8.82533 10.0001L4.16699 14.6584L5.34199 15.8334L10.0003 11.1751L14.6587 15.8334L15.8337 14.6584L11.1753 10.0001L15.8337 5.34175Z"
                          fill="white"
                        />
                      </svg>
                    </CloseButton>
                    <img src={simNumberLocation} />
                  </ModalHeader>
                  <ModalBody>
                    <ModalTitle>打印机流量卡号位置</ModalTitle>
                    <p>
                      · 在打印机背面找到流量卡槽
                      <br />· 按住流量卡并拆卸出来
                      <br />· 流量卡下方的20位卡号为打印机流量卡号
                    </p>
                    <Button onClick={() => setHintModalShown(false)}>知道了</Button>
                  </ModalBody>
                </ModalContent>
              </Modal>
            )}
          </Transition>
        </>
      )}
    </Formik>
  );
}

function IccidInput() {
  const { queueId } = useSearchParams();

  const { isValidating } = useFormikContext();

  const simMemo = z
    .string()
    .length(20, { message: 'SIM 卡长度为 20' })
    .transform(async (value, ctx) => {
      const validation = await checkSimIccid({ queueId, simIccid: value });
      if (!validation.result) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: validation.reason,
        });
      }

      return value;
    });
  const [field, meta] = useField({
    name: 'simcard',
    validate: async function (value) {
      const result = await simMemo.safeParseAsync(value);
      if (!result.success) {
        return result.error.issues[0].message;
      }
    },
    type: 'string',
    multiple: false,
  });

  useEffect(
    function () {
      if (meta.error) {
        setIsValid(false);
        setSimcard('');
      } else {
        setIsValid(true);
        setSimcard(meta.value as string);
      }
    },
    [meta.error, meta.value]
  );

  const { setSimcard, setIsValid } = useSim();

  return (
    <div className="relative">
      <Input maxLength={20} {...field} hasError={Boolean(meta.error && meta.touched)} />
      {isValidating && (
        <Loader>
          <Spinner size="extra-small" color="gray" />
        </Loader>
      )}
      {meta.error && (
        <ErrorMessage>
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M7.99992 1.33337C4.31992 1.33337 1.33325 4.32004 1.33325 8.00004C1.33325 11.68 4.31992 14.6667 7.99992 14.6667C11.6799 14.6667 14.6666 11.68 14.6666 8.00004C14.6666 4.32004 11.6799 1.33337 7.99992 1.33337ZM8.66658 11.3334H7.33325V10H8.66658V11.3334ZM8.66658 8.66671H7.33325V4.66671H8.66658V8.66671Z"
              fill="#C4232B"
            />
          </svg>
          <div className="ml-2">{meta.error}</div>
        </ErrorMessage>
      )}
    </div>
  );
}

export default PrinterSimCardNoValidator;
