import * as React from 'react';

function SvgCullinanNavCollapse(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path d="M2 16V4h2v12H2zM9 10l4.95 4.95 1.414-1.414L12.828 11H18V9h-5.172l2.536-2.535L13.95 5.05 9 10z" />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgCullinanNavCollapse);
export default ForwardRef;
