import { spawnHttpClient } from '@/http-clients';
import {
  GetPrintLayoutResponse,
  PrintLayout,
  PrintPreviewResponse,
  StoresOfFacilityResponse,
  TemplateListResponse,
  TicketTemplate,
  UpdatePrintLayoutInput,
  VariablesByTemplateID,
} from './types';
import { createMutation, createQuery } from 'react-query-kit';
import { useFacilityId } from '@/hooks/use-facility-id';
import { queryClient } from '@/query-client';
import { usePrintQueues } from './use-print-queue';

function fetchTemplateList({ facilityId }: { facilityId: string }): Promise<TemplateListResponse> {
  return spawnHttpClient().get<TemplateListResponse>({
    path: `/print/templates`,
    method: 'POST',
    requestParams: {
      data: {
        customer: {
          facilityId,
        },
        client: {
          clientId: 'OtterOne/iOS/CN',
          clientVersion: 0,
        },
      },
    },
  });
}

export const useTemplateList = createQuery({
  primaryKey: 'templateList',
  queryFn: async function () {
    const facilityId = await queryClient.fetchQuery({
      queryKey: useFacilityId.getKey(),
      queryFn: useFacilityId.queryFn,
    });
    const templateListRes = await fetchTemplateList({ facilityId });
    const advancedTemplates = templateListRes.advancedTemplates;

    // filter out advanced template from templates,
    // since backend decide to add duplicate advanced template into templates
    // because native app's lastest update for preview deeplink does not work properly.
    const normalTemplates = templateListRes.templates.filter((template) => {
      return !advancedTemplates.some((advanced) => {
        return template.templateId === advanced.templateId;
      });
    });
    // set advanced template isAdvanced = true
    advancedTemplates.forEach((item) => {
      item.isAdvanced = true;
    });
    // query for singleTemplate
    normalTemplates.forEach((item) => {
      queryClient.setQueryData(useSingleTemplate.getKey({ templateId: item.templateId }), item);
    });

    advancedTemplates.forEach((item) => {
      queryClient.setQueryData(useSingleTemplate.getKey({ templateId: item.templateId }), item);
    });

    return {
      templates: normalTemplates,
      advancedTemplates,
    };
  },
});

export const useSingleTemplate = createQuery<TicketTemplate, { templateId: string }, Error>({
  primaryKey: 'singleTemplate',
  queryFn: async function ({ queryKey: [, { templateId }] }) {
    const cachedData = queryClient.getQueryData<TicketTemplate>(['singleTemplate', { templateId }]);
    if (cachedData) {
      return cachedData;
    }
    const res = await queryClient.fetchQuery({
      queryKey: useTemplateList.getKey(),
      queryFn: useTemplateList.queryFn,
    });
    const targetTemplate = res.templates.find((item) => item.templateId === templateId);
    if (!targetTemplate) {
      throw Error('template not found');
    }
    return targetTemplate;
  },
});

function fetchPrintLayout({ storeIds }: { storeIds: string[] }): Promise<GetPrintLayoutResponse> {
  return spawnHttpClient().get<GetPrintLayoutResponse>({
    path: `/print/layout/shared`,
    method: 'POST',
    requestParams: {
      data: {
        storeIds: storeIds,
      },
    },
  });
}

export const usePrintLayout = createQuery<GetPrintLayoutResponse, void, Error>({
  primaryKey: 'printLayout',
  queryFn: async function () {
    const storeIds = await queryClient.fetchQuery({
      queryKey: useStoreIDsOfFacility.getKey(),
      queryFn: useStoreIDsOfFacility.queryFn,
    });
    if (!storeIds) {
      throw Error('store ids not found');
    }
    const currentPrintLayoutRes = await fetchPrintLayout({ storeIds });
    if (!currentPrintLayoutRes) {
      throw Error('fetch shared printLayout failed');
    }
    return currentPrintLayoutRes;
  },
});

export const useSelectedTemplateId = createQuery({
  primaryKey: 'useSelectedTemplateId',
  queryFn: async function () {
    const storeIds = await queryClient.fetchQuery({
      queryKey: useStoreIDsOfFacility.getKey(),
      queryFn: useStoreIDsOfFacility.queryFn,
    });
    if (!storeIds) {
      throw Error('store ids not found');
    }
    const currentPrintLayoutRes = await fetchPrintLayout({ storeIds });
    if (!currentPrintLayoutRes) {
      throw Error('fetch shared printLayout failed');
    }
    return currentPrintLayoutRes.sharedPrintLayout.ptsTemplateId;
  },
  refetchOnWindowFocus: true,
});

export const usePrintlayoutMap = createQuery<VariablesByTemplateID[], { storeIds: string[] }, Error>({
  primaryKey: 'printlayoutMap',
  queryFn: async function () {
    const storeIds = await queryClient.fetchQuery({
      queryKey: useStoreIDsOfFacility.getKey(),
      queryFn: useStoreIDsOfFacility.queryFn,
    });
    const currentPrintLayoutRes = await fetchPrintLayout({ storeIds });
    if (!currentPrintLayoutRes) {
      throw Error('fetch shared printLayout failed');
    }
    return currentPrintLayoutRes.variablesByTemplateId;
  },
  refetchOnWindowFocus: true,
});

function updatePrintLayout({ input }: { input: UpdatePrintLayoutInput }): Promise<Record<string, never>> {
  return spawnHttpClient().get<Record<string, never>>({
    path: '/print/layout',
    method: 'PUT',
    requestParams: {
      data: input,
    },
  });
}

export const useChooseTemplate = createMutation({
  mutationFn: async function ({ templateId }: { templateId: string }) {
    const storeIds = await queryClient.fetchQuery({
      queryKey: useStoreIDsOfFacility.getKey(),
      queryFn: useStoreIDsOfFacility.queryFn,
    });
    if (!storeIds) {
      throw Error('store ids not found');
    }
    const currentPrintLayoutRes = await fetchPrintLayout({ storeIds });
    const currentPrintLayout = currentPrintLayoutRes.sharedPrintLayout;

    const printLayotMap = await queryClient.fetchQuery({
      queryKey: usePrintlayoutMap.getKey({ storeIds }),
      queryFn: usePrintlayoutMap.queryFn,
    });
    const newPrintLayoutVariableConfig = printLayotMap.find((item) => item.ptsTemplateId === templateId);
    const newPrintLayout: PrintLayout = {
      ticketType: currentPrintLayout.ticketType ?? 'DESTINATION_FORMAT_UNSPECIFIED',
      destinationFormat: currentPrintLayout.destinationFormat ?? 'DESTINATION_FORMAT_UNSPECIFIED',
      ptsTemplateId: templateId,
      locale: currentPrintLayout.locale ?? '',
      variables: newPrintLayoutVariableConfig?.variables ?? [],
    };
    const input: UpdatePrintLayoutInput = {
      storeIds: storeIds,
      printLayout: newPrintLayout,
    };
    const result = await updatePrintLayout({ input });

    return result;
  },
});

function fetchStores({ facilityId }: { facilityId: string }): Promise<StoresOfFacilityResponse> {
  return spawnHttpClient().get<StoresOfFacilityResponse>({
    path: `/facilities/${facilityId}/stores`,
    method: 'GET',
    requestParams: {},
  });
}

export const useStoreIDsOfFacility = createQuery<string[], void, Error>({
  primaryKey: 'storeIDsOfFacility',
  queryFn: async function () {
    const facilityId = await queryClient.fetchQuery({
      queryKey: useFacilityId.getKey(),
      queryFn: useFacilityId.queryFn,
    });
    const res = await fetchStores({ facilityId });
    return res.stores.map((store) => store.storeId);
  },
});

function fetchPreviewImage({ printLayout }: { printLayout: PrintLayout }): Promise<PrintPreviewResponse> {
  return spawnHttpClient().get<PrintPreviewResponse>({
    path: `/print/preview`,
    method: 'POST',
    requestParams: {
      data: {
        printLayout,
      },
    },
  });
}

export const usePreviewImage = createQuery<string | undefined, { templateId: string }, Error>({
  primaryKey: 'previewImage',
  queryFn: async function ({ queryKey: [, { templateId }] }) {
    const template = await queryClient.fetchQuery({
      queryKey: useSingleTemplate.getKey({ templateId }),
      queryFn: useSingleTemplate.queryFn,
    });
    if (typeof template === 'undefined') {
      throw Error('template not found');
    }

    const newPrintLayout: PrintLayout = {
      ticketType: 'TICKET_TYPE_CUSTOMER',
      destinationFormat: 'DESTINATION_FORMAT_UNSPECIFIED',
      ptsTemplateId: templateId,
      locale: 'zh_cn',
      variables: [],
    };

    const res = await fetchPreviewImage({ printLayout: newPrintLayout });
    return res.preview[0]?.base64PngImage ?? undefined;
  },
});

function printPreview({
  printLayout,
  queueId,
}: {
  printLayout: PrintLayout;
  queueId: string;
}): Promise<Record<string, never>> {
  return spawnHttpClient().get<Record<string, never>>({
    path: `/print/printpreview`,
    method: 'POST',
    requestParams: {
      data: {
        printLayout,
        queueId,
      },
    },
  });
}

export const usePrintPreviewMutation = createMutation({
  mutationFn: async function ({ templateId }: { templateId: string }) {
    const template = await queryClient.fetchQuery({
      queryKey: useSingleTemplate.getKey({ templateId }),
      queryFn: useSingleTemplate.queryFn,
    });
    if (typeof template === 'undefined') {
      throw Error('template not found');
    }

    const queues = await queryClient.fetchQuery({
      queryKey: usePrintQueues.getKey(),
      queryFn: usePrintQueues.queryFn,
    });
    const queueId = queues.printQueueMappings[0]?.queueId;
    if (typeof queueId === 'undefined') {
      throw Error('queueId not found');
    }

    const newPrintLayout: PrintLayout = {
      ticketType: 'TICKET_TYPE_CUSTOMER',
      destinationFormat: 'DESTINATION_FORMAT_UNSPECIFIED',
      ptsTemplateId: templateId,
      locale: 'zh_cn',
      variables: [],
    };

    await printPreview({ printLayout: newPrintLayout, queueId });
    return;
  },
});
