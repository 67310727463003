import { urqlClient } from '@/http-clients';
import { useGetCoupons } from '@/iap/gql/useGetCoupons';
import { EnrichedFlatValueCoupon } from '@/iap/types';
import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { createQuery } from 'react-query-kit';

import { gql } from 'graphql-request';
import { queryClient } from '@/query-client';
import { useUserId } from '@/hooks/use-user-id';
import type { Coupon, CouponStatus } from '@/api-binding/coupon';

export function useCoupons(status?: CouponStatus, options?: UseQueryOptions) {
  const { data, isSuccess } = useGetCoupons();

  const queryOptions = {
    enabled: isSuccess,
  };

  return useQuery<
    Array<EnrichedFlatValueCoupon>,
    Error,
    Array<EnrichedFlatValueCoupon>,
    ['coupons', 'status', string | undefined]
  >(
    ['coupons', 'status', status],
    function () {
      if (typeof status === 'undefined') {
        return data ?? [];
      }

      return data?.filter((coupon) => coupon.status === status) ?? [];
    },
    options
      ? {
          ...queryOptions,
          ...options,
        }
      : queryOptions
  );
}

const fetchCouponsQuery = gql`
  query fetchCoupons($userId: ID!, $status: CouponStatus) {
    lutrahubCoupons(input: { merchantId: $userId, status: $status, pagination: { first: 50 } }) {
      coupons {
        id
        code
        title
        status
        vases
        discountType
        expiryTime
        amountOff {
          currencyCode
          units
          nanos
        }
        plans {
          name
          productName
          distributorName
          localizedPlanName
          localizedPlanShortName(locale: "zh_CN")
        }
        redemptionLimit
        redeemedTimes
        percentage
        discountType
        tags {
          key
          value
        }
      }
    }
  }
`;

export const useCouponsNg = createQuery<Array<Coupon>, { status: CouponStatus }, Error>({
  primaryKey: 'coupon-ng',
  queryFn: async function ({ queryKey: [, { status }] }) {
    const userId = await queryClient.fetchQuery({
      queryKey: useUserId.getKey(),
      queryFn: useUserId.queryFn,
    });

    const res = await urqlClient
      .query<{
        lutrahubCoupons: {
          coupons: Array<Coupon>;
        };
      }>(fetchCouponsQuery, {
        status,
        userId,
      })
      .toPromise();

    return res.data?.lutrahubCoupons.coupons ?? [];
  },
});

export function isCouponAppliableToProduct(coupon: Coupon, productName: string): boolean {
  return coupon.plans.some((plan) => plan.productName === productName);
}

export function isUpsellCoupon(coupon: Pick<Coupon, 'tags'>): boolean {
  return Boolean(coupon.tags?.some((tag) => tag.key === 'upsell' && tag.value === 'true'));
}
