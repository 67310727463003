import { gql } from 'graphql-request';
import { useQuery } from '@tanstack/react-query';

import { spawnGraphqlClient } from '../../http-clients';
import fromEntries from 'fromentries';
import { fetchVasStoreId } from '@/hooks/use-vas-store-id';

const featureKey = 'vas.bundle.analysis';
export const query = gql`
  query FeatureEntitlements($storeId: ID!) {
    featureEntitlements(
      input: {
        entityFamilies: [{ targetEntity: { entityId: $storeId, entityType: STORE } }]
        entitlementRequest: { features: ["vas.bundle.analysis"] }
      }
    ) {
      featureEntitlementsForEntityFamilies {
        entityFamily {
          targetEntity {
            entityId
            entityType
          }
        }
        featureEntitlements {
          feature
          isEntitled
        }
      }
    }
  }
`;
export function useIsNewUser() {
  const client = spawnGraphqlClient();

  const {
    isLoading: loading,
    isError: error,
    data,
  } = useQuery(
    ['featureEntitlement-for-new-user-check'],
    async function () {
      const storeId = await fetchVasStoreId();
      const res = await client.request(query, {
        storeId: storeId,
      });
      return res;
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  let isNewUser = false;
  if (data) {
    try {
      const featureEntitlements: { [key: string]: boolean } = data
        ? fromEntries(
            data.featureEntitlements.featureEntitlementsForEntityFamilies.flatMap((entitlementsForEntityFamily: any) =>
              entitlementsForEntityFamily.featureEntitlements.map((entitlement: any) => [
                entitlement.feature,
                entitlement.isEntitled,
              ])
            )
          )
        : {};

      console.log('🚀 ~ file: useIsNewUser.ts:55 ~ useIsNewUser ~ data:', data);
      isNewUser = featureEntitlements[featureKey];
    } catch {
      // do nothing
    }
  }

  return {
    loading,
    error,
    isNewUser,
  };
}
