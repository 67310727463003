import { createLogger } from '@/logger';
import { useEffect } from 'react';

interface TExitConfirmParams {
  content: string;
  ok: string;
  cancel: string;
  title?: string;
  shouldEnable: () => boolean;
}

const logger = createLogger('useExitConfirm');

export function useExitConfirm({ shouldEnable, content, ok, cancel, title = '' }: TExitConfirmParams) {
  useEffect(
    function () {
      if (shouldEnable()) {
        logger.debug({ exitConfirmParams: { ok, cancel, title, content } }, 'set exit confirm params');
        window.__lutrahub_config__ = {
          closeConfirm: { ok, cancel, title, content },
          backConfirm: { ok, cancel, title, content },
        };
      }
      return () => {
        logger.debug('wipe out exit confirm config');
        delete window?.__lutrahub_config__?.closeConfirm;
        delete window?.__lutrahub_config__?.backConfirm;
      };
    },
    [content, ok, cancel, title]
  );
}
