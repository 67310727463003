import * as React from 'react';

function SvgCompetitionInsightsPromptLight(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 16 16"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      ref={svgRef}
      {...props}
    >
      <path d="M8.4 2v1.6h-.8V2h.8zM3.717 3.883l.566-.566L5.414 4.45l-.565.565-1.132-1.131zM12.517 11.883l.566-.566-1.132-1.131-.565.566 1.131 1.13zM12.4 8H14v-.8h-1.6V8zM12.614 3.883l-.565-.566-1.132 1.132.566.565 1.131-1.131zM2.917 11.317l.566.566 1.131-1.131-.565-.566-1.132 1.131zM2 8h1.6v-.8H2V8zM11.2 8a3.2 3.2 0 01-1.9 2.925V12H6.7v-1.075A3.2 3.2 0 1111.2 8zM9.3 14.4v-1.6H6.7v1.6h2.6z" />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgCompetitionInsightsPromptLight);
export default ForwardRef;
