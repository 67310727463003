import * as React from 'react';

function SvgChartLineArrow(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path d="M4 29l11.5-11.5 6 6L33 12" stroke="currentColor" strokeWidth={2} />
      <path d="M36.742 8.378l-1.848 7.698-5.85-5.85 7.698-1.848z" fill="currentColor" />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgChartLineArrow);
export default ForwardRef;
