import * as React from 'react';

function SvgCullinanSettings(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path fillRule="evenodd" clipRule="evenodd" d="M6 3l-4 7 4 7h8l4-7-4-7H6zm4 10a3 3 0 100-6 3 3 0 000 6z" />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgCullinanSettings);
export default ForwardRef;
