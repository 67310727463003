import { GiantCoupon } from '@/iap/GiantCoupon';
import { IapContext } from '@/iap/IapContext';
import { FullWidthCTA } from '@/iap/components';
import { CouponTextIcon } from '@/iap/icons/couponText.svg';
import { GiftIcon } from '@/iap/icons/gift.svg';
import { EnrichedFlatValueCoupon } from '@/iap/types';
import { usePlanSelector } from '@/store/plan-slice';
import { moneyToNumber } from '@css/currency';
import queryString from 'query-string';
import { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useCoupons } from '../hooks/use-redeemable-coupons';
import { useAmount } from '@/next/hooks/use-amount';

export function RegularCouponButton({ planHasCouponCode = false }: { planHasCouponCode?: boolean }) {
  const history = useHistory();
  const { selectedCoupon } = useContext(IapContext);
  const { productName } = useParams<{ productName: string }>();
  const { search } = useLocation();

  return (
    <FullWidthCTA
      onClick={() => {
        history.push({
          ...location,
          pathname: '/iap/couponSelect',
          search: queryString.stringify({
            ...queryString.parse(search),
            productName,
          }),
        });
      }}
      text={
        planHasCouponCode
          ? `${selectedCoupon?.couponInfo.title || selectedCoupon?.couponInfo.code}${
              selectedCoupon?.percentage
                ? `优惠${selectedCoupon.percentage * 10}折`
                : `抵扣${moneyToNumber(selectedCoupon?.value)}`
            }`
          : '选择优惠券'
      }
      isTextHighlighted={planHasCouponCode}
      Icon={planHasCouponCode ? CouponTextIcon : GiftIcon}
      background={planHasCouponCode ? 'rgba(224, 110, 77, 0.06)' : undefined}
    />
  );
}

export function RegularCouponButtonNg({ fromPage }: { fromPage?: string }) {
  const history = useHistory();
  const { productName } = useParams<{ productName: string }>();
  const { search } = useLocation();

  const getPaymentParam = usePlanSelector((state) => state.getPaymentParam);
  const currentPlan = usePlanSelector((state) => state.currentPlan);
  const { couponCode } = getPaymentParam();
  const couponsQuery = useCoupons('REDEEMABLE');
  const [selectedCoupon, setSelectedCoupon] = useState<EnrichedFlatValueCoupon>();

  const amountQuery = useAmount({
    variables: {
      planName: currentPlan ?? '',
      couponCode: couponCode,
    },
  });

  useEffect(
    function () {
      if (couponsQuery.data) {
        const selectedCoupon = couponsQuery.data.find((coupon) => {
          return coupon.couponInfo.code === couponCode;
        });
        setSelectedCoupon(selectedCoupon);
      }

      if (couponCode && currentPlan) {
        void amountQuery.refetch();
      }
    },
    [couponsQuery.data, couponCode, currentPlan]
  );

  const planHasCouponCode = typeof selectedCoupon !== 'undefined';

  if (amountQuery.data === 0.01 && ['vasbundleoperate', 'vasbundleorder'].includes(productName)) {
    return <GiantCoupon planHasCouponCode={planHasCouponCode} coupon={selectedCoupon} />;
  }

  return (
    <FullWidthCTA
      onClick={() => {
        history.push(
          {
            ...location,
            pathname: '/iap/couponSelect',
            search: queryString.stringify({
              ...queryString.parse(search),
              productName,
            }),
          },
          {
            fromPage,
          }
        );
      }}
      text={
        planHasCouponCode
          ? `${selectedCoupon?.couponInfo.title || selectedCoupon?.couponInfo.code}${
              selectedCoupon?.percentage
                ? `优惠${selectedCoupon.percentage * 10}折`
                : `抵扣${moneyToNumber(selectedCoupon?.value)}`
            }`
          : '选择优惠券'
      }
      isTextHighlighted={planHasCouponCode}
      Icon={planHasCouponCode ? CouponTextIcon : GiftIcon}
      background={planHasCouponCode ? 'rgba(224, 110, 77, 0.06)' : undefined}
    />
  );
}
