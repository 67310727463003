import * as React from 'react';

function SvgNotificationBadge(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <rect x={13} y={1} width={6} height={6} rx={3} fill="#DA252F" />
      <path
        d="M12 4.437V4.5a3.501 3.501 0 002.843 3.439c.102.419.157.858.157 1.311V12h2v2H3v-2h2V9.25c0-2.54 1.718-4.658 4-5.145V2h2v2.105c.348.074.683.187 1 .332zM10 18a2 2 0 002-2H8a2 2 0 002 2z"
        fill="#141414"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgNotificationBadge);
export default ForwardRef;
