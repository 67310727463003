import * as React from 'react';

function SvgDragHandle(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="1em"
      viewBox="0 0 24 24"
      width="1em"
      fill="currentColor"
      ref={svgRef}
      {...props}
    >
      <path d="M0 0h24v24H0z" fill="none" />
      <path d="M20 9H4v2h16V9zM4 15h16v-2H4v2z" />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgDragHandle);
export default ForwardRef;
