import * as React from 'react';

function SvgPlayRound(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 25 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M22.95 11.662c1.8 1.039 1.8 3.637 0 4.676L4.05 27.25C2.25 28.29 0 26.99 0 24.912V3.088C0 1.01 2.25-.289 4.05.75l18.9 10.912z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgPlayRound);
export default ForwardRef;
