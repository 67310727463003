import * as React from 'react';

function SvgSmile(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M7.834 7.01c-.631 0-1.155.523-1.155 1.155s.524 1.156 1.155 1.156c.632 0 1.156-.524 1.156-1.156 0-.632-.524-1.156-1.156-1.156h0zM14.163 7.01c-.632 0-1.156.523-1.156 1.155s.524 1.156 1.156 1.156c.632 0 1.156-.524 1.156-1.156 0-.632-.524-1.156-1.156-1.156z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth={0.2}
      />
      <path
        d="M11 .9C5.428.9.9 5.428.9 11S5.428 21.1 11 21.1 21.1 16.571 21.1 11C21.1 5.428 16.571.9 11 .9zm0 1.422A8.663 8.663 0 0119.678 11 8.663 8.663 0 0111 19.678 8.663 8.663 0 012.322 11 8.663 8.663 0 0111 2.322z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth={0.2}
      />
      <path
        d="M15.84 14.289h0l.03-.045c.19-.288.187-.582.059-.803a.703.703 0 00-.64-.345h0c-.255.01-.466.18-.593.37-.827 1.245-2.259 1.873-3.694 1.873s-2.867-.628-3.695-1.872h0c-.127-.191-.337-.36-.593-.371h0a.703.703 0 00-.639.345c-.128.221-.13.515.059.803h0c1.108 1.689 2.997 2.517 4.868 2.517 1.854 0 3.726-.813 4.838-2.472z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth={0.2}
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgSmile);
export default ForwardRef;
