import * as React from 'react';

function SvgReset(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 14 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M2 9.07c0-1.374.558-2.624 1.467-3.533L2.283 4.354a6.679 6.679 0 00-1.95 4.717c0 3.4 2.542 6.2 5.834 6.608v-1.683C3.808 13.596 2 11.546 2 9.07zm11.667 0A6.665 6.665 0 007 2.405c-.05 0-.1.008-.15.008l.908-.908L6.583.321 3.667 3.237l2.916 2.917L7.758 4.98l-.9-.9c.05 0 .1-.008.142-.008 2.758 0 5 2.241 5 5 0 2.475-1.808 4.525-4.167 4.925v1.683a6.657 6.657 0 005.834-6.608z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgReset);
export default ForwardRef;
