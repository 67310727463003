import { Decimal } from "decimal.js";

const NANOS = 1_000_000_000;

/** Represents an amount of money in protobuf */
export interface Money {
  /** The ISO 4217 currency code, e.g. 'USD' */
  currencyCode: string;
  /** Whole units of the currency */
  units: number;
  /** Remainder as a fraction of 1/1,000,000,000 of a unit */
  nanos: number;
}

/**
 * Sums a money amount into a unit-less number.
 *
 * @param money a Money type to format.
 */
export function moneyToNumber(money?: Money): number {
  if (!money) {
    return 0;
  }
  let value = new Decimal(money.nanos);
  value = value.dividedBy(NANOS);
  value = value.add(money.units);
  return value.toNumber();
}

/**
 * Subtracts a number from Money.
 */
export function subtractFromMoney(money: Money, price: number): Money {
  return addToMoney(money, -price);
}

/**
 * Adds a number to Money.
 */
export function addToMoney(money: Money, price: number): Money {
  const sum = new Decimal(moneyToNumber(money)).add(price);
  return toMoney(sum.toNumber(), money.currencyCode);
}

/**
 * Converts a numeric price, e.g. 3.99 into a Money type.
 *
 * @param price number to be parsed and converted if valid.
 * @param currencyCode ISO 4217 currency code 3-char string.
 */
export function toMoney(price: number, currencyCode: string): Money {
  return decimalToMoney(new Decimal(price), currencyCode);
}

/**
 * Converts a decimal value into a Money type.
 *
 * @param value string to be parsed and converted.
 * @param currencyCode ISO 4217 currency code 3-char string.
 */
export function decimalToMoney(value: Decimal, currencyCode: string): Money {
  const inNanos = value.times(NANOS);
  const units = inNanos.dividedToIntegerBy(NANOS);
  const nanos = inNanos.modulo(NANOS);
  return {
    currencyCode: currencyCode,
    units: units.toNumber(),
    nanos: nanos.toNumber(),
  };
}

/**
 * Formats a price string in the form 0,000.00 based on the Money type given.
 *
 * @param money a Money type to format.
 */
export function formatMoney(
  money: Money | undefined,
  fallbackText = "--"
): string {
  if (!money) {
    return fallbackText;
  }
  const total = moneyToNumber(money);
  return CurrencyFormatter.INSTANCE.getFormatter(money.currencyCode).format(
    total
  );
}

export type IntlCurrencyDisplay = "symbol" | "narrowSymbol" | "code" | "name";

export class CurrencyFormatter {
  public static readonly INSTANCE = new CurrencyFormatter();

  private readonly formatters: Record<string, Intl.NumberFormat> = {};

  /**
   * Returns an instance of an Intl.NumberFormat for the given currency code.
   *
   * @param currency The ISO 4217 currency code.
   * @param currencyDisplay Controls how the currency code is displayed.  See Intl.numberFormat documentation for details.
   */
  public getFormatter(
    currency: string,
    currencyDisplay: IntlCurrencyDisplay = "symbol"
  ): Intl.NumberFormat {
    if (!currency) {
      console.warn("Empty currency, defaulting to USD");
      return this.getFormatter("USD");
    }

    const cacheKey = `${currency}##${currencyDisplay}`;

    if (!this.formatters[cacheKey]) {
      this.formatters[cacheKey] = Intl.NumberFormat(undefined, {
        style: "currency",
        currency,
        currencyDisplay,
      });
    }
    return this.formatters[cacheKey]!;
  }
}
