import * as React from 'react';

function SvgMarketing(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <rect width={24} height={24} rx={8} fill="#46B760" />
      <path
        d="M7.832 9.375a3.336 3.336 0 013.334 3.339 3.336 3.336 0 01-3.334 3.338A3.336 3.336 0 014.5 12.714a3.336 3.336 0 013.333-3.339zM16.636 6.798c.973-1.162 2.867-.474 2.867 1.042v8.32c0 1.516-1.894 2.204-2.867 1.042l-3.483-4.16a1.623 1.623 0 010-2.084l3.483-4.16z"
        fill="#fff"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgMarketing);
export default ForwardRef;
