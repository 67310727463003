import * as React from 'react';

function SvgCullinanSlash(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path d="M14.832 4.555l-8 12-1.664-1.11 8-12 1.664 1.11z" />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgCullinanSlash);
export default ForwardRef;
