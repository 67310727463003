import * as React from 'react';

function SvgFrozen(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 22 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M21.093 16.881l-2.438-1.407 2.113-1.246a.82.82 0 00-.832-1.41l-2.91 1.715L12.638 12l4.387-2.533 2.91 1.716a.819.819 0 10.832-1.411l-2.112-1.246 2.438-1.407a.819.819 0 10-.819-1.419l-2.438 1.408-.022-2.453a.818.818 0 10-1.638.015l.03 3.378-4.387 2.533V5.516l2.942-1.663a.82.82 0 10-.806-1.426l-2.136 1.207V.82a.819.819 0 00-1.638 0v2.815L8.046 2.427a.819.819 0 10-.806 1.426l2.941 1.663v5.065L5.794 8.048l.03-3.378a.819.819 0 00-.811-.827h-.008a.819.819 0 00-.818.812l-.023 2.453L1.727 5.7a.819.819 0 10-.82 1.419l2.439 1.407-2.113 1.246a.82.82 0 00.832 1.411l2.91-1.716L9.364 12l-4.388 2.533-2.91-1.716a.82.82 0 00-.832 1.411l2.112 1.246L.908 16.88a.819.819 0 10.819 1.419l2.437-1.408.022 2.453a.82.82 0 00.82.812h.007a.819.819 0 00.811-.827l-.03-3.379 4.387-2.533v5.066L7.24 20.147a.819.819 0 10.806 1.426l2.135-1.207v2.815a.819.819 0 001.638 0v-2.815l2.136 1.207a.819.819 0 10.806-1.426l-2.942-1.663v-5.066l4.388 2.533-.032 3.38a.819.819 0 00.812.826h.008a.82.82 0 00.818-.812l.023-2.453 2.438 1.408a.818.818 0 10.819-1.419z"
        fill="#707070"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgFrozen);
export default ForwardRef;
