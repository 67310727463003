import { useFacilityId } from '@/hooks/use-facility-id';
import { urqlClient } from '@/http-clients';
import { gql } from 'graphql-request';
import { createQuery } from 'react-query-kit';

const query = gql`
  query fetchIsCssFacility($facilityId: ID!) {
    facilityById(input: { facilityId: $facilityId }) {
      isCssFacility
    }
  }
`;

export const useIsCssFacility = createQuery<boolean, { facilityId?: string | undefined }, Error>({
  primaryKey: 'is-css-facility',
  queryFn: async function ({ queryKey: [, { facilityId }] }) {
    const res = await urqlClient.query<{
      facilityById: {
        isCssFacility: boolean;
      };
    }>(query, {
      facilityId,
    });

    return res.data?.facilityById.isCssFacility ?? false;
  },
  staleTime: 10 * 60 * 1000,
  useDefaultOptions() {
    const { data: facilityId } = useFacilityId();
    return {
      variables: {
        facilityId,
      },
      enabled: Boolean(facilityId),
    };
  },
});
