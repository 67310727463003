import * as React from 'react';

function SvgLgLogo(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 27 24" ref={svgRef} {...props}>
      <path
        d="M25.297 5.316L21.734 2.79 18.793.707c-1.402-1-3.36-.715-4.406-.484L2.824 2.84C1.266 3.195 0 4.78 0 6.387v11.34c0 1.609 1.266 3.195 2.824 3.55l11.559 2.621c1.562.356 3.887-.109 5.191-1.035l5.723-4.062c1.5-1.055 1.777-1.91 1.777-3.434V8.754c0-1.57-.265-2.375-1.777-3.438"
        fill="#ec0b8d"
      />
      <path
        d="M20.41 4.805v.37l-2.988-1.534c-.32-.164-.867-.254-1.223-.196l-3.367.547a.885.885 0 00-.715.848v9.488c0 1.945-1.324 2.422-2.785 2.277l-2.082-.199c-1.594-.156-2.184-1.332-2.184-2.707V5.992a.6.6 0 00-.714-.613l-1.86.3a.884.884 0 00-.715.84v7.532c0 3.164 2.012 4.902 5.618 5.445l2.609.402c3.824.575 6.836-1.25 6.836-5.613V7.84l3.57.918v3.765c0 .403.32.7.719.665l1.289-.098a.81.81 0 00.723-.793v-2.84l1.875.484c.23.059.422-.086.422-.328V8.191a.8.8 0 00-.387-.636l-1.91-.98v-.49c0-1.94-.871-2.765-1.446-3.245-.043-.035-.008-.078.035-.05A1552.72 1552.72 0 0018.793.706c-.004-.004-.004-.004-.008-.004.938.664 1.625 1.899 1.625 4.102"
        fill="#fff"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgLgLogo);
export default ForwardRef;
