import { useLayoutEffect } from 'react';
import { trackEvent } from '../mixpanel';
import * as Sentry from '@sentry/react';
import { createLogger } from '@/logger';

const logger = createLogger('error:fallback');
export function ErrorFallback({ page_name, error }: { page_name: string; error: Error }) {
  useLayoutEffect(function () {
    try {
      Sentry.captureException(error);
      logger.error({ page_name, errorMessage: error.message }, 'error fallback rendered.');
      const [perfResult] = performance.getEntriesByType('navigation');
      if (perfResult) {
        trackEvent('page_loaded', {
          page_name,
          load_status: 'error',
          load_error: error?.message,
          load_latency: Math.max(perfResult.duration, 0),
        });
      }
    } catch (e) {
      trackEvent('page_loaded', {
        page_name: 'IAP_PAGE',
        load_status: 'error',
        load_error: error?.message,
      });
    }
  }, []);
  return null;
}
