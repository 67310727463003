import * as React from 'react';

function SvgDelete(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.5 4H19v2H5V4h3.5l1-1h5l1 1zM8 21c-1.1 0-2-.9-2-2V7h12v12c0 1.1-.9 2-2 2H8z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgDelete);
export default ForwardRef;
