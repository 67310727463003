import cx from 'classnames';
import React from 'react';

import './style.scss';

type ButtonProps = {
  ghost?: boolean;
  small?: boolean;
  primary?: boolean;
  fill?: boolean;
  tooltip?: boolean;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

export function Button({ ghost, small, primary, fill, tooltip, ...props }: ButtonProps) {
  return (
    <button
      className={cx([
        'button',
        {
          'button--ghost': ghost,
          'button--small': small,
          'button--primary': primary,
          'button--tooltip': tooltip,
          'button--fill': fill,
        },
      ])}
      {...props}
    ></button>
  );
}
