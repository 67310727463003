import * as React from 'react';

function SvgCompetitionInsightsPromptConfig(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 20 20"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      ref={svgRef}
      {...props}
    >
      <path d="M9.286 3H7.143v6h6V3H11v2.143H9.286V3zM5.143 11H3v6h6v-6H6.857v2H5.143v-2zM13.143 11H11v6h6v-6h-2.143v2.143h-1.714V11z" />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgCompetitionInsightsPromptConfig);
export default ForwardRef;
