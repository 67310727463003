import { gql } from 'graphql-request';
import { useState, useEffect, useLayoutEffect } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useHistory } from 'react-router-dom';

import ErrorOverlay from '../../common/components/ErrorOverlay/ErrorOverlay';
import { MeituanOfoType, useStoreOfoAuthorizations } from '../../hooks/useOfoAuth';
import { spawnGraphqlClient } from '../../http-clients';
import { trackEvent, trackPageView } from '../../mixpanel';
import { Button } from '../ui';
import { Mask } from './Mask';
import { Modal } from './Modal';
import { OfoAuthorizationCard } from './OfoCard';
import { PrepMode } from './constant';
import './style.scss';
import { useIsNewUser } from './useIsNewUser';
import { fetchVasStoreId } from '@/hooks/use-vas-store-id';
import { getToken } from '@/hooks/get-token';

// TODO: open this later maybe
// const mutation = gql`
//   mutation upsertOrderReadyReminderConfig(
//     $storeId: String!
//     $elemeDefaultPrepTimeInMins: Int
//     $elemeAutoMarkReady: Boolean
//     $meituanDefaultPrepTimeInMins: Int
//     $meituanAutoMarkReady: Boolean
//   ) {
//     upsertOrderReadyReminderConfig(
//       input: {
//         entityId: $storeId
//         entityType: STORE
//         elemeAutoMarkReady: $elemeAutoMarkReady
//         elemeDefaultPrepTimeInMins: $elemeDefaultPrepTimeInMins
//         meituanAutoMarkReady: $meituanAutoMarkReady
//         meituanDefaultPrepTimeInMins: $meituanDefaultPrepTimeInMins
//       }
//     ) {
//       success
//     }
//   }
// `;
const mutation = gql`
  mutation upsertOrderReadyReminderConfig(
    $storeId: String!
    $defaultPrepTimeInMins: Int
    $checkSkippedOrders: Boolean
  ) {
    upsertOrderReadyReminderConfig(
      input: {
        entityId: $storeId
        entityType: STORE
        defaultPrepTimeInMins: $defaultPrepTimeInMins
        checkSkippedOrders: $checkSkippedOrders
      }
    ) {
      success
    }
  }
`;

const mutationForNewUser = gql`
  mutation upsertOrderReadyReminderConfig(
    $storeId: String!
    $defaultPrepTimeInMins: Int
    $checkSkippedOrders: Boolean
  ) {
    upsertOrderReadyReminderConfig(
      input: {
        entityId: $storeId
        entityType: STORE
        elemeAutoMarkReady: true
        defaultPrepTimeInMins: $defaultPrepTimeInMins
        checkSkippedOrders: $checkSkippedOrders
      }
    ) {
      success
    }
  }
`;

const getOrderReadyReminderConfigQuery = gql`
  query getOrderReadyReminderConfig($storeId: String!) {
    getEnabledOrderReadyReminderConfig(input: { entityId: $storeId, entityType: STORE }) {
      edges {
        enabled
        defaultPrepTimeInMins
        elemeAutoMarkReady
        elemeDefaultPrepTimeInMins
        meituanAutoMarkReady
        meituanDefaultPrepTimeInMins
        checkSkippedOrders
      }
    }
  }
`;

type OrderReadyReminderConfigQueryResult = {
  getEnabledOrderReadyReminderConfig: {
    edges: {
      enabled: boolean;
      defaultPrepTimeInMins: number;
      elemeAutoMarkReady: boolean;
      elemeDefaultPrepTimeInMins: number;
      meituanAutoMarkReady: boolean;
      meituanDefaultPrepTimeInMins: number;
      checkSkippedOrders: boolean;
    }[];
  };
};

async function getOfoConfigFromServer({ storeId }: { storeId: string }) {
  const client = spawnGraphqlClient();
  const data = await client.request(
    getOrderReadyReminderConfigQuery,
    {
      storeId: storeId,
    },
    {
      authorization: `Bearer ${getToken()}`,
    }
  );

  return data;
}

async function updateOfoConfigToServer({
  elemeDefaultPrepTimeInMins,
  isNewUser,
  checkSkippedOrders,
}: {
  elemeAutoMarkReady: boolean;
  elemeDefaultPrepTimeInMins: number;
  meituanAutoMarkReady: boolean;
  meituanDefaultPrepTimeInMins: number;
  isNewUser: boolean;
  checkSkippedOrders: boolean;
}) {
  const client = spawnGraphqlClient();

  const storeId = await fetchVasStoreId();

  const data = isNewUser
    ? await client.request(
        mutationForNewUser,
        {
          storeId: storeId,
          defaultPrepTimeInMins: elemeDefaultPrepTimeInMins,
          checkSkippedOrders,
        },
        {
          authorization: `Bearer ${getToken()}`,
        }
      )
    : await client.request(
        mutation,
        {
          storeId: storeId,
          defaultPrepTimeInMins: elemeDefaultPrepTimeInMins,
          checkSkippedOrders,
        },
        {
          authorization: `Bearer ${getToken()}`,
        }
      );

  return data;
}

export default function PrepTimeConfig() {
  const [configs, setConfigs] = useState(() => ({
    meituan: {
      minute: 5,
      mode: PrepMode.manual,
    },
    eleme: {
      minute: 5,
      mode: PrepMode.auto,
    },
    checkSkippedOrders: true,
  }));
  const [showToolTip, setShowToolTip] = useState(() => false);
  const history = useHistory();
  const {
    ofoAuthorizations,
    loading: ofoAuthLoading,
    error: getOfoAuthError,
  } = useStoreOfoAuthorizations({
    meituanOfoType: MeituanOfoType.meituan,
  });
  const [maskOpen, setMaskOpen] = useState(false);
  const { isLoading: ofoConfigLoading, error: getOfoConfigError } = useQuery(
    ['getOfoConfigFromServer'],
    async function () {
      const storeId = await fetchVasStoreId();
      return getOfoConfigFromServer({ storeId });
    },
    {
      onSuccess: function (data: OrderReadyReminderConfigQueryResult) {
        const result = data.getEnabledOrderReadyReminderConfig.edges[0];
        if (result) {
          setConfigs({
            checkSkippedOrders: result.checkSkippedOrders,
            meituan: {
              minute: result.meituanDefaultPrepTimeInMins,
              mode: result.meituanAutoMarkReady ? PrepMode.auto : PrepMode.manual,
            },
            eleme: {
              minute: result.elemeDefaultPrepTimeInMins,
              mode: result.elemeAutoMarkReady ? PrepMode.auto : PrepMode.manual,
            },
          });
        }
      },
      onError: function (e) {
        trackEvent('product_onboarding_end', {
          page_name: 'WEB',
          product_name: 'ONE_CLICK_BATCHPREP',
          result: 'failure',
          failure_reason: 'server_error',
        });
      },
      refetchOnWindowFocus: false,
    }
  );

  const { isNewUser } = useIsNewUser();
  const { isLoading: isUpdating, mutate } = useMutation(updateOfoConfigToServer, {
    onSuccess: function () {
      trackEvent('product_onboarding_end', {
        page_name: 'WEB',
        product_name: 'ONE_CLICK_BATCHPREP',
        result: 'success',
      });

      history.push('/am/wechat-intro/orderprep');
    },
    onError: function () {
      trackEvent('product_onboarding_end', {
        page_name: 'WEB',
        product_name: 'ONE_CLICK_BATCHPREP',
        result: 'failure',
        failure_reason: 'server_error',
      });
    },
  });

  const isOfoLoading = ofoAuthLoading || ofoConfigLoading;
  useLayoutEffect(() => {
    trackPageView({
      page: '/am/preptime-config',
    });
    trackEvent('product_onboarding_start', {
      page_name: 'WEB',
      product_name: 'ONE_CLICK_BATCHPREP',
    });
  }, []);

  useEffect(
    function () {
      trackEvent('order_batchprep_setting_changed', {
        configs,
      });
    },
    [configs]
  );

  return (
    <div className="page">
      <Mask open={maskOpen} />
      <div className="container">
        {/* exploitation of the settings */}
        <Modal
          bottom
          onClose={() => {
            setShowToolTip(false);
            setMaskOpen(false);
          }}
          onOpen={() => {
            setMaskOpen(true);
          }}
          open={showToolTip}
          title="什么是出餐时长"
        >
          出餐时长是指店铺接单后，我们提醒你点击“出餐”按钮的时间
        </Modal>

        <HeaderSections
          onClick={() => {
            setShowToolTip(true);
            trackEvent('product_config_show_hint', {
              productName: 'orderprep',
            });
          }}
        />

        <div className="ofo_card_container">
          {ofoAuthorizations.map((item) =>
            item.ofo === 'eleme' ? (
              <OfoAuthorizationCard
                key={`k-${item.ofo}`}
                {...item}
                {...configs[item.ofo]}
                meituanOfoType={MeituanOfoType.meituan}
                loading={isOfoLoading}
                onClickSetMode={(ofo, mode) => {
                  setConfigs((configs) => ({
                    ...configs,
                    [ofo]: {
                      ...configs[ofo],
                      mode,
                    },
                  }));
                }}
                onClickSetPrepTime={(ofo, minute) => {
                  setConfigs((configs) => ({
                    ...configs,
                    [ofo]: {
                      ...configs[ofo],
                      minute,
                    },
                  }));
                }}
              />
            ) : null
          )}
        </div>
      </div>

      <footer className="bottom-footer">
        <Button
          fill
          primary
          disabled={isUpdating}
          onClick={() => {
            mutate({
              elemeAutoMarkReady: configs.eleme.mode === PrepMode.auto,
              elemeDefaultPrepTimeInMins: configs.eleme.minute,
              meituanAutoMarkReady: configs.meituan.mode === PrepMode.auto,
              meituanDefaultPrepTimeInMins: configs.meituan.minute,
              isNewUser,
              checkSkippedOrders: configs.checkSkippedOrders,
            });
          }}
        >
          {isUpdating ? '跳转中...' : '保存设置'}
        </Button>
      </footer>
      <>
        {(getOfoAuthError || getOfoConfigError) && (
          <ErrorOverlay
            message={getOfoAuthError ? 'getOfoAuthError' : 'getOfoConfigError'}
            onDismiss={() => {
              window.location.reload();
            }}
          />
        )}
      </>
    </div>
  );
}

const HeaderSections = (props: { onClick: () => void }) => {
  return (
    <header className="header">
      <div className="header__content">
        <h2 className="header__content__label">一键出餐</h2>
        <h1 className="header__content__title">设置出餐模式和时长</h1>

        <Button tooltip onClick={() => props.onClick()}>
          <svg width={23} height={23} fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M17.25 10.542v1.917h3.833v-1.917H17.25ZM15.333 16.876c.92.68 2.118 1.582 3.067 2.29.383-.507.767-1.025 1.15-1.532-.949-.71-2.147-1.61-3.067-2.3-.383.517-.766 1.035-1.15 1.542ZM19.55 5.367c-.383-.508-.767-1.026-1.15-1.534-.949.71-2.147 1.61-3.067 2.3.384.508.767 1.026 1.15 1.534.92-.69 2.118-1.581 3.067-2.3ZM3.833 8.625a1.922 1.922 0 0 0-1.916 1.917v1.917c0 1.054.862 1.916 1.916 1.916h.959v3.834h1.916v-3.834h.959l4.791 2.875V5.75L7.667 8.625H3.833Zm4.82 1.639 1.889-1.13v4.733l-1.888-1.13-.46-.278h-4.36v-1.917h4.36l.46-.278ZM14.854 11.5a4.293 4.293 0 0 0-1.437-3.21V14.7a4.257 4.257 0 0 0 1.437-3.2Z"
              fill="#fff"
            />
          </svg>
          什么是出餐模式和时长
        </Button>
      </div>
    </header>
  );
};
