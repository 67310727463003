import React from 'react';
import { trackEvent } from '../../mixpanel';
import * as styles from './agreements.module.scss';
import { useAgreement } from '@/store/plan-slice';

const agreements = {
  'user-agreement': {
    title: '用户协议',
    url: 'https://otterapp-assets.csschina.com/otter_user_agreement.html',
  },
  'privacy-policy': {
    title: '隐私协议',
    url: 'https://otterapp-assets.csschina.com/otter_privacy_policy.html',
  },
  'auto-renew-agreement': {
    title: '自动续费服务规则',
    url: 'https://otterapp-assets.csschina.com/otter_auto_renew_agreement.html',
  },
  'orderbooster-agreement': {
    title: '冲单宝服务协议',
    url: 'https://ecdwv.csschina.com/static/cashback/ipo-user-agreement.html',
  },
} as const;

type Agreement = typeof agreements;
export type AgreementFile = keyof Agreement;

export function Agreements({
  trackingContext,
  files = ['user-agreement', 'privacy-policy', 'auto-renew-agreement'],
  actionName = '购买',
}: {
  trackingContext?: Record<string, unknown>;
  files?: Array<AgreementFile>;
  actionName?: string;
}) {
  const agreed = useAgreement((state) => state.agreed);
  const setAgreed = useAgreement((state) => state.setAgreed);

  return (
    <div className={styles.agreements}>
      <input type="checkbox" checked={agreed} onChange={(e) => setAgreed(e.target.checked)} />
      {actionName}服务表示您同意
      {files.map((file, index) => {
        const { title, url } = agreements[file];
        return (
          <React.Fragment key={file}>
            {index > 0 ? (index <= files.length - 2 ? '，' : '和') : null}
            <a
              href={url}
              title={title}
              onClick={() =>
                trackEvent('agreement_clicked', {
                  file,
                  ...trackingContext,
                })
              }
            >
              {title}
            </a>
          </React.Fragment>
        );
      })}
    </div>
  );
}
