import React from 'react';
import { Button } from '../../../common/components/UnbindModal';

import './ErrorOverlay.scss';

interface Props {
  message?: string;
  onDismiss: () => void;
}

const ErrorOverlay: React.FC<Props> = ({ onDismiss, message }) => {
  return (
    <div className="error-overlay">
      <div className="error-overlay-body">
        <div className="error-icon" />
        <div className="error-msg">{message ?? '网络发生错误，请重试'}</div>
        <Button onClick={onDismiss}>确定</Button>
      </div>
    </div>
  );
};

export default ErrorOverlay;
