import * as React from 'react';

function SvgCullinanMarketing(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 14 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M10 .923L13 0v4.268a2 2 0 010 3.464V12l-3-.923V.923zM8 1.538L0 4v4l1.778.547 3.37 5.477 1.704-1.048-2.177-3.538L8 10.461V1.538z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgCullinanMarketing);
export default ForwardRef;
