import * as React from 'react';

function SvgCullinanDocument(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 2H4v16h12V7l-5-5zm0 3H6v1.5h5V5zM6 9.213h8v1.531H6v-1.53zm8 4.287H6V15h8v-1.5z"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgCullinanDocument);
export default ForwardRef;
