import * as React from 'react';

function SvgBell(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="-3 0 24 24"
      width="1em"
      height="1em"
      ref={svgRef}
      {...props}
    >
      <path
        d="M12.563 5.815c2.6.927 4.225 2.262 3.005 7.877-.878 4.04-.067 6.216-.067 6.216L1.372 14.871s2.005-1.172 3.88-4.856c2.608-5.12 4.71-5.127 7.31-4.2z"
        stroke="currentColor"
        strokeWidth={2.5}
        strokeMiterlimit={10}
        strokeLinejoin="round"
      />
      <path
        d="M12.752 5.283l.636-1.784"
        stroke="currentColor"
        strokeWidth={2.5}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.412 18.799a1.24 1.24 0 00.765 1.577 1.24 1.24 0 001.59-.738"
        stroke="currentColor"
        strokeWidth={2.5}
        strokeMiterlimit={10}
        strokeLinejoin="round"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgBell);
export default ForwardRef;
