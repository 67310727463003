import * as React from 'react';

function SvgDrag(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M7.953.781a.667.667 0 00-.667.667v5.885H1.4a.667.667 0 000 1.334h5.886v5.885a.667.667 0 101.333 0V8.667h5.886a.667.667 0 100-1.334H8.619V1.448A.667.667 0 007.953.78z"
        fill="currentColor"
      />
      <path
        d="M0 8.003l3-2.887v5.773L0 8.003zM8 0l2.886 3H5.113L8 0zM16 7.997l-3 2.887V5.111l3 2.886zM8 16l-2.887-3h5.773L8 16z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgDrag);
export default ForwardRef;
