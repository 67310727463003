import * as React from 'react';

function SvgCullinanOrderManager(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 20 20"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      ref={svgRef}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 2h12v16l-2-2-2 2-2-2-2 2-2-2-2 2V2zm2.25 4h7.5V4.5h-7.5V6zm0 3.287h7.5V7.756h-7.5v1.53zm4.688 3.213H6.25V11h4.688v1.5z"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgCullinanOrderManager);
export default ForwardRef;
