import * as React from 'react';

function SvgCircleChart(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 28 28"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path d="M15.167 2.392v3.535c3.955.571 7 3.955 7 8.073 0 1.05-.21 2.042-.56 2.963l3.033 1.785A11.523 11.523 0 0025.667 14c0-6.043-4.609-11.025-10.5-11.608zM14 22.167A8.16 8.16 0 015.834 14c0-4.118 3.045-7.502 7-8.073V2.392A11.656 11.656 0 002.334 14c0 6.44 5.214 11.667 11.655 11.667 3.861 0 7.28-1.879 9.403-4.772l-3.034-1.785A8.109 8.109 0 0114 22.167z" />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgCircleChart);
export default ForwardRef;
