import logo from '../../assets/otter-logo.svg';
import simLocation from '../../assets/printer-sim-location.png';
import PrinterSimCardNoValidator from './PrinterSimCardNoValidator';
import useSearchParams from '../../hooks/useSearchParams';
import ErrorOverlay from '../../common/components/ErrorOverlay/ErrorOverlay';
import { moneyToNumber } from '@css/currency';
import { usePlan } from '../use-product';
import { usePlanSelector } from '@/store/plan-slice';
import { useParams } from 'react-router';
import { ProductSlug } from '@/product-registry';
import {
  Container,
  CardOuter,
  CardShadow,
  TagOuter,
  Tag,
  Card,
  CardLeft,
  Title,
  Subtitle,
  CardRight,
  CurrencySymbol,
  Price,
  HintTitle,
  HintImage,
} from './printer-4g-plan-ui';

export function Printer4GPlan() {
  const { queueId } = useSearchParams();
  const { productName } = useParams<{ productName: ProductSlug }>();
  const currentPlan = usePlanSelector((state) => state.currentPlan);
  const planQuery = usePlan(productName, currentPlan);
  const selectedPlan = planQuery.data;

  if (!queueId) {
    return <ErrorOverlay message="queueId missing" onDismiss={() => {}} />;
  }

  return (
    <Container>
      <CardOuter>
        <CardShadow />
        <TagOuter>
          <Tag>
            <div>流量包</div>
          </Tag>
        </TagOuter>
        <Card>
          <CardLeft>
            <img src={logo} alt="Otter" style={{ height: 34, width: 34 }} />
            <Title>{selectedPlan?.localizedPlanName ?? '--'}</Title>
            <Subtitle>
              {selectedPlan?.autoRenewable ? (
                <>
                  到期后自动续费{selectedPlan?.unitPriceNumber ?? '--'}元/年
                  <br />
                </>
              ) : null}
              可随时取消
            </Subtitle>
          </CardLeft>
          <CardRight>
            <CurrencySymbol>¥</CurrencySymbol>
            <Price>{selectedPlan ? moneyToNumber(selectedPlan.displayPrice) : '--'}</Price>
          </CardRight>
        </Card>
      </CardOuter>

      <PrinterSimCardNoValidator />

      <div style={{ marginTop: 24 }}>
        <HintTitle>在哪里找到流量卡？</HintTitle>
        <HintImage src={simLocation} />
      </div>
    </Container>
  );
}

export default Printer4GPlan;
