import * as React from 'react';

function SvgCirclePhone(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <circle cx={16} cy={16} r={16} fill="#E6E9ED" />
      <path
        d="M12 15c1.12 2.201 2.799 3.872 5 5l1.652-1.527c.21-.21.521-.28.794-.186a8.874 8.874 0 002.776.443.78.78 0 01.778.778v2.714a.78.78 0 01-.778.778C14.92 23 9 17.081 9 9.778A.78.78 0 019.778 9H12.5a.78.78 0 01.778.778c0 .972.155 1.905.443 2.776a.78.78 0 01-.194.794L12 15z"
        fill="#3C5266"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgCirclePhone);
export default ForwardRef;
