import React from 'react';
import { Route, Switch } from 'react-router';
import { PaymentSuccess } from './success';
import { PaymentFail } from './fail';
import { PaymentPending } from './pending';
import { PaymentCreate } from './create';

export function PaymentPage() {
  // TODO: 合并这两个 结果的页面，实现基本上是完全一样的（除了看起来不同）
  return (
    <Switch>
      <Route path="/payment/create/:productName/:planName/:paymentMethod">
        <PaymentCreate />
      </Route>
      <Route path="/payment/pending/:recordId">
        <PaymentPending />
      </Route>
      <Route path="/payment/success">
        <PaymentSuccess />
      </Route>

      <Route path="/payment/failure">
        <PaymentFail />
      </Route>
    </Switch>
  );
}
