import type { LandingRule } from './types';

function* toGen(rules: Array<LandingRule | Promise<LandingRule>>) {
  yield* rules;
}

export async function ruleExecutor(rules: Array<LandingRule | Promise<LandingRule>>) {
  for await (const rule of toGen(rules)) {
    const passed = await rule.checker();
    if (!passed) {
      await rule.otherwise();
      return;
    }
  }
}
