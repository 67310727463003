import * as React from 'react';

function SvgBank(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M10.651 28.337h3.624V11.34h-3.71v16.996h.086zM3.577 28.337h3.71V11.34h-3.71v16.996zM24.714 28.337h3.71V11.34h-3.71v16.996zM17.64 28.337h3.709V11.34h-3.71v16.996z"
        fill="#DBDBDB"
      />
      <path d="M1.333 30.667l.69-4.831h28.04l.604 4.831H1.333zM30.321 13.24V10.65H1.678v2.589h28.643z" fill="#B5B5B5" />
      <path d="M1.678 10.651L16 1.334 30.32 10.65H1.678z" fill="#DBDBDB" />
      <path
        d="M7.286 13.24h-3.71v2.156h3.71V13.24zM14.275 13.24h-3.71v2.156h3.71V13.24zM21.349 13.24h-3.71v2.156h3.71V13.24zM28.424 13.24h-3.71v2.156h3.71V13.24z"
        fill="#1F2B3D"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgBank);
export default ForwardRef;
