import * as React from 'react';

function SvgAppIconSettings(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 10a3 3 0 11-6 0 3 3 0 016 0zm-1 0a2 2 0 11-4 0 2 2 0 014 0z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 0a1 1 0 00-1 1v1.582a7.992 7.992 0 00-1.923 1.112l-1.372-.792a1 1 0 00-1.366.366l-2 3.464a1 1 0 00.366 1.366l1.371.791a8.07 8.07 0 000 2.222l-1.37.79a1 1 0 00-.367 1.367l2 3.464a1 1 0 001.366.366l1.372-.792c.581.455 1.228.831 1.923 1.113V19a1 1 0 001 1h4a1 1 0 001-1v-1.581a7.993 7.993 0 001.922-1.113l1.372.792a1 1 0 001.366-.366l2-3.464a1 1 0 00-.366-1.366l-1.37-.791a8.072 8.072 0 000-2.222l1.37-.79a1 1 0 00.366-1.367l-2-3.464a1 1 0 00-1.366-.366l-1.372.792A7.992 7.992 0 0013 2.582V1a1 1 0 00-1-1H8zm-.625 3.508L8 3.256V1h4v2.256l.624.252a6.992 6.992 0 011.683.973l.531.416 1.956-1.13 2 3.465L16.84 8.36l.093.667a7.07 7.07 0 010 1.946l-.093.667 1.954 1.128-2 3.464-1.956-1.129-.531.416a6.99 6.99 0 01-1.683.972l-.624.253V19H8v-2.256l-.625-.253a6.99 6.99 0 01-1.683-.973l-.531-.415-1.956 1.129-2-3.464L3.16 11.64l-.092-.667a7.07 7.07 0 010-1.946l.092-.667-1.954-1.128 2-3.464 1.956 1.129.531-.416a6.992 6.992 0 011.683-.973z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgAppIconSettings);
export default ForwardRef;
