import { DisplayPlan, EnrichedFlatValueCoupon } from '@/iap/types';
import { findBestCouponForPlan } from '@/iap/utils';
import { StateCreator, create } from 'zustand';
import { immer } from 'zustand/middleware/immer';

interface AgreementSlice {
  agreed: boolean;
  setAgreed: (agreed: boolean) => void;
}

const agreementSlice: StateCreator<AgreementSlice, [], [], AgreementSlice> = (set) => ({
  agreed: false,
  setAgreed: (agreed) => set(() => ({ agreed })),
});

export const useAgreement = create(immer(agreementSlice));

interface PaymentParam {
  planName: string;
  couponCode: string | undefined;
}

interface PlanSlice {
  currentPlan: string | undefined;
  mapping: Record<string, string | undefined>;
  coupons: Array<EnrichedFlatValueCoupon>;
  init: (plans: Array<DisplayPlan>, coupons: Array<EnrichedFlatValueCoupon>, currentPlanOverride?: string) => void;
  selectPlan: (plan: string, wat?: unknown) => void;
  getPaymentParam: () => PaymentParam;
  selectCoupon: (coupon: EnrichedFlatValueCoupon) => void;
  getCurrentCouponCode: () => string | undefined;
  getCouponName: (couponCode?: string) => string | undefined;
}

const planSlice: StateCreator<PlanSlice, [], [], PlanSlice> = (set, get) => ({
  currentPlan: undefined,
  mapping: {},
  coupons: Array<EnrichedFlatValueCoupon>(),
  getCurrentCouponCode: () => {
    const { currentPlan, mapping } = get();
    const planName = currentPlan ?? '';
    return mapping?.[planName];
  },

  getCouponName: (couponCode) => {
    const { coupons } = get();
    const coupon = coupons.find((c) => c.couponInfo.code === couponCode);
    return coupon?.couponInfo.title;
  },

  init: (plans = [], coupons, currentPlanOverride) =>
    set((state) => {
      state.coupons = coupons;
      if (currentPlanOverride) {
        state.currentPlan = state.currentPlan ?? currentPlanOverride;
      } else {
        const recommendedPlan = plans.find((p) => p.isRecommended);
        state.currentPlan = state.currentPlan ?? recommendedPlan?.name ?? plans[0]?.name;
      }

      for (const plan of plans) {
        const bestCoupon = findBestCouponForPlan(plan, coupons);
        state.mapping[plan.name] = state.mapping[plan.name] ?? bestCoupon?.couponInfo.code;
      }
      return state;
    }),

  selectPlan: (planName) =>
    set((state) => {
      state.currentPlan = planName;

      if (!state.mapping[planName]) {
        state.mapping[planName] = undefined;
      }

      state.currentPlan = planName;

      return state;
    }),

  getPaymentParam: () => {
    const { currentPlan, mapping } = get();
    const planName = currentPlan ?? '';
    return {
      planName,
      couponCode: mapping?.[planName],
    };
  },

  selectCoupon: (coupon: EnrichedFlatValueCoupon) =>
    set((state) => {
      if (coupon?.applicablePlans) {
        const currentPlan = state.currentPlan ?? coupon.applicablePlans[0];
        state.currentPlan = currentPlan;
        state.mapping[currentPlan] = coupon.couponInfo.code;
      }
      return state;
    }),
});

export const usePlanSelector = create(immer<PlanSlice>(planSlice));
