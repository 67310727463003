export const FALLBACK_DOMAIN = 'baitime.cn';

export const PURCHASE_POLLING_LIMIT = 10;
export const IS_INTEGRATION_MODE = import.meta.env.VITE_INTEGRATION_MODE;

export const BAAS_HOSTNAME = isUnderFallbackMode()
  ? import.meta.env.VITE_BAAS_FALLBACK_HOSTNAME
  : import.meta.env.VITE_BAAS_HOSTNAME;
export const ECD_HOSTNAME = isUnderFallbackMode()
  ? import.meta.env.VITE_ECD_FALLBACK_HOSTNAME
  : import.meta.env.VITE_ECD_HOSTNAME;

export function isUnderFallbackMode() {
  return window.location.hostname.endsWith(`.${FALLBACK_DOMAIN}`);
}

export const AUTO_AGREE = true;
