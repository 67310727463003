import * as React from 'react';

function SvgMessage(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 14 14"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path fillRule="evenodd" clipRule="evenodd" d="M14 0H0v14l2.986-3H14V0zM3 3h8v1.5H3V3zm8 3H3v1.5h8V6z" />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgMessage);
export default ForwardRef;
