import { CustomError } from 'ts-custom-error';

interface PaymentContext {
  planName: string;
  paymentMethod: string;
  couponCode?: string;
  memo?: string;
  source?: string;
}

export class PaymentError extends CustomError {
  public constructor(public message: string, public cause: unknown, public context?: Partial<PaymentContext>) {
    super(message);
    Object.defineProperty(this, 'name', { value: 'PaymentError' });
  }
}

interface AmountContext {
  planName: string;
  couponCode?: string;
  orgId: string;
  storeId: string;
}

export class AmountError extends CustomError {
  public constructor(public message: string, public cause: unknown, public context?: Partial<AmountContext>) {
    super(message);
    Object.defineProperty(this, 'name', { value: 'AmountError' });
  }
}

export class ServerInternalError extends CustomError {
  public constructor(public message: string, public cause: unknown, public traceId?: string) {
    super(message);
    Object.defineProperty(this, 'name', { value: 'ServerInternalError' });
  }
}
