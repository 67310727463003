import * as React from 'react';

function SvgTable(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 42 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M41.721 14.994L37.71.952H4.29L.278 14.994H7.36L4.952 33.048h4.012l1.344-10.03h21.404l1.323 10.03h4.012L34.64 14.994h7.081zM10.83 19.006l.541-4.012h19.257l.542 4.012H10.83z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgTable);
export default ForwardRef;
