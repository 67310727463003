import * as React from 'react';

function SvgCullinanClose(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path d="M8.588 10L4 14.588 5.413 16 10 11.412 14.588 16 16 14.588 11.413 10 16 5.413 14.588 4 10 8.587 5.413 4 4 5.413 8.588 10z" />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgCullinanClose);
export default ForwardRef;
