import * as React from 'react';

function SvgFilterTriple(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 30 30"
      width="1em"
      height="1em"
      ref={svgRef}
      {...props}
    >
      <path
        d="M10 9a3 3 0 110-6 3 3 0 010 6zm14 10a3 3 0 110-6 3 3 0 010 6zm-10 9a3 3 0 110-6 3 3 0 010 6zM6.126 7a4.007 4.007 0 010-2H0v2h6.126zm7.748 0H30V5H13.874a4.01 4.01 0 010 2zm6.252 10a4.01 4.01 0 010-2H0v2h20.126zm7.748 0H30v-2h-2.126a4.01 4.01 0 010 2zm-17.748 9a4.01 4.01 0 010-2H0v2h10.126zm7.748 0H30v-2H17.874a4.01 4.01 0 010 2z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgFilterTriple);
export default ForwardRef;
