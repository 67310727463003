import { FireButton } from '@/features/upsell/components/fire-button';
import { Carousel } from '../FeatureUnlockedPage/carousel';
import { useEffect, useMemo } from 'react';
import { trackEvent } from '@/mixpanel';
import { TProduct, getTrackingName } from '@/product-registry';
import { useParams } from 'react-router';
import { Agreements } from '@/common/components/agreements';
import { PrintTestAndKnowMoreButton } from '../FeatureUnlockedPage/components';
import useSearchParams from '@/hooks/useSearchParams';
import { LaunchPaymentButton } from '@/next/components/launch-payment-button';
import { cssTracking } from '@/tracking/css-tracking';
import { RenewButton } from '@/features/payment/components/renew-button';
import { useUpsellPayMode } from '@/bnm-common/hooks/useUpsellPayMode';
import {
  GetRemoteContentByCategory,
  HideForUpsellNewVersion,
  RemotePositiveButtonText,
  useRemoteContent,
} from '@/features/cms/components/remote-content';

function commonDescription(isSignContract: boolean) {
  return (
    <p>
      {isSignContract ? (
        '美团/饿了么双平台可用，老店拉新客，新店冲排名，快速提升订单！'
      ) : (
        <>
          <div>【双11限时福利】</div>
          <div>美团/饿了么双平台可用，老店拉新客，新店冲排名，快速提升订单！</div>
        </>
      )}
    </p>
  );
}

export function XCDUpsellPage() {
  const { productName } = useParams<{ productName: string }>();
  const { couponId } = useSearchParams();

  useEffect(function () {
    cssTracking('page_loaded', {
      url: window.location.href,
    });
  }, []);

  const { payMode } = useUpsellPayMode();
  const isSignContract = useMemo(() => payMode === 'sign-contract', [payMode]);
  const remoteContent = useRemoteContent<GetRemoteContentByCategory<'upsell'>>({
    variables: { category: 'upsell', productName },
  });
  const { isFreeSign, isNewVersion } = useMemo(() => {
    const buttonContent = remoteContent.data?.positiveButton?.[0] ?? '免费试用7天';
    const isNew = remoteContent.data?.isNewVersion[0] === 'yes';
    return { isFreeSign: buttonContent.includes('免费试用'), isNewVersion: isNew };
  }, [remoteContent]);

  return (
    <section className="h-screen h-[100svh] mx-auto grid grid-rows-footer-page pb-safe">
      <HideForUpsellNewVersion productName={'xcd'}>
        <div data-testid="xcd-upsell-description" className="max-h-screen overflow-auto p-4">
          <h1 className="font-bold text-2xl flex items-center">
            邀你体验<span className="text-primary">店铺拉新客神器</span>
          </h1>
          <h1 className="font-bold text-2xl">
            <span className="text-primary">{isSignContract ? '0元畅享7天' : '1元试用全部功能7天'}</span> 原价<s>￥99</s>
          </h1>
          {commonDescription(isSignContract)}
          <div className="flex flex-col justify-center rounded-lg pb-2 my-4">
            <Carousel />
            <FireButton productName={productName as TProduct} />
          </div>
        </div>
      </HideForUpsellNewVersion>
      {isNewVersion && (
        <div className="flex flex-col pl-4 pr-4">
          <Carousel />
        </div>
      )}

      <footer className="p-4 text-md border-t border-neutral-400 w-full bg-white flex flex-col gap-2 ">
        <Agreements actionName="开通" />
        <HideForUpsellNewVersion productName={'xcd'}>
          {isSignContract ? (
            <RenewButton
              productName={productName}
              planName={'xcd.emcd.recurring.p1m.store.chn.campaign.v0'}
              children="免费试用7天"
            />
          ) : (
            <LaunchPaymentButton couponCode={couponId} planName="xcd.emcd.recurring.p1m.store.chn.campaign.v0">
              <span className="font-bold text-2xl">确认协议并开通</span>
            </LaunchPaymentButton>
          )}
        </HideForUpsellNewVersion>
        {isNewVersion && (
          <>
            {isFreeSign ? (
              <RenewButton
                productName={productName}
                planName={'xcd.emcd.recurring.p1m.store.chn.campaign.v0'}
                children={<RemotePositiveButtonText fallback="免费试用7天" productName="xcd" />}
              />
            ) : (
              <LaunchPaymentButton couponCode={couponId} planName="xcd.emcd.recurring.p1m.store.chn.campaign.v0">
                <span className="font-bold text-2xl">
                  <RemotePositiveButtonText fallback="确认协议并开通" productName="xcd" />
                </span>
              </LaunchPaymentButton>
            )}
          </>
        )}
        <PrintTestAndKnowMoreButton />
      </footer>
    </section>
  );
}
