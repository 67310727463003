import { createQuery } from 'react-query-kit';
import { createLogger } from '@/logger';
import { trackEvent } from '@/mixpanel';
import { fetchVasStoreIdQueryFn } from '@/rqs';
import { queryClient } from '@/query-client';
import { cssTracking } from '@/tracking/css-tracking';
import { getUserId } from './use-user-id';

const logger = createLogger('use-vas-store-id');

export const useVasStoreId = createQuery({
  primaryKey: 'vas-store-id',
  queryFn: async function () {
    logger.debug('get vas store id start');

    try {
      return await fetchVasStoreIdQueryFn();
    } catch (error) {
      trackEvent('FAILED_GET_VAS_STORE_ID', {
        message: (error as Error).message,
      });
      const userId = await getUserId();
      cssTracking('GET_VAS_STORE_ID_FAILED', {
        url: window.location.href,
        userId,
      });

      throw new Error('get vas store id failed');
    }
  },
  retryDelay: 500,
  retry(count: number, error: Error) {
    logger.debug({ context: { count, error: error.message } }, 'retry get vas store id');
    return count < 3;
  },
});

export async function fetchVasStoreId() {
  return await queryClient.fetchQuery({
    queryKey: useVasStoreId.getKey(),
    queryFn: useVasStoreId.queryFn,
  });
}
