import * as React from 'react';

function SvgCullinanHamburger(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path fillRule="evenodd" clipRule="evenodd" d="M17 6H3V4h14v2zM17 11H3V9h14v2zM17 16H3v-2h14v2z" />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgCullinanHamburger);
export default ForwardRef;
