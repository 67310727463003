import * as React from 'react';

function SvgCable(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M16.667 4.167v-.834a.836.836 0 00-.834-.833h-1.666a.836.836 0 00-.833.833v.834H12.5V7.5c0 .458.375.833.834.833h.833v5.834c0 .916-.75 1.666-1.667 1.666-.916 0-1.666-.75-1.666-1.666V5.833a3.332 3.332 0 10-6.667 0v5.834h-.834a.836.836 0 00-.833.833v3.333h.833v.834c0 .458.375.833.834.833h1.666a.836.836 0 00.834-.833v-.834H7.5V12.5a.836.836 0 00-.833-.833h-.834V5.833c0-.916.75-1.666 1.667-1.666s1.667.75 1.667 1.666v8.334a3.332 3.332 0 106.667 0V8.333h.833A.836.836 0 0017.5 7.5V4.167h-.833z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgCable);
export default ForwardRef;
