import React, { useEffect, useMemo } from 'react';
import useCdStorePerformance from './useStorePerformance';
import moment from 'moment';
import { useVasStoreId } from '@/hooks/use-vas-store-id';
import { moneyToNumber } from '@css/currency';
import { trackEvent } from '@/mixpanel';
import { getTrackingName } from '@/product-registry';
import { useParams } from 'react-router-dom';
import useXCDSubscriptions from './useXCDSubscriptions';
import { useBundle } from '@/bundle/components/use-bundle';

export const useAchievement = () => {
  const { data: storeId } = useVasStoreId();
  const { productName } = useParams<{ productName: string }>();

  const { startTime, endTime } = useTimeRangeFromSubscription(storeId as string);

  const storePerformance = useCdStorePerformance(storeId as string, startTime, endTime);
  const achievements = useMemo(
    () => ({
      meituanCouponList: [
        {
          label: '美团发券量',
          key: 'meituanSentCoupons',
          num: storePerformance?.cdPerformanceByStore?.meituanPerformance.totalSentCoupons ?? 0,
          suffix: '张',
        },
        {
          label: '美团增量订单',
          key: 'meituanFirstOrders',
          num: storePerformance?.cdPerformanceByStore?.meituanPerformance.totalFirstOrders ?? 0,
          suffix: '单',
        },
        {
          label: '美团增量营收',
          key: 'meituanFirstOrderRevenue',
          num: moneyToNumber(storePerformance?.cdPerformanceByStore?.meituanPerformance.totalFirstOrderRevenue) ?? 0,
          suffix: '元',
        },
      ],
      elemeCouponList: [
        {
          label: '饿了么发券量',
          key: 'elemeSentCoupons',
          num: storePerformance?.cdPerformanceByStore?.elemePerformance.totalSentCoupons ?? 0,
          suffix: '张',
        },
        {
          label: '饿了么增量订单',
          key: 'elemeFirstOrders',
          num: storePerformance?.cdPerformanceByStore?.elemePerformance.totalFirstOrders ?? 0,
          suffix: '单',
        },
        {
          label: '饿了么增量营收',
          key: 'elemeFirstOrderRevenue',
          num: moneyToNumber(storePerformance?.cdPerformanceByStore?.elemePerformance.totalFirstOrderRevenue) ?? 0,
          suffix: '元',
        },
      ],

      shouldShowDetail: storePerformance
        ? (storePerformance.cdPerformanceByStore?.totalPerformance.totalFirstOrders ?? 0) >= 5
        : undefined,
    }),
    [storePerformance]
  );

  useEffect(() => {
    if (storePerformance) {
      trackEvent('achievement_page_performanceData', {
        page_name: 'ACHIEVEMENT_PAGE',
        product_name: getTrackingName(productName),
        isEffectivePerformance: achievements.shouldShowDetail,
        meituanPerformance: achievements.meituanCouponList.reduce<Record<string, number>>(
          (pre, { key, num }) => Object.assign({}, pre, { [key]: num }),
          {}
        ),
        elemePerformance: achievements.elemeCouponList.reduce<Record<string, number>>(
          (pre, { key, num }) => Object.assign({}, pre, { [key]: num }),
          {}
        ),
      });
    }
  }, [achievements, storePerformance]);

  return achievements;
};

function useTimeRangeFromSubscription(storeId: string) {
  const { data: bundle } = useBundle();
  const { ecdData, mcdData } = useXCDSubscriptions(storeId);

  const res = useMemo(() => {
    if (bundle) {
      if (bundle.expired) {
        if (ecdData || mcdData) {
          const eStartTime = moment(ecdData?.startTime).unix();
          const mStartTime = moment(mcdData?.startTime).unix();
          const eEndTime = moment(ecdData?.endTime).unix();
          const mEndTime = moment(mcdData?.endTime).unix();
          return {
            startTime: moment(
              (ecdData && mcdData ? (eStartTime < mStartTime ? ecdData : mcdData) : ecdData || mcdData)!.startTime
            ).format('YYYY-MM-DD'),
            endTime: moment(
              (ecdData && mcdData ? (eEndTime > mEndTime ? ecdData : mcdData) : ecdData || mcdData)!.endTime
            ).format('YYYY-MM-DD'),
          };
        } else {
          return { startTime: undefined, endTime: undefined };
        }
      } else {
        return {
          startTime: moment().subtract(30, 'days').format('YYYY-MM-DD'),
          endTime: moment().format('YYYY-MM-DD'),
        };
      }
    } else {
      return { startTime: undefined, endTime: undefined };
    }
  }, [bundle, ecdData, mcdData]);

  return res;
}
