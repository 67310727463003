import React from 'react';

type PlanName = string;

interface PlanDisplaySpec {
  title: React.ReactNode;
  note: React.ReactNode;
  banner: React.ReactNode;
}

export const override: Record<PlanName, Partial<PlanDisplaySpec>> = {
  'vas.ofoapi.recurring.p12m.facility.chn.v0': {
    banner: '包年不限量',
    note: '12个月内有效',
  },

  'vas.ofoapi.recurring.p1m.facility.chn.v0': {
    banner: '包月不限量',
    note: ' 30天内有效',
  },

  'vas.ofoapi.p12m.count100.facility.chn.v0': {
    banner: '定量包',
    note: '平均每单￥0.02',
  },

  'vas.ofoapi.p12m.count300.facility.chn.v0': {
    banner: '定量包',
    note: '平均每单￥0.02',
  },

  'vas.ofoapi.p12m.count500.facility.chn.v0': {
    banner: '定量包',
    note: '平均每单￥0.02',
  },

  'vas.ofoapi.p12m.count1000.facility.chn.v0': {
    banner: '定量包',
    note: '平均每单￥0.02',
  },

  'vas.ofoapi.p12m.count2000.facility.chn.v0': {
    banner: '定量包',
    note: '平均每单￥0.02',
  },

  'vas.ofoapi.p12m.count4000.facility.chn.v0': {
    banner: '定量包',
    note: '平均每单￥0.02',
  },
};
