import * as React from 'react';

function SvgClockEmpty(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M6.994.333A6.663 6.663 0 00.333 7c0 3.68 2.98 6.667 6.66 6.667A6.67 6.67 0 0013.666 7 6.67 6.67 0 006.994.333zm.006 12A5.332 5.332 0 011.667 7 5.332 5.332 0 017 1.667 5.332 5.332 0 0112.334 7 5.332 5.332 0 017 12.333zm.333-8.666h-1v4l3.5 2.1.5-.82-3-1.78v-3.5z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgClockEmpty);
export default ForwardRef;
