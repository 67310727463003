import * as React from 'react';

function SvgCullinanCaretMulti(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path d="M10.27 6l3 3h-6l3-3zM10.27 14l-3-3h6l-3 3z" fill="currentColor" />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgCullinanCaretMulti);
export default ForwardRef;
