import * as React from 'react';

function SvgFunnel(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 12 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M0 1a1 1 0 011-1h10a1 1 0 110 2H1a1 1 0 01-1-1zM2 5a1 1 0 011-1h6a1 1 0 010 2H3a1 1 0 01-1-1zM8 9a1 1 0 00-1-1H5a1 1 0 000 2h2a1 1 0 001-1z"
        fill="#112232"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgFunnel);
export default ForwardRef;
