import { moneyToNumber } from '@css/currency';

import type { Duration, DisplayPlan, EnrichedFlatValueCoupon } from './types';
import { CouponEntity, Money } from '@/types';

const DAYS_PER_MONTH = 30;

export function getPeriodText(duration: Duration): string {
  return duration.days === 7 ? '首周' : duration.months === 1 ? '首月' : '首期';
}

/**
 * Get a plan's duration in unit of days
 */
export function getCurrentDuration(plan: DisplayPlan): number {
  const usedDuration = plan.firstDuration || plan.duration;

  return usedDuration.days > 0 ? usedDuration.days : usedDuration.months > 0 ? usedDuration.months * DAYS_PER_MONTH : 0;
}

/**
 * Find the minimum duration in unit of days among all plans
 */
export function getShortestDuration(plans: DisplayPlan[]): number {
  // Initialize as 12 months
  let shortestDuration = 12 * DAYS_PER_MONTH;

  plans.forEach((plan: DisplayPlan) => {
    const currentDuration = getCurrentDuration(plan);
    shortestDuration = Math.min(shortestDuration, currentDuration);
  });

  return shortestDuration;
}

/**
 * Get unit price of the passed in plan, of the shortest
 * duration among a product's all plans.
 */
export function getShortestDurationPrice(plan: DisplayPlan, shortestDuration: number): string {
  const usedDuration = plan.firstDuration || plan.duration;
  const planDuration =
    usedDuration.days > 0 ? usedDuration.days : usedDuration.months > 0 ? usedDuration.months * DAYS_PER_MONTH : 0;

  const firstPurchaseUnitPrice = plan.firstPurchaseUnitPrice ? moneyToNumber(plan.firstPurchaseUnitPrice) : 0;
  const unitPrice = moneyToNumber(plan.unitPrice);
  const price = plan.autoRenewable && firstPurchaseUnitPrice > 0 ? firstPurchaseUnitPrice : unitPrice;
  const pricePerDuration = (price / planDuration) * shortestDuration;

  return Number.isInteger(pricePerDuration) ? pricePerDuration.toString() : Math.max(pricePerDuration, 0.01).toFixed(2);
}

export function getShortestDurationText(duration: number): string {
  if (duration === 7) {
    return '周';
  } else if (isNaN(duration / DAYS_PER_MONTH)) {
    return '期';
  } else if (duration / DAYS_PER_MONTH === 1) {
    return '月';
  } else if (duration / DAYS_PER_MONTH > 1) {
    return `${duration / DAYS_PER_MONTH}个月`;
  }

  return '期';
}

/***
 * @deprecated please switch to findBestCouponForPlanName
 */
export function findBestCouponForPlan(
  plan: DisplayPlan | undefined,
  coupons: Array<EnrichedFlatValueCoupon> | undefined
): EnrichedFlatValueCoupon | undefined {
  if (typeof plan === 'undefined' || typeof coupons === 'undefined') {
    return undefined;
  }
  const zeroMoney = {
    currencyCode: 'CNY',
    units: 0,
    nanos: 0,
  };
  const displayPriceMoney: Money =
    (plan.autoRenewable
      ? plan.firstPurchaseUnitPrice
      : plan.lineItemPricingSpec?.pricingSpecs[0]?.feeSpec.staticFeeUnitPrice) ?? zeroMoney;
  const bestCoupon = coupons
    .filter((coupon) => coupon.applicablePlans.includes(plan.name))
    .sort((couponA, couponB) => {
      const originPrice = moneyToNumber(displayPriceMoney);
      const finalPriceA = couponA.percentage
        ? originPrice * couponA.percentage
        : originPrice - moneyToNumber(couponA.value);
      const finalPriceB = couponB.percentage
        ? originPrice * couponB.percentage
        : originPrice - moneyToNumber(couponB.value);
      return finalPriceA - finalPriceB;
    })?.[0];

  return bestCoupon;
}

export function findBestCouponForPlanName(
  planName: string,
  coupons: Array<CouponEntity> | undefined
): string | undefined {
  if (typeof planName === 'undefined' || typeof coupons === 'undefined') {
    return undefined;
  }

  let result: CouponEntity | undefined = undefined;

  for (const coupon of coupons) {
    if (coupon.plans.find((plan) => plan.name === planName)) {
      if (result) {
        if (moneyToNumber(coupon.amountOff) > moneyToNumber(result.amountOff)) {
          result = coupon;
        }
      } else {
        result = coupon;
      }
    }
  }

  return result?.code;
}

export const getUnitDuration = (duration: { months: number; days: number }) => {
  if (duration.months) {
    if (duration.months === 12) {
      return {
        unit: '年',
        value: 1,
      };
    }
    return {
      unit: '月',
      value: duration.months,
    };
  } else if (duration.days) {
    return {
      unit: '周',
      value: duration.days / 7,
    };
  } else {
    return {
      unit: '',
      value: 1,
    };
  }
};
