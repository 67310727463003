const STOP_PROPAGATION = (ev: React.MouseEvent) => ev.stopPropagation();

export function stopPropagation(fn?: (ev: React.MouseEvent) => any): (ev: React.MouseEvent) => void {
  if (!fn) {
    // return a constant to prevent unnecessary react re-renders
    return STOP_PROPAGATION;
  }
  return (ev: React.MouseEvent) => {
    ev.stopPropagation();
    fn(ev);
  };
}
