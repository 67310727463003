import { QueryOptions, useQuery, useQueryClient } from '@tanstack/react-query';
import { useParams, useHistory, useLocation } from 'react-router-dom';

import { urqlClient } from '../../http-clients';
import { queryVasBundle } from '../../query/vasBundle';

import moment from 'moment';
import { fetchVasStoreId, useVasStoreId } from '../../hooks/use-vas-store-id';
import { TDeepLink } from '@/app-binding/deeplink';
import { getLinkForPrintTemplate } from '@/app-binding/deeplink';
import { useEffect } from 'react';
import { createQuery } from 'react-query-kit';
import { queryClient } from '@/query-client';

interface IBundleGraphqlResult {
  vasBundle: {
    bundle: {
      name: string;
      status: 'UNKNOWN' | 'AUTO_RENEWAL' | 'IN_USE' | 'EXPIRED' | 'IN_USE_NO_RENEWAL';
      shouldRenew?: boolean;
      subscriptionEndTime?: string;
      points: {
        id: string;
        name: string;
        deepLink: TDeepLink;
      }[];
    };
  };
}
interface IBundleResponse {
  response: IBundleGraphqlResult;
  imminent: boolean;
  expired: boolean;
}

async function fetchBundle({ productName }: { productName: string }): Promise<IBundleResponse> {
  const storeId = await fetchVasStoreId();
  const { data: response } = await urqlClient.query<IBundleGraphqlResult>(queryVasBundle, {
    entityId: storeId,
    productName: productName,
  });

  const { status, shouldRenew, subscriptionEndTime, points = [] } = response?.vasBundle?.bundle ?? {};
  const imminent =
    shouldRenew ||
    status === 'EXPIRED' ||
    (status === 'IN_USE' && moment(subscriptionEndTime).diff(moment(), 'days') < 3);

  const overridedPoints =
    productName === 'vasbundleorder'
      ? points.map((point) => {
          const deepLink = overrideFeaturePointDeeplink({
            name: point.name,
            deepLink: point.deepLink,
          });
          return {
            ...point,
            deepLink,
          };
        })
      : response?.vasBundle?.bundle?.points ?? [];

  return {
    response: {
      vasBundle: {
        bundle: {
          ...(response?.vasBundle?.bundle ?? { status: 'UNKNOWN', name: '' }),
          points: overridedPoints,
        },
      },
    },
    imminent,
    expired: status === 'EXPIRED',
  };
}

const useBundleQuery = createQuery<IBundleResponse, { productName: string }>({
  primaryKey: 'bundle-detail',
  staleTime: 1000 * 10,
  useDefaultOptions: () => {
    const { bundleId: _bundleId, productName: _productName } = useParams<{ bundleId?: string; productName?: string }>();
    const productName = _productName ?? _bundleId;

    return {
      variables: {
        productName,
      } as {
        productName: string;
      },
    };
  },
  queryFn: async function ({ queryKey: [, { productName }] }) {
    const data = await fetchBundle({ productName });

    for (const point of data.response.vasBundle.bundle.points) {
      queryClient.setQueryData(['feature-point', point.name], point);
    }

    return data;
  },
});

export const useBundleStatus = createQuery<string, { productName: string }>({
  primaryKey: 'bundle-detail',
  staleTime: 1000 * 10,
  queryFn: async function ({ queryKey: [, { productName }] }) {
    const data = await fetchBundle({ productName });

    for (const point of data.response.vasBundle.bundle.points) {
      queryClient.setQueryData(['feature-point', point.name], point);
    }

    return data.response.vasBundle.bundle.status;
  },
});

export function useBundle(options?: QueryOptions<IBundleResponse>) {
  const location = useLocation();
  const history = useHistory();

  const queryResult = useBundleQuery(options);
  const { data: storeId } = useVasStoreId();

  useEffect(
    function () {
      const status = queryResult.data?.response.vasBundle?.bundle?.status;
      const search = new URLSearchParams(location.search);
      search.set('storeId', (storeId as string) ?? '');

      // TODO: 这里的逻辑应该挪到 landing page 去
      if (status === 'UNKNOWN') {
        history.replace({
          ...location,
          pathname: location.pathname.replace('bundle/', 'iap/'),
          search: search.toString(),
        });
      } else if (status === 'EXPIRED') {
        if (location.pathname.startsWith('/bundle/')) {
          history.replace({
            ...location,
            pathname: location.pathname.replace('bundle/', 'renew/'),
            search: search.toString(),
          });
        }
      }
    },
    [queryResult.data]
  );

  return queryResult;
}

function overrideFeaturePointDeeplink({ name, deepLink }: { name: string; deepLink: TDeepLink }): TDeepLink {
  if (name === 'LOGO') {
    return getLinkForPrintTemplate('edit_logo', deepLink);
  } else if (name === 'CUSTOMIZATION_NOTE') {
    return getLinkForPrintTemplate('edit_seller_note', deepLink);
  } else if (name === 'LINE_SPACING') {
    return getLinkForPrintTemplate('edit_line_spacing', deepLink);
  } else {
    return deepLink;
  }
}

export function useFeaturePoint({ name }: { name: string }) {
  const { data } = useBundle();

  const queryClient = useQueryClient();

  return useQuery(
    ['feature-point', name],
    function () {
      return data?.response.vasBundle?.bundle?.points.find((point) => point.name === name);
    },
    {
      enabled: Boolean(data),
      staleTime: 1000 * 10,
      initialData: () => {
        return queryClient.getQueryData(useBundleQuery.getKey());
      },
      initialDataUpdatedAt: () => {
        return queryClient.getQueryState(useBundleQuery.getKey())?.dataUpdatedAt;
      },
    }
  );
}
