import { cva } from 'class-variance-authority';
import { useParams } from 'react-router';
import type { TProduct } from '@/product-registry';
import moment from 'moment';
import { useBundle } from '@/bundle/components/use-bundle';
import { useEffect, useMemo, useState } from 'react';
import { FireButton } from '@/features/upsell/components/fire-button';
import { useStats } from '../hooks/use-stats';
import { images } from '../images';

const renewPageNotebook = cva(['flex flex-col justify-around py-8 max-w-md rounded-lg mx-auto relative']);
const renewPageNotebookInfo = cva(['top-[200px] flex-shrink mb-4']);
const renewPageNotebookInfoHeader = cva(['flex gap-2 justify-center items-baseline']);
const renewPageNotebookLetter = cva(['block w-full flex-shrink-0']);

export function Notebook() {
  const { productName } = useParams<{ productName: TProduct }>();
  return (
    <div className="mx-auto bg-white rounded-lg max-w-lg ">
      <div className={renewPageNotebook()}>
        <BundleUsageInfo />

        <img className={renewPageNotebookLetter()} src={images['./bookletter.png']} alt="" />

        <div className="block pt-4 mx-auto">
          <FireButton productName={productName} action="订阅" />
        </div>
      </div>
    </div>
  );
}

function BundleUsageInfo() {
  const now = moment().toISOString();

  const bundleQuery = useBundle();
  const [variables, setVariables] = useState<{
    toTime: string;
    fromTime: string;
    period: 30 | 7;
  }>(() => ({
    toTime: now,
    period: 7,
    fromTime: moment(now).subtract(7, 'days').toISOString(),
  }));

  useEffect(
    function () {
      if (bundleQuery.data) {
        if (bundleQuery.data.expired) {
          const toTime = bundleQuery.data.response.vasBundle.bundle.subscriptionEndTime ?? now;
          setVariables({
            period: 30,
            toTime,
            fromTime: moment(toTime).subtract(30, 'days').toISOString(),
          });
        } else {
          setVariables({
            period: 7,
            toTime: now,
            fromTime: moment(now).subtract(7, 'days').toISOString(),
          });
        }
      }
    },
    [bundleQuery.data]
  );

  const { data: count = 0 } = useStats({
    variables,
  });

  const shouldShowDetail = useMemo(
    function () {
      // NOTE:  如果用户过期，那么超过 120 才显示，如果用户没过期
      // 那么超过 30 就显示（因为没过期，取的是过去一周的数据）
      // 这里的逻辑应该会挪到 backend
      if (bundleQuery.data?.expired) {
        return count > 120;
      } else {
        count > 30;
      }
    },
    [count, bundleQuery.data?.expired]
  );

  return (
    <div className={renewPageNotebookInfo()}>
      <div className={renewPageNotebookInfoHeader()}>
        <h2 className="text-3xl font-bold text-primary">接单大师</h2>
        {shouldShowDetail ? <b className="bg-primary text-white text-md rotate-12 ">最近{variables.period}日</b> : null}
      </div>
      {shouldShowDetail ? (
        <p className="text-center text-sm">
          处理订单<b className="text-primary text-2xl font-bold">{count}</b>次
        </p>
      ) : null}
    </div>
  );
}
