import * as React from 'react';

function SvgCompetitionInsights(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      fill="currentColor"
      width="1em"
      height="1em"
      ref={svgRef}
      {...props}
    >
      <path d="M5 6H3V4h2v2zM12 11H3V9h9v2zM8 16H3v-2h5v2zM14 6H6V4h8v2zM15 11h-2V9h2v2zM11 16H9v-2h2v2zM17 6h-2V4h2v2z" />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgCompetitionInsights);
export default ForwardRef;
