import React from 'react';
import { usePlanSelector } from '@/store/plan-slice';
import { useAmount } from '@/next/hooks/use-amount';

export function ButtonContent() {
  const currentPlan = usePlanSelector((state) => state.currentPlan);
  const getCurrentCouponCode = usePlanSelector((state) => state.getCurrentCouponCode);

  const query = useAmount({
    variables: { planName: currentPlan ?? '', couponCode: getCurrentCouponCode() ?? '' },
    enabled: !!currentPlan,
  });

  return (
    <span className="text-lg">
      确认协议并以<span className="text-3xl font-bold">{query.data}</span>元开通
    </span>
  );
}
