import * as React from 'react';

function SvgReviews(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <rect width={24} height={24} rx={6} fill="#1C69E8" />
      <path
        d="M11.15 4.366a1 1 0 011.7 0l1.95 3.139a1 1 0 00.61.442l3.588.886a1 1 0 01.525 1.616l-2.382 2.825a1 1 0 00-.233.717l.266 3.686a1 1 0 01-1.374.998l-3.423-1.392a1 1 0 00-.754 0L8.2 18.675a1 1 0 01-1.374-.998l.266-3.686a1 1 0 00-.233-.716l-2.382-2.826a1 1 0 01.525-1.616l3.588-.886a1 1 0 00.61-.442l1.95-3.139z"
        fill="#fff"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgReviews);
export default ForwardRef;
