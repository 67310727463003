import * as React from 'react';

function SvgStarFilled(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 30 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M15 0l4.85 8.048 9.416 1.971-6.42 6.945.97 9.267L15 22.475l-8.817 3.756.97-9.267L.735 10.02l9.417-1.97L15 0z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgStarFilled);
export default ForwardRef;
