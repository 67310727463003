import queryString from 'query-string';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

export const useSearchParams = (): { [k: string]: string } => {
  const { search } = useLocation();
  const params = useMemo(() => {
    const searchParams = new URLSearchParams(search);

    // inline the polyfill for Object.fromEntries
    // source: https://github.com/feross/fromentries/blob/master/index.js
    return [...searchParams].reduce((obj, [key, val]) => {
      obj[key] = val;
      return obj;
    }, {} as Record<string, string>);
  }, [search]);

  return params;
};

export default useSearchParams;

export function getSearchParams() {
  const { search } = window.location;
  return queryString.parse(search);
}
