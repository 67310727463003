import * as React from 'react';

function SvgEye(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 17 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.518.653A8.595 8.595 0 005.36.936l.002.001a8.656 8.656 0 00-5.354 5.06c1.259 3.207 4.363 5.483 8 5.483 3.636 0 6.74-2.276 7.999-5.483a8.656 8.656 0 00-5.353-5.06h.001c-.37-.12-.75-.215-1.138-.284zm-.43 1.859a3.66 3.66 0 102.204 1.916 3.655 3.655 0 00-2.203-1.917z"
        fill="currentColor"
      />
      <path d="M10.177 6.003a2.19 2.19 0 11-4.379 0 2.19 2.19 0 014.38 0z" fill="currentColor" />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgEye);
export default ForwardRef;
