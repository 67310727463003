import * as React from 'react';

function SvgCullinanFinancials(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 20 20"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      ref={svgRef}
      {...props}
    >
      <path d="M13.16 14.285c-.512.627-1.218 1.025-2.094 1.194l-.04.008V17.45H9.059V15.487l-.04-.008c-.92-.177-1.65-.58-2.157-1.164-.498-.574-.782-1.327-.81-2.221l2.458-.314c.015.562.157 1 .423 1.299.275.31.677.465 1.187.465.402 0 .728-.111.953-.31.227-.198.348-.48.348-.812 0-.285-.05-.496-.236-.67-.18-.17-.482-.302-.967-.449l-.814-.248c-1.122-.356-1.898-.803-2.325-1.335-.427-.533-.65-1.205-.65-2.034 0-.765.236-1.437.693-2.018.47-.58 1.106-.963 1.897-1.132l.04-.008V2.55h1.967v1.978l.039.008c1.537.35 2.515 1.476 2.656 2.983l-2.42.313c-.059-.453-.186-.794-.394-1.024-.217-.24-.517-.352-.9-.352-.394 0-.709.095-.926.27a.898.898 0 00-.333.727c0 .256.05.457.216.626.163.164.43.291.846.416l.998.28c1.121.332 1.906.748 2.41 1.284.503.535.732 1.194.732 2.022 0 .842-.263 1.576-.79 2.204z" />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgCullinanFinancials);
export default ForwardRef;
