import * as React from 'react';

function SvgLightningStrike(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path d="M11 2l-6 9h4v7l6-9h-4V2zM17.652 3.409l-2.622 2.62-1.06-1.06 2.621-2.62 1.06 1.06zM3.53 17.53l2.5-2.5-1.06-1.06-2.5 2.5 1.06 1.06zM4.97 6.03l-2.5-2.5 1.06-1.06 2.5 2.5-1.06 1.06zM13.97 15.03l2.621 2.622 1.06-1.061-2.62-2.621-1.061 1.06z" />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgLightningStrike);
export default ForwardRef;
