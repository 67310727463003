import * as React from 'react';

function SvgHubsterLogo(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 154 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M138.53 33.252h6.468V20.89c0-1.849.517-3.85 1.331-4.65.83-.813 2.125-1.316 3.953-1.316h1.184l2.157-6.468h-3.341c-3.912 0-5.988 1.033-8.266 3.108-2.303 2.098-3.486 5.137-3.486 8.817v12.87zM125.749 9.535c-7.361 0-12.014 5.475-12.014 11.875 0 6.674 5.382 11.842 12.327 11.842 3.959 0 7.674-1.746 9.758-4.655l-4.063-3.217c-1.354 1.746-3.612 2.499-5.695 2.499-3.473 0-5.764-1.985-6.424-4.723h17.675c.104-.582.139-1.267.139-1.883-.035-6.331-4.862-11.738-11.703-11.738zm-5.938 9.48c.869-2.465 3.126-4.107 5.904-4.107 2.882 0 5.313 1.78 5.66 4.107h-11.564zM105.058 16.003v6.904c0 1.553.556 2.64 1.53 3.26.974.586 3.669.81 6.069.673v6.2c-1.113.103-1.774.155-2.226.155a15.05 15.05 0 01-1.096.052c-1.53.034-2.956-.104-4.277-.414-1.287-.276-2.417-.76-3.391-1.449-.939-.69-1.686-1.621-2.243-2.794-.521-1.173-.782-2.82-.782-4.544V.91l6.416 2.156v7.547h8.677l-2.157 5.39h-6.52zM88.94 24.627a1.617 1.617 0 010 3.234H77.08l-2.155 5.39h15.092v-.02a7.007 7.007 0 10.54-13.994h-7.008a1.617 1.617 0 010-3.234h11.859l2.156-5.39H82.47v.02a7.007 7.007 0 10-.54 13.994h7.008zM25.334 10.613h6.469v10.78a5.39 5.39 0 1010.78 0v-10.78h6.468v10.78c0 6.55-5.309 11.859-11.858 11.859-6.55 0-11.859-5.31-11.859-11.859v-10.78z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M57.137 3.066L50.669.91v21.561c0 6.55 5.309 11.859 11.858 11.859 6.55 0 11.859-5.31 11.859-11.859s-5.31-11.858-11.859-11.858c-1.94 0-3.773.466-5.39 1.293v-8.84zm0 19.405a5.39 5.39 0 1010.78 0 5.39 5.39 0 00-10.78 0z"
        fill="currentColor"
      />
      <path
        d="M6.468 3.066L0 .91v32.342h6.468v-10.78a5.39 5.39 0 1110.78 0v10.78h6.47V21.393c0-6.55-5.31-10.78-11.86-10.78-1.94 0-3.772.466-5.39 1.293v-8.84z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgHubsterLogo);
export default ForwardRef;
