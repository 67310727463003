import { cva } from 'class-variance-authority';
import { Route, Switch, useParams } from 'react-router';
import { Notebook } from './notebook';
import { PlanSelector } from '@/iap/planSelector/plan-selector-ng';
import { PlanNoteNg } from '@/iap/PlanNote';
import { RegularCouponButtonNg } from '@/features/coupon/components/RegularCouponButton';
import { images } from '../images';
import { Cond } from '@/next/components/cond';
import { shouldDisplayPlans } from '@/app-binding/qs';
import { useEffect } from 'react';
import { cssTracking } from '@/tracking/css-tracking';

const renewPageBackground = cva(['block overflow-auto bg-cover']);

export function VasBundleOrderContent() {
  const { productName } = useParams<{ productName: string }>();

  useEffect(function () {
    cssTracking('page_loaded', {
      url: window.location.href,
    });
  }, []);

  return (
    <div
      className={renewPageBackground()}
      style={{
        backgroundImage: `url(${images['./bg.png']})`,
      }}
    >
      <div className="p-4 bg-white m-2 rounded-md">
        <Notebook />
        <Switch>
          <Route path="/renew/:productName(vasbundleorder)">
            <Cond when={shouldDisplayPlans()}>
              <PlanSelector productName={productName} />
              <PlanNoteNg />
              <RegularCouponButtonNg fromPage={`/renew/${productName}`} />
            </Cond>
          </Route>
        </Switch>
      </div>
    </div>
  );
}
