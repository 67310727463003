import { Img } from '@/components/image';
import { fetchRemoteContent } from '@/features/cms/components/remote-content';
import { useQuery } from '@tanstack/react-query';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

const Wrapper = styled.div`
  .alice-carousel {
    margin: 0;
  }
`;

async function getCarouselItemsByProductName(productName: string) {
  const { carouselImages } = await fetchRemoteContent({ category: 'upsell', productName });
  return carouselImages;
}

export function Carousel() {
  const { productName } = useParams<{ productName: string }>();

  const { data: items } = useQuery(
    ['carousel-images', productName],
    async function () {
      const items = await getCarouselItemsByProductName(productName);
      return items;
    },
    {
      select: function (data) {
        return Object.values(data).map((image, index) => (
          <Img
            style={{
              display: 'block',
              width: '100%',
              margin: 'auto',
            }}
            key={image}
            loading="eager"
            decoding="async"
            trackingName={`carousel-image:${productName}`}
            src={image}
            data-value={index}
            alt=""
          />
        ));
      },
    }
  );

  const hasMultipleImages = items && items.length > 1;

  return (
    <Wrapper>
      <AliceCarousel
        autoWidth
        autoPlay={hasMultipleImages}
        autoPlayStrategy="none"
        autoPlayInterval={1500}
        animationDuration={1500}
        animationType="slide"
        infinite={hasMultipleImages}
        touchTracking={hasMultipleImages}
        disableDotsControls
        disableButtonsControls
        disableSlideInfo
        items={items}
      />
    </Wrapper>
  );
}
