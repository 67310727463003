import * as React from 'react';

function SvgNav(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 18 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <rect width={18} height={2} rx={1} fill="#112232" />
      <rect y={6} width={18} height={2} rx={1} fill="#112232" />
      <rect y={12} width={18} height={2} rx={1} fill="#112232" />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgNav);
export default ForwardRef;
