import styled from 'styled-components';
import {
  background,
  BackgroundProps,
  border,
  BorderProps,
  color,
  ColorProps,
  flexbox,
  FlexboxProps,
  grid,
  GridProps,
  layout,
  LayoutProps,
  position,
  PositionProps,
  shadow,
  ShadowProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
} from 'styled-system';

import { injDataTest } from '../../../utilities/injDataTest';

export interface BoxPropsInner
  extends BackgroundProps,
    BorderProps,
    ColorProps,
    FlexboxProps,
    GridProps,
    LayoutProps,
    PositionProps,
    ShadowProps,
    SpaceProps,
    TypographyProps {
  /**
   * Optional data test id.
   * @default bm-box
   */
  dataTest?: string;
}

export type BoxProps = React.HTMLAttributes<HTMLElement> & BoxPropsInner;

/**
 * The Box component is a low-level primitive used for layout purposes. It can be used to create grid layouts, apply padding or margin, and more.
 *
 * https://styled-system.com/guides/build-a-box/
 */
export const Box = styled.div.attrs<BoxPropsInner>((props) => injDataTest(props.dataTest || 'op-box'))<BoxPropsInner>`
  box-sizing: border-box;
  min-width: 0;
  ${background}
  ${border}
  ${color}
  ${flexbox}
  ${grid}
  ${layout}
  ${position}
  ${shadow}
  ${space}
  ${typography}
`;
