import { spawnHttpClient } from '../../../http-clients';
import { useEffect, useState } from 'react';

type PerformanceFragment = {
  __typename?: 'CDPerformanceData';
  totalBoostedOrders: number;
  totalFirstOrders: number;
  totalRetentionOrders: number;
  totalBoostedRevenue: { __typename?: 'Money'; currencyCode: string; nanos: number; units: number };
  totalFirstOrderRevenue: {
    __typename?: 'Money';
    currencyCode: string;
    nanos: number;
    units: number;
  };
  totalRetentionRevenue: {
    __typename?: 'Money';
    currencyCode: string;
    nanos: number;
    units: number;
  };
  averageOrderRevenue: { __typename?: 'Money'; currencyCode: string; nanos: number; units: number };
  totalSentCoupons: number;
};

type EcdOverralPerformanceResponse = {
  cdPerformanceByStore: {
    elemePerformance: PerformanceFragment;
    meituanPerformance: PerformanceFragment;
    totalPerformance: PerformanceFragment;
  };
};

const useCdStorePerformance = (
  storeId?: string,
  startDate?: string,
  endDate?: string
): EcdOverralPerformanceResponse | undefined => {
  const [overralPerformanceData, setOverralPerformanceData] = useState<EcdOverralPerformanceResponse>();

  useEffect(() => {
    if (storeId && startDate && endDate) {
      spawnHttpClient({})
        .get<{ data: EcdOverralPerformanceResponse }>({
          path: `/graphql`,
          method: 'POST',
          requestParams: {
            data: {
              query: CDStorePerformanceQuery,
              variables: {
                input: {
                  storeId,
                  dateRange: {
                    startDayInclusive: startDate,
                    endDayInclusive: endDate,
                  },
                },
              },
            },
          },
        })
        .then((res) => {
          setOverralPerformanceData(res?.data ?? undefined);
        })
        .catch((e) => {});
    }
  }, [storeId, startDate, endDate]);

  return overralPerformanceData;
};

export default useCdStorePerformance;

const CDStorePerformanceQuery = `
query cdPerformanceByStore($input: CDPerformanceByStoreInput!) {
  cdPerformanceByStore(input: $input) {
    totalPerformance {
      ...PerformanceData
    }
    meituanPerformance {
      ...PerformanceData
    }
    elemePerformance {
      ...PerformanceData
    }
  }
}

fragment PerformanceData on CDPerformanceData {
  totalBoostedRevenue {
    ...MoneyValue
  }
  totalFirstOrderRevenue {
    ...MoneyValue
  }
  totalRetentionRevenue {
    ...MoneyValue
  }
  totalBoostedOrders
  totalFirstOrders
  totalSentCoupons
  totalRetentionOrders
  averageOrderRevenue {
    ...MoneyValue
  }
}
fragment MoneyValue on Money {
  currencyCode
  nanos
  units
}

`;
