import * as React from 'react';

function SvgFilledQuestionMark(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <circle cx={8} cy={8} r={8} fill="#ECECEC" />
      <path
        d="M8.296 7.756c-.354.311-.568.556-.658.733-.082.177-.124.455-.115.85h.864c.008-.235.033-.404.074-.513.05-.11.197-.278.453-.497.263-.228.436-.388.518-.472a3.12 3.12 0 00.23-.261c.231-.312.338-.633.338-.995C10 5.632 9.309 5 8.165 5c-.626 0-1.144.194-1.548.581-.403.388-.609.902-.617 1.534l.897.152c.041-.936.494-1.45 1.218-1.45.568 0 .89.312.89.826a.915.915 0 01-.133.497c-.082.144-.271.354-.576.616zM8.47 11V9.904H7.424V11h1.045z"
        fill="#707070"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgFilledQuestionMark);
export default ForwardRef;
