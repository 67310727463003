import { jsbc } from '@/jsbc';
import { createLogger } from '@/logger';
import { createQuery } from 'react-query-kit';

const logger = createLogger('use-faciliy-id');
export const useFacilityId = createQuery({
  primaryKey: 'get-facility-id',
  queryFn: async function () {
    const facilityId = await jsbc.getFacilityId();

    if (facilityId) {
      logger.debug({ facilityId }, 'get facility id from jsbc');

      return facilityId;
    }
    throw new Error('facility id is not found');
  },
});
