import * as React from 'react';

function SvgExclamationTriangleFilledRounded(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 14 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M1.98 11.67h10.04a1.333 1.333 0 001.154-2L8.154.995a1.333 1.333 0 00-2.307 0L.827 9.669c-.513.887.127 2 1.154 2zM7 7.001a.669.669 0 01-.666-.666V5.002c0-.366.3-.666.667-.666.366 0 .666.3.666.666v1.334c0 .366-.3.666-.666.666zm.667 2.667H6.334V8.336h1.333v1.333z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgExclamationTriangleFilledRounded);
export default ForwardRef;
