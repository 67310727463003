import * as React from 'react';

function SvgPin(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 12 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.698 14.446c1.206-1.368 5.024-5.893 5.024-8.379 0-2.952-2.411-5.345-5.385-5.345C3.363.722.952 3.115.952 6.067c0 2.486 3.818 7.011 5.024 8.38a.479.479 0 00.722 0zm-.361-6.088a2.3 2.3 0 002.308-2.29 2.3 2.3 0 00-2.308-2.291 2.3 2.3 0 00-2.308 2.29 2.3 2.3 0 002.308 2.291z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgPin);
export default ForwardRef;
