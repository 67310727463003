import recommendImage from './recommend-table.png';

export function RecommendTable() {
  return (
    <section className="mb-4">
      <p>预估单量与实际单量可能有差异，建议您购买时适当留出余量，以避免超过单量额度时无法接单</p>
      <img src={recommendImage} className="block w-full my-2" alt="单量包购买参考表" />
    </section>
  );
}
