import * as React from 'react';

function SvgMinimize(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M0 23.571h6.429V30h4.285V19.286H0v4.285zM6.429 6.43H0v4.285h10.714V0H6.43v6.429zM19.286 30h4.285v-6.429H30v-4.285H19.286V30zM23.57 6.429V0h-4.285v10.714H30V6.43h-6.429z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgMinimize);
export default ForwardRef;
