import * as React from 'react';

function SvgDomeServingTray(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 55 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      ref={svgRef}
      {...props}
    >
      <path
        d="M.532 24.172c0 2.462 2.084 4.546 4.547 4.546h2.083c1.516 0 3.032.948 3.79 2.274.757 1.326 2.272 2.273 3.788 2.273h25.953c1.705 0 3.03-.947 3.788-2.273s2.084-2.274 3.79-2.274h2.083c2.463 0 4.546-2.084 4.546-4.546H.532z"
        fill="#2F3A4C"
      />
      <path
        d="M51.112 24.172c-3.22-9.85-12.502-17.049-23.3-17.049-10.987 0-20.08 7.199-23.3 17.05h46.6z"
        fill="#B8C2D1"
      />
      <path
        d="M27.622 7.31c1.705 0 3.22-1.515 3.22-3.22 0-1.894-1.515-3.22-3.22-3.22-1.705 0-3.22 1.516-3.22 3.22 0 1.705 1.515 3.22 3.22 3.22z"
        fill="#2F3A4C"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgDomeServingTray);
export default ForwardRef;
