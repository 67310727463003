import LoadingOverlay from '@/common/components/LoadingOverlay/LoadingOverlay';
import { IS_INTEGRATION_MODE } from '@/constant';
import { PaymentError } from '@/errors';
import { planNameIsOfoApiBundle } from '@/features/plan/utils';
import useSearchParams from '@/hooks/useSearchParams';
import { PaymentMethodType } from '@/iap/types';
import { trackEvent } from '@/mixpanel';
import { usePay } from '@/next/hooks/use-pay';
import { getTrackingName } from '@/product-registry';
import { useIsCssFacility } from '@/rqs/facility';
import { capturePaymentException } from '@/sentry/capture';
import { usePlanSelector } from '@/store/plan-slice';
import { useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router';

export function PaymentCreate() {
  const { productName, planName, paymentMethod } = useParams<{
    productName: string;
    planName: string;
    paymentMethod: PaymentMethodType;
  }>();
  const [lines, setLines] = useState<Array<string>>([]);
  const { source = 'web' } = useSearchParams();

  const { couponCode: _couponCode, couponId } = useSearchParams();
  const couponCode = useMemo(() => _couponCode ?? couponId, [_couponCode, couponId]);

  useEffect(
    function () {
      trackEvent('payment_create_page_loaded', {
        productName: getTrackingName(productName),
        planName,
        paymentMethod,
      });
    },
    [planName, paymentMethod, productName]
  );

  const mutation = usePay({});

  const history = useHistory();

  const getCouponName = usePlanSelector((state) => state.getCouponName);
  const { data: is_css_facility } = useIsCssFacility();

  useEffect(function () {
    mutation.mutate(
      {
        planName,
        couponCode,
        paymentMethod,
        source,
      },
      {
        onSuccess: function (paymentRecordId) {
          trackEvent('payment_order_created', {
            paymentMethodType: paymentMethod,
            product_name: getTrackingName(planNameIsOfoApiBundle(planName) ? 'ofoapibundle' : productName),
            planName,
            is_css_facility,
          });

          const couponName = getCouponName(couponCode) ?? 'n/a';
          history.replace(`/payment/pending/${paymentRecordId}`, {
            planName,
            productName,
            couponName,
          });
        },
      }
    );
  }, []);

  // clear page lines after unmount.
  useEffect(function () {
    return function () {
      setLines([]);
    };
  }, []);

  return (
    <div className="flex flex-col justify-center text-neutral-500">
      <LoadingOverlay />
      {IS_INTEGRATION_MODE
        ? lines.map((line) => (
            <p className="text-white" key={line}>
              {line}
            </p>
          ))
        : null}
    </div>
  );
}
