import { RemoteContent } from '@/features/cms/components/remote-content';
import { PageDefinition } from '@/iap/pages';

export const page: PageDefinition = {
  slug: 'cpc',
  hero: {
    CustomComponent: () => <RemoteContent contentKey={['iap', 'cpc']} slot="headerImages" />,
  },
  detail: {
    CustomComponent: () => <RemoteContent contentKey={['iap', 'cpc']} slot="detailImages" />,
  },
  planProductName: 'cpc',
  title: '推广助手',
  trackingProductName: 'cpc', //FIXME: ensure this.
  defaultCallbackUrl: '', //FIXME: ensure this also.
};
