import * as React from 'react';

function SvgCross(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M9.699.5a.795.795 0 00-1.125 0L5.199 3.877 1.824.5A.796.796 0 00.699 1.626L4.074 5 .699 8.376A.795.795 0 001.824 9.5l3.375-3.375L8.574 9.5a.795.795 0 001.125-1.125L6.324 5l3.375-3.375a.795.795 0 000-1.125z"
        fill="#707070"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgCross);
export default ForwardRef;
