import * as React from 'react';

function SvgClock(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 29 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M14.417 0C6.455 0 0 6.268 0 14s6.455 14 14.417 14c7.963 0 14.418-6.268 14.418-14S22.38 0 14.417 0zm0 4.828c.55 0 .995.432.995.965v7.815l4.987 4.827a.955.955 0 010 1.373 1.025 1.025 0 01-1.414 0l-5.267-5.13a.943.943 0 01-.295-.678V5.793c0-.533.445-.965.994-.965z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgClock);
export default ForwardRef;
