import * as React from 'react';

function SvgStarOutline(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 30 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M15 .993l4.417 7.584.111.19.215.047 8.578 1.858-5.848 6.544-.146.165.022.219.884 8.731-8.031-3.538-.202-.09-.202.09-8.03 3.538.883-8.731.022-.22-.146-.164-5.848-6.544 8.578-1.858.215-.046.11-.19L15 .992z"
        stroke="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgStarOutline);
export default ForwardRef;
