import { Suspense, useEffect } from 'react';
import { stickyFooterContainer } from '../product/sticky-footer-layout';
import { trackEvent } from '@/mixpanel';
import { useHistory, useLocation, useParams } from 'react-router';
import LoadingOverlay from '@/common/components/LoadingOverlay/LoadingOverlay';
import { LaunchPaymentButton } from '@/next/components/launch-payment-button';
import { cx } from 'class-variance-authority';
import useTitle from '@/hooks/useTitle';
import { usePreviewImage, useSingleTemplate } from './useTemplate';
import { Experiment } from '@/features/experiment/components/experiment';
import { usePlanSelector } from '@/store/plan-slice';
import { usePlan, useProduct } from '@/iap/use-product';
import { useCoupons } from '@/features/coupon/hooks/use-redeemable-coupons';
import { Agreements } from '@/common/components/agreements';
import { button } from '@/cva/button';
import { useAmount } from '@/next/hooks/use-amount';
import queryString from 'query-string';
import { templatePreviewMockImageMap } from './advanced-template-iap-map';

function AdvancedTemplateIAPPage() {
  return (
    <Suspense fallback={<LoadingOverlay />}>
      <div className={cx('pb-safe', stickyFooterContainer())}>
        <Content />
        <Footer />
      </div>
    </Suspense>
  );
}

function Content() {
  const { templateId, productName } = useParams<{ templateId: string; productName: string }>();
  console.log(productName);
  const couponQuery = useCoupons('REDEEMABLE');
  const productQuery = useProduct(productName);
  const init = usePlanSelector((state) => state.init);

  const useTemplatQuery = useSingleTemplate({ variables: { templateId }, suspense: true });
  const template = useTemplatQuery.data;

  const usePreviewImageQuery = usePreviewImage({ variables: { templateId }, suspense: true });
  const base64ImageString = usePreviewImageQuery.data;
  const templatePreviewImage = templatePreviewMockImageMap[template?.templateName ?? ''];
  useEffect(
    function () {
      if (couponQuery.isSuccess && productQuery.isSuccess) {
        const recommendedPlanName = productQuery.data.find((plan) => plan.isRecommended)?.name;
        init(productQuery.data, couponQuery.data, recommendedPlanName);
      }
    },
    [couponQuery.isSuccess, productQuery.isSuccess]
  );
  useEffect(
    function () {
      trackEvent('template_preview_page_loaded', {
        template_name: template?.templateName,
        product_name: productName,
      });
    },
    [useTemplatQuery.data]
  );

  useTitle(template?.templateName ?? '');

  return (
    <div className="flex flex-col items-center overflow-auto bg-slate-100 py-[20px] ">
      {typeof base64ImageString !== 'undefined' ? (
        <img className="w-[243px]" src={templatePreviewImage ?? toDataUrl(base64ImageString)} />
      ) : null}
    </div>
  );
}

function toDataUrl(base64: string, contentType: 'image/png' = 'image/png') {
  return `data:${contentType};base64,${base64}`;
}

function Footer() {
  return (
    <div className="flex flex-col py-3 px-4 gap-[10px]">
      <Experiment
        name="sales_plan_advance_template"
        variantMap={{
          A: PaymentButtonVariantA,
          B: PaymentButtonVariantB,
          default: PaymentButtonVariantB,
        }}
      />
    </div>
  );
}

function PaymentButtonVariantA() {
  const currentPlan = usePlanSelector((state) => state.currentPlan);
  console.log(currentPlan);
  return (
    <>
      <Agreements files={['user-agreement', 'privacy-policy']} />
      <LaunchPaymentButton planName={currentPlan ?? ''}>
        <ButtonContentVariantA />
      </LaunchPaymentButton>
    </>
  );
}

function PaymentButtonVariantB() {
  const currentPlan = usePlanSelector((state) => state.currentPlan);
  const history = useHistory();
  console.log(currentPlan);
  const { search } = useLocation();
  return (
    <button
      className={cx(button({ intent: 'primary', size: 'full', disabled: false }), 'font-semibold py-3')}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        history.push({
          ...location,
          pathname: '/landing/vasbundleorder',
          search: queryString.stringify({
            ...queryString.parse(search),
            utm_source: 'advanced_template',
            purchase_origin: 'advanced_template_preview',
          }),
        });
      }}
    >
      立即订阅接单大师
    </button>
  );
}

function ButtonContentVariantA() {
  const { productName } = useParams<{ productName: string }>();
  const currentPlan = usePlanSelector((state) => state.currentPlan);
  const usePlanQuery = usePlan(productName, currentPlan);
  const plan = usePlanQuery.data;
  const amountQuery = useAmount({
    variables: { planName: currentPlan ?? '' },
    enabled: !!currentPlan,
    suspense: true,
  });
  const amount = amountQuery.data;
  return (
    <div className="py-1 font-semibold">
      立即购买 <span className="line-through text-gray-200 font-normal">￥{plan?.basePriceNumber}</span> ￥{amount}
    </div>
  );
}

export default AdvancedTemplateIAPPage;
