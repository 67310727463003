import React from 'react';
import styled from 'styled-components';

import { RightArrowIcon } from './icons/rightArrow.svg';

const SelectCouponButton = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 12px;
  height: 44px;
  box-sizing: border-box;

  background: #f5f5f5;
  color: black;
  border-radius: 8px;
  border: none;

  margin: 16px 16px 0px;
  width: calc(100% - 32px);

  & > .text {
    padding: 0 8px;
    flex-grow: 1;
    text-align: left;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
  }
`;
export const FullWidthCTA = (props: {
  text: string;
  onClick?: () => void;
  Icon: React.ComponentType;
  className?: string;
  background?: string;
  isTextHighlighted?: boolean;
}) => {
  const Icon = props.Icon;
  return (
    <SelectCouponButton style={{ background: props.background }} className={props.className} onClick={props.onClick}>
      <Icon />
      <span className="text" style={props.isTextHighlighted ? { color: '#CC5A3D' } : {}}>
        {props.text}
      </span>
      {props.onClick && <RightArrowIcon />}
    </SelectCouponButton>
  );
};

export const PageContainer = styled.div`
  background: #f9f9f9;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px 16px 8px;
  overflow: hidden;
  box-sizing: border-box;
  * {
    box-sizing: border-box;
  }
`;
export const StyledFullWidthCTA = styled(FullWidthCTA)`
  background: rgba(0, 0, 0, 0.08);
  height: 64px;
  margin: 0 0 16px;
  width: 100%;
`;
export const StyledSectionHeader = styled.h6`
  margin: 0;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: start;
  width: 100%;
  padding: 8px 0;
  margin-top: 16px;
`;
export const CouponCardContainer = styled.div`
  display: grid;
  grid-template-columns: 86px 2px 1fr minmax(60px, auto);
  grid-gap: 10px;

  width: 100%;
  padding: 16px;

  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 12px;

  .price {
    font-family: PingFang SC;
    color: #cc5a3d;
    text-align: center;

    margin: auto;
  }

  .divider {
    height: 100%;
    border-left: 1px dashed rgba(0, 0, 0, 0.16);
  }

  .info {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .rightArea {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 5px;
  }

  .toIAU {
    display: flex;
    flex-direction: row;
    gap: 10px;
  }

  .remainingRedemptions {
    font-size: 14px;
    color: #525252;
  }

  .prompt {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #525252;

    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .icon {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &.disabled {
    .price {
      color: #8f8f8f;
    }
  }
`;
export const StyledCurrency = styled.span`
  height: 26px;

  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 32px;
`;
export const StyledPrice = styled.span`
  height: 36px;

  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 36px;
`;
export const StyledCouponTitle = styled.span`
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
`;
export const StyledCouponDescription = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  color: #8f8f8f;
`;
export const StyledCouponExpiryTime = styled.span`
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;

  color: #525252;

  margin-top: 12px;
`;
export const StyledCouponList = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: grid;
  grid-gap: 8px;
`;
export const StyledCenterContainer = styled.div`
  flex: 1 1 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
`;
export const StyledRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;
export const StyledEmptyListText = styled.div`
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #525252;
  text-align: center;
`;
