import * as React from 'react';

function SvgCullinanArrowRight(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path d="M16.5 9.996l-5.306-5.3L9.78 6.108l2.891 2.888h-9.17v1.998h9.17L9.78 13.883l1.414 1.413 5.306-5.3z" />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgCullinanArrowRight);
export default ForwardRef;
