import * as React from 'react';

function SvgDownload(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M8.992 1a.571.571 0 00-.563.571v10.052L4.837 8.03a.571.571 0 00-.807.808l4.567 4.57.006.005.001.001.018.017h0l.024.02v.002l.016.01.001.002.027.019.003.001a.269.269 0 00.02.013l.003.001.023.013.003.002a.165.165 0 00.02.01h.004l.023.011.004.002.025.009h.004a.388.388 0 00.02.008h.004l.026.007h.003a.228.228 0 00.024.006h.003a.356.356 0 00.025.004h.003a.315.315 0 00.027.003h.031l.014.001h.014a.556.556 0 00.05-.004h-.002.007-.003a.527.527 0 00.039-.006h-.004l.031-.006H9.13a.453.453 0 00.026-.006l-.004.001.038-.011h-.004l.024-.008-.003.001a.332.332 0 00.033-.014l-.003.001a.81.81 0 00.046-.023l-.003.002.004-.003-.002.002.034-.022-.002.002.024-.017-.001.001a.656.656 0 00.037-.03h0l.003-.003.027-.023 4.566-4.57a.57.57 0 00-.398-.98h-.017 0a.57.57 0 00-.392.172l-3.592 3.595V1.58v-.008a.57.57 0 00-.57-.571h-.01 0V1zM1.579 15.857H1.57a.57.57 0 100 1.143H16.429a.57.57 0 100-1.143H1.58z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth={0.5}
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgDownload);
export default ForwardRef;
