import { Button } from '../ui';

export function ModalHeader({ handleClose, title }: { handleClose: () => void; title: string }) {
  return (
    <div className="minute-input-modal">
      <div className="minute-input-modal__header">
        <Button small ghost></Button>
        <span className="minute-input-modal__header__title">{title}</span>
        <Button
          small
          ghost
          onClick={() => {
            handleClose();
          }}
          style={{
            color: '#e06e4d',
          }}
        >
          完成
        </Button>
      </div>
    </div>
  );
}
