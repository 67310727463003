import * as React from 'react';

function SvgArrange(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M.5 2.167h1.667V.5C1.25.5.5 1.25.5 2.167zm0 6.666h1.667V7.167H.5v1.666zM3.833 15.5H5.5v-1.667H3.833V15.5zM.5 5.5h1.667V3.833H.5V5.5zm8.333-5H7.167v1.667h1.666V.5zm5 0v1.667H15.5C15.5 1.25 14.75.5 13.833.5zm-11.666 15v-1.667H.5c0 .917.75 1.667 1.667 1.667zM.5 12.167h1.667V10.5H.5v1.667zM5.5.5H3.833v1.667H5.5V.5zm1.667 15h1.666v-1.667H7.167V15.5zm6.666-6.667H15.5V7.167h-1.667v1.666zm0 6.667c.917 0 1.667-.75 1.667-1.667h-1.667V15.5zm0-10H15.5V3.833h-1.667V5.5zm0 6.667H15.5V10.5h-1.667v1.667zM10.5 15.5h1.667v-1.667H10.5V15.5zm0-13.333h1.667V.5H10.5v1.667zm-6.667 10h8.334V3.833H3.833v8.334zM5.5 5.5h5v5h-5v-5z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgArrange);
export default ForwardRef;
