import { DisplayPlan } from '@/iap/types';
import { moneyToNumber, subtractFromMoney } from '@css/currency';
import { clamp } from 'lodash-es';
export function priceUsingCoupon(plan: DisplayPlan): number {
  const defaultDisplayPrice = moneyToNumber(plan.displayPrice);
  const valueCouponDiscount = moneyToNumber(plan.bestValueCoupon?.value);
  const priceAfterUsingCoupon = plan.bestValueCoupon?.percentage
    ? plan.bestValueCoupon.percentage * defaultDisplayPrice
    : moneyToNumber(subtractFromMoney(plan.displayPrice, valueCouponDiscount));
  return clamp(priceAfterUsingCoupon, 0.01, defaultDisplayPrice);
}

export function planIsOfoApiBundle(plan: DisplayPlan): boolean {
  return plan.name.includes('ofoapibundle');
}

export function planIsTypeOfPlan(plan: DisplayPlan, planType: string): boolean {
  return plan.name.startsWith(planType);
}

export function planNameIsOfoApiBundle(planName: string): boolean {
  return planName.includes('ofoapibundle');
}

export function planNameIsRecurringPlan(planName: string): boolean {
  return planName.includes('recurring');
}

export function planIsMonthly(plan: DisplayPlan): boolean {
  return plan.name.includes('p1m');
}
