import { getUserId } from '@/hooks/use-user-id';
import { urqlClient } from '@/http-clients';
import { CouponEntity } from './../types';
import { gql } from '@urql/core';
import { createQuery } from 'react-query-kit';
import { EnrichedFlatValueCoupon } from './../types';
import { moneyToNumber } from '@css/currency';
import { convertToEnrichedFlatValueCoupon } from '../util/coupons';

export const fetchCouponsQuery = gql`
  query fetchCoupons($userId: ID!, $status: CouponStatus) {
    lutrahubCoupons(input: { merchantId: $userId, status: $status, pagination: { first: 50 } }) {
      coupons {
        code
        title
        status
        expiryTime
        couponType
        discountType
        periodExtendDays
        tags {
          key
          value
        }
        amountOff {
          currencyCode
          units
          nanos
        }
        plans {
          name
          productName
          distributorName
          localizedPlanName
          localizedPlanShortName(locale: "zh_CN")
        }
        redemptionLimit
        redeemedTimes
        percentage
        discountType
      }
    }
  }
`;

export enum CouponType {
  /** created automatically when certain event happens (e.g. printer binding) */
  Automatic = 'AUTOMATIC',
  /** created by admin via ops tool */
  Manual = 'MANUAL',
  /** Unspecified */
  Unspecified = 'UNSPECIFIED',
}

export enum CouponDiscountType {
  CouponTypeAmountOff = 'COUPON_TYPE_AMOUNT_OFF',
  CouponTypeFreeTrial = 'COUPON_TYPE_FREE_TRIAL',
  CouponTypePercentOff = 'COUPON_TYPE_PERCENT_OFF',
  CouponTypePeriodExtension = 'COUPON_TYPE_PERIOD_EXTENSION',
  CouponTypeUnknown = 'COUPON_TYPE_UNKNOWN',
}

export type CouponFromApi = {
  code: string;
  title: string;
  status: 'REDEEMABLE' | 'UNSPECIFIED' | 'REDEEMED' | 'EXPIRED';
  couponType: CouponType | undefined;
  discountType: CouponDiscountType | undefined;
  periodExtendDays: number | undefined;
  tags: Array<{ __typename?: 'Tag'; key: string; value: string }> | undefined;
  expiryTime: string;
  amountOff: {
    currencyCode: string;
    units: number;
    nanos: number;
  };
  plans: Array<{
    name: string;
    productName: string;
    distributorName?: string;
    localizedPlanName: string;
    localizedPlanShortName: string;
  }>;
  redemptionLimit: number;
  redeemedTimes: number;
  percentage?: number;
};

export type LutrahubCouponsQueryType = {
  lutrahubCoupons: {
    coupons: Array<CouponFromApi>;
  };
};

async function fetchCoupons(): Promise<Array<CouponEntity>> {
  const userId = await getUserId();
  if (!userId) {
    throw new Error('no user id');
  }
  const res = await urqlClient.query<LutrahubCouponsQueryType>(fetchCouponsQuery, { userId }).toPromise();
  return res.data?.lutrahubCoupons.coupons ?? [];
}

export const useCoupons = createQuery<Array<CouponEntity>, { status: string | undefined }, Error>({
  primaryKey: 'coupons-ng',
  queryFn: async function ({ queryKey: [,] }) {
    const couponsFromApi = await fetchCoupons();
    return status ? couponsFromApi.filter((coupon) => coupon.status === status) : couponsFromApi;
  },
});

export const useEnrichedFlatValueCoupons = createQuery<
  Array<EnrichedFlatValueCoupon>,
  { status: string | undefined },
  Error
>({
  primaryKey: 'coupons-ng',
  queryFn: async function ({ queryKey: [,] }) {
    const couponsFromApi = await fetchCoupons();
    const couponsOfStatus = status ? couponsFromApi.filter((coupon) => coupon.status === status) : couponsFromApi;
    const enrichedFlatValueCoupons = couponsOfStatus
      .filter((coupon) => !!coupon.plans[0] && (moneyToNumber(coupon.amountOff) > 0 || coupon.percentage))
      .map(convertToEnrichedFlatValueCoupon);
    return enrichedFlatValueCoupons;
  },
});
