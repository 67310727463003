import {
  buildClaimCouponRule,
  buildEntitlementRule,
  buildFinalRule,
  buildOfoCheckRule,
  buildUpsellCouponRule,
  ruleExecutor,
} from '../rules';

export async function vasbundleprotection() {
  const productName = 'vasbundleprotection';
  const rules = [
    buildOfoCheckRule((ofo) => Object.keys(ofo).length > 0, productName),
    buildClaimCouponRule(productName),
    buildUpsellCouponRule(productName),
    buildEntitlementRule(productName, 'vas.bundle.protection', 'STORE'),
    buildFinalRule(() => window.location.replace('/bundle/vasbundleprotection')),
  ];

  await ruleExecutor(rules);
}
