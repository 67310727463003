import { gql } from 'graphql-request';
import { useQuery } from '@tanstack/react-query';
import { urqlClient } from '../http-clients';
import { createQuery } from 'react-query-kit';

export const query = gql`
  query printerSimCard($input: QueryPrinterSimCardInput!) {
    printerSimCard(input: $input) {
      printerSimCard {
        availableToRenew
        simStatus
      }
    }
  }
`;
export function useIsSimIccidValid(queueId: string, simIccid: string, options: { skip?: boolean }) {
  const {
    isLoading: loading,
    isError: error,
    data,
    isFetchedAfterMount,
  } = useQuery(
    ['simIccidValidation', queueId, simIccid],
    async function () {
      return checkSimIccid({ queueId, simIccid });
    },
    {
      refetchOnWindowFocus: false,
      enabled: !options.skip,
    }
  );

  return {
    loading,
    error,
    isValid: data,
    isFetchedAfterMount,
  };
}
interface SimParam {
  queueId: string;
  simIccid: string;
}

type SimResult =
  | {
      result: false;
      reason: string;
    }
  | {
      result: true;
    };

type SimStatus =
  | 'SIM_STATUS_UNKNOWN'
  | 'SIM_STATUS_TO_CHECK'
  | 'SIM_STATUS_TESTING_STAGE'
  | 'SIM_STATUS_SILENT_STAGE'
  | 'SIM_STATUS_INVENTORY_STAGE'
  | 'SIM_STATUS_INACTIVE'
  | 'SIM_STATUS_ACTIVE'
  | 'SIM_STATUS_STOPPED'
  | 'SIM_STATUS_ACCOUNT_CLOSED'
  | 'SIM_STATUS_ACCOUNT_PRE_CLOSED'
  | 'SIM_STATUS_UNSUPPORTED_SUPPLIER';

function getValidationMessageByReason(reason: string | undefined) {
  switch (reason) {
    case 'SIM_STATUS_UNSUPPORTED_SUPPLIER':
      return '无效卡号，请重新输入！';
    case 'SIM_STATUS_INACTIVE':
      return '卡号尚未激活，请先使用数天，待卡号激活后重试';
    case 'SIM_STATUS_ACCOUNT_CLOSED':
      return '卡号已注销，请重新购卡！';
    default:
      return '无效卡号，请重新输入！';
  }
}

export async function checkSimIccid({ queueId, simIccid }: SimParam): Promise<SimResult> {
  if (simIccid.length !== 20) {
    return {
      result: false,
      reason: 'SIM 卡长度为 20',
    };
  }
  const res = await urqlClient
    .query<{
      printerSimCard: {
        printerSimCard: {
          availableToRenew: boolean;
          simStatus: SimStatus;
        };
      };
    }>(query, {
      input: {
        queueId: queueId,
        simIccid: simIccid,
      },
    })
    .toPromise();
  const result = res.data?.printerSimCard.printerSimCard?.availableToRenew ?? false;
  if (result) {
    return { result };
  } else {
    return {
      result,
      reason: getValidationMessageByReason(res.data?.printerSimCard.printerSimCard?.simStatus),
    };
  }
}

export const useCheckSimIccid = createQuery<SimResult, SimParam, Error>({
  primaryKey: 'sim-iccid',
  queryFn: async function ({ queryKey: [, { queueId, simIccid }] }) {
    return checkSimIccid({ queueId, simIccid });
  },
});
