import React from 'react';
import { ThemeProvider } from 'styled-components';

import { theme } from '@css/op-components';

const ThemeShell: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  theme.colors.primary = '#CE6D50';
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};
export { ThemeShell };
