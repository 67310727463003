import * as React from 'react';

function SvgVirtualBrands(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <rect width={24} height={24} rx={6} fill="#81C93A" />
      <path
        d="M4.976 7.692A1.128 1.128 0 015.953 6h12.094c.868 0 1.41.94.977 1.692l-6.047 10.48a1.128 1.128 0 01-1.954 0L4.976 7.693z"
        fill="#fff"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgVirtualBrands);
export default ForwardRef;
