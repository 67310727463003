import * as React from 'react';

function SvgCullinanBell(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path d="M3 12h2V9.25c0-2.54 1.718-4.659 4-5.145V2h2v2.105c2.282.486 4 2.605 4 5.145V12h2v2H3v-2zM10 18a2 2 0 002-2H8a2 2 0 002 2z" />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgCullinanBell);
export default ForwardRef;
