import * as React from 'react';

function SvgCircleInfo(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 6A6 6 0 110 6a6 6 0 0112 0zm-1.125 0a4.875 4.875 0 11-9.75 0 4.875 4.875 0 019.75 0zM5.437 8.25v-3h1.125v3H5.438zm0-4.875V4.5h1.125V3.375H5.438z"
        fill="#A5A5A5"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgCircleInfo);
export default ForwardRef;
