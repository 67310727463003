import * as React from 'react';

function SvgCullinanNavExpandHover(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path d="M2.038 16V4h2v12h-2zM14.172 9H6v2h8.172l-2.536 2.536 1.415 1.414L18 10l-4.95-4.95-1.415 1.415L14.172 9z" />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgCullinanNavExpandHover);
export default ForwardRef;
