import * as React from 'react';

function SvgCullinanChartLine(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M11.457 10.082L15.627 6 17 7.344l-5.543 5.427-2.914-2.853L4.373 14 3 12.655 8.543 7.23l2.914 2.853z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgCullinanChartLine);
export default ForwardRef;
