import { trackResponseStatusCode } from './mixpanel';

const originalFetch = window.fetch;

export function fetch(input: RequestInfo, init?: RequestInit) {
  const response = originalFetch(input, init);

  trackResponseStatusCode(response, { input, init });

  return response;
}

// @ts-ignore overridden fetch ignores URL api
window.fetch = fetch;
