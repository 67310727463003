import { ResizeObserver as Polyfill, ResizeObserverEntry } from '@juggle/resize-observer';
import { supportsResizeObserver } from './utils';

export type ResizeObserverConfig = {
  callback: (entry: ResizeObserverEntry) => void;
  options?: ResizeObserverOptions;
};

export class GlobalResizeObserver {
  // Performance of ResizeObserver heavily favors a single ResizeObserver instance handling multiple
  // entries over creating a new instance for each element. Therefore, let's keep a global instance
  // singleton and append/remove elements to it as necessary.
  // Ref: https://groups.google.com/a/chromium.org/g/blink-dev/c/z6ienONUb5A/m/F5-VcUZtBAAJ?pli=1
  private observer: ResizeObserver | undefined;

  // Because the ref for an element can change frequently (becoming null, or even changing from one
  // DOM node to another), we maintain a map from the element to the observer config. If the
  // underlying node reference changes, this allows us to stop the old node observer and start
  // a new one for the new node.
  private targetConfigMap = new Map<Element, ResizeObserverConfig>();

  constructor() {
    supportsResizeObserver().then((isSupported) => {
      const ResizeObserver = isSupported ? window.ResizeObserver : Polyfill;

      this.observer = new ResizeObserver((entries) => {
        if (entries.length === 0) {
          return;
        }

        window.requestAnimationFrame(() => {
          entries.forEach((entry) => {
            this.targetConfigMap.get(entry.target)?.callback({
              borderBoxSize: entry.borderBoxSize,
              // Old Firefox implemented `contentBoxSize` as a single content rect, rather than an array.
              // Let's update to follow the spec
              contentBoxSize: Array.isArray(entry.contentBoxSize) ? entry.contentBoxSize : [entry.contentBoxSize],
              contentRect: entry.contentRect,
              devicePixelContentBoxSize: (entry as ResizeObserverEntry).devicePixelContentBoxSize,
              target: entry.target,
            } as ResizeObserverEntry);
          });
        });
      });

      // We may have already tried to register some elements for observation while waiting for the
      // support check to execute. Now that we've loaded, let's initialize the observers
      this.targetConfigMap.forEach((config, target) => {
        this.addTarget(target, config);
      });
    });
  }

  addTarget(target: Element, config: ResizeObserverConfig): void {
    this.targetConfigMap.set(target, config);
    this.observer?.observe(target, config.options);
  }

  removeTarget(target: Element): void {
    this.targetConfigMap.delete(target);
    this.observer?.unobserve(target);
  }

  updateTarget(oldTarget: Element, newTarget: Element): void {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const config = this.targetConfigMap.get(oldTarget)!;

    this.targetConfigMap.set(newTarget, config);
    this.targetConfigMap.delete(oldTarget);

    this.observer?.unobserve(oldTarget);
    this.observer?.observe(newTarget, config.options);
  }
}
