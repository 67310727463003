import * as React from 'react';

function SvgCullinanExportXlsx(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 20 20"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      ref={svgRef}
      {...props}
    >
      <path d="M9.848 12.122a20.37 20.37 0 00-.132.358L7.476 17H4l4-7-4-7h3.5l2 4.198c.142.325.394.71.507 1.154.112-.444.352-.829.493-1.154L12.5 3H16l-3.991 7L16 17h-3.469l-2.234-4.52c-.05-.14-.094-.256-.132-.358-.075-.2-.128-.339-.158-.474-.03.135-.083.275-.159.474z" />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgCullinanExportXlsx);
export default ForwardRef;
