import * as React from 'react';

function SvgCullinanDotsThree(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path d="M12.97 5.985a2.986 2.986 0 11-5.97 0 2.986 2.986 0 015.97 0zM7.97 13.985a2.986 2.986 0 11-5.97 0 2.986 2.986 0 015.97 0zM17.97 13.985a2.986 2.986 0 11-5.97 0 2.986 2.986 0 015.97 0z" />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgCullinanDotsThree);
export default ForwardRef;
