import styled from 'styled-components';

interface PlanItemProps {
  selected?: boolean;
}

export const CouponBanner = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  text-align: center;
  height: 22px;
  background: #ce6d50;
  font-size: 10px;
  color: #fff;
  line-height: 22px;
  font-weight: 400;
`;
export const ErrorMessage = styled.div`
  display: flex;
  padding: 8px 16px;
  color: #c4232b;
  font-weight: 500;
`;
export const Plans = styled.div`
  box-sizing: border-box;
  width: 100%;
  overflow: scroll;
  min-height: 130px;
  display: flex;
  flex-direction: row;
`;
export const PlanItem = styled.div<PlanItemProps>`
  padding-bottom: 20px;
  position: relative;
  background: ${(props): string => (props.selected ? 'rgba(206, 109, 80, 0.05)' : '#fff')};
  border: 1px solid;
  border-color: ${(props): string => (props.selected ? '#BF5636' : '#E0E0E0')};
  margin-right: 10px;
  width: 104px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  box-sizing: border-box;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  justify-content: flex-start;
  overflow: hidden;
`;
export const ParaphText = styled.div`
  display: block;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #fff;
  padding: 0 5px;
  text-align: center;
`;
export const EmphasisText = styled.span`
  font-weight: 700;
  color: #ce6d50;
  font-size: 22px;
  line-height: 16px;
  margin-left: 8px;
  margin-right: 8px;
`;
