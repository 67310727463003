import * as React from 'react';

function SvgCullinanExportCsv(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 20 20"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      ref={svgRef}
      {...props}
    >
      <path d="M6.5 8V3h7.058v5c0 1 .287 4.216-1.475 5.945C10.501 15.5 9.443 16 7 17l-.5-2c1-.5 1.894-1.172 2.5-2 .664-.905 1-2 1-5H6.5z" />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgCullinanExportCsv);
export default ForwardRef;
