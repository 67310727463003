import * as React from 'react';

function SvgDocument(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 0h5.586a1 1 0 01.707.293l4.414 4.414a1 1 0 01.293.707V15a1 1 0 01-1 1H3a1 1 0 01-1-1V1a1 1 0 011-1zm10 6H9a1 1 0 01-1-1V1H3v14h10V6zM9 1.414L12.586 5H9V1.414zM11 8v1H5V8h6zm0 4v-1H5v1h6z"
        fill="#707070"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgDocument);
export default ForwardRef;
