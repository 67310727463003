import * as React from 'react';

function SvgCompetitionInsightsPromptLocation(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 20 20"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      ref={svgRef}
      {...props}
    >
      <path d="M14.8 11.143C15.542 10.083 16 9 16 7.714 16 4.558 13.314 2 10 2S4 4.558 4 7.714c0 1.287.453 2.363 1.2 3.429L10 18l4.8-6.857zM10 10c1.325 0 2.4-1.023 2.4-2.286 0-1.262-1.075-2.285-2.4-2.285S7.6 6.452 7.6 7.714C7.6 8.977 8.675 10 10 10z" />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgCompetitionInsightsPromptLocation);
export default ForwardRef;
