import * as React from 'react';

function SvgCreditCardHighlight(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 34 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M31.483 24.093l-26.145.082c-.82 0-1.519-.697-1.519-1.518L3.78 6.239c0-.821.697-1.519 1.518-1.519l26.145-.04c.821 0 1.52.697 1.52 1.518l.04 16.418c0 .82-.698 1.477-1.519 1.477z"
        fill="#112232"
      />
      <path d="M32.954 8.876l-29.183.062.01 4.145 29.182-.062-.009-4.145z" fill="#FF5530" />
      <path
        d="M27.664 19.455H1.52C.698 19.455 0 18.758 0 17.937V1.519C0 .698.698 0 1.519 0h26.145c.821 0 1.519.698 1.519 1.519v16.418c0 .82-.657 1.518-1.519 1.518z"
        fill="#FFE4DE"
      />
      <path
        d="M20.974 5.583c0-.575.246-1.108.656-1.437-.328-.328-.82-.492-1.313-.492a1.97 1.97 0 00-1.97 1.97c0 1.108.862 1.97 1.97 1.97.492 0 .944-.205 1.313-.492-.41-.41-.656-.944-.656-1.52z"
        fill="#fff"
      />
      <path
        d="M22.944 3.612c-.493 0-.944.205-1.314.493.41.369.657.862.657 1.436 0 .575-.246 1.108-.657 1.437.329.328.821.492 1.314.492a1.97 1.97 0 001.97-1.97c0-1.108-.903-1.888-1.97-1.888z"
        fill="#112232"
      />
      <path
        d="M22.287 5.582c0-.574-.246-1.108-.657-1.436a1.9 1.9 0 00-.656 1.436c0 .575.246 1.109.656 1.437.41-.328.657-.862.657-1.437z"
        fill="#FF5530"
      />
      <path d="M3.366 12.93h22.656M3.366 15.187h10.097" stroke="#FF5530" strokeWidth={1.051} strokeMiterlimit={10} />
      <path
        d="M7.676 7.388H4.31a.736.736 0 01-.739-.739V4.515c0-.41.329-.739.74-.739h3.365c.41 0 .739.328.739.739v2.134c0 .41-.329.74-.74.74z"
        fill="#fff"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgCreditCardHighlight);
export default ForwardRef;
