import * as React from 'react';

function SvgPlay(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 5 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path d="M5 3L.5 5.598V.402L5 3z" fill="currentColor" />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgPlay);
export default ForwardRef;
