import 'polyfill-object.fromentries';
import 'globalthis/auto';
import 'array-flat-polyfill';
import 'intersection-observer';
import './launch-sentry';
import '@formatjs/intl-locale/polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import { Toaster } from 'react-hot-toast';
import { QueryClientProvider, QueryErrorResetBoundary } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { BrowserRouter as Router, Switch, Route, Redirect, useLocation, useHistory } from 'react-router-dom';
import { ErrorFallback } from './iap/ErrorFallback';

import { AppModuleIndex } from './app-modules';
import { GlobalStyle } from './common/components/GlobalStyle';
import LoadingOverlay from './common/components/LoadingOverlay/LoadingOverlay';
import { PaymentPage } from './features/payment/pages';
import './override-fetch';
import FeatureUnlockedPage from './pages/FeatureUnlockedPage';
import './index.css';
import { jsbc } from './jsbc';
import { isVersionAfter } from './utils/cmpVersions';
import { RenewPage } from './features/renew/pages';
import { AchievementPage } from './features/achievement/pages';

import { queryClient } from './query-client';
import { ThemeShell } from './common/ThemeShell';
import { Page } from './components/page';
import { XCDUpsellPage } from './pages/upsell/xcd';
import { ErrorBoundary } from 'react-error-boundary';
import { TicketTemplateListPage } from './pages/PrinterTemplate/ticket-template-list';
import { StoreCloseMonitorPage } from './pages/FeatureUnlockedPage/store-close-notification/store-close-notification-page';
import { SplashLandingPage } from './pages/Landing/landing-page';
import AdvancedTemplateIAPPage from './pages/PrinterTemplate/advanced-template-iap';

const Campaign5Page = React.lazy(() => import('./pages/Campaign5/Campaign5'));
const Campaign6Page = React.lazy(() => import('./pages/Campaign6/Campaign6'));
const SupplyChainRegisterPage = React.lazy(() => import('./pages/SupplyChainRegisterPage/index'));
const PaymentSuccessPage = React.lazy(() => import('./pages/paymentSuccessPage'));
const RedirectPage = React.lazy(() => import('./pages/redirectPage'));
const RemindToPurchasePage = React.lazy(() => import('./pages/remindToPurchasePage'));
const DistributionCampaignPage = React.lazy(() => import('./pages/distributionCampaign'));
const IapPage = React.lazy(() => import('./iap/index'));
const UpsellPage = React.lazy(() => import('./pages/upsell'));
const BundlePage = React.lazy(() => import('./bundle/index'));
const LandingPage = React.lazy(() => import('./pages/OtterLandingPage'));
const DevPage = React.lazy(() => import('./pages/dev'));
const EntrancePage = React.lazy(() => import('./features/entrance/pages'));
const IntegrationMockPage = React.lazy(() => import('./pages/integration-mocks'));
const ProductPage = React.lazy(() => import('./pages/product'));
const BnmPaymentPages = React.lazy(() => import('@/bnm-common/BnmPaymentPages'));

function WebViewControlManager() {
  // figma: https://www.figma.com/file/JUHHdixBKr72ZreIhYYhoP/vasbundleoperate-webview-control-flow?node-id=0%3A1&t=N6qFgL1FWTionVss-1
  return (
    <Switch>
      {/*** 接单大师： 回复 back and close; 运营大师： 恢复 close ，但是隐藏 back */}
      <Route path={['/unlock/vasbundleoperate', '/upsell/vasbundleoperate']}>
        <HideWebViewControl controls={['back']} />
      </Route>

      <Route path={['/iap/vasbundleorder']}>
        {/** 接单大师 ，隐藏掉 webview 的 关闭 按钮 */}
        <HideWebViewControl controls={['close']} />
      </Route>

      <Route path={['/iap/vasbundleoperate', '/iap/xcd', '/iap/orderbooster']}>
        {/** 运营大师，xcd, 冲单宝隐藏掉 webview 的 后退 按钮 */}
        <HideWebViewControl controls={['back']} />
      </Route>

      <Route path={['/iap/couponSelect', '/iap/addCoupon', '/payment']}>
        {/** 针对这些 routes ，什么都不要动，保持原样 */}
      </Route>

      <Route>
        {/** 剩下所有的路径，都恢复 webview controls 成显示 back 和 close */}
        <HideWebViewControl controls={[]} />
      </Route>
    </Switch>
  );
}

function HideWebViewControl({ controls }: { controls: Array<'back' | 'close'> }) {
  if (
    isVersionAfter({
      iOS: '3.8.0',
      android: '3.9.6',
    })
  ) {
    setTimeout(function () {
      jsbc.hideWebViewControl(controls);
    }, 400);
  }
  return null;
}

const Root: React.FC = () => {
  return (
    <Page name="WEB">
      <QueryClientProvider client={queryClient}>
        <QueryErrorResetBoundary>
          {({ reset }) => (
            <ErrorBoundary
              FallbackComponent={({ error }) => <ErrorFallback error={error} page_name="Lutrahub Pages" />}
              onReset={reset}
            >
              <ThemeShell>
                <Router>
                  <GlobalStyle />
                  <React.Suspense fallback={<LoadingOverlay delay={500} />}>
                    <WebViewControlManager />
                    <Switch>
                      <Route path="/product">
                        <ProductPage />
                      </Route>
                      <Route path="/_integration_">
                        <IntegrationMockPage />
                      </Route>
                      <Route path="/entrance">
                        <EntrancePage />
                      </Route>
                      <Route path="/payment">
                        <PaymentPage />
                      </Route>
                      <Route path="/bnma-payment">
                        <BnmPaymentPages />
                      </Route>
                      <Route path="/upsell">
                        <UpsellPage />
                      </Route>
                      <Route path="/campaign-5">
                        <Campaign5Page />
                      </Route>
                      <Route path="/campaign-6">
                        <Campaign6Page />
                      </Route>
                      <Route path="/supply-store-register">
                        <SupplyChainRegisterPage />
                      </Route>
                      <Route path="/payment-success">
                        <PaymentSuccessPage />
                      </Route>
                      <Route path="/remind-to-purchase">
                        <RemindToPurchasePage />
                      </Route>
                      <Route path="/unlock/:productName">
                        <Page name="UPSELLING_PAGE">
                          <FeatureUnlockedPage />
                        </Page>
                      </Route>
                      <Route path="/landing/:productName">
                        <SplashLandingPage />
                      </Route>
                      <Route path="/redirect/:action">
                        <RedirectPage />
                      </Route>
                      <Route path="/iap">
                        <Page name="IAP_PAGE">
                          <IapPage />
                        </Page>
                      </Route>
                      <Route path="/renew/:productName">
                        <Page name="RENEW_PAGE">
                          <RenewPage />
                        </Page>
                      </Route>
                      <Route path="/achievement/:productName">
                        <AchievementPage />
                      </Route>
                      <Route path="/am">
                        <AppModuleIndex />
                      </Route>
                      <Route path="/dc">
                        <DistributionCampaignPage />
                      </Route>
                      <Route path="/bundle">
                        <BundlePage />
                      </Route>
                      <Route path="/landing">
                        <LandingPage />
                      </Route>

                      <Route path="/printer/advanced-template/iap/:templateId/:productName">
                        <AdvancedTemplateIAPPage />
                      </Route>
                      <Route path="/printer/template">
                        <TicketTemplateListPage />
                      </Route>

                      <Route path="/store/close-monitor">
                        <StoreCloseMonitorPage />
                      </Route>
                      <Route path="/_dev_">
                        <DevPage />
                      </Route>
                      <FallbackRoutes />
                    </Switch>
                  </React.Suspense>
                </Router>
              </ThemeShell>
              <ReactQueryDevtools initialIsOpen={false} />
              <Toaster />
            </ErrorBoundary>
          )}
        </QueryErrorResetBoundary>
      </QueryClientProvider>
    </Page>
  );
};

function FallbackRoutes() {
  const location = useLocation();

  return <Route path="/qrcoIntroPageB" render={() => <Redirect to={{ ...location, pathname: '/iap/qrco' }} />} />;
}

ReactDOM.render(<Root />, document.getElementById('root'));
