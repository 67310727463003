import { useContext, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { moneyToNumber } from '@css/currency';
import { IapContext } from './IapContext';
import type { EnrichedFlatValueCoupon } from './types';
import * as styles from './InAppPurchasePage.module.scss';
import { RightArrowIcon } from './icons/rightArrow.svg';
import moment from 'moment';
import { RegularCouponButton } from '@/features/coupon/components/RegularCouponButton';
import { usePlanSelector } from '@/store/plan-slice';
import { useCoupons } from '@/features/coupon/hooks/use-redeemable-coupons';

export function GiantCoupon({
  planHasCouponCode,
  coupon,
}: {
  planHasCouponCode: boolean;
  coupon: EnrichedFlatValueCoupon | undefined;
}) {
  const location = useLocation();

  const [selectedCoupon, setSelectedCoupon] = useState<EnrichedFlatValueCoupon>();

  const {
    selectedCoupon: selectedCouponFromContext,
    setSelectedCoupon: setSelectedCouponToContext,
    selectedPlan,
  } = useContext(IapContext);

  const couponsQuery = useCoupons('REDEEMABLE');

  const getCurrentCouponCode = usePlanSelector((state) => state.getCurrentCouponCode);

  useEffect(
    function () {
      const couponCode = getCurrentCouponCode();
      if (couponsQuery.data) {
        const selectedCoupon = couponsQuery.data.find((coupon) => {
          return coupon.couponInfo.code === couponCode;
        });
        setSelectedCoupon(selectedCoupon);
        setSelectedCouponToContext(selectedCoupon);
      } else {
        setSelectedCoupon(selectedCouponFromContext);
      }
    },
    [couponsQuery.data, selectedCouponFromContext, getCurrentCouponCode]
  );

  if (typeof coupon === 'undefined') {
    return <RegularCouponButton planHasCouponCode={planHasCouponCode} />;
  }

  if (selectedPlan?.name && !coupon.applicablePlans.includes(selectedPlan?.name)) {
    return <RegularCouponButton planHasCouponCode={planHasCouponCode} />;
  }

  return (
    <div className={styles.giantCoupon}>
      <div className={styles.giantCoupon__bg}>券</div>
      <div className={styles.giantCoupon__price}>{moneyToNumber(coupon.value).toFixed(2)}</div>
      <div className={styles.giantCoupon__info}>
        <h3>{coupon.couponInfo.title}</h3>
        <p>
          有效期至
          <br /> {moment(coupon.couponInfo.expiryTime).format('YYYY-MM-DD')}
        </p>
      </div>
      <input
        type="checkbox"
        className={styles.giantCoupon__checkbox}
        checked={coupon.couponInfo.code === selectedCoupon?.couponInfo.code}
        onChange={(e) => {
          if (e.target.checked) {
            setSelectedCoupon(coupon);
          } else {
            setSelectedCoupon(undefined);
          }
          console.log(e.target.checked, selectedCoupon);
        }}
      />
      <Link
        to={{
          ...location,
          pathname: '/iap/couponSelect',
        }}
      >
        <div
          style={{
            padding: '8px',
            margin: '8px',
            color: 'white',
          }}
        >
          <RightArrowIcon fill="white" />
        </div>
      </Link>
    </div>
  );
}
