import * as React from 'react';

function SvgCullinanServingTray(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path d="M3 15h14v2H3v-2zM3 11h14v2H3v-2zM16 11H4a6 6 0 1112 0z" />
      <path d="M9 3h2v3H9V3z" />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgCullinanServingTray);
export default ForwardRef;
