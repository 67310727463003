import React from 'react';
import styled from 'styled-components';

import { getPlatform } from '../../utils/cmpVersions';
import { MobOverlayDialog } from './MobOverlayDialog';

const Body = styled.div`
  position: relative;
  padding: 16px;
  border-radius: 12px;
  background: #fff;
  z-index: 2;
`;

const Title = styled.div`
  font-size: 22px;
  text-align: center;
  font-weight: 600;
`;

const Paragraph = styled.div`
  font-size: 16px;
  text-align: center;
  font-weight: 400;
  margin: 12px 0 8px;
  color: #525252;
`;

const Button = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #e06e4d;
  border-radius: 8px;
  height: 44px;
  color: #fff;
  margin-top: 16px;
  text-decoration: none;
`;

const UpgradeModal: React.FC<{ onClose: () => void; androidMin: string; iosMin: string; message?: string }> = (
  props
) => {
  const isIOS = getPlatform() === 'IOS';
  const { onClose, iosMin, androidMin, message } = props;
  return (
    <MobOverlayDialog onClose={onClose}>
      <Body>
        <Title>提示</Title>
        <Paragraph>
          {message ??
            `您已选择连续包月订阅计划，此类型订阅在${isIOS ? iosMin : androidMin}以上版本支持，请更新您的应用程序`}
        </Paragraph>
        {isIOS && <Button href="itms-apps://itunes.apple.com/app/id1542571221">点击升级</Button>}
      </Body>
    </MobOverlayDialog>
  );
};

export default UpgradeModal;
