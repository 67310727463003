import * as React from 'react';

function SvgDeleteTable(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 21 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M13.505 12h4v2h-4v-2zm0-8h7v2h-7V4zm0 4h6v2h-6V8zm-12 6c0 1.1.9 2 2 2h6c1.1 0 2-.9 2-2V4h-10v10zm2-8h6v8h-6V6zm5-6h-4l-1 1h-3v2h12V1h-3l-1-1z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgDeleteTable);
export default ForwardRef;
