import * as React from 'react';

function SvgPizza(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 42 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path d="M.318 40.76L34.34 28.92 12.159 6.66.319 40.76z" fill="#fff" />
      <path d="M14.527.029l-2.368 6.63 22.18 22.182 6.71-2.29L14.528.03z" fill="#707070" />
      <path
        d="M7.107 30.894c.71-.71 1.105-1.816 1.105-2.842 0-2.526-.948-3.552-2.053-4.183l-2.841 8.209a4.284 4.284 0 003.789-1.184zM20.052 31.21c-.868.868-1.263 1.973-1.263 3.157l7.894-2.763c-.079-.157-.158-.236-.316-.394-1.815-1.737-4.657-1.737-6.315 0zM17.29 25.684c2.447 0 4.42-2.053 4.42-4.5s-1.973-4.499-4.42-4.499-4.42 2.052-4.42 4.5c0 2.447 1.973 4.499 4.42 4.499z"
        fill="#B9B9B9"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgPizza);
export default ForwardRef;
