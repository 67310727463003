import * as React from 'react';

function SvgMenu(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path d="M7 11a1 1 0 011-1h16a1 1 0 011 1v12a1 1 0 01-1 1H8a1 1 0 01-1-1V11z" fill="#B9B9B9" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 8v13h1.848c2.088 0 3.162.337 5.142.997a.031.031 0 00.02 0c1.98-.66 3.054-.997 5.142-.997H23V8h-1.848c-2.088 0-3.162.337-5.142.997a.032.032 0 01-.02 0C14.01 8.337 12.936 8 10.848 8H9z"
        fill="#fff"
      />
      <path
        d="M20 14h-3v1h3v-1zM21 16h-4v1h4v-1zM21 12h-4v1h4v-1zM14 14h-3v1h3v-1zM15 16h-4v1h4v-1zM15 12h-4v1h4v-1z"
        fill="#E2E2E2"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgMenu);
export default ForwardRef;
