import * as React from 'react';

function SvgPages(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 38 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M5.7 5.7h11.4v11.4H5.7V5.7zM5.7 20.9h11.4v11.4H5.7V20.9zM20.9 20.9h11.4v11.4H20.9V20.9zM20.9 5.7h11.4v11.4H20.9V5.7z"
        fill="#A5A5A5"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgPages);
export default ForwardRef;
