import { urqlClient } from '@/http-clients';
import { queryClient } from '@/query-client';
import gql from 'graphql-tag';
import { createQuery } from 'react-query-kit';
import { useFacilityId } from './use-facility-id';
import { fetchVasStoreId } from './use-vas-store-id';

const ENTITLEMENTQUERY = gql`
  query EntitlementQuery($feature: String!, $entityId: ID!, $entityType: EntitlementEntityType!) {
    featureEntitlements(
      input: {
        entitlementRequest: { features: [$feature], consistency: STRONG }
        entityFamilies: { targetEntity: { entityId: $entityId, entityType: $entityType } }
      }
    ) {
      featureEntitlementsForEntityFamilies {
        featureEntitlements {
          isEntitled
          feature
          entitledDays
        }
      }
    }
  }
`;

export const useEntitlement = createQuery<boolean, { feature: string; entityType?: 'FACILITY' | 'STORE' }, Error>({
  primaryKey: 'use-entitlement',
  queryFn: async function ({ queryKey: [, { feature, entityType = 'FACILITY' }] }) {
    const facilityId = await queryClient.fetchQuery(useFacilityId.getKey(), useFacilityId.queryFn);
    const vasStoreId = await fetchVasStoreId();

    const res = await urqlClient
      .query<{
        featureEntitlements: {
          featureEntitlementsForEntityFamilies: {
            featureEntitlements: {
              feature: string;
              isEntitled: boolean;
            }[];
          }[];
        };
      }>(
        ENTITLEMENTQUERY,
        {
          feature,
          entityType,
          entityId: entityType === 'FACILITY' ? facilityId : vasStoreId,
        },
        {
          requestPolicy: 'network-only',
        }
      )
      .toPromise();
    return (
      res.data?.featureEntitlements.featureEntitlementsForEntityFamilies[0]?.featureEntitlements.find(
        (entitlement) => entitlement.feature === feature
      )?.isEntitled ?? false
    );
  },
});
