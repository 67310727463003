import React from 'react';
import { FC } from 'react';

export const GiftIcon: FC<React.SVGProps<SVGSVGElement>> = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="16" fill="none" viewBox="0 0 18 16">
      <path
        fill="#000"
        d="M15.67 3.42h-1.82a2.5 2.5 0 00-4.43-2.2L9 1.76l-.42-.57a2.5 2.5 0 00-4.43 2.22H2.33c-.92 0-1.66.74-1.66 1.66v9.17c0 .93.74 1.67 1.66 1.67h13.34c.92 0 1.66-.74 1.66-1.67V5.08c0-.92-.74-1.66-1.66-1.66zM11.5 1.75a.834.834 0 010 1.67.84.84 0 01-.83-.84c0-.45.37-.83.83-.83zm-5 0a.834.834 0 010 1.67.84.84 0 01-.83-.84c0-.45.37-.83.83-.83zm9.17 12.5H2.33v-1.67h13.34v1.67zm0-4.17H2.33v-5h4.24L4.83 7.44l1.35.98L9 4.58l2.82 3.84 1.35-.98-1.74-2.36h4.24v5z"
      ></path>
    </svg>
  );
};
