import { AgreementConfirm } from '@/components/agreement-confirm';
import { usePageName } from '@/components/page';
import { AUTO_AGREE } from '@/constant';
import { button } from '@/cva/button';
import { planNameIsOfoApiBundle } from '@/features/plan/utils';
import { useVasStoreId } from '@/hooks/use-vas-store-id';
import useSearchParams from '@/hooks/useSearchParams';
import { PaymentMethodType } from '@/iap/types';
import { usePlan } from '@/iap/use-product';
import { createLogger } from '@/logger';
import { useTrack } from '@/mixpanel';
import { getTrackingName } from '@/product-registry';
import { queryClient } from '@/query-client';
import { useAgreement, usePlanSelector } from '@/store/plan-slice';
import { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { useHistory, useParams } from 'react-router';
import { usePay } from '../hooks/use-pay';
import { PaymentMethodBottomSheet } from './payment-method-bottom-sheet';
import { capturePaymentException } from '@/sentry/capture';
import { PaymentError } from '@/errors';

const logger = createLogger('launch-payment-button');

interface LaunchPaymentButtonProps {
  planName: string;
  couponCode?: string;
  children?: React.ReactNode;
  trackingContext?: Record<string, string | number | undefined>;
  memo?: string;
  disabled?: boolean;
  buttonIntent?: 'primary' | 'secondary';
}

/***
 * LaunchPaymentButton
 *
 * @description 用来启动支付的按钮，点击后会根据当前的 planName, couponCode 计算出最终的价格，
 * 再根据当前 plan 支持的 payment method 来决定是否显示 payment method 的选择
 * 最后决定是否 call launchPayment 或者调用 freePassPay
 *
 * @props planName: string
 * @props couponCode?: string
 * @props children?: React.ReactNode
 */
export function LaunchPaymentButton({
  planName,
  couponCode,
  children,
  trackingContext,
  memo,
  disabled = false,
  buttonIntent = 'primary',
}: LaunchPaymentButtonProps) {
  const trackEvent = useTrack({ component_name: 'LaunchPaymentButton', ...trackingContext });
  const source = usePageName();
  const { purchase_origin } = useSearchParams();
  useEffect(function () {
    void queryClient.prefetchQuery({ queryKey: useVasStoreId.getKey(), queryFn: useVasStoreId.queryFn });
  }, []);

  const { productName } = useParams<{ productName: string }>();
  const getCouponName = usePlanSelector((state) => state.getCouponName);
  const [paymentMethodTypes, setPaymentMethodTypes] = useState<Array<PaymentMethodType>>([]);
  const [bottomSheetOpen, setBottomSheetOpen] = useState(() => false);

  const planQuery = usePlan(productName, planName);
  const [fullPlanName, setFullPlanName] = useState<string>(planName);
  const history = useHistory();
  const agreed = useAgreement((state) => state.agreed);

  const setAgreed = useAgreement((state) => state.setAgreed);

  const mutation = usePay({
    onSuccess: function (recordId, { paymentMethod }) {
      trackEvent('payment_order_created', {
        paymentMethodType: paymentMethod,
        product_name: getTrackingName(planNameIsOfoApiBundle(fullPlanName) ? 'ofoapibundle' : productName),
        planName: fullPlanName,
        purchase_origin: purchase_origin,
      });

      history.push(`/payment/pending/${recordId}`, {
        planName: fullPlanName,
        productName,
        purchase_origin: purchase_origin,
      });
    },
    onError: function (error, variables) {
      toast.error('创建订单失败，请稍后尝试。');
      capturePaymentException(new PaymentError('创建订单失败', error, variables));
    },
  });

  function createOrder(paymentMethod: PaymentMethodType) {
    const payload = {
      planName: fullPlanName,
      couponCode,
      paymentMethod,
      source,
      memo,
    };

    mutation.mutate(payload);
  }

  useEffect(
    function () {
      if (planQuery.data) {
        const plan = planQuery.data;

        const _paymentMethodTypes = plan.paymentProviders[0].paymentMethods
          .sort((a, b) => a.preference - b.preference)
          .map((method) => method.paymentMethodType);
        setPaymentMethodTypes(_paymentMethodTypes);
        setFullPlanName(plan.name);
      }

      return function () {
        setPaymentMethodTypes([]);
      };
    },
    [planQuery.data]
  );
  useEffect(
    function () {
      if (planQuery.error) {
        if ((planQuery.error as Error).message.startsWith('Plan not found')) {
          toast.error('获取支付方式失败');
        }
      }
    },
    [planQuery.error]
  );

  function handleClick(skipAgreementCheck = false) {
    if (!agreed && !skipAgreementCheck) {
      if (AUTO_AGREE) {
        setAgreed(true);
      } else {
        toast.custom(
          (t) => (
            <AgreementConfirm
              toastId={t.id}
              handleClick={(agreed) => {
                setAgreed(agreed);
                if (agreed) {
                  handleClick(true);
                }
              }}
            />
          ),
          {
            duration: Infinity,
          }
        );
        return void 0;
      }
    }

    logger.debug({ productName, fullPlanName }, 'handle payment button click');

    if (paymentMethodTypes.length > 1) {
      setBottomSheetOpen(true);
      return void 0;
    } else {
      createOrder(paymentMethodTypes[0]);
    }
  }

  return (
    <div>
      <button
        disabled={disabled || paymentMethodTypes.length === 0}
        className={button({
          intent: buttonIntent,
          size: 'full',
          disabled: !agreed || disabled || paymentMethodTypes.length === 0,
        })}
        onClick={(e) => {
          e.preventDefault();
          trackEvent('payment_plan_agreed_and_pay', {
            product_name: getTrackingName(planNameIsOfoApiBundle(fullPlanName) ? 'ofoapibundle' : productName),
            plan_name: fullPlanName,
            source,
            coupon_name: getCouponName(couponCode) ?? 'n/a',
            purchase_origin: purchase_origin,
          });
          handleClick();
        }}
      >
        {children}
      </button>
      <PaymentMethodBottomSheet
        planName={fullPlanName}
        isOpen={bottomSheetOpen}
        handleClose={() => setBottomSheetOpen(false)}
        handleSelect={(method) => {
          createOrder(method);
          setBottomSheetOpen(false);
        }}
        paymentMethodTypes={paymentMethodTypes}
      />
    </div>
  );
}
