import * as React from 'react';

function SvgConceptSearch(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 31 30"
      width="1em"
      height="1em"
      ref={svgRef}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 13c0 7.18 5.82 13 13 13s13-5.82 13-13S20.18 0 13 0 0 5.82 0 13zm2 0c0 6.075 4.925 11 11 11s11-4.925 11-11S19.075 2 13 2 2 6.925 2 13z"
        fill="currentColor"
      />
      <path d="M22.22 20.8l7.786 7.786L28.59 30l-7.785-7.785L22.22 20.8z" fill="currentColor" />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgConceptSearch);
export default ForwardRef;
