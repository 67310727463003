import cx from 'classnames';
import React, { useEffect } from 'react';

export function Modal({
  bottom,
  title,
  children,
  open,
  onClose,
  CustomHeader,
  style,
  onOpen,
}: {
  bottom?: boolean;
  open: boolean;
  title?: string;
  children?: React.ReactNode;
  onClose: () => void;
  CustomHeader?: React.ElementType;
  style?: React.CSSProperties;
  onOpen?: () => void;
}) {
  useEffect(() => {
    if (open) {
      onOpen?.();
    } else {
      onClose?.();
    }
  }, [open]);

  if (open) {
    return (
      <div
        style={style}
        className={cx([
          'modal',
          {
            'modal--bottom': bottom,
          },
        ])}
      >
        <header className="modal__header">
          {CustomHeader ? (
            <CustomHeader />
          ) : (
            <>
              <h3 className="modal__header__title">{title}</h3>
              <span onClick={() => onClose()} className="modal__header__close">
                &times;
              </span>
            </>
          )}
        </header>
        <div className="modal__content">{children}</div>
      </div>
    );
  }
  return null;
}
