import { trackEvent } from '@/mixpanel';
import { ImgHTMLAttributes, useRef } from 'react';

type ImgProps = ImgHTMLAttributes<HTMLImageElement> & {
  trackingName: string;
};

export function Img({ trackingName, ...props }: ImgProps) {
  const s = +new Date();
  const ref = useRef<HTMLImageElement>(null);
  return (
    <img
      ref={ref}
      {...props}
      onLoad={() => {
        trackEvent('image-load', {
          trackingName,
          src: props.src,
          duration: +new Date() - s,
          width: ref.current?.width,
          height: ref.current?.height,
          result: 'success',
        });
      }}
      onError={() => {
        trackEvent('image-load', {
          trackingName,
          src: props.src,
          duration: +new Date() - s,
          width: ref.current?.width,
          height: ref.current?.height,
          result: 'failed',
        });
      }}
    />
  );
}
