import LoadingOverlay from '@/common/components/LoadingOverlay/LoadingOverlay';
import { PrintTemplateCard } from '@/components/print-template-card';
import { cva, cx } from 'class-variance-authority';
import { Suspense, useEffect } from 'react';
import { useSelectedTemplateId, useTemplateList } from './useTemplate';
import useTitle from '@/hooks/useTitle';
import { trackEvent } from '@/mixpanel';
import onSale from './assets/on-sale.svg';

import { Experiment, useExperiment } from '@/features/experiment/components/experiment';
import useSearchParams from '@/hooks/useSearchParams';
import { useHistory } from 'react-router';
import { templateNameToProductNameMap } from './advanced-template-iap-map';
import { usePrintQueues } from './use-print-queue';
import { useBundleStatus } from '@/bundle/components/use-bundle';
import { useEntitlement } from '@/hooks/use-entitlement';
import { invert } from 'lodash-es';
import { getTemplatePreviewDeepLink } from '@/app-binding/deeplink';
export function TicketTemplateListPage() {
  useTitle('选择打印模板');
  const advancedTemplateExperimentQuery = useExperiment({ variables: { name: 'sales_plan_advance_template' } });
  useEffect(
    function () {
      if (advancedTemplateExperimentQuery.data) {
        trackEvent('page_loaded', {
          page_name: 'TICKET_TEMPLATE_LIST_PAGE',
          abtest: advancedTemplateExperimentQuery.data,
        });
      }
    },
    [advancedTemplateExperimentQuery.data]
  );
  return (
    <Suspense fallback={<LoadingOverlay />}>
      <div className="pb-safe">
        <TicketTemplateList />
      </div>
    </Suspense>
  );
}

function TicketTemplateList() {
  const history = useHistory();
  const { templateSlugFromCallback } = useSearchParams();
  const useTemplates = useTemplateList({ suspense: true });
  const { templates: templateList = [], advancedTemplates: advancedTemplates = [] } = useTemplates.data ?? {};
  const useSelectTemplateQuery = useSelectedTemplateId({ suspense: true });
  const selectedTemplate = useSelectTemplateQuery.data;
  const templatName = invert(templateNameToProductNameMap)[templateSlugFromCallback];
  const printQueuesQuery = usePrintQueues({ suspense: true });
  const printQueueIds = printQueuesQuery.data?.printQueueMappings.map((queue) => queue.queueId) ?? [];

  useEffect(
    function () {
      if (useTemplates.data && printQueuesQuery.data && typeof templatName !== 'undefined' && printQueueIds.length) {
        const templateId = advancedTemplates.find((template) => template.templateName === templatName)?.templateId;
        if (typeof templateId !== 'undefined') {
          const qs = new URLSearchParams(window.location.search);
          qs.delete('templateSlugFromCallback');
          history.replace({
            ...location,
            search: qs.toString(),
          });
          window.location.href = getTemplatePreviewDeepLink({
            queueId: printQueueIds[0],
            templateId,
          });
        }
      }
    },
    [useTemplates.data, printQueuesQuery.data]
  );
  return (
    <div>
      <div className={cx(sectionTitle(), sectionTitleContainer())}>基础小票模版</div>
      <div className="flex overflow-scroll flex-row w-full px-[16px] gap-2 no-scrollbar">
        {templateList.map((template) => {
          return (
            <div
              key={template.templateId}
              className={cardContainer({
                layout: 'horizontal',
                selected: template.templateId === selectedTemplate,
              })}
            >
              <PrintTemplateCard
                key={template.templateId}
                templateId={template.templateId}
                sectionLayout="horizontal"
              />
            </div>
          );
        })}
      </div>
      <div className={sectionTitleContainer()}>
        <div className={sectionTitle()}>高级小票模版</div>
        <SectionUpsell />
      </div>
      <AdvancedTemplatesInList layout="vertical" />
    </div>
  );
}

function SectionUpsell() {
  const bundleStatusQuery = useBundleStatus({
    variables: {
      productName: 'vasbundleorder',
    },
    suspense: true,
  });
  const zhengcanEntitlementQuery = useEntitlement({
    variables: { feature: 'advance.template.branded_dinner', entityType: 'STORE' },
  });
  const teaEntitlementQuery = useEntitlement({
    variables: { feature: 'advance.template.tea', entityType: 'STORE' },
  });
  const hotpotEntitlementQuery = useEntitlement({
    variables: { feature: 'advance.template.spicy_hot_pot', entityType: 'STORE' },
  });
  const zhengcanEntitled = zhengcanEntitlementQuery.data ?? false;
  const teaEntitled = teaEntitlementQuery.data ?? false;
  const hotpotEntitled = hotpotEntitlementQuery.data ?? false;

  const vasBundleOrderNotEntitled = bundleStatusQuery.data !== 'UNKNOWN' && bundleStatusQuery.data !== 'EXPIRED';
  if (vasBundleOrderNotEntitled || (zhengcanEntitled && teaEntitled && hotpotEntitled)) {
    return null;
  }
  return (
    <div className="flex items-center gap-1">
      <Experiment
        name="sales_plan_advance_template"
        variantMap={{
          A: UpsellSectionVariantA,
          B: UpsellSectionVariantB,
          default: UpsellSectionVariantB,
        }}
      />
      <img src={onSale} />
    </div>
  );
}

function UpsellSectionVariantA() {
  return <span className="text-[14px] text-primary">限时直降</span>;
}

function UpsellSectionVariantB() {
  return <span className="text-[14px] text-primary">接单大师专享</span>;
}

export function AdvancedTemplatesInList({
  layout = 'vertical',
}: {
  layout: 'horizontal' | 'horizontal-height' | 'vertical';
}) {
  const useTemplates = useTemplateList({ suspense: true });
  const useSelectTemplateQuery = useSelectedTemplateId({ suspense: true });
  const selectedTemplate = useSelectTemplateQuery.data;
  const { advancedTemplates: advancedTemplateList = [] } = useTemplates.data ?? {};
  return (
    <>
      {advancedTemplateList.map((template) => {
        return (
          <div
            key={template.templateId}
            className={cardContainer({
              layout,
              selected: template.templateId === selectedTemplate,
            })}
          >
            <PrintTemplateCard key={template.templateId} templateId={template.templateId} sectionLayout={layout} />
          </div>
        );
      })}
    </>
  );
}

const cardContainer = cva('overflow-hidden border-2 border-[#0000001F] rounded-xl', {
  variants: {
    layout: {
      vertical: 'h-[366px] m-[12px]',
      horizontal: 'w-[168px] flex-shrink-0',
      'horizontal-height': 'h-[366px] w-[168px] flex-shrink-0',
    },
    selected: {
      true: 'border-[#e06e4d] shadow-inner',
    },
  },
});

const sectionTitle = cva('font-sans text-[20px] font-semibold');

const sectionTitleContainer = cva('flex mx-[16px] mt-[32px] mb-[16px] leading-[24px] tracking-[0.4px] justify-between');
