import { Padding, Placement } from '@popperjs/core';
import React, { useCallback, useEffect } from 'react';
import { OverlayContainer as PortalOverlayContainer } from 'react-aria';
import { usePopper } from 'react-popper';
import { CSSProperties } from 'styled-components';

import { useOnClickOutside, useHover } from '@css/op-hooks';

import { popperConfig } from './popperConfig';
import { PopoverArrow, PopoverWrapper, PopoverCloseWrapper, IconContainer, PopoverClose } from './styles';
import { DisplayVariant, InteractiveMode, TriggerMode } from './types';

export interface PopoverProps {
  /**
   * Optional class name
   */
  className?: string;
  /**
   * Content to display in the tooltip.
   */
  content?: React.ReactNode;
  /**
   * Display variant for styling the tooltip.
   */
  displayVariant?: DisplayVariant;
  /**
   *  Controls whether the popover is open or not.
   */
  open?: boolean;
  /**
   * Optional handler when popover has closed.
   */
  onClose?: () => void;
  /**
   * Optional handler when popover has opened.
   */
  onOpen?: () => void;
  /**
   * Data test id used for automated testing.
   */
  dataTest?: string;
  /**
   * How to place the tooltip around the child.
   */
  placement?: Placement;
  /**
   * Specify how the tooltip is triggered.
   */
  triggerMode?: TriggerMode;
  /**
   * This will hide the arrow.
   */
  isArrowHidden?: boolean;
  /**
   * @default false
   *
   * If true, will set default max-width and padding.
   */
  hasContentPadding?: boolean;

  /**
   * A tooltip can be interactive.
   * If 'hoverable' the popover won't close when the user hovers over the tooltip before the leaveDelay is expired.
   * If 'close' the popover won't close until the user clicks the close button.
   * @default undefined
   */
  interactive?: InteractiveMode;

  /**
   * The number of milliseconds to wait before hiding the tooltip. @default 200
   */
  leaveDelay?: number;

  /**
   * Popovers by default render in a React Portal (i.e. they’re appended to the bottom of the DOM).
   */
  usePortal?: boolean;

  /**
   * HTML tag name for the target element..
   * @default 'div'
   */
  targetTagName?: keyof JSX.IntrinsicElements;

  /**
   * Applies a virtual padding to the parent element and keeps the popover within this boundary
   */
  boundaryPadding?: Padding;

  /**
   * Styles to apply to the trigger element
   */
  triggerStyle?: CSSProperties;

  dismissOnClickOutside?: boolean;
  fadeIn?: boolean;
}

const POPOVER_CLASS_NAME = 'op-popover';

/**
 * Informational display that needs to be closed before it is dismissed.
 */
export const Popover: React.FC<React.PropsWithChildren<PopoverProps>> = (
  props: React.PropsWithChildren<PopoverProps>
) => {
  const {
    onClose,
    onOpen,
    className,
    open = false,
    dataTest = 'op-popover',
    placement = 'top',
    triggerMode = 'click',
    hasContentPadding = false,
    content,
    displayVariant,
    children,
    isArrowHidden = false,
    interactive,
    leaveDelay = 200,
    usePortal = true,
    targetTagName = 'div',
    boundaryPadding,
    triggerStyle: popoverTriggerStyle,
    dismissOnClickOutside = true,
    fadeIn,
  } = props;
  const dataTestPopover = `${dataTest}-panel`;

  const [referenceElement, setReferenceElement] = React.useState<HTMLDivElement | null>(null);
  const [popperElement, setPopperElement] = React.useState<HTMLDivElement | null>(null);
  const [arrowElement, setArrowElement] = React.useState<HTMLDivElement | null>(null);
  const [actualOpen, setOpen] = React.useState<boolean>(false);

  const { isHovered: isTriggerHovered } = useHover<HTMLDivElement>(referenceElement);
  const { isHovered: isTooltipHovered } = useHover<HTMLDivElement>(popperElement);

  const handleToggle = (e: React.MouseEvent) => {
    e.stopPropagation();
    setOpen((currentIsOpen) => {
      if (currentIsOpen) {
        onClose?.();
      } else {
        onOpen?.();
      }
      return !currentIsOpen;
    });
  };

  const handleOpen = useCallback(() => {
    setOpen(true);
    onOpen?.();
  }, [onOpen]);

  const handleClose = useCallback(() => {
    setOpen(false);
    onClose?.();
  }, [onClose]);

  const handleMouseLeave = useCallback(() => {
    if (interactive) {
      return;
    }
    handleClose();
  }, [handleClose, interactive]);

  useEffect(() => {
    if (interactive === 'hoverable') {
      const hoverTimeout = setTimeout(() => {
        if (!isTooltipHovered && !isTriggerHovered) {
          handleClose();
        }
      }, leaveDelay);
      return (): void => {
        clearTimeout(hoverTimeout);
      };
    }
    return undefined;
  }, [handleClose, interactive, isTooltipHovered, isTriggerHovered, leaveDelay, onClose]);

  const handlePopoverClick = (e: React.MouseEvent<HTMLElement>) => {
    if (!popperElement) {
      return;
    }

    const eventTarget = e.target as HTMLElement;
    // get the popover the click event occurred in
    const eventPopover = popperElement.closest(`.${POPOVER_CLASS_NAME}`);
    // in case we have multiple popovers, lets only dismiss this one
    const isEventFromSelf = eventPopover === popperElement;
    // get the closes element that allows dismissing
    const dismissElement = eventTarget.closest(`[data-popover-dismiss=true]`);
    const shouldDismiss = dismissElement != null;
    const isDisabled = eventTarget.closest(`:disabled`) != null;
    if (shouldDismiss && !isDisabled && isEventFromSelf) {
      handleClose();
    }
  };

  let targetProps: React.HTMLProps<HTMLElement> = {};
  if (triggerMode === 'hover') {
    targetProps = {
      onMouseEnter: handleOpen,
      onMouseLeave: handleMouseLeave,
    };
  } else if (triggerMode === 'click') {
    targetProps = {
      onClick: handleToggle,
    };
  }

  useOnClickOutside(
    [{ current: popperElement }, { current: referenceElement }],
    interactive === 'close' || !dismissOnClickOutside ? () => null : handleClose
  );

  useEffect(() => {
    setOpen(open);
  }, [open]);

  const { styles, attributes } = usePopper(
    referenceElement,
    popperElement,
    popperConfig(placement, arrowElement, boundaryPadding)
  );

  const contentEl =
    interactive === 'close' ? (
      <PopoverCloseWrapper>
        <div>{content}</div>
        <IconContainer>
          <PopoverClose onClick={handleClose} width="18px" height="18px" />
        </IconContainer>
      </PopoverCloseWrapper>
    ) : (
      content
    );

  const OverlayContainer = usePortal ? PortalOverlayContainer : React.Fragment;

  return (
    <>
      {React.createElement(
        targetTagName,
        {
          ...targetProps,
          'data-testid': dataTest,
          style: popoverTriggerStyle,
          ref: setReferenceElement,
        },
        children
      )}
      {actualOpen && (
        <OverlayContainer>
          <PopoverWrapper
            fadeIn={fadeIn}
            data-testid={dataTestPopover}
            className={`${POPOVER_CLASS_NAME} ${className}`}
            displayVariant={displayVariant}
            hasContentPadding={hasContentPadding}
            ref={setPopperElement}
            style={styles.popper}
            onClick={handlePopoverClick}
            {...attributes.popper}
          >
            {contentEl}
            {!isArrowHidden && (
              <PopoverArrow
                ref={setArrowElement}
                displayVariant={displayVariant}
                style={styles.arrow}
                data-popper-arrow
              />
            )}
          </PopoverWrapper>
        </OverlayContainer>
      )}
    </>
  );
};
