import { urqlClient } from '@/http-clients';
import { getTraceId } from '@/utils/trace-id';
import { getUtmTags } from '@/utils/utm-tags';
import gql from 'graphql-tag';

const MUTATION = gql`
  mutation upsertUserEvent($event: String!, $properties: [PropertyInput!]!) {
    upsertUserEvent(input: { event: $event, properties: $properties }) {
      success
    }
  }
`;

export function cssTracking(event: string, payload?: Record<string, string>) {
  const trace_id = getTraceId();
  const utmTags = getUtmTags();

  const target = {
    trace_id,
    ...payload,
    ...utmTags,
  } as Record<string, string> & {
    trace_id: string;
  };

  const properties = Object.keys(target).map((key) => ({
    key,
    value: target[key],
  }));

  void urqlClient.mutation(MUTATION, { event, properties }).toPromise();
}

export function cssTrackingOnce(identifier: string, event: string, payload?: Record<string, string>) {
  const key = `css-tracking-${event}-${identifier}`;
  if (!sessionStorage.getItem(key)) {
    cssTracking(event, payload);
    sessionStorage.setItem(key, '1');
  }
}

export function cssTrackingRequestFail({ api_name }: { api_name: string }) {
  const trace_id = getTraceId();
  if (api_name !== 'upsertUserEvent') {
    cssTracking('api_request_result', {
      url: location.href,
      api_name,
      status: 'fail',
      trace_id,
    });
  }
}
