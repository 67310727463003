import { create, StateCreator } from 'zustand';
interface SimSlice {
  // TODO: infer zod here.
  simcard: string;
  setSimcard: (simcard: string) => void;
  isValid: boolean;
  setIsValid: (isValid: boolean) => void;
}

const simSlice: StateCreator<SimSlice, [], [], SimSlice> = (set) => ({
  simcard: '',
  setSimcard: (simcard) => set(() => ({ simcard })),
  isValid: false,
  setIsValid: (isValid) => set(() => ({ isValid })),
});
export const useSim = create(simSlice);
