import * as React from 'react';

function SvgCullinanGraph(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path d="M8.5 17h3.035V3H8.5v14zM6 17H3v-7h3v7zM14 17V7h3v10h-3z" />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgCullinanGraph);
export default ForwardRef;
