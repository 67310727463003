import * as React from 'react';

function SvgCullinanNavCollapseHover(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path d="M2 16V4h2v12H2zM18 11H7.416V9H18v2z" />
      <path d="M10.95 14.95L6 10l4.95-4.95 1.414 1.415L8.828 10l3.536 3.536-1.414 1.414z" />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgCullinanNavCollapseHover);
export default ForwardRef;
