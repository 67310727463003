import * as React from 'react';

function SvgCorporateFare(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M10 4V2c0-1.1-.9-2-2-2H2C.9 0 0 .9 0 2v14c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2h-8zM8 16H2v-2h6v2zm0-4H2v-2h6v2zm0-4H2V6h6v2zm0-4H2V2h6v2zm10 12h-8V6h8v10zm-2-8h-4v2h4V8zm0 4h-4v2h4v-2z"
        fill="#000"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgCorporateFare);
export default ForwardRef;
