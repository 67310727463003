import { isInChinaOtterAppAndroid } from '../../utils/userAgent';
import { Ofo } from './constant';

type OpenOfoAuthParams = {
  storeId: string;
  ofo: Ofo;
  callback: string;
  callbackParams?: Record<string, string>;
  productName: string;
};
export const openNativeOfoAuth = ({ productName, storeId, ofo, callback, callbackParams = {} }: OpenOfoAuthParams) => {
  const searchParams = new URLSearchParams({
    storeId,
    product: productName,
    ofotype: ofo,
    account_type: ofo === 'eleme' ? 'eleme' : 'meituan',
  });
  const isAndroid = isInChinaOtterAppAndroid();
  if (!isAndroid) {
    searchParams.set(
      'callbackurl',
      `${window.location.origin}${callback}?${new URLSearchParams({
        ...callbackParams,
      }).toString()}`
    );
  }
  const targetPath = `otter://onboard?${searchParams.toString()}`;
  window.location.href = targetPath;
};
