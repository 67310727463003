import { FetchMiddleware } from '../middleware/types';
import { FetchClient, FetchClientRequest } from '../types';

interface MiddlewareFetchClientOpts {
  middlewares: FetchMiddleware[];
  delegate: FetchClient;
}

/**
 * Takes in a list of FetchMiddleware to apply over the FetchClientRequest.
 */
export class MiddlewareFetchClient implements FetchClient {
  public constructor(private opts: MiddlewareFetchClientOpts) {}

  public get<T>(request: FetchClientRequest): Promise<T> {
    const finalRequest = this.opts.middlewares.reduce((accRequest, middleware) => middleware(accRequest), request);
    return this.opts.delegate.get<T>(finalRequest);
  }
}
