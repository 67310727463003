import { css } from 'styled-components';

import { BreakpointProp, BreakpointVariant } from '../../components/types';
import { BreakpointMinWidths, BreakpointMaxWidths, BREAKPOINT_UNIT } from '../constants';
import { CssOutput } from './types';

export const mediaQuery = (
  min: BreakpointVariant | undefined,
  { direction = 'width', max }: Partial<{ direction: 'width' | 'height'; max: Exclude<BreakpointVariant, '$12'> }> = {}
) =>
  `@media screen${min !== undefined ? ` and (min-${direction}: ${BreakpointMinWidths[min]}${BREAKPOINT_UNIT})` : ''}${
    max !== undefined ? ` and (max-${direction}: ${BreakpointMaxWidths[max]}${BREAKPOINT_UNIT})` : ''
  }`;

// Generates style rules for all specified breakpoints (using min-width)
export const getBreakpointPropRules = <T>(
  { _: defaultValue, ...breakpoints }: BreakpointProp<T> = {},
  renderValue: (value: T) => CssOutput
) => css`
  ${defaultValue && renderValue(defaultValue)}
  ${(Object.entries(breakpoints) as Array<[BreakpointVariant, T]>).map(
    ([breakpoint, value]) =>
      css`
        ${mediaQuery(breakpoint)} {
          ${renderValue(value)}
        }
      `
  )}
`;

// Returns the orientation given width/height dimensions
export const getOrientation = ({ width, height }: { width: number; height: number }) =>
  width / height > 1 ? 'landscape' : 'portrait';
