import * as React from 'react';

function SvgStorePlus(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 23 23"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path d="M18.335 2.732a.75.75 0 01.75.75v2.42h2.385a.75.75 0 010 1.5h-2.385v2.385a.75.75 0 01-1.5 0V7.403h-2.42a.75.75 0 010-1.5h2.42V3.482a.75.75 0 01.75-.75zm-5.92 3.902c0 1.531.582 2.926 1.538 3.974H5.706V18.2c0 .03.005.054.012.07H8.56v-4.944c0-.463.375-.838.838-.838H12.6c.463 0 .838.375.838.838v4.944h2.843a.197.197 0 00.012-.07V12.18a5.891 5.891 0 002.095.355V18.2c0 1.065-.787 2.165-2.032 2.165H5.643c-1.24 0-2.032-1.096-2.032-2.165v-7.591h-.374c-.631 0-1.577-.443-1.577-1.468v-.826c0-.223.053-.447.163-.653L3.51 4.456v-.001c.306-.583.916-.82 1.418-.82h8.305a5.875 5.875 0 00-.817 3z" />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgStorePlus);
export default ForwardRef;
