import * as React from 'react';

function SvgSend(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path d="M2.01 21L23 12 2.01 3 2 10l15 2-15 2 .01 7z" fill="inherit" />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgSend);
export default ForwardRef;
