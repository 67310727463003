import { useStoreLinks } from '@/features/store-links/hooks/use-store-links';
import { OfoType } from '@/hooks/use-ofo-status';
import { buildOfoAuthCallbackUrl } from '@/iap/buildOfoAuthCallbackUrl';
import { queryClient } from '@/query-client';
import type { LandingRule } from './types';

export function buildOfoCheckRule(
  judger: (ofo: Partial<Record<OfoType, boolean>>) => boolean,
  productName: string
): LandingRule {
  return {
    checker: async function () {
      const storelinks = await queryClient.fetchQuery({
        queryKey: useStoreLinks.getKey({}),
        queryFn: useStoreLinks.queryFn,
      });
      return judger(storelinks);
    },
    otherwise: function () {
      window.location.replace(buildOfoAuthCallbackUrl(productName));
    },
  };
}
