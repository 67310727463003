import * as React from 'react';

function SvgVerticalDots(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.714 6.857a1.714 1.714 0 11-3.429 0 1.714 1.714 0 013.429 0zm0 5.143a1.714 1.714 0 11-3.429 0 1.714 1.714 0 013.429 0zm-1.715 6.857a1.714 1.714 0 100-3.428 1.714 1.714 0 000 3.428z"
        fill="#707070"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgVerticalDots);
export default ForwardRef;
