export type CouponStatus = 'REDEEMABLE' | 'UNSPECIFIED' | 'REDEEMED' | 'EXPIRED';

export interface Tag {
  key: string;
  value: string;
}
export function convertTagsToDictionary(tags: Tag[]) {
  const dictionary: Record<string, string> = {};

  tags.forEach((tag) => {
    dictionary[tag.key] = tag.value;
  });

  return dictionary;
}
export interface Coupon {
  id: string;
  code: string;
  vases: Array<string>;
  discountType: CouponDiscountType;
  title: string;
  status: CouponStatus;
  expiryTime: string;
  amountOff: {
    currencyCode: string;
    units: number;
    nanos: number;
  };
  plans: Array<{
    name: string;
    productName: string;
    distributorName?: string;
    localizedPlanName: string;
    localizedPlanShortName: string;
  }>;
  redemptionLimit: number;
  redeemedTimes: number;
  percentage?: number;
  tags?: Tag[];
}

export type ClaimedCoupon = Pick<Coupon, 'id' | 'code' | 'vases' | 'discountType' | 'tags'>;
export interface CouponTag {
  couponId: string;
  tags: Tag[];
}
export interface ClaimedCouponResponse {
  claimedCoupons: ClaimedCoupon[];
  couponTags: CouponTag[];
}
export type CouponDiscountType =
  | 'COUPON_TYPE_UNKNOWN'
  | 'COUPON_TYPE_AMOUNT_OFF'
  | 'COUPON_TYPE_PERCENT_OFF'
  | 'COUPON_TYPE_FREE_TRIAL'
  | 'COUPON_TYPE_PERIOD_EXTENSION';
