import * as React from 'react';

function SvgFinancials(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <rect width={24} height={24} rx={6} fill="#1588D5" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.65 4.51c.734-.382 1.698-.382 3.625-.382h1.512c1.928 0 2.891 0 3.625.381a3.385 3.385 0 011.444 1.444c.38.733.38 1.697.38 3.625v4.896c0 1.928 0 2.892-.38 3.625a3.384 3.384 0 01-1.444 1.444c-.733.38-1.697.38-3.625.38h-1.512c-1.927 0-2.891 0-3.625-.38a3.385 3.385 0 01-1.443-1.444c-.381-.733-.381-1.697-.381-3.625V9.578c0-1.928 0-2.892.38-3.625A3.385 3.385 0 017.65 4.509zm.433 5.26c0-.78.631-1.41 1.41-1.41h5.077a1.41 1.41 0 010 2.82H9.493a1.41 1.41 0 01-1.41-1.41zm0 4.513c0-.78.631-1.41 1.41-1.41h2.82a1.41 1.41 0 110 2.82h-2.82a1.41 1.41 0 01-1.41-1.41z"
        fill="#fff"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgFinancials);
export default ForwardRef;
