import * as React from 'react';

function SvgMemo(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.707 2.707l-6 6a1 1 0 01-.26.193l-3.154.807.812-3.154a1 1 0 01.188-.26l6-6a1 1 0 011.414 0l1 1a1 1 0 010 1.414zm-10.56 8.44A.5.5 0 01.5 11h11a.5.5 0 110 1H.5a.5.5 0 01-.354-.854z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgMemo);
export default ForwardRef;
