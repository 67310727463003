import { Agreements } from '@/common/components/agreements';
import { usePageName } from '@/components/page';
import { Experiment } from '@/features/experiment/components/experiment';
import { RenewButton } from '@/features/payment/components/renew-button';
import { FireButton } from '@/features/upsell/components/fire-button';
import useSearchParams from '@/hooks/useSearchParams';
import { LaunchPaymentButton } from '@/next/components/launch-payment-button';
import { TProduct, getTrackingName } from '@/product-registry';
import { cssTracking } from '@/tracking/css-tracking';
import { useEffect } from 'react';
import { useParams } from 'react-router';
import { Carousel } from '../FeatureUnlockedPage/carousel';
import { PrintTestAndKnowMoreButton } from '../FeatureUnlockedPage/components';
import { HideForUpsellNewVersion, RemotePositiveButtonText } from '@/features/cms/components/remote-content';

const PLAN_NAME = 'vas.bundle.protection.recurring.p1m.store.chn.v0';
const EXPERIMENT_NAME = 'tof-coupon-type-test';

export default function VasBundleProtectionUpsellPage() {
  useEffect(function () {
    cssTracking('page_loaded', {
      url: window.location.href,
    });
  }, []);

  return (
    <section className="h-screen h-[100svh] mx-auto grid grid-rows-footer-page pb-safe">
      <div data-testid="vasbundleprotection-upsell-description" className="max-h-screen overflow-auto p-4">
        <HideForUpsellNewVersion productName="vasbundleprotection">
          <Experiment
            name={EXPERIMENT_NAME}
            variantMap={{
              default: HeaderFreeTrail,
              A: HeaderFreeTrail,
              B: HeaderAmountOff,
            }}
          />
        </HideForUpsellNewVersion>
        <UpsellHero />
      </div>

      <footer className="p-4 text-md border-t border-neutral-400 w-full bg-white flex flex-col gap-2 ">
        <Agreements actionName="开通" />
        <Experiment
          name={EXPERIMENT_NAME}
          variantMap={{
            default: ActionFreeTrial,
            A: ActionFreeTrial,
            B: ActionAmountOff,
          }}
        />
        <PrintTestAndKnowMoreButton />
      </footer>
    </section>
  );
}

function HeaderFreeTrail() {
  return (
    <>
      <h1 className="font-bold text-2xl flex items-center">
        <span className="text-primary">外卖守护宝</span>
        服务特权已激活
      </h1>
      <div>
        <p className="font-bold text-2xl flex items-center">
          <span className="text-primary">0元畅享7天</span>
          原价<s>￥99</s>
        </p>
        <p>实时监控门店经营状态，避免漏单和投诉处罚。</p>
      </div>
    </>
  );
}

function ActionFreeTrial() {
  const source = usePageName();
  const { productName } = useParams<{ productName: TProduct; couponId: string }>();

  return (
    <RenewButton
      createdMetricEvent={{
        name: 'payment_order_created',
        payload: {
          paymentMethodType: 'sign-contract-override',
          product_name: getTrackingName(productName),
          planName: PLAN_NAME,
          source,
        },
      }}
      productName={productName}
      planName={PLAN_NAME}
    >
      <RemotePositiveButtonText fallback="免费试用7天" productName="vasbundleprotection" />
    </RenewButton>
  );
}

function HeaderAmountOff() {
  return (
    <>
      <h1 className="font-bold text-2xl flex items-center">
        <span className="text-primary">外卖守护宝</span>
        服务特权已激活
      </h1>
      <div>
        <p className="font-bold text-2xl flex items-center">
          <span className="text-primary">1元试用全部功能7天</span>
          原价<s>￥99</s>
        </p>
        <p>
          【双11限时福利】
          <br />
          实时监控门店经营状态，避免漏单和投诉处罚。
        </p>
      </div>
    </>
  );
}

function ActionAmountOff() {
  const { couponId } = useSearchParams();
  return (
    <LaunchPaymentButton planName={PLAN_NAME} couponCode={couponId}>
      <RemotePositiveButtonText fallback="确认协议并开通" productName="vasbundleprotection" />
    </LaunchPaymentButton>
  );
}

function UpsellHero() {
  const { productName } = useParams<{ productName: TProduct }>();
  return (
    <div className="flex flex-col justify-center rounded-lg pb-2 my-2">
      <Carousel />
      <HideForUpsellNewVersion productName="vasbundleprotection">
        <FireButton productName={productName} />
      </HideForUpsellNewVersion>
    </div>
  );
}
