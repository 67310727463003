import React from 'react';
import { useParams } from 'react-router-dom';
import useSearchParams from '../../hooks/useSearchParams';
import { trackEvent } from '../../mixpanel';
import { getTrackingName, ProductSlug } from '../../product-registry';
import { StyledButton } from './ui';
import { useMutation } from '@tanstack/react-query';
import { spawnHttpClient } from '../../http-clients';
import { toast } from 'react-hot-toast';

export function ButtonContent() {
  return <span className="my-2 block rounded-sm text-white text-center text-base">确认协议并开通</span>;
}
export function PrintTestAndKnowMoreButton() {
  const { queueId } = useSearchParams();
  const { productName } = useParams<{ productName: ProductSlug }>();

  const { mutate } = useMutation(
    async function () {
      const res = await spawnHttpClient({}).get({
        path: '/print/printtestmessage',
        method: 'POST',
        requestParams: {
          data: {
            queueId,
          },
        },
      });

      return res;
    },
    {
      onSuccess: () => {
        toast.success('打印测试请求已发送', {
          position: 'bottom-center',
        });
      },
      onError: () => {
        toast.error('打印测试失败', {
          position: 'bottom-center',
        });
      },
    }
  );

  const shouldShowPrintTestButton = Boolean(queueId) && (productName === 'vasbundleorder' || productName === 'xcd');

  if (shouldShowPrintTestButton) {
    return (
      <StyledButton
        onClick={() => {
          trackEvent('test_ticket_clicked', {
            product_name: getTrackingName(productName),
            page_name: 'UPSELLING_PAGE',
          });
          mutate();
        }}
      >
        打印测试
      </StyledButton>
    );
  }

  return null;
}
