import mixpanel, { Callback, RequestOptions } from 'mixpanel-browser';
import { usePageName } from './components/page';
import { getUserId } from './hooks/use-user-id';
import { ManagedTrackingEvent } from './tracking/events';
import { getTraceId } from './utils/trace-id';

function getMixpanelToken() {
  return import.meta.env.VITE_MIXPANEL_TOKEN as string;
}

try {
  void getUserId().then((userId) => {
    if (userId) {
      mixpanel.identify(userId);
      mixpanel.people.set({ userId });
    }
  });
} catch (e) {
  console.log(e);
}

mixpanel.init(getMixpanelToken(), {
  debug: import.meta.env.MODE !== 'production',
});

mixpanel.register({ 'app name': 'otterapp-campaign-page', page_name: 'WEB' });

type TrackingProps = Record<string, any>;
type TrackingEvent = string;

export const trackEvent = buildMixpanelTracker('WEB');

export function trackPageView(props: TrackingProps) {
  mixpanel.track('Page View', { pathname: window.location.pathname, ...props });
}

/**
 * @deprecated This method send event to "User Action" rather than action property, please use trackEvent instead.
 * @param action TrackingEvent "action" field inside "User Action" event
 * @param props TrackingProps other props inside "User Action" event
 */
export function trackAction(action: TrackingEvent, props?: TrackingProps) {
  mixpanel.track('User Action', { ...props, action });
}

/**
 * @param eventName The name of the event
 * @param props TrackingProps other props inside "Page View" event
 */
export function trackAnonymousEvent(eventName: TrackingEvent, props?: TrackingProps) {
  mixpanel.track(eventName, { pathname: window.location.pathname, ...props });
}

/**
 * imagine a virtual roulette wheel with {number} slots
 * there is only 1 slot can trigger `fn()`
 *
 * slots: number of roulette slots, or a function return the number of roulette slots.
 * fn: the function need to call with possibility
 */
export function rouletteCall(slots: () => number, fn: () => void): void;
export function rouletteCall(slots: number, fn: () => void): void;
export function rouletteCall(slots: any, fn: () => void): void {
  if (typeof slots === 'function') {
    slots = slots();
  }

  // NOTE: if there is no slots, always return false.
  const shouldCall = slots !== 0 && Math.floor(Math.random() * slots) === 0;
  if (shouldCall) {
    fn();
  }
}

function pluckStatus({ status }: Response) {
  return status;
}

export async function trackResponseStatusCode(
  response: Promise<Response>,
  {
    input,
    init,
  }: {
    input: RequestInfo;
    init?: RequestInit;
  }
): Promise<void> {
  let url = typeof input === 'string' ? input : input.url;

  const startTime = Date.now();

  response.then(pluckStatus, pluckStatus).then((status) => {
    if (url.endsWith('/graphql')) {
      const { operationName } = JSON.parse(init!.body as string) as Partial<{
        operationName: string;
      }>;
      url += `::${operationName}`;
    }

    rouletteCall(
      () => {
        return 10;
      },
      () => {
        if (!url.includes('segment.io')) {
          trackAction('IAP Perf - API Latency', {
            url,
            duration: Date.now() - startTime,
            status,
          });
        }
      }
    );
  });
}

export function useTrack<TDefaults extends Record<string, string | number>>(defaults?: TDefaults) {
  const page_name = usePageName();

  return buildMixpanelTracker(page_name, defaults);
}

function buildMixpanelTracker(page_name: string, defaults?: Record<string, string | number>) {
  return function track<TName extends string>(
    ...args: Extract<ManagedTrackingEvent, { name: TName }> extends { payload: infer TPayload }
      ? [
          eventName: TName,
          eventProperties: TPayload,
          optionsOrCallback?: RequestOptions | Callback,
          callback?: Callback
        ]
      : [
          eventName: TName,
          eventProperties?: Record<string, string | number>,
          optionsOrCallback?: RequestOptions | Callback,
          callback?: Callback
        ]
  ) {
    const [eventName, eventProperties, optionsOrCallback, callback] = args;

    const trace_id = getTraceId();

    const payload: {
      page_name: string;
      pathname: string;
      trace_id: string;
      [key: string]: string | number;
    } = {
      page_name,
      pathname: location.pathname,
      trace_id,
      lutrahub_page_version: __OTTERAPP_ASSETS_VERSION__,
      ...defaults,
      ...(typeof eventProperties === 'object' ? eventProperties : {}),
    };

    mixpanel.track(eventName, payload, optionsOrCallback, callback);
  };
}
