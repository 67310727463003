import * as React from 'react';

function SvgCullinanWarningContainedOutline(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path d="M7.64 4.205H6.36v3.5h1.28v-3.5zM6.36 8.515h1.28v1.28H6.36v-1.28z" fill="currentColor" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 7A7 7 0 110 7a7 7 0 0114 0zm-1.313 0A5.688 5.688 0 111.313 7a5.688 5.688 0 0111.376 0z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgCullinanWarningContainedOutline);
export default ForwardRef;
