import React from 'react';
import { createPortal } from 'react-dom';
import { Icon } from '@iconify/react';
import { Transition } from '@headlessui/react';

interface BottomSheetProps {
  isOpen?: boolean;
  title: React.ReactNode;
  children?: React.ReactNode;
  handleClose?: (isOpen: boolean) => void;
  showClose?: boolean;
}

export function BottomSheet({ showClose = true, isOpen, title, handleClose, children }: BottomSheetProps) {
  return createPortal(
    <Transition appear={true} show={Boolean(isOpen)}>
      <Transition.Child
        enter="transition-opacity duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100 z-40"
        leave="transition-opacity duration-300"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        className="fixed top-0 bottom-0 left-0 right-0 w-screen h-screen bg-black/50"
      ></Transition.Child>

      <Transition.Child
        enter="transition-all duration-300"
        enterFrom="translate-y-full"
        enterTo="translate-y-0 z-40"
        leave="transition-all duration-300"
        leaveFrom=""
        leaveTo="translate-y-full"
        className="fixed bottom-0 w-screen rounded-t-xl p-4 bg-white"
      >
        {showClose ? (
          <div
            className="absolute top-2 p-2 right-2 z-10"
            onClick={() => {
              handleClose?.(isOpen ?? false);
            }}
          >
            <Icon icon="material-symbols:close" />
          </div>
        ) : null}

        <div className="text-center relative">{title}</div>
        {children}
      </Transition.Child>
    </Transition>,

    document.body
  );
}
