import { captureException } from '@sentry/browser';
import React from 'react';
import handleViewport, { type InjectedViewportProps } from 'react-in-viewport';
import { useTimeout } from 'usehooks-ts';

export const EnsureInViewport = handleViewport(Block);

function Block({
  inViewport,
  forwardedRef,
  children,
  name,
}: InjectedViewportProps<HTMLDivElement> & {
  name: string;
  children: React.ReactNode;
}) {
  useTimeout(() => {
    if (!inViewport) {
      captureException(`EnsureInViewport: [ ${name} ] is not in viewport`);
    }
  }, 500);
  return <div ref={forwardedRef}>{children}</div>;
}
