import { cva } from 'class-variance-authority';
import React from 'react';
import { Switch, Route } from 'react-router-dom';

export const stickyFooterContainer = cva([
  'h-screen grid bg-white grid-rows-footer-page overflow-auto m-0 pb-safe box-border',
]);

export function StickyFooterLayout({ Content, Footer }: { Content: React.ComponentType; Footer: React.ComponentType }) {
  return (
    <Switch>
      <Route path="/product/:productName">
        <div id="product-page-container" className={stickyFooterContainer()}>
          <Content />
          <Footer />
        </div>
      </Route>
    </Switch>
  );
}
