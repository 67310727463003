import * as React from 'react';

function SvgPos(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.4 2.4v19.2h14.4V2.4H2.4zm12 2.4H4.8v2.4h9.6V4.8zM7.2 9.6H4.8V12h2.4V9.6zm-2.4 3.6h2.4v2.4H4.8v-2.4zm2.4 3.6H4.8v2.4h2.4v-2.4zm1.2-7.2h2.4V12H8.4V9.6zm2.4 3.6H8.4v2.4h2.4v-2.4zm-2.4 3.6h2.4v2.4H8.4v-2.4zm6-7.2H12V12h2.4V9.6zM12 13.2h2.4v2.4H12v-2.4zm2.4 3.6H12v2.4h2.4v-2.4z"
        fill="currentColor"
      />
      <path d="M20.4 21.6H18v-8.4h2.4v8.4zM18 12V2.4h3.6V12H18z" fill="currentColor" />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgPos);
export default ForwardRef;
