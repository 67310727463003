import * as React from 'react';

function SvgCalendar(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.666 6V3.336l-2-.001v.663a.666.666 0 11-1.333 0v-.663l-6.666-.001v.664a.666.666 0 11-1.334 0v-.665H1.337c-.001 0-.002 1.126-.002 2.667h13.331zm0 1.333H1.335l-.002 7.331c0 .002 13.33.003 13.33.003.002 0 .003-4.072.003-7.334zm-2-5.333h1.997C15.402 2 16 2.597 16 3.336v11.328c0 .738-.598 1.336-1.337 1.336H1.337A1.336 1.336 0 010 14.664V3.336C0 2.598.598 2 1.337 2h1.996V.669a.666.666 0 111.334 0V2h6.666V.669a.666.666 0 111.334 0V2z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgCalendar);
export default ForwardRef;
