import React from 'react';
import { createPortal } from 'react-dom';
import { Transition } from '@headlessui/react';
interface PopupProps {
  isOpen: boolean;
  children: React.ReactNode;
}

export function Popup({ isOpen, children }: PopupProps) {
  return createPortal(
    <Transition appear={true} show={Boolean(isOpen)}>
      <Transition.Child
        enter="transition-opacity duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100 z-20"
        leave="transition-opacity duration-300"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        className="fixed top-0 bottom-0 left-0 right-0 w-screen h-screen bg-black/50"
      ></Transition.Child>

      <Transition.Child
        enter="transition-all duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-1 z-20"
        leave="transition-all duration-300"
        leaveFrom="opacity-1"
        leaveTo="opacity-0"
        className="flex justify-center items-center fixed top-0 left-0 right-0 bottom-0 w-screen h-screen "
      >
        {children}
      </Transition.Child>
    </Transition>,

    document.body
  );
}
