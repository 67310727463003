import { usePrintQueues } from '@/pages/PrinterTemplate/use-print-queue';
import { getLinkForHomepage } from '@/app-binding/deeplink';
import { queryClient } from '@/query-client';

export async function advancedtemplatelist() {
  const printQueue = await queryClient.fetchQuery({
    queryKey: usePrintQueues.getKey(),
    queryFn: usePrintQueues.queryFn,
  });
  if (printQueue.printQueueMappings.length === 0) {
    return getLinkForHomepage();
  }
  return '/printer/template';
}
