import { ReactComponent as Firework } from '@/assets/firework.svg';
import * as styles from './success.module.scss';
import useSearchParams from '@/hooks/useSearchParams';
import { useLocation } from 'react-router';
import { useQueryClient } from '@tanstack/react-query';
import { pages } from '@/iap/pages';
import { useEffect } from 'react';

export function PaymentSuccess() {
  const { state = { planName: '', productName: '' } } = useLocation<{
    planName: string;
    productName: string;
  }>();

  const { productName } = state;

  const fallbackCallbackUrl = 'otter://orderfeed?fetchResource=true';
  const { defaultCallbackUrl } = pages.find((page) => page.slug === productName) ?? { defaultCallbackUrl: undefined };

  const { callbackUrl: callbackUrlFromQS } = useSearchParams();

  const callbackUrl = decodeURIComponent(callbackUrlFromQS ?? defaultCallbackUrl ?? fallbackCallbackUrl);
  const queryClient = useQueryClient();

  useEffect(function () {
    window.location.href = callbackUrl;
    void queryClient.invalidateQueries(['bundle-detail']);
  }, []);

  return (
    <div className={styles.paymentSuccessPage}>
      <Firework />
      <h1>购买成功</h1>
      <p>
        支付成功，如有问题，请联系客服 <br />
        400-0110-988
      </p>
      <a href={callbackUrl} className={styles.paymentSuccessButton}>
        完成
      </a>
    </div>
  );
}
