import styled from 'styled-components';

import { Text } from '@css/op-components';

import { StyledPageContainer } from '../../common/components/Layouts';
import { Button } from '../../iap/ui';

export { Text };

export const StyledContainer = styled(StyledPageContainer)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 32px 24px;
  gap: 8px;
`;

export const StyledTopInfo = styled.div``;
export const StyledSubTitle = styled(Text)`
  color: #666666;

  display: block;
  margin-top: 12px;
`;

export const StyledButton = styled(Button)`
  height: 46px;
  cursor: pointer;
`;

export const StyledImage = styled.img`
  display: block;
  width: 100%;
  height: auto;
`;

export const ButtonContainer = styled.div`
  display: flex;
  gap: 10px;
  justify-content: space-between;
  flex-direction: column;
`;
