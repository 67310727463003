import { gql } from 'graphql-request';
import { createMutation } from 'react-query-kit';
import { SuccessEventType } from './types';
import { queryClient } from '@/query-client';
import { useOrgId } from '@/hooks/use-org-id';
import { urqlClient } from '@/http-clients';
import type { ClaimedCoupon, ClaimedCouponResponse } from '@/api-binding/coupon';

const claimEventCouponMutation = gql`
  mutation claimSuccessEventCoupon($organizationId: String!, $successEventType: SuccessEventType!) {
    claimSuccessEventCoupon(input: { organizationId: $organizationId, successEventType: $successEventType }) {
      claimedCoupons {
        id
        code
        discountType
        vases
        tags {
          key
          value
        }
      }
      couponTags {
        couponId
        tags {
          key
          value
        }
      }
    }
  }
`;

export const useClaimEventCouponMutation = createMutation<ClaimedCoupon, { successEventType: SuccessEventType }, Error>(
  {
    mutationFn: async function ({ successEventType }: { successEventType: SuccessEventType }) {
      const orgId = await queryClient.fetchQuery({
        queryKey: useOrgId.getKey(),
        queryFn: useOrgId.queryFn,
      });
      if (!orgId) {
        throw Error('org id not found');
      }
      const res = await urqlClient
        .mutation<{ claimSuccessEventCoupon: { claimedCoupons: ClaimedCoupon[] } }>(claimEventCouponMutation, {
          organizationId: orgId,
          successEventType,
        })
        .toPromise();
      const coupons = res.data?.claimSuccessEventCoupon.claimedCoupons ?? [];
      if (coupons.length === 0) {
        throw Error('claim coupon failed');
      }
      return coupons[0];
    },
  }
);

export async function claimSuccessCouponMutation({
  successEventType,
}: {
  successEventType: SuccessEventType;
}): Promise<ClaimedCouponResponse | undefined> {
  const orgId = await queryClient.fetchQuery({
    queryKey: useOrgId.getKey(),
    queryFn: useOrgId.queryFn,
  });
  if (!orgId) {
    throw Error('org id not found');
  }
  const res = await urqlClient
    .mutation<{ claimSuccessEventCoupon: ClaimedCouponResponse }>(claimEventCouponMutation, {
      organizationId: orgId,
      successEventType,
    })
    .toPromise();
  return res.data?.claimSuccessEventCoupon;
}
