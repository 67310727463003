import * as React from 'react';

function SvgCullinanContainedTooltipFilled(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 17a7 7 0 100-14 7 7 0 000 14zm.688-4.833V13.3H9.412v-1.133h1.274zM7.5 8.767C7.5 7.514 8.641 6.5 10.05 6.5c1.409 0 2.55 1.014 2.55 2.267 0 .727-.504 1.118-.994 1.499-.465.361-.918.713-.918 1.334H9.412c0-1.032.6-1.441 1.128-1.801.414-.282.784-.534.784-1.032 0-.624-.574-1.134-1.275-1.134s-1.275.51-1.275 1.134H7.5z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgCullinanContainedTooltipFilled);
export default ForwardRef;
