import { useEffect, useState } from 'react';
import { CouponDiscountType, useCoupons } from './useCoupons';
import useSearchParams from '@/hooks/useSearchParams';

export type UpsellPayMode = 'sign-contract' | 'pay-for-plan';

export function useUpsellPayMode() {
  const { couponId } = useSearchParams();
  const [payMode, setPayMode] = useState<UpsellPayMode>('sign-contract');
  const { data: coupons } = useCoupons({
    variables: { status: 'REDEEMABLE' },
    enabled: !!couponId,
  });
  useEffect(() => {
    const curCoupon = coupons?.find((c) => c.code === couponId);
    setPayMode(curCoupon?.discountType === CouponDiscountType.CouponTypeFreeTrial ? 'sign-contract' : 'pay-for-plan');
  }, [coupons]);

  return {
    payMode,
  };
}
