import * as React from 'react';

function SvgSoup(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.93 17.672a.878.878 0 001.522.878l.221-.383a.877.877 0 001.444.85l7.898-7.112a.878.878 0 10-1.175-1.306l-7.898 7.112a.459.459 0 00-.01.01l4.834-8.375a.878.878 0 10-1.521-.878l-5.314 9.204z"
        fill="#B9B9B9"
      />
      <path
        d="M7 16.436c0-.21.17-.381.381-.381h17.385c.21 0 .381.17.381.38a7.625 7.625 0 01-7.625 7.626h-2.897A7.625 7.625 0 017 16.436z"
        fill="#fff"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgSoup);
export default ForwardRef;
