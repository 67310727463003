import { Loading } from '@css/op-icons';
import React, { HTMLAttributes, MouseEvent, PropsWithChildren, ReactNode } from 'react';
import styled, { css } from 'styled-components';

import { border, boxShadow, borderRadius, color } from '../../../theme';
import { SizeVariant } from '../../types';

export type ButtonVariant = 'primary' | 'secondary' | 'minimal';

type ButtonState = 'disabled' | undefined;

export interface ButtonProps {
  /**
   * Optional component id.
   */
  id?: string;

  /**
   * Optional component class name.
   */
  className?: string;

  /**
   * Inline styles.
   * Libraries like Popper inject positioning elements via style tags. Do not use styles to make stylistic updates.
   */
  style?: React.CSSProperties;

  /**
   * Optional data test id
   */
  dataTest?: string;

  /**
   * Optional aria-label. When rendering an icon-only button, useful for screen readers.
   */
  'aria-label'?: string;

  /**
   * Toggle for button width to be 100% or unset;
   */
  fullWidth?: boolean;

  /**
   * Optional button label. Replaces children if used.
   */
  label?: ReactNode;

  /**
   * Prefix react node. Primarily to show icons.
   */
  prefix?: ReactNode;

  /**
   * How large should the button be?
   * @default medium
   */
  size?: SizeVariant;

  /**
   * Some icon-only buttons should be squared off.
   */
  isSquare?: boolean;

  /**
   * Is the button in a loading state
   */
  loading?: boolean;

  /**
   * Is the button in a disabled state
   */
  disabled?: boolean;

  /**
   * Does the button represent a dangerous action
   */
  danger?: boolean;

  /**
   * Suffix react node. Primarily to show icons.
   */
  suffix?: ReactNode;

  /**
   * Optional flag to use type="submit" @default button
   */
  type?: 'button' | 'submit' | 'reset';

  /**
   * What background color to use
   */
  variant?: ButtonVariant;

  /**
   * Optional click handler
   */
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
  /**
   * Optional click handler
   */
  onMouseEnter?: (event: MouseEvent<HTMLButtonElement>) => void;
  /**
   * Optional click handler
   */
  onMouseLeave?: (event: MouseEvent<HTMLButtonElement>) => void;

  /**
   * Optional tab index attribute
   */
  tabIndex?: HTMLAttributes<HTMLButtonElement>['tabIndex'];

  /**
   * Optional target form of this button
   */
  form?: string;
}

const AffixWrapper = styled.div`
  display: flex;
  justify-content: center;
  min-width: 24px;
`;

const PrefixWrapper = styled(AffixWrapper)`
  margin-left: -${(props) => props.theme.space.$1};
  margin-right: ${(props) => props.theme.space.$1};
`;

const SuffixWrapper = styled(AffixWrapper)`
  margin-left: ${(props) => props.theme.space.$1};
  margin-right: -${(props) => props.theme.space.$1};
`;

const LoadingWrapper = styled(PrefixWrapper)``;

type StyledButtonProps = Pick<ButtonProps, 'size' | 'variant' | 'fullWidth' | 'danger' | 'isSquare'> & {
  state?: ButtonState;
};

export const StyledButton = styled.button<StyledButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  cursor: pointer;
  /* Default dimensions */
  height: 40px;
  width: ${(props) => (props.fullWidth ? '100%' : 'unset')};
  ${(props) =>
    props.isSquare
      ? css`
          width: 40px;
        `
      : css`
          padding: 0 24px;
        `}

  ${border('1px', 'transparentBlack05')}
  ${borderRadius('lg')}
  ${color('greyDark')}

  font-family: ${(props) => props.theme.fontFamily.sans};
  font-size: ${(props) => props.theme.fontSizes.paragraph};
  font-weight: ${(props) => props.theme.fontWeights.medium};

  /* Default hover, disabled, active states */
  &:hover:not(:active):not(:disabled),
  &:focus-visible {
    ${border('1px', 'transparentBlack10')}
    ${boxShadow('md')}
  }

  &:active {
    ${boxShadow('none')}
  }

  ${(props) =>
    props.state === 'disabled' &&
    css`
      cursor: not-allowed;
    `}

  /* Size variants */
  ${(props) =>
    props.size === 'small' &&
    css`
      font-size: ${(props) => props.theme.fontSizes.small};
      height: 32px;
      ${props.isSquare
        ? css`
            width: 32px;
          `
        : css`
            padding: 0 16px;
          `}
    `}


  ${(props) =>
    props.size === 'large' &&
    css`
      height: 48px;
      ${props.isSquare
        ? css`
            width: 48px;
          `
        : css`
            padding: 0 32px;
          `}
    `}

  /* Variants */
  ${(props) =>
    props.variant === 'primary' &&
    css`
      ${color('white')}
      ${color.bg('primary')}
      &:hover:not(:active):not(:disabled),
      &:focus-visible {
        ${color.bg('primaryDark')}
      }
      ${LoadingWrapper} {
        ${color.fill('white')}
      }
      ${props.state === 'disabled' &&
      css`
        ${color('grayLighter')}
        ${color.bg('grey')}
        ${border('1px', 'transparentBlack10')}
      `}
      ${props.danger &&
      css`
        ${border('1px', 'red')}
        ${color.bg('red')}
        &:hover:not(:active):not(:disabled),
        &:active:not(:disabled),
        &:focus-visible {
          ${color.bg('redDark')}
          ${border('1px', 'redDark')}
        }
      `}
    `}

  ${(props) =>
    props.variant === 'secondary' &&
    css`
      ${color.bg('secondary')}
      ${border()}
      &:hover:not(:active):not(:disabled),
      &:active:not(:disabled),
      &:focus-visible {
        ${color.bg('secondaryDark')}
        ${border('1px', 'greyLighter')}
      }

      ${LoadingWrapper} {
        ${color.fill('greenLighter')}
      }
      ${props.state === 'disabled' &&
      css`
        ${color('greyLight')}
        ${color.bg('ashLight')}
      `}
      ${props.danger &&
      css`
        ${border('1px', 'red')}
        ${color('red')}
        &:hover:not(:active):not(:disabled),
        &:active:not(:disabled),
        &:focus-visible {
          ${color('redDark')}
          ${border('1px', 'redDark')}
        }
      `}
    `}

  ${(props) =>
    props.variant === 'minimal' &&
    css`
      background: transparent;
      ${color('grey')}
      border: none;
      padding: 0 12px;
      height: unset;
      &:hover:not(:active):not(:disabled),
      &:focus-visible {
        border: none;
        ${boxShadow('none')}
        ${color('greyDark')}
      }
      &:active {
        ${color('primary')}
      }
      ${props.state === 'disabled' &&
      css`
        ${color('grey')}
      `}
      ${props.danger &&
      css`
        ${color('red')}
        &:hover:not(:active):not(:disabled),
        &:focus-visible,
        &:active {
          ${color('redDark')}
        }
      `}
    `}
`;

export const Button = React.forwardRef<HTMLButtonElement, PropsWithChildren<ButtonProps>>((props, ref) => {
  const {
    children,
    dataTest = 'op-button',
    fullWidth,
    label,
    prefix,
    size = 'medium',
    loading,
    disabled,
    danger,
    suffix,
    variant = 'primary',
    type = 'button',
    ...buttonProps
  } = props;

  const state: ButtonState = disabled ? 'disabled' : undefined;

  return (
    <StyledButton
      {...buttonProps}
      data-testid={dataTest}
      disabled={loading || disabled}
      danger={danger}
      fullWidth={fullWidth}
      ref={ref}
      state={state}
      size={size}
      variant={variant}
      type={type}
    >
      {loading && (
        <LoadingWrapper>
          <Loading />
        </LoadingWrapper>
      )}
      {prefix && <PrefixWrapper>{prefix}</PrefixWrapper>}
      {label ?? children}
      {suffix && <SuffixWrapper>{suffix}</SuffixWrapper>}
    </StyledButton>
  );
});
