import * as React from 'react';

function SvgEyeSlash(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 16 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M8 2.96a3.646 3.646 0 013.636 3.653c0 .372-.072.73-.174 1.067l2.225 2.235A8.623 8.623 0 0016 6.605c-1.258-3.2-4.364-5.472-8-5.472-.924 0-1.81.146-2.647.416L6.93 3.135A3.722 3.722 0 018 2.959zM1.76 0L.735 1.038l1.949 1.958A8.573 8.573 0 000 6.613c1.258 3.207 4.364 5.48 8 5.48 1.105 0 2.16-.22 3.134-.6L13.63 14l1.026-1.03L1.76 0zM8 10.266A3.646 3.646 0 014.72 5.05l1.142 1.147a2.52 2.52 0 00-.044.417c0 1.213.975 2.192 2.182 2.192.145 0 .276-.022.415-.051L9.556 9.9A3.486 3.486 0 018 10.266zm2.16-3.894a2.164 2.164 0 00-1.92-1.93l1.92 1.93z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgEyeSlash);
export default ForwardRef;
