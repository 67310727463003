import * as React from 'react';

function SvgAssign(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path d="M3.674 7h1.493a5.688 5.688 0 110 6H3.674a7 7 0 100-6z" fill="currentColor" />
      <path
        d="M10.062 9.37L8.5 7.9 9.384 7l3.116 3-3.116 3-.884-.901 1.573-1.48-7.072.006L3 9.375l7.062-.005z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgAssign);
export default ForwardRef;
