import { gql } from 'graphql-request';

export const queryStoreLink = gql`
  query StoreLinksForStore($storeId: ID!) {
    storeById(input: { storeId: $storeId, consistency: STRONG }) {
      id
      storeLinksV2(first: 1500) {
        edges {
          node {
            ...StoreLinkOverview
            __typename
          }
          __typename
        }
        __typename
      }
      __typename
    }
  }

  fragment StoreLinkOverview on StoreLink {
    id
    ofoSlug
    serviceConfiguration {
      slug
      displayConfiguration {
        ...IntegrationDisplayConfiguration
        __typename
      }
      authConfiguration {
        supportedAccountTypes {
          slug
          __typename
        }
        __typename
      }
      __typename
    }
    accountConnectionsV2 {
      edges {
        node {
          ...AccountConnectionOverview
          __typename
        }
        __typename
      }
      __typename
    }
    __typename
  }

  fragment IntegrationDisplayConfiguration on IntegrationDisplayConfiguration {
    largeIconUrl
    displayName {
      fallback
      __typename
    }
    mainColor {
      red
      green
      blue
      alpha
      __typename
    }
    __typename
  }

  fragment AccountConnectionOverview on AccountConnection {
    type
    connectionStatus
    serviceSlug
    isActive
    currentVersion
    account {
      ...AccountOverview
      __typename
    }
    settings {
      ...AccountConnectionSettings
      __typename
    }
    __typename
  }

  fragment AccountOverview on Account {
    id
    createdAt
    updatedAt
    accountType {
      slug
      displayConfiguration {
        displayName {
          fallback
          __typename
        }
        __typename
      }
      __typename
    }
    status {
      status
      createdAt
      lastUpdateEvent
      __typename
    }
    capabilityStatuses {
      ...AccountCapabilityStatus
      __typename
    }
    credentialsV2 {
      edges {
        node {
          ...SafeCredential
          __typename
        }
        __typename
      }
      __typename
    }
    owner {
      __typename
      ... on Store {
        id
        brand {
          id
          name
          __typename
        }
        facility {
          id
          name
          __typename
        }
        __typename
      }
      ... on StoreLink {
        storeLinkId: id
        store {
          id
          brand {
            id
            name
            __typename
          }
          facility {
            id
            name
            __typename
          }
          __typename
        }
        __typename
      }
      ... on Brand {
        id
        name
        __typename
      }
      ... on Organization {
        id
        name
        __typename
      }
    }
    parentAccount {
      id
      accountType {
        slug
        __typename
      }
      __typename
    }
    __typename
  }

  fragment AccountCapabilityStatus on AccountCapabilityStatusContainer {
    status
    capability {
      id
      displayName {
        key
        singular
        __typename
      }
      description {
        key
        singular
        __typename
      }
      __typename
    }
    __typename
  }

  fragment SafeCredential on AccountCredential {
    type
    key
    safeValue
    __typename
  }

  fragment AccountConnectionSettings on AccountConnectionSettings {
    __typename
    ... on OrderSourceConnectionSettings {
      prepTimeMinutes
      autoConfirm
      acceptsNonIntegratedOrders
      disablePrint
      disableKeepAlive
      __typename
    }
    ... on PosConnectionSettings {
      paymentTypeId
      diningOptionId
      pickupDiningOptionId
      restaurantDeliveryDiningOptionId
      orderTypeId
      orderInjectionEvent
      __typename
    }
    ... on FulfillmentConnectionSettings {
      autoDispatchCourier
      __typename
    }
    ... on MenuConnectionSettings {
      canServeAlcoholAlone
      cannotPublishAlcohol
      disableAutoLinking
      __typename
    }
    ... on StorefrontConnectionSettings {
      disableAutomaticReopening
      disableStorePauseOperations
      disableAvailabilityMonitoring
      fulfillmentType
      __typename
    }
    ... on TaxConnectionSettings {
      includeDeliveryFeeInTaxInvoices
      includeTipInTaxInvoices
      __typename
    }
  }
`;
