import { urqlClient } from '@/http-clients';
import { gql } from '@urql/core';
import { UseQueryOptions } from '@tanstack/react-query';
import { TRole } from '@/types';
import queryString from 'query-string';
import { jsbc } from '@/jsbc';
import { createLogger } from '@/logger';

const logger = createLogger('rqs');

export async function queryOrgId(): Promise<string> {
  const query = gql`
    query fetchOrgId {
      viewer {
        assignedRoles(first: 3) {
          edges {
            node {
              name
              organization {
                id
                name
              }
            }
          }
        }
      }
    }
  `;

  const { data } = await urqlClient.query(query, {});
  return getOrganizationId(data.viewer.assignedRoles.edges);
}

export function fetchOrgId(options?: UseQueryOptions) {
  return {
    queryKey: ['user', 'orgid'],
    queryFn: queryOrgId,
    staleTime: 60 * 1000,
    ...options,
  };
}

function getOrganizationId(roles: Array<TRole>): string {
  const licensee = roles.find((role) => role.node.name === 'Otter Licensee');
  const others = roles.find((role) => role.node.name === 'Otter Admin');

  return (licensee ?? others)?.node.organization.id ?? '';
}

export function fetchVasStoreId(options?: UseQueryOptions) {
  return {
    queryKey: ['use-vas-store-id'],
    queryFn: fetchVasStoreIdQueryFn,
    staleTime: 30 * 1000,
    ...options,
  };
}

export async function fetchVasStoreIdQueryFn() {
  const { storeId: storeIdFromSearch } = queryString.parse(location.search);
  if (storeIdFromSearch) {
    logger.debug({ result: storeIdFromSearch }, 'get vas store id from query string');
    return String(storeIdFromSearch);
  }
  const storeIdFromJSB = await getVasStoreId();

  if (storeIdFromJSB) {
    logger.debug('get vas store id from jsb %s', storeIdFromJSB);
    return storeIdFromJSB;
  }

  if (window.Android) {
    const vasStoreIdInAndroid = await window.Android.getVasStoreId();
    if (vasStoreIdInAndroid) {
      logger.debug('get vas store id android fallabck hit');
      return vasStoreIdInAndroid;
    }
  }

  throw new Error('did not get vas store id from jsb');
}

async function getVasStoreId() {
  const vasStoreId = await jsbc.getVasStoreId();
  if (!vasStoreId) {
    throw new Error('invalid vas store id');
  }
  return vasStoreId;
}
