import * as React from 'react';

function SvgLock(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 14 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 6.667h-.834V5C11.166 2.7 9.3.833 7 .833A4.168 4.168 0 002.833 5v1.667H2c-.917 0-1.667.75-1.667 1.666v8.334c0 .916.75 1.666 1.667 1.666h10c.916 0 1.666-.75 1.666-1.666V8.333c0-.916-.75-1.666-1.666-1.666zm-5 7.5c-.917 0-1.667-.75-1.667-1.667s.75-1.667 1.667-1.667c.916 0 1.666.75 1.666 1.667S7.916 14.167 7 14.167zM4.5 5v1.667h5V5c0-1.383-1.117-2.5-2.5-2.5A2.497 2.497 0 004.5 5z"
        fill="#B9B9B9"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgLock);
export default ForwardRef;
