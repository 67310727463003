import { fetchVasStoreId } from '@/hooks/use-vas-store-id';
import { urqlClient } from '@/http-clients';
import { gql } from 'graphql-tag';
import { createQuery } from 'react-query-kit';

export type OfoType = 'meituan' | 'meituan-admin' | 'eleme';

export const useStoreLinks = createQuery<
  Partial<Record<OfoType, boolean>>,
  { storeId?: string; consistency?: 'EVENTUAL' | 'STRONG' },
  unknown
>({
  primaryKey: 'use-store-links',
  queryFn: async function ({ queryKey: [, { storeId, consistency = 'EVENTUAL' }] }) {
    if (typeof storeId === 'undefined') {
      storeId = await fetchVasStoreId();
    }
    const ofoList = await fetchStoreLinks({ storeId, consistency });
    const result = {} as Record<OfoType, boolean>;
    for (const ofo of ofoList) {
      result[ofo.node.storeLinkConnectionStats.ofoSlug as OfoType] = ofo.node.storeLinkConnectionStats.isActive;
    }
    return result;
  },
});

/**
 * 用来获取 store link 的详情
 */
export const useStoreLinksConnectionStats = createQuery<
  Array<{
    node: {
      storeLinkConnectionStats: StoreLinkConnectionStats;
    };
  }>,
  { storeId?: string; consistency?: 'EVENTUAL' | 'STRONG' },
  unknown
>({
  primaryKey: 'use-store-links-connection-stats',
  queryFn: async function ({ queryKey: [, { storeId, consistency = 'EVENTUAL' }] }) {
    if (typeof storeId === 'undefined') {
      storeId = await fetchVasStoreId();
    }
    const ofoList = await fetchStoreLinks({ storeId, consistency });
    return ofoList;
  },
});

interface StoreLinkConnectionStats {
  ofoSlug: string;
  isActive: boolean;
  activeAccountTypeSlugs: Array<string>;
  featuresEnabled: Array<string>;
}

async function fetchStoreLinks({
  storeId,
  consistency = 'EVENTUAL',
}: {
  storeId: string;
  consistency: 'EVENTUAL' | 'STRONG';
}) {
  const queryForStoreLinks = gql`
    query storeById($storeId: ID!, $consistency: ConsistencyRequirement!) {
      storeById(input: { storeId: $storeId, consistency: $consistency }) {
        storeLinksV2(first: 100) {
          edges {
            node {
              id
              storeLinkConnectionStats {
                ofoSlug
                isActive
                activeAccountTypeSlugs
                featuresEnabled
              }
            }
          }
        }
      }
    }
  `;
  const res = await urqlClient
    .query<{
      storeById: {
        storeLinksV2: {
          edges: Array<{
            node: {
              storeLinkConnectionStats: StoreLinkConnectionStats;
            };
          }>;
        };
      };
    }>(queryForStoreLinks, { storeId, consistency })
    .toPromise();
  if (res.error) {
    throw new Error('fetch store link error: ' + res.error.message ?? 'unknown server error');
  }
  return res.data?.storeById?.storeLinksV2?.edges ?? [];
}
