import { vasbundleorder } from './vasbundleorder';
import { vasbundleprotection } from './vasbundleprotection';
import { advancedtemplatelist } from './advancedtemplatelist';

type RouteSpec = Record<string, string | (() => Promise<string>)>;

export async function routeExecutor(productName: keyof RouteSpec): Promise<string> {
  const routingTable: RouteSpec = {
    vasbundleorder,
    vasbundleprotection,
    advancedtemplatelist,
  };

  const nextHop = routingTable[productName];
  if (typeof nextHop === 'string') {
    return nextHop;
  }

  if (typeof nextHop === 'function') {
    return await nextHop();
  }

  return `/iap/${productName}`;
}
