import { RetryOptions } from '../api/types';
import { DebouncingFetchClient } from './clients/debouncingFetchClient';
import { DefaultFetchClient } from './clients/fetchClient';
import { MiddlewareFetchClient } from './clients/middlewareFetchClient';
import { AuthFetch, AuthFetchOpts } from './fetch-wrappers/authFetch';
import { createRetryingFetch } from './fetch-wrappers/retryingFetch';
import { AppConfig, createApplicationHeaderMiddleware } from './middleware/ApplicationMiddleware';
import { createBaseUrlMiddleware } from './middleware/BaseUrlMiddleware';
import { createTracingMiddleware } from './middleware/TracingMiddleware';
import { FetchClient } from './types';

export interface CreateDefaultFetchClientOpts {
  appConfig: AppConfig;
  baseUrl: string;
  retryOpts?: RetryOptions;
  authFetchOpts?: AuthFetchOpts;
}

/**
 * Creates a FetchClient with application info, auth, and retry-logic.
 */
export function createDefaultFetchClient(opts: CreateDefaultFetchClientOpts): FetchClient {
  const middlewares = [
    createApplicationHeaderMiddleware(opts),
    createTracingMiddleware(),
    createBaseUrlMiddleware(opts),
  ];

  const delegate = typeof window === 'undefined' || !window.fetch ? emptyFetch : window.fetch.bind(window);

  const fetchFn = createRetryingFetch({
    delegate: new AuthFetch(opts.authFetchOpts ? opts.authFetchOpts : { delegate }).fetch,
    retryOpts: opts.retryOpts,
  });

  return new MiddlewareFetchClient({
    middlewares,
    delegate: new DebouncingFetchClient({ delegate: new DefaultFetchClient({ fetchFn }) }),
  });
}

const emptyFetch: typeof window.fetch = () => {
  return Promise.reject('window.fetch is not defined');
};
