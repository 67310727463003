import * as React from 'react';

function SvgCullinanArrowExport(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 20 20"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      ref={svgRef}
      {...props}
    >
      <path d="M4 18h12v-2H4v2zM14.95 9.05L10 14 5.05 9.05l1.415-1.414L9 10.172V2h2v8.172l2.536-2.536L14.95 9.05z" />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgCullinanArrowExport);
export default ForwardRef;
