import * as React from 'react';

function SvgSearch(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 11a5 5 0 11-10 0 5 5 0 0110 0zm-1.077 5.798a7 7 0 111.814-1.786l3.798 3.732-1.82 1.78-3.792-3.726z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgSearch);
export default ForwardRef;
