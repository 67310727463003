import * as React from 'react';

function SvgMove(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M13.01 3.75V6l3-3-3-3v2.25H5.76A5.75 5.75 0 00.01 8v1h1.5V8a4.25 4.25 0 014.25-4.25h7.25zM16.01 16h-16v-1.5h16V16zM.01 12.5h16V11h-16v1.5z"
        fill="currentColor"
      />
      <path d="M16.01 9h-12V7.5h12V9z" fill="currentColor" />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgMove);
export default ForwardRef;
