import { css, DefaultTheme, FlattenInterpolation, ThemeProps } from 'styled-components';

import { OpThemeColor } from '../../../theme';
import { TextVariant, TextColorVariant } from '../../../theme/constants';

export const textColors: Record<TextColorVariant, OpThemeColor> = {
  primary: 'greyDark',
  secondary: 'grey',
  subtle: 'greyLighter',
  error: 'red',
  warning: 'yellow',
  success: 'green',
  inverse: 'white',
  subtleInverse: 'navyLighter',
};

/**
 * Returns a flatten interpolation for a text color variant
 * @param variant a text color variant
 */
export const textColor = (variant: TextColorVariant): FlattenInterpolation<ThemeProps<DefaultTheme>> => {
  return css`
    color: ${(props) => props.theme.colors[textColors[variant]]};
  `;
};

/**
 * Returns a flatten interpolation that results in ellipsis being applied o clip the text (https://developer.mozilla.org/en-US/docs/Web/CSS/text-overflow)
 */
export const ellipsis = (): FlattenInterpolation<ThemeProps<DefaultTheme>> => {
  return css`
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-wrap: normal;
  `;
};

/**
 * Returns a flatten interpolation for a size variant
 * @param variant a size variant
 */
export const fontSize = (variant: keyof DefaultTheme['fontSizes']): FlattenInterpolation<ThemeProps<DefaultTheme>> => {
  return css`
    font-size: ${(props) => props.theme.fontSizes[variant]};
  `;
};

/**
 * Returns a flatten interpolation for a weight variant
 * @param variant a weight variant
 */
export const fontWeight = (
  variant: keyof DefaultTheme['fontWeights']
): FlattenInterpolation<ThemeProps<DefaultTheme>> => {
  return css`
    font-weight: ${(props) => props.theme.fontWeights[variant]};
  `;
};

/**
 * Returns a flatten interpolation for a line height variant
 * @param variant a line height variant
 */
export const lineHeight = (
  variant: keyof DefaultTheme['lineHeights']
): FlattenInterpolation<ThemeProps<DefaultTheme>> => {
  return css`
    line-height: ${(props) => props.theme.lineHeights[variant]};
  `;
};

/**
 * Returns a flatten interpolation for a letter spacing variant
 * @param variant a line spacing variant
 */
export const letterSpacing = (
  variant: keyof DefaultTheme['letterSpacings']
): FlattenInterpolation<ThemeProps<DefaultTheme>> => {
  return css`
    letter-spacing: ${(props) => props.theme.letterSpacings[variant]};
  `;
};

export const textDecoration = (
  variant: keyof DefaultTheme['textDecorations']
): FlattenInterpolation<ThemeProps<DefaultTheme>> => {
  return css`
    text-decoration: ${(props) => props.theme.textDecorations[variant]};
  `;
};

interface TypographySetting {
  fontSize: keyof DefaultTheme['fontSizes'];
  fontWeight: keyof DefaultTheme['fontWeights'];
  lineHeight: keyof DefaultTheme['lineHeights'];
  letterSpacing?: keyof DefaultTheme['letterSpacings'];
  other?: FlattenInterpolation<ThemeProps<DefaultTheme>>;
}

export const typographySettings: Record<TextVariant, TypographySetting> = {
  megaSemiBold: {
    fontSize: 'mega',
    fontWeight: 'semiBold',
    lineHeight: 'none',
    letterSpacing: 'snug',
  },
  megaRegular: {
    fontSize: 'mega',
    fontWeight: 'normal',
    lineHeight: 'none',
    letterSpacing: 'snug',
  },
  displayLargeSemiBold: {
    fontSize: 'displayLarge',
    fontWeight: 'semiBold',
    lineHeight: 'none',
    letterSpacing: 'snug',
  },
  displayLargeMedium: {
    fontSize: 'displayLarge',
    fontWeight: 'medium',
    lineHeight: 'none',
    letterSpacing: 'snug',
  },
  displayLargeRegular: {
    fontSize: 'displayLarge',
    fontWeight: 'normal',
    lineHeight: 'none',
    letterSpacing: 'snug',
  },
  displaySemiBold: {
    fontSize: 'display',
    fontWeight: 'semiBold',
    lineHeight: 'none',
    letterSpacing: 'snug',
  },
  displayMedium: {
    fontSize: 'display',
    fontWeight: 'medium',
    lineHeight: 'none',
    letterSpacing: 'snug',
  },
  displayRegular: {
    fontSize: 'display',
    fontWeight: 'normal',
    lineHeight: 'none',
    letterSpacing: 'tight',
  },
  titleLargeSemiBold: {
    fontSize: 'titleLarge',
    fontWeight: 'semiBold',
    lineHeight: 'none',
  },
  titleLargeMedium: {
    fontSize: 'titleLarge',
    fontWeight: 'medium',
    lineHeight: 'none',
  },
  titleLargeRegular: {
    fontSize: 'titleLarge',
    fontWeight: 'normal',
    lineHeight: 'none',
  },
  titleSemiBold: {
    fontSize: 'title',
    fontWeight: 'semiBold',
    lineHeight: 'normal',
  },
  titleMedium: {
    fontSize: 'title',
    fontWeight: 'medium',
    lineHeight: 'normal',
  },
  titleRegular: {
    fontSize: 'title',
    fontWeight: 'normal',
    lineHeight: 'normal',
  },
  paragraphSemiBold: {
    fontSize: 'paragraph',
    fontWeight: 'semiBold',
    lineHeight: 'tight',
  },
  paragraphMedium: {
    fontSize: 'paragraph',
    fontWeight: 'medium',
    lineHeight: 'tight',
  },
  paragraphRegular: {
    fontSize: 'paragraph',
    fontWeight: 'normal',
    lineHeight: 'tight',
  },
  smallSemiBold: {
    fontSize: 'small',
    fontWeight: 'semiBold',
    lineHeight: 'tight',
  },
  smallMedium: {
    fontSize: 'small',
    fontWeight: 'medium',
    lineHeight: 'tight',
  },
  smallRegular: {
    fontSize: 'small',
    fontWeight: 'normal',
    lineHeight: 'tight',
  },
  microSemiBold: {
    fontSize: 'micro',
    fontWeight: 'semiBold',
    lineHeight: 'snug',
  },
  microMedium: {
    fontSize: 'micro',
    fontWeight: 'medium',
    lineHeight: 'snug',
  },
  microRegular: {
    fontSize: 'micro',
    fontWeight: 'normal',
    lineHeight: 'snug',
  },
  labelSemiBold: {
    fontSize: 'label',
    fontWeight: 'semiBold',
    lineHeight: 'relaxed',
    letterSpacing: 'wide',
    other: css`
      text-transform: uppercase;
    `,
  },
  macroRegular: {
    fontSize: 'label',
    fontWeight: 'normal',
    lineHeight: 'tight',
    other: css`
      text-transform: capitalize;
    `,
  },
  nanoSemiBold: {
    fontSize: 'label',
    fontWeight: 'semiBold',
    lineHeight: 'snug',
  },
};

/**
 * Returns a flatten interpolation for a typography variant
 * @param variant a text variant as defined by designers in the OP Design System (https://www.figma.com/file/QDorFoJrLM53TvfUZiOKcB/?node-id=1743%3A5611)
 */
export const typography = (variant: TextVariant): FlattenInterpolation<ThemeProps<DefaultTheme>> => {
  const settings = typographySettings[variant];
  return css`
    ${({ theme }) =>
      theme.refreshTypography[variant]
        ? `
        font-family: ${theme.refreshTypography[variant]!.fontFamily};
      font-size: ${theme.refreshTypography[variant]!.fontSize};
      line-height: ${theme.refreshTypography[variant]!.lineHeight};
      font-weight: ${theme.refreshTypography[variant]!.fontWeight};
      letter-spacing: ${theme.refreshTypography[variant]!.letterSpacing};
      font-feature-settings: ${theme.refreshTypography[variant]!.fontFeatureSettings};`
        : css`
            ${fontSize(settings.fontSize)}
            ${fontWeight(settings.fontWeight)}
    ${lineHeight(settings.lineHeight)}
    ${settings.letterSpacing ? letterSpacing(settings.letterSpacing) : ''}
    ${settings.other ? settings.other : ''}
          `}
  `;
};
