import * as React from 'react';

function SvgInvoiceError(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 81 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M65.5 64.25V10H13.835l-.016 52.95v1.35c0 3.15 2.55 5.7 5.85 5.7h51.667l-1.884-5.75H65.5z"
        fill="#DBDBDB"
      />
      <path d="M77.167 64.25H65.5c0 3.15 2.534 5.75 5.834 5.75s5.833-2.6 5.833-5.75z" fill="#1F2B3D" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.5 33.333h28.333v-1.666H25.5v1.666zm0 8.334h28.333V40H25.5v1.667zM53.833 50H25.5v-1.667h28.333V50zm-28.7 8.333h12.166v-1.666H25.133v1.666z"
        fill="#fff"
      />
      <path d="M65.5 10H13.835v8.333h51.667V10z" fill="#B5B5B5" />
      <path
        d="M15.5 70c6.364 0 11.667-5.289 11.667-11.667 0-6.377-5.151-11.666-11.666-11.666-6.515.155-11.667 5.288-11.667 11.822C3.834 64.867 8.986 70 15.501 70z"
        fill="#1F2B3D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.501 59.214l4.185 4.185.88-.881-4.184-4.185 4.183-4.183-.88-.881-4.184 4.184-4.185-4.185-.88.88 4.184 4.185-4.184 4.184.881.88 4.184-4.183z"
        fill="#fff"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgInvoiceError);
export default ForwardRef;
