import React from 'react';
import { FC } from 'react';

export const CouponTextIcon: FC<React.SVGProps<SVGSVGElement>> = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="38" height="20" fill="none" viewBox="0 0 38 20">
      <g clipPath="url(#clip0_1049_136722)">
        <rect width="38" height="20" fill="#E06E4D" rx="4"></rect>
        <path
          fill="#fff"
          d="M8.47 9.62v4.21h.83V8.4c.3-.51.54-1.06.72-1.62l-.76-.36a8.68 8.68 0 01-1.78 3.2l.26.82c.25-.27.5-.54.73-.82zM9.71 8.3v.8h1.52c-.17 1.86-.77 3.25-1.78 4.18l.62.54c1.12-1.05 1.78-2.63 1.97-4.72h.26v3.83c0 .51.24.77.74.77h1.02c.24-.01.42-.1.56-.24.1-.15.2-.66.28-1.52l-.75-.25a4.57 4.57 0 01-.11 1.13c-.06.08-.12.12-.18.12h-.6c-.1 0-.16-.05-.16-.16V9.1h1.58v-.8h-2.6l.02-.36V6.46h-.81v1.48l-.01.36H9.71zm3.4-1.76l-.53.5c.52.4.94.76 1.24 1.1l.56-.57a7.9 7.9 0 00-1.27-1.03zm2.53.34v.7h3.16v.3h-2.5v2.44h2.5v.32l-3 .04.1.72c2.75-.06 4.72-.14 5.9-.24.16.16.3.31.42.47l.61-.41c-.23-.26-.54-.56-.93-.9h.23V7.89h-2.5v-.3h3.16v-.71h-3.16v-.46h-.83v.46h-3.16zm5.58 3.7l-1.59.04v-.3h1.55l-.15.09.19.17zm.12-.88h-1.7v-.33h1.7v.33zm-2.54 0h-1.7v-.33h1.7v.33zm-1.7-.86V8.5h1.7v.34h-1.7zm2.53-.34h1.7v.34h-1.7V8.5zm-3.33 3.1c-.15.54-.4 1.04-.74 1.52l.69.44c.33-.51.6-1.11.79-1.8l-.74-.16zm4.08 2.1c.28 0 .5-.05.64-.16.14-.12.24-.48.3-1.1l-.75-.24c-.02.35-.06.57-.11.64a.27.27 0 01-.23.1H18.5c-.18 0-.26-.08-.26-.22v-1.14h-.81v1.32c0 .53.27.8.83.8h2.13zm-1.04-2.32l-.62.38c.38.35.68.68.9.98l.62-.44c-.2-.26-.51-.57-.9-.92zm2.4.04l-.64.4c.5.58.88 1.1 1.17 1.59l.65-.45c-.27-.45-.67-.96-1.18-1.54zm4.35-.03c-.14.39-.32.7-.55.95-.3.3-.76.58-1.36.84l.52.67c.68-.34 1.19-.7 1.54-1.12.26-.34.46-.79.62-1.34h1.75a4.46 4.46 0 01-.19 1.34c-.08.2-.27.3-.56.3-.35 0-.72-.01-1.12-.04l.21.79h1.31c.32-.02.56-.12.72-.3.14-.2.25-.52.32-.98.04-.4.08-.92.1-1.52.3.23.63.46 1.02.68l.54-.68c-.8-.35-1.4-.75-1.78-1.2h1.5v-.75h-3.82c.12-.2.24-.43.33-.65h3.06v-.74h-1.07c.23-.3.41-.63.57-1.02l-.7-.25c-.15.41-.35.77-.59 1.1l.32.17h-1.32c.12-.38.22-.77.3-1.18l-.84-.1c-.07.44-.18.87-.3 1.28h-.99l.4-.18a6.99 6.99 0 00-.55-1.06l-.72.24c.2.29.37.62.54 1h-1.12v.74h2.13c-.11.22-.24.44-.37.65h-2.2v.75h1.57c-.5.5-1.11.92-1.85 1.23l.47.7c.43-.2.83-.45 1.2-.73v.41h.96zm.27-1.6h1.95c.18.28.41.56.7.83h-3.43c.28-.25.54-.53.78-.84z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_1049_136722">
          <path fill="#fff" d="M0 0h38v20H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
};
