import * as React from 'react';

function SvgAppIconServices(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 4a4 4 0 114.5 3.969v1.824l3.047 3.047a4 4 0 11-.707.707L10 10.707l-2.84 2.84a4 4 0 11-.707-.707L9.5 9.793V7.969A4 4 0 016 4zm4 3a3 3 0 110-6 3 3 0 010 6zM4 19a3 3 0 110-6 3 3 0 010 6zm9-3a3 3 0 106 0 3 3 0 00-6 0z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgAppIconServices);
export default ForwardRef;
