import { BAAS_HOSTNAME } from '@/constant';
import { OfoAuthSetting } from './pages';
import { OfoType } from '@/hooks/use-ofo-status';
import queryString from 'qs';

export function buildOfoAuthCallbackUrl(bundleId: string) {
  const search = window.location.search;
  const params = new URLSearchParams(search);

  const ofoMapping: Record<string, OfoAuthSetting> = {
    ofoapi: {
      meituanAuthType: 'meituan',
      disableCancel: true,
      nextUrl: `${window.location.origin}/product/ofoapi`,
    },
    vasbundleorder: {
      meituanAuthType: 'meituan',
      disableCancel: true,
      nextUrl: `${window.location.origin}/bundle/vasbundleorder/setting`,
    },
    vasbundleoperate: {
      meituanAuthType: 'meituan-admin',
      disableCancel: true,
      nextUrl: `https://${BAAS_HOSTNAME}/mobile/vasbundleoperate?fromPurchase`,
    },
    ordermissing: {
      meituanAuthType: 'meituan',
      disableCancel: true,
      nextUrl: `otter://orderfeed?fetchResource=true`,
    },
    orderprep: {
      meituanAuthType: 'meituan',
      title: '%E4%B8%80%E9%94%AE%E5%87%BA%E9%A4%90',
      hide: 'meituan',
      nextUrl: `${window.location.origin}/am/preptime-config`,
    },
    vasbundleprotection: {
      meituanAuthType: 'meituan',
      title: '守护宝',
      nextUrl: `${window.location.origin}/bundle/vasbundleprotection`,
    },
  };

  params.set('nextUrl', encodeURIComponent(ofoMapping?.[bundleId]?.nextUrl ?? ''));
  params.set('meituanAuthType', ofoMapping?.[bundleId].meituanAuthType);
  params.set('productName', 'xcd');

  if (ofoMapping?.[bundleId].disableCancel) {
    params.set('disableCancel', 'yes');
  }

  const url = `https://${BAAS_HOSTNAME}/mobile/ofo-auth?${new URLSearchParams(params).toString()}`;
  return url;
}
export function spellOfoAuthUrl(ofos: Array<OfoType>): string {
  const params = {
    productName: 'xcd',
    meituanAuthType: ofos.includes('meituan-admin') ? 'meituan-admin' : 'meituan',
  };
  return `https://${BAAS_HOSTNAME}/mobile/ofo-auth?${queryString.stringify(params)}`;
}
