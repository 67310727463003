import { BottomSheet } from '@/common/components/bottom-sheet';
import { button } from '@/cva/button';
import { InlineIcon } from '@iconify/react';

import { PaymentMethodType } from '@/iap/types';
import { useEffect, useState } from 'react';

type PaymentMethodSpecMapping = Partial<
  Record<
    PaymentMethodType,
    {
      name: string;
      icon: string;
      color: `#${string}`;
    }
  >
>;

function getMeta(method: PaymentMethodType) {
  const mapping: PaymentMethodSpecMapping = {
    PAYMENT_METHOD_TYPE_ALIPAY_MINIAPP: {
      name: '支付宝',
      icon: 'ri:alipay-fill',
      color: '#027aff',
    },
    PAYMENT_METHOD_TYPE_ALI_PAY: {
      name: '支付宝',
      icon: 'ri:alipay-fill',
      color: '#027aff',
    },
    PAYMENT_METHOD_TYPE_WECHAT_PAY: {
      name: '微信',
      icon: 'ri:wechat-pay-fill',
      color: '#2ba245',
    },
  };

  return mapping[method] ?? null;
}

interface PaymentMethodBottomSheetProps {
  planName: string;
  isOpen: boolean;
  handleClose: () => void;
  handleSelect: (paymentMethod: PaymentMethodType) => void;
  paymentMethodTypes: Array<PaymentMethodType>;
}

export function PaymentMethodBottomSheet({
  isOpen,
  handleClose,
  handleSelect,
  paymentMethodTypes,
}: PaymentMethodBottomSheetProps) {
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<PaymentMethodType>(() => paymentMethodTypes[0]);

  useEffect(
    function () {
      if (paymentMethodTypes.length > 0 && typeof selectedPaymentMethod === 'undefined') {
        setSelectedPaymentMethod(paymentMethodTypes[0]);
      }
    },
    [paymentMethodTypes]
  );

  return (
    <BottomSheet isOpen={isOpen} handleClose={handleClose} title="请选择支付方式">
      <div role="list">
        {paymentMethodTypes.map((method) => {
          const { name, icon, color } = getMeta(method) ?? {};

          return (
            <label
              key={method}
              className="py-4 border-solid border-netrual-300 border-b-2 flex text-lg items-center last:border-b-0"
            >
              <input
                type="radio"
                name="provider"
                value={method}
                checked={method === selectedPaymentMethod}
                className="w-4 h-4 mx-2 text-primary"
                onChange={() => setSelectedPaymentMethod(method)}
              />
              {icon ? <InlineIcon width="24" height="24" icon={icon} color={color} /> : null}
              {name}
            </label>
          );
        })}
      </div>
      <button
        className={button({ intent: 'primary', size: 'full' })}
        onClick={() => handleSelect(selectedPaymentMethod)}
      >
        去支付
      </button>
    </BottomSheet>
  );
}
