import * as React from 'react';

function SvgPause(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M5.625 0h-3.75C.839 0 0 .84 0 1.875v26.25C0 29.16.84 30 1.875 30h3.75c1.036 0 1.875-.84 1.875-1.875V1.875C7.5.839 6.66 0 5.625 0zM17.625 0h-3.75C12.839 0 12 .84 12 1.875v26.25C12 29.16 12.84 30 13.875 30h3.75c1.035 0 1.875-.84 1.875-1.875V1.875C19.5.839 18.66 0 17.625 0z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgPause);
export default ForwardRef;
