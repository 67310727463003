import * as React from 'react';

function SvgExclamationTriangle(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path d="M10.64 8.564H9.36v3.5h1.28v-3.5zM9.36 12.873h1.28v1.28H9.36v-1.28z" fill="currentColor" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 3.5l8 13H2l8-13zM4.517 15.075h10.966L10 6.166l-5.483 8.91z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgExclamationTriangle);
export default ForwardRef;
