import * as React from 'react';

function SvgPhone(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M14 17c1.12 2.201 2.799 3.872 5 5l1.652-1.527c.21-.21.521-.28.794-.186a8.874 8.874 0 002.776.443.78.78 0 01.778.778v2.714a.78.78 0 01-.778.778C16.92 25 11 19.081 11 11.778a.78.78 0 01.778-.778H14.5a.78.78 0 01.778.778c0 .972.155 1.905.443 2.776a.78.78 0 01-.194.794L14 17z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgPhone);
export default ForwardRef;
