import * as React from 'react';

function SvgShipping(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 48"
      width="1em"
      height="1em"
      ref={svgRef}
      {...props}
    >
      <path d="M24 14.999H6.27v24H24v-24z" fill="#fff" />
      <path d="M19.73 20.887H2L6.27 15H24l-4.27 5.888zm22-5.887H24v23.999h17.73v-24z" fill="#B9B9B9" />
      <path d="M28.335 20.887H46L41.73 15H24l4.335 5.888z" fill="#fff" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.64 10.582c.582 0 1.1-.517 1.1-1.1 0-.582-.453-1.1-1.1-1.1-.582 0-1.1.518-1.1 1.1 0 .583.518 1.1 1.1 1.1zm4.36 0c.582 0 1.1-.517 1.1-1.1 0-.582-.453-1.1-1.1-1.1s-1.1.518-1.1 1.1c0 .583.518 1.1 1.1 1.1zm5.46-1.1c0 .583-.518 1.1-1.1 1.1a1.09 1.09 0 01-1.1-1.1c0-.582.518-1.1 1.1-1.1.582 0 1.1.518 1.1 1.1z"
        fill="#1F2B3D"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgShipping);
export default ForwardRef;
