import { cmpVersions, getPlatform } from './cmpVersions';

const ANDROID_SUPPORT_COUPON_VERSION = '3.4.9';
const IOS_SUPPORT_COUPON_VERSION = '3.4.4';

export const isAppVersionAllowCouponFeature = (): boolean => {
  try {
    const ua = window.navigator.userAgent;
    const platform = getPlatform();
    const isIOS = platform === 'IOS';
    const isAndroid = platform === 'ANDROID';
    if (!isAndroid && !isIOS) {
      // for testing in web page.
      return true;
    }
    const matched =
      ua
        .match(/\bOtterApp.*/)?.[0]
        ?.replace(/\s/g, '')
        ?.split('/') ?? [];
    const version = matched.length >= 3 ? matched[2] : '0.0.0';
    if (isAndroid) {
      return cmpVersions(version, ANDROID_SUPPORT_COUPON_VERSION) >= 0;
    } else if (isIOS) {
      return cmpVersions(version, IOS_SUPPORT_COUPON_VERSION) >= 0;
    }
    return false;
  } catch (e) {
    console.warn(e);
    return false;
  }
};
