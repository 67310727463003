import LoadingOverlay from '@/common/components/LoadingOverlay/LoadingOverlay';
import { usePageName } from '@/components/page';
import { trackEvent } from '@/mixpanel';
import { getTrackingName } from '@/product-registry';
import { useIsCssFacility } from '@/rqs/facility';
import { useHistory, useLocation, useParams } from 'react-router';
import { usePurchaseResult } from '../hooks/payment-record';

export function PaymentPending() {
  const { recordId } = useParams<{ recordId: string }>();

  useIsCssFacility();

  const {
    state = {
      planName: '',
      productName: '',
      couponName: 'n/a',
      purchase_origin: 'n/a',
    },
  } = useLocation<{ productName: string; planName: string; couponName: string; purchase_origin: string }>();

  const history = useHistory();

  const source = usePageName();
  usePurchaseResult({
    variables: {
      recordId,
    },

    onSuccess: function (data) {
      if (data.result === 'success') {
        trackEvent('payment_order_fulfilled', {
          product_name: getTrackingName(state.productName),
          coupon_name: state.couponName,
          plan_name: state.planName,
          result: 'success',
          source,
          purchase_origin: state.purchase_origin,
        });

        history.replace({
          ...location,
          pathname: '/payment/success',
          state: {
            planName: state.planName,
            productName: state.productName,
          },
        });
      } else if (data.result === 'failed') {
        history.replace({
          ...location,
          pathname: '/payment/failure',
          state: {
            planName: state.planName,
            productName: state.productName,
            couponName: state.couponName,
            purchase_origin: state.purchase_origin,
          },
        });
      }
    },
  });

  return (
    <div className="flex flex-col justify-center items-center h-screen">
      <LoadingOverlay />
    </div>
  );
}
