import * as React from 'react';

function SvgScheduleSend(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M7.25 4.5a2.5 2.5 0 000 5 2.5 2.5 0 000-5zm.825 3.675L7 7.1V5.5h.5v1.395l.925.925-.35.355z"
        fill="currentColor"
      />
      <path
        d="M4.25 4.5l-3-.75V2.005l4.435 1.87c.47-.235 1-.375 1.565-.375.05 0 .095.005.14.005L.25.5v8l3.5-1.475V7c0-.4.07-.78.195-1.14L1.25 6.995V5.25l3-.75z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgScheduleSend);
export default ForwardRef;
