import * as React from 'react';

function SvgReorder(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="1em"
      viewBox="0 0 24 24"
      width="1em"
      fill="currentColor"
      ref={svgRef}
      {...props}
    >
      <path d="M0 0h24v24H0V0z" fill="none" />
      <path d="M3 15h18v-2H3v2zm0 4h18v-2H3v2zm0-8h18V9H3v2zm0-6v2h18V5H3z" />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgReorder);
export default ForwardRef;
