import { FC, useRef } from 'react';

export const VideoContainer: FC<{ videoLink: string }> = ({ videoLink }) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  // Start tracking once video is played 10 seconds
  const checkpointOfVideoWatchingInSeconds = useRef<number>(10);

  return (
    <video
      ref={videoRef}
      src={videoLink}
      style={{
        height: 'calc(100vh - 243px)',
        width: '100%',
        objectFit: 'cover',
        borderRadius: '16px',
        paddingBottom: '16px',
      }}
      controls
      // Note(chujun): see if mixpanel tracking is needed in the future
      // onPlay={() => trackXcdAction('start playing video')}
      // onEnded={() => trackXcdAction('finish watching video')}
      onTimeUpdate={() => {
        if ((videoRef.current?.currentTime ?? 0) > checkpointOfVideoWatchingInSeconds.current) {
          // trackXcdAction(`watching video over ${checkpointOfVideoWatchingInSeconds.current}s`);
          checkpointOfVideoWatchingInSeconds.current = checkpointOfVideoWatchingInSeconds.current + 10;
        }
      }}
    />
  );
};
