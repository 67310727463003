import { createQuery } from 'react-query-kit';
import { PrintQueueResponse } from './types';
import { spawnHttpClient } from '@/http-clients';
import { useFacilityId } from '@/hooks/use-facility-id';
import { queryClient } from '@/query-client';

function fetchPrintQueue({ facilityId }: { facilityId: string }): Promise<PrintQueueResponse> {
  return spawnHttpClient().get<PrintQueueResponse>({
    path: `/print/queues/facility/${facilityId}`,
    method: 'GET',
    requestParams: {},
  });
}

export const usePrintQueues = createQuery({
  primaryKey: 'printQueue',
  queryFn: async function () {
    const facilityId = await queryClient.fetchQuery({
      queryKey: useFacilityId.getKey(),
      queryFn: useFacilityId.queryFn,
    });
    const printQueues = await fetchPrintQueue({ facilityId });
    return printQueues;
  },
});
