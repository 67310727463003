import * as React from 'react';

function SvgLinkExternal(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M15.293 2H10.5a.5.5 0 010-1h6a.5.5 0 01.5.5v6a.5.5 0 01-1 0V2.707l-9.146 9.147a.5.5 0 01-.708-.708L15.293 2zM13 16V8.5a.5.5 0 011 0v8a.5.5 0 01-.5.5h-12a.5.5 0 01-.5-.5v-12a.5.5 0 01.5-.5h8a.5.5 0 010 1H2v11h11z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth={0.5}
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgLinkExternal);
export default ForwardRef;
