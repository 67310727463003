import { useBundle } from '@/bundle/components/use-bundle';
import { AgreementConfirm } from '@/components/agreement-confirm';
import { usePageName } from '@/components/page';
import { AUTO_AGREE } from '@/constant';
import { button } from '@/cva/button';
import { PaymentError } from '@/errors';
import { useCoupons } from '@/features/coupon/hooks/use-redeemable-coupons';
import useSearchParams from '@/hooks/useSearchParams';
import { urqlClient } from '@/http-clients';
import { useTrack } from '@/mixpanel';
import { getTrackingName } from '@/product-registry';
import { fetchOrgId, fetchVasStoreId } from '@/rqs';
import { capturePaymentException } from '@/sentry/capture';
import { useAgreement } from '@/store/plan-slice';
import { ManagedTrackingEvent } from '@/tracking/events';
import { useMutation, useQueries } from '@tanstack/react-query';
import { gql } from '@urql/core';
import { useEffect, useState, useMemo } from 'react';
import { toast } from 'react-hot-toast';
import { createQuery } from 'react-query-kit';

async function exchangeSignContractPayload({
  planName,
  orgId,
  storeId,
  couponCode,
  source,
}: {
  planName: string;
  orgId: string;
  storeId: string;
  couponCode?: string;
  source?: string;
}) {
  const mutation = gql`
    mutation SignContract($source: String, $planName: String!, $orgId: ID!, $storeId: ID!, $couponCode: String) {
      signContract(
        input: {
          planName: $planName
          paymentMethod: PAYMENT_METHOD_TYPE_ALI_PAY
          payerOrganizationId: $orgId
          planAssociatedEntity: { entityId: $storeId, entityType: STORE }
          couponCode: $couponCode
          source: $source
        }
      ) {
        contractId
        providerPayload
      }
    }
  `;

  const result = await urqlClient
    .mutation<{
      signContract: {
        contractId: string;
        providerPayload: string;
      };
    }>(mutation, {
      planName,
      orgId,
      storeId,
      couponCode,
      source,
    })
    .toPromise();

  if (result.error || typeof result.data?.signContract === 'undefined') {
    throw new PaymentError('签约 mutation api call 失败', result.error, {
      planName,
      couponCode,
      source,
    });
  }

  return result.data.signContract;
}

interface SignContractResult {
  queryContract: {
    status: string;
    success: boolean;
    errorMessage: string;
  };
}

async function fetchSignContractResult(contractId: string) {
  const query = gql`
    query SignContractResult($contractId: String!) {
      queryContract(input: { contractId: $contractId }) {
        status
        success
        errorMessage
      }
    }
  `;
  const res = await urqlClient
    .query<SignContractResult>(
      query,
      { contractId },
      {
        requestPolicy: 'network-only',
      }
    )
    .toPromise();

  return res.data;
}

const useContractResult = createQuery<SignContractResult | undefined, { contractId: string }, Error>({
  primaryKey: 'use-contract-result',
  queryFn: async function ({ queryKey: [, { contractId }] }) {
    return await fetchSignContractResult(contractId);
  },
});

export function RenewButton({
  productName,
  pageName,
  planName,
  children = '去订阅',
  trackingContext = {},
  couponCodeFromParent = '',
  clickMetricEvent,
  createdMetricEvent,
  fulfilledMetricEvent,
}: {
  productName: string;
  pageName?: string;
  planName?: string;
  children?: React.ReactNode;
  trackingContext?: Record<string, string | number>;
  couponCodeFromParent?: string;
  clickMetricEvent?: ManagedTrackingEvent;
  createdMetricEvent?: ManagedTrackingEvent;
  fulfilledMetricEvent?: ManagedTrackingEvent;
}) {
  const agreed = useAgreement((state) => state.agreed);

  const [contractId, setContractId] = useState<string | undefined>(() => undefined);

  const trackEvent = useTrack(trackingContext);

  const { couponId: couponCode, purchase_origin } = useSearchParams();
  const couponQuery = useCoupons('REDEEMABLE');
  const { data: coupons } = couponQuery;
  const couponName = coupons?.find((coupon) => coupon.couponInfo.code === couponCode)?.couponInfo.title;
  const { data: bundle } = useBundle();

  const resultQuery = useContractResult({
    variables: {
      contractId: contractId as string,
    },
    enabled: Boolean(contractId),
    staleTime: 0,
    cacheTime: 0,
    refetchInterval: function (res, query) {
      const signStatus = res?.queryContract?.status;
      const isRequestSuccess = res?.queryContract?.success;

      if (isRequestSuccess && signStatus === 'SIGNED') {
        return false;
      }

      if (typeof signStatus === 'undefined') {
        return 2000;
      }

      if (isRequestSuccess && ['UNSIGNED'].includes(signStatus)) {
        toast.error('签约失败', {
          id: 'polling-sign-contract-result',
        });

        capturePaymentException(
          new PaymentError('轮询签约结果显示签约失败', undefined, {
            planName,
            couponCode,
            paymentMethod: 'PAYMENT_METHOD_TYPE_ALI_PAY',
          })
        );

        return false;
      }

      if (query.state.dataUpdateCount + query.state.errorUpdateCount >= 50) {
        toast.error('请求超时', {
          id: 'polling-sign-contract-result',
          duration: 2000,
        });
        return false;
      }

      if (signStatus === 'IN_PROCESS') {
        return 2000;
      }

      return 1000;
    },
    refetchOnWindowFocus: true,
    refetchIntervalInBackground: true,
    retry: 1,
  });

  useEffect(
    function () {
      if (resultQuery.data) {
        if (resultQuery.data.queryContract?.status === 'SIGNED') {
          if (productName === 'xcd') {
            // To fix xcd iap callback but not want to affect xcd renew. update logic later.
            window.location.pathname = `/iap/xcdRenew/callback`;
          } else {
            window.location.pathname = `/iap/${productName}/callback`;
          }
          if (fulfilledMetricEvent) {
            trackEvent(fulfilledMetricEvent.name, fulfilledMetricEvent.payload);
          } else {
            trackEvent('payment_order_fulfilled', {
              product_name: getTrackingName(productName),
              coupon_name: couponName ?? 'n/a',
              plan_name: planName,
              result: 'success',
              variant: 'SIGN_CONTRACT',
              source,
              purchase_origin,
            });
          }
        }
      }
    },
    [resultQuery.data]
  );

  const queries = [fetchOrgId(), fetchVasStoreId()];
  const result = useQueries({ queries });

  const setAgreed = useAgreement((state) => state.setAgreed);

  const { mutate, isLoading: loadingSignContractMutation } = useMutation(exchangeSignContractPayload, {
    onSuccess(data) {
      setContractId(data.contractId);
      window.location.href = data.providerPayload;

      if (createdMetricEvent) {
        trackEvent(createdMetricEvent.name, createdMetricEvent.payload);
      } else {
        trackEvent('renew_page_sign_contract_success', {
          page_name: pageName ?? 'RENEW_PAGE',
          product_name: getTrackingName(productName),
        });
      }
    },
    onError(error, variables) {
      capturePaymentException(new PaymentError('签约失败', error, variables));
      toast.error('签约失败');
    },
  });
  const source = usePageName();

  function handleClick(skipAgreementCheck = false) {
    if (!agreed && !skipAgreementCheck) {
      if (AUTO_AGREE) {
        setAgreed(true);
      } else {
        toast.custom(
          (t) => (
            <AgreementConfirm
              toastId={t.id}
              handleClick={(agreed) => {
                setAgreed(agreed);
                if (agreed) {
                  handleClick(true);
                }
              }}
            />
          ),
          {
            duration: Infinity,
          }
        );
        return void 0;
      }
    }

    mutate({
      planName: planName ?? bundle?.response.vasBundle.bundle.name ?? '',
      orgId: result[0].data as string,
      storeId: result[1].data as string,
      couponCode: couponCode || couponCodeFromParent,
      source,
    });
  }

  /* 
   disable renew button when:
   1) sign contract result is in-process OR signed
   2) mutation loading
   3) signContract mutation completes while querySignContractResult doesn't start
   */
  const disableButton = useMemo(
    () =>
      resultQuery.data?.queryContract.status === 'IN_PROCESS' ||
      loadingSignContractMutation ||
      (!!contractId && !resultQuery.data) ||
      resultQuery.data?.queryContract?.status === 'SIGNED',
    [contractId, resultQuery.data?.queryContract.status, loadingSignContractMutation]
  );

  return (
    <button
      data-testid="launch-btn"
      className={button({
        intent: 'primary',
        size: 'full',
        disabled: disableButton,
      })}
      disabled={disableButton}
      onClick={() => {
        if (clickMetricEvent) {
          trackEvent(clickMetricEvent.name, clickMetricEvent.payload);
        } else {
          trackEvent('renew_page_sign_contract_clicked', {
            page_name: pageName ?? 'RENEW_PAGE',
            product_name: getTrackingName(productName),
          });
        }

        trackEvent('payment_plan_agreed_and_pay', {
          plan_name: planName,
          product_name: getTrackingName(productName),
          variant: 'SIGN_CONTRACT',
          source,
          storeId: result[1].data as string,
          coupon_name: couponName ?? 'n/a',
          purchase_origin,
        });
        handleClick();
      }}
    >
      {children}
    </button>
  );
}
