import React, { FC } from 'react';

const ElemeIcon: FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect width="40" height="40" rx="20" fill="#009BE2" />
      <path
        d="M29.8105 20.0246C29.7397 19.9143 29.6418 19.8241 29.5263 19.7622C29.4108 19.7004 29.2813 19.6691 29.1503 19.6713C29.0008 19.6709 28.8545 19.7148 28.7298 19.7975L27.0478 20.8908C26.8791 21.0039 26.7614 21.1786 26.7198 21.3774C26.6781 21.5761 26.7159 21.7833 26.825 21.9547L27.9056 23.6367C27.9752 23.744 28.0705 23.8321 28.1829 23.8931C28.2953 23.9541 28.4212 23.9859 28.549 23.9857C28.6987 23.9869 28.8452 23.9429 28.9695 23.8596L29.8105 23.3171C30.2018 23.0586 30.4755 22.6564 30.5723 22.1975C30.6692 21.7386 30.5813 21.2601 30.3277 20.8656L29.8105 20.0246Z"
        fill="white"
      />
      <path
        d="M28.7771 14.2216C27.6487 12.4595 26.0201 11.0741 24.0998 10.243C22.1795 9.41183 20.0549 9.17265 17.9978 9.55606C15.9408 9.93946 14.045 10.928 12.553 12.3952C11.0611 13.8623 10.041 15.7413 9.62317 17.7917C9.3312 19.1545 9.31053 20.5616 9.56234 21.9324C9.81414 23.3033 10.3335 24.6111 11.0907 25.7812C12.5822 28.1177 14.9387 29.7681 17.6443 30.371C20.3499 30.9739 23.1842 30.4803 25.5266 28.9981L25.6822 28.9098L25.7873 28.8341C25.9724 28.7079 26.1616 28.5692 26.3466 28.4136C26.3689 28.4019 26.3888 28.3862 26.4055 28.3673C26.5234 28.2796 26.6092 28.1554 26.6495 28.014C26.6898 27.8727 26.6823 27.7219 26.6283 27.5852V27.5137L26.1237 26.782L26.027 26.6475C25.8844 26.4729 25.7048 26.3322 25.5012 26.2354C25.2976 26.1387 25.075 26.0884 24.8496 26.0882C24.5778 26.0897 24.3111 26.1622 24.0759 26.2985L24.0422 26.3195H24.017L23.9792 26.3489C23.1537 26.8821 22.231 27.2471 21.2641 27.4228C20.2972 27.5986 19.3051 27.5816 18.3448 27.373C17.3844 27.1644 16.4748 26.7681 15.6679 26.2071C14.8611 25.646 14.173 24.9311 13.6432 24.1034C12.5656 22.4235 12.1931 20.3867 12.6062 18.4341C13.0194 16.4815 14.1851 14.7702 15.8508 13.6707C17.2149 12.776 18.8351 12.354 20.4623 12.4694C22.0896 12.5849 23.6339 13.2315 24.858 14.3099C24.9455 14.3899 25.0008 14.499 25.0136 14.6169C25.0156 14.6893 24.9989 14.7611 24.965 14.8252C24.9311 14.8893 24.8812 14.9436 24.8202 14.9827L18.3528 19.2088C18.0131 19.4321 17.7756 19.7808 17.6921 20.1786C17.6086 20.5765 17.6859 20.9912 17.9071 21.3323L18.3696 22.0514C18.4174 22.1257 18.4794 22.1898 18.5521 22.24C18.6248 22.2903 18.7067 22.3256 18.7931 22.344C18.8795 22.3623 18.9687 22.3634 19.0555 22.3471C19.1424 22.3308 19.2251 22.2975 19.299 22.249L28.8276 16.0171C28.9491 15.9256 29.0453 15.8046 29.1071 15.6655C29.1689 15.5265 29.1943 15.374 29.1808 15.2224C29.1406 14.8581 29.0009 14.5118 28.7771 14.2216Z"
        fill="white"
      />
    </svg>
  );
};

export default ElemeIcon;
