import { useOrgId } from '@/hooks/use-org-id';
import { fetchVasStoreId } from '@/hooks/use-vas-store-id';
import { urqlClient } from '@/http-clients';
import { PaymentMethodType } from '@/iap/types';
import { queryClient } from '@/query-client';
import { createMutation } from 'react-query-kit';

import { jsbc } from '@/jsbc';
import gql from 'graphql-tag';
import { queryAmount } from './use-amount';
import { createLogger } from '@/logger';
import { PaymentError } from '@/errors';

const logger = createLogger('use-pay');

const createOrderMutation = gql`
  mutation CreatePaymentRecord(
    $planName: String!
    $entityId: ID!
    $paymentMethod: PaymentMethodType!
    $payerOrganizationId: ID!
    $couponCode: String
    $memo: String
    $source: String
  ) {
    payForPlan(
      input: {
        planName: $planName
        planAssociatedEntity: { entityId: $entityId, entityType: STORE }
        paymentProviderAccountType: "unionpay"
        paymentMethod: $paymentMethod
        payerOrganizationId: $payerOrganizationId
        couponCode: $couponCode
        memo: $memo
        source: $source
      }
    ) {
      recurringPayment
      createPaymentResponse {
        paymentRecordId
        providerPayload
      }
    }
  }
`;

export interface ProductPair {
  planName: string;
  couponCode?: string;
}

interface UsePayParams extends ProductPair {
  paymentMethod: PaymentMethodType;
  source: string;
  memo?: string;
}

async function resolvePaymentMethod({
  planName,
  couponCode,
  paymentMethod,
}: Omit<UsePayParams, 'source' | 'memo'>): Promise<PaymentMethodType> {
  if (typeof couponCode === 'undefined') {
    return paymentMethod;
  }

  try {
    const amount = await queryAmount({ planName, couponCode });

    if (amount === 0) {
      return 'PAYMENT_METHOD_TYPE_FREE_PASS';
    }

    return paymentMethod;
  } catch (e) {
    logger.warn({ paymentMethod, planName, couponCode }, 'query amount failed, reusing payment method from input.');
    return paymentMethod;
  }
}

export const usePay = createMutation({
  async mutationFn({ planName, couponCode = '', paymentMethod, memo, source }: UsePayParams) {
    if (!planName) {
      throw new Error('planName is required');
    }
    const orgId = await queryClient.fetchQuery({ queryKey: useOrgId.getKey(), queryFn: useOrgId.queryFn });
    const storeId = await fetchVasStoreId();

    if (paymentMethod !== 'PAYMENT_METHOD_TYPE_FREE_PASS') {
      paymentMethod = await resolvePaymentMethod({ planName, couponCode, paymentMethod });
    }

    // TODO: params validation for payForPlan mutation
    const createOrderPayload = {
      memo,
      source,
      planName,
      couponCode,
      paymentMethod,
      payerOrganizationId: orgId,
      entityId: storeId,
    };

    const res = await urqlClient
      .mutation<
        {
          payForPlan: {
            recurringPayment: boolean;
            createPaymentResponse: {
              paymentRecordId: string;
              providerPayload: string;
            };
          };
        },
        UsePayParams & {
          payerOrganizationId: string;
          entityId: string;
        }
      >(createOrderMutation, createOrderPayload)
      .toPromise();

    if (typeof res.data?.payForPlan === 'undefined') {
      throw new PaymentError('创建订单 api 返回失败', res.error, createOrderPayload);
    }

    const isRecurring = res.data.payForPlan.recurringPayment;
    const payload = res.data.payForPlan.createPaymentResponse.providerPayload;
    const paymentRecordId = res.data.payForPlan.createPaymentResponse.paymentRecordId;

    void queryClient.invalidateQueries(['coupons']);
    jsbc.launchPayment(paymentMethod, payload, isRecurring, paymentRecordId);

    return paymentRecordId;
  },
});
