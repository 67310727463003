import * as React from 'react';

function SvgForkKnife(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 114 126"
      fill="#fff"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path d="M82.333 25.2v50.4h19V126H114V0C96.52 0 82.333 14.112 82.333 25.2zM50.667 44.1H38V0H25.333v44.1H12.667V0H0v44.1c0 13.923 11.337 25.2 25.333 25.2V126H38V69.3c13.997 0 25.333-11.277 25.333-25.2V0H50.667v44.1z" />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgForkKnife);
export default ForwardRef;
