import * as React from 'react';

function SvgBuild(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 22 22"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path d="M11.09 1.91C9.08-.1 6.07-.51 3.65.67l4.34 4.34-3 3L.65 3.67C-.52 6.1-.11 9.09 1.9 11.1a6.507 6.507 0 006.89 1.48l9.11 9.11c.39.39 1.02.39 1.41 0l2.3-2.3a.996.996 0 000-1.41L12.54 8.9c.92-2.34.44-5.1-1.45-6.99z" />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgBuild);
export default ForwardRef;
