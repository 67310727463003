import { TransitionStatus } from 'react-transition-group';
import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 32px;
`;
export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 16px;
`;
export const Title = styled.div`
  font-size: 20px;
  font-weight: 500;
  color: #000;

  sup {
    color: #da252f;
  }
`;
export const Hint = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  color: #525252;
`;
export const Input = styled.input<{ hasError: boolean }>`
  appearance: none;
  height: 56px;
  padding: 0 40px 0 12px;
  border-radius: 8px;
  border: 1px solid ${(props) => (props.hasError ? 'rgba(218, 37, 47, 0.9)' : 'rgba(0, 0, 0, 0.16)')};
  box-sizing: border-box;
  width: 100%;
  font-size: 16px;
`;
export const ErrorMessage = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 500;
  color: #c4232b;
  margin-top: 8px;
`;
export const Modal = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
`;
export const ModalOverlay = styled.div`
  position: absolute;
  background: rgba(0, 0, 0, 0.5);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;
export const ModalContent = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  background: #fff;
  z-index: 2;
  overflow: hidden;
  width: 100%;
`;
export const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: radial-gradient(100% 434.03% at 0% 100%, #e45734 0%, #ff7857 100%);
  height: 180px;

  img {
    width: 158px;
  }
`;
export const ModalBody = styled.div`
  padding: 20px;
  padding-bottom: calc(20px + env(safe-area-inset-bottom));
  font-weight: 400;
  font-size: 16px;
`;
export const ModalTitle = styled.div`
  font-size: 28px;
  font-weight: 500;
  margin-bottom: 12px;
`;
export const CloseButton = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 20px;
  right: 20px;
  height: 36px;
  width: 36px;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  cursor: pointer;
`;
export const Loader = styled.div`
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
`;
export const duration = 450;
export const loadingDefaultStyle = {
  transition: `transform ${duration}ms cubic-bezier(.36,.66,.04,1)`,
  transform: 'translateY(100%)',
};
export const loadingTransitionStyles: Record<TransitionStatus, any> = {
  entering: { transform: 'translateY(0)' },
  entered: { transform: 'translateY(0)' },
  exiting: { transform: 'translateY(100%)' },
  exited: { transform: 'translateY(100%)' },
  unmounted: { transform: 'translateY(100%)' },
};
export const overlayDefaultStyle = {
  transition: `opacity ${duration}ms ease`,
  opacity: 0,
};
export const overlayTransitionStyles: Record<TransitionStatus, any> = {
  entering: { opacity: 1 },
  entered: { opacity: 1 },
  exiting: { opacity: 0 },
  exited: { opacity: 0 },
  unmounted: { opacity: 0 },
};
