import * as React from 'react';

function SvgCullinanRepeat(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 20 20"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      ref={svgRef}
      {...props}
    >
      <path d="M6.12 9a4 4 0 015.603-1.727l-1 1.733 4.964-.598-1.964-4.598-1 1.732a6 6 0 00-8.339 2.456L6.12 9zm8.058 2a4 4 0 01-5.603 1.727l1-1.732-4.965.598 1.964 4.598 1-1.732a6 6 0 008.339-2.457L14.178 11z" />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgCullinanRepeat);
export default ForwardRef;
