import * as React from 'react';

function SvgCullinanEdit(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      width="1em"
      height="1em"
      ref={svgRef}
      {...props}
    >
      <path
        d="M17.707 3.707l-1.5 1.5-1.414-1.414 1.5-1.5 1.414 1.414zM3 3h8v2H5v10h10V9h2v8H3V3z"
        fill="currentColor"
      />
      <path d="M8.707 12.707l6.5-6.5-1.414-1.414-6.5 6.5 1.414 1.414z" fill="currentColor" />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgCullinanEdit);
export default ForwardRef;
