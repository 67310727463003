import React from 'react';
import { Icon } from '@iconify/react';
import { useHistory, useLocation } from 'react-router';
import { useIsCssFacility } from '@/rqs/facility';
import { trackEvent } from '@/mixpanel';
import { getTrackingName } from '@/product-registry';
import { usePageName } from '@/components/page';
import { planNameIsOfoApiBundle } from '@/features/plan/utils';

export function PaymentFail() {
  const history = useHistory();
  const source = usePageName();
  const { state } = useLocation<{
    planName: string;
    productName: string;
    couponName: string;
    purchase_origin?: string;
  }>();

  useIsCssFacility({
    onSettled: function () {
      trackEvent(
        'payment_order_fulfilled',
        {
          plan_name: state.planName,
          product_name: getTrackingName(planNameIsOfoApiBundle(state.planName) ? 'ofoapibundle' : state.productName),
          coupon_name: state.couponName,
          result: 'failure',
          purchase_origin: state.purchase_origin,
          source,
        },
        {
          send_immediately: true,
        }
      );
    },
    refetchOnWindowFocus: false,
  });

  return (
    <div className="flex flex-col min-h-screen justify-center items-center">
      <Icon
        className="ring-4 ring-offset-8 ring-[#b41312] rounded-full m-8"
        icon="bi:exclamation-circle-fill"
        color="#b41312"
        width="128"
        height="128"
      />
      <section>
        <h2 className="font-bold text-2xl text-center my-4">支付失败！</h2>
        <p className="text-center text-sm">
          好像哪里出现了问题…如需帮助，请联系客服
          <br />
          400-0110-988
        </p>
      </section>
      <button
        onClick={() => history.go(-1)}
        className="border-gray-200 text-sm font-bold rounded-md border px-10 py-3 m-4"
      >
        返回重试
      </button>
    </div>
  );
}
