import * as React from 'react';

function SvgCourier(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 34 34"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.375 8.5a4.25 4.25 0 100-8.5 4.25 4.25 0 000 8.5zm-2.062 2.37a3.188 3.188 0 00-4.295-.195l-4.736 3.946a3.188 3.188 0 00-.213 4.703l2.805 2.805v7.62h4.25v-7.62a4.25 4.25 0 00-1.245-3.005l-1.982-1.983 3.097-2.58 3.317 3.318a4.25 4.25 0 003.006 1.245h4.432v-4.25h-4.432l-4.004-4.004zM8.5 27.625a2.125 2.125 0 11-4.25 0 2.125 2.125 0 014.25 0zm4.25 0a6.375 6.375 0 11-12.75 0 6.375 6.375 0 0112.75 0zm14.875 2.125a2.125 2.125 0 100-4.25 2.125 2.125 0 000 4.25zm0 4.25a6.375 6.375 0 100-12.75 6.375 6.375 0 000 12.75z"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgCourier);
export default ForwardRef;
