import styled from 'styled-components';

const Container = styled.div`
  z-index: 99;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.3);
  height: 100vh;
  width: 100vw;
  left: 0;
  top: 0;
`;
export function Mask({ open }: { open: boolean }) {
  if (open) {
    return <Container />;
  }
  return null;
}
