import * as React from 'react';

function SvgCopy2(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path fillRule="evenodd" clipRule="evenodd" d="M3 3.5h11v2H5v9H3v-11z" fill="currentColor" />
      <path d="M7 7.5h10v10H7v-10z" fill="currentColor" />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgCopy2);
export default ForwardRef;
