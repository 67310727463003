import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Agreements } from '@/common/components/agreements';
import { Switch, Route } from 'react-router-dom';

import { isVersionBefore } from '@/utils/cmpVersions';
import { trackEvent } from '@/mixpanel';
import { getTrackingName } from '@/product-registry';

import { usePlanSelector } from '@/store/plan-slice';
import { RenewButton } from '@/features/payment/components/renew-button';
import useSearchParams from '@/hooks/useSearchParams';
import { LaunchPaymentButton } from '@/next/components/launch-payment-button';
import { ButtonContent } from '@/iap/purchase-button-default-content';
import { VasBundleOperateContent } from '../components/vas-bundle-operate-content';
import { VasBundleOrderContent } from '../components/vas-bundle-order-content';
import { shouldDisplayPlans } from '@/app-binding/qs';
import { cssTrackingOnce } from '@/tracking/css-tracking';

export function RenewPage() {
  const { planName, user_cohort } = useSearchParams();
  useEffect(function () {
    trackEvent('renew_page_loaded', {
      page_name: 'RENEW_PAGE',
      product_name: getTrackingName(productName),
    });
    cssTrackingOnce(`renew-${productName}`, 'page_loaded');
  }, []);

  const currentPlan = usePlanSelector((state) => planName ?? state.currentPlan);
  const getPaymentParam = usePlanSelector((state) => state.getPaymentParam);
  const { productName } = useParams<{ productName: string }>();

  if (!import.meta.env.DEV && isVersionBefore({ iOS: '3.9.3', android: '4.0.5' })) {
    location.pathname = `/iap/${productName}`;
  }

  const [couponCode, setCouponCode] = useState<string | undefined>(() => {
    return getPaymentParam().couponCode;
  });

  useEffect(
    function () {
      setCouponCode(getPaymentParam().couponCode);
    },
    [currentPlan, couponCode]
  );

  return (
    <div className="h-screen h-[100svh] mx-auto grid grid-rows-footer-page pb-safe">
      <Switch>
        <Route path="/renew/:productName(vasbundleorder)">
          <VasBundleOrderContent />
        </Route>
        <Route path="/renew/:productName(vasbundleoperate)">
          <VasBundleOperateContent />
        </Route>
      </Switch>

      <footer className="p-4 text-md border-t border-neutral-400 w-full bg-white flex flex-col gap-2 ">
        <Agreements actionName="签约" files={['user-agreement', 'auto-renew-agreement', 'privacy-policy']} />
        {shouldDisplayPlans() ? (
          <LaunchPaymentButton planName={currentPlan ?? ''} couponCode={couponCode}>
            <ButtonContent />
          </LaunchPaymentButton>
        ) : (
          <RenewButton
            productName={productName}
            trackingContext={{
              user_cohort,
            }}
          >
            <Switch>
              <Route path="/renew/vasbundleorder">
                <span className="text-lg font-bold my-2">确认协议并开通</span>
              </Route>
              <Route>
                <>去订阅</>
              </Route>
            </Switch>
          </RenewButton>
        )}
      </footer>
    </div>
  );
}
