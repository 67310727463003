import * as React from 'react';

function SvgStaff(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <rect width={24} height={24} rx={6} fill="#FCC73A" />
      <path d="M12 4.125a3.75 3.75 0 100 7.5 3.75 3.75 0 000-7.5z" fill="#fff" />
      <rect width={16.5} height={5.25} rx={2.625} transform="matrix(-1 0 0 1 20.25 13.875)" fill="#fff" />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgStaff);
export default ForwardRef;
