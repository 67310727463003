import * as React from 'react';

function SvgWallet(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 28 28"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path d="M24.5 21v1.167a2.34 2.34 0 01-2.333 2.333H5.833A2.333 2.333 0 013.5 22.167V5.833A2.333 2.333 0 015.833 3.5h16.334A2.34 2.34 0 0124.5 5.833V7H14a2.333 2.333 0 00-2.333 2.333v9.334A2.333 2.333 0 0014 21h10.5zM14 18.667h11.667V9.333H14v9.334zm4.667-2.917c-.969 0-1.75-.782-1.75-1.75s.781-1.75 1.75-1.75c.968 0 1.75.782 1.75 1.75s-.782 1.75-1.75 1.75z" />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgWallet);
export default ForwardRef;
