import { captureException } from '@sentry/browser';
import jwtDecode, { JwtPayload } from 'jwt-decode';
import { createQuery } from 'react-query-kit';
import { getToken } from './get-token';
import { jsbc } from '@/jsbc';

type UserId = string;

export const useUserId = createQuery<string, void, Error>({
  primaryKey: 'use-user-id',
  queryFn: getUserId,
});

export async function getUserId(): Promise<string> {
  const token = getToken();
  const { sub: userIdFromJwt } = jwtDecode<
    JwtPayload & {
      sub: UserId;
    }
  >(token);

  if (userIdFromJwt) {
    return userIdFromJwt;
  }
  captureException('user id not found in jwt');

  const userIdFromJsb = await jsbc.getUserId();
  if (userIdFromJsb) {
    return userIdFromJsb;
  }
  captureException('user id not found in jwt');

  throw new Error('user id not found');
}
