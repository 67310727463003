import { CouponFromApi } from '../hooks/useCoupons';
import { EnrichedFlatValueCoupon } from '../types';

export function convertToEnrichedFlatValueCoupon(coupon: CouponFromApi): EnrichedFlatValueCoupon {
  return {
    __typename: 'FlatValueCoupon',
    couponInfo: {
      code: coupon.code,
      title: coupon.title,
      expiryTime: coupon.expiryTime,
    },
    value: coupon.amountOff,
    applicablePlans: coupon.plans.map((plan) => plan.name),
    distributorPlans: coupon.plans.filter((o) => !!o.distributorName),
    status: coupon.status,
    descriptionFromPlan: coupon.plans[0].localizedPlanName,
    productName: coupon.plans[0].productName,
    remainingRedemptions: Math.max(0, coupon.redemptionLimit - coupon.redeemedTimes),
    percentage: coupon.percentage,
    plans: coupon.plans,
    couponType: coupon.couponType,
    tags: coupon.tags,
    discountType: coupon.discountType,
    periodExtendDays: coupon.periodExtendDays,
  };
}

export function isPlanNameIdentical(planName1: string, planName2: string) {
  const planName1WithoutVersion = planName1.replace(/\.v\d+$/, '');
  const planName2WithoutVersion = planName2.replace(/\.v\d+$/, '');
  return planName1WithoutVersion === planName2WithoutVersion;
}
