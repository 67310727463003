import * as React from 'react';

function SvgFilter(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.824 8a1 1 0 100-2 1 1 0 000 2zm0 2a3.001 3.001 0 002.83-2h7.17V6h-7.17a3.001 3.001 0 00-5.66 0h-3.17v2h3.17a3.001 3.001 0 002.83 2zm4 8a1 1 0 100-2 1 1 0 000 2zm0 2a3.001 3.001 0 002.83-2h3.17v-2h-3.17a3.001 3.001 0 00-5.66 0h-7.17v2h7.17a3.001 3.001 0 002.83 2z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgFilter);
export default ForwardRef;
