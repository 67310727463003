import { GraphQLClient, ClientError } from 'graphql-request';
import { captureException } from '@sentry/react';
import { Client, cacheExchange, fetchExchange, mapExchange } from '@urql/core';
import * as Sentry from '@sentry/react';
import axios from 'axios';

import { AppConfig, createDefaultFetchClient } from '@css/web-core';
import { getApiBase } from './iap/migration-helper';
import { getTraceIdFromErrorResponse } from './utils/http';
import { createLogger } from './logger';
import { getToken } from './hooks/get-token';
import { cssTrackingRequestFail } from './tracking/css-tracking';
import { isInChinaOtterApp } from './utils/userAgent';
import { createZodFetcher } from 'zod-fetch';

const logger = createLogger('http-client');

// TODO: 需要增加对应的 error handling interceptors
const spawnAxiosInstance = (withToken = true) => {
  const headers = {
    accept: '*/*',
    'Content-Type': 'application/json',
    'Application-Name': 'otter-app-assets(axios)',
    'Application-Version': __OTTERAPP_ASSETS_VERSION__,
  } as Record<string, string | number>;

  if (withToken) {
    headers['Authorization'] = `Bearer ${getToken()}`;
  }

  return axios.create({
    baseURL: `${getApiBase()}/`,
    timeout: 5000,
    headers,
  });
};

/***
 * fetcher with zod: 带有当前用户的 token 的 http client （包含 zod 的类型检查）
 */
export const fz = createZodFetcher(spawnAxiosInstance());

/***
 * fetcher with zod anonymous: 不带有当前用户的 token 的 http client （包含 zod 的类型检查）
 */
export const fza = createZodFetcher(spawnAxiosInstance(false));

/***
 * spawnGraphqlClient
 * @deprecated please switch to urqlClient for better error handling and caching.
 */
export function spawnGraphqlClient() {
  const endpoint = `${getApiBase()}/graphql`;
  return new GraphQLClient(endpoint, {
    credentials: 'include',
    mode: 'cors',
    headers: {
      authorization: `Bearer ${getToken()}`,
    },
    responseMiddleware: (response) => {
      if (response instanceof ClientError) {
        const api_name = (response.response.errors?.[0].path ?? '') as string;

        cssTrackingRequestFail({
          api_name,
        });

        if (isInChinaOtterApp()) {
          Sentry.addBreadcrumb({
            category: 'graphql-request-middleware',
            message: 'response with exception:' + response?.message,
            level: 'info',
          });

          const traceId = getTraceIdFromErrorResponse(response?.message);
          captureException(response, {
            extra: {
              cssTraceId: traceId,
            },
          });
        }
      }
    },
  });
}

const getUserAgent = (): AppConfig => {
  return { name: 'otter-app-assets', version: String(__OTTERAPP_ASSETS_VERSION__) };
};

interface SpawnHttpClientOptions {
  retries?: number;
}

export function spawnHttpClient(options: SpawnHttpClientOptions = {}) {
  const { retries = 0 } = options;
  const baseUrl = getApiBase();
  return createDefaultFetchClient({
    appConfig: getUserAgent(),
    baseUrl: baseUrl,
    retryOpts: { retries },
  });
}

export const urqlClient = new Client({
  url: `${getApiBase()}/graphql`,
  exchanges: [
    mapExchange({
      onError(error) {
        if (error.networkError) {
          logger.warn({ message: error.networkError.message }, 'network error');
        }

        if (error.graphQLErrors.length > 0) {
          const errorMessage: Array<string> = [];
          for (const gqlError of error.graphQLErrors) {
            errorMessage.push(gqlError.message);
          }
          logger.warn({ messages: errorMessage }, 'graphql error');

          Sentry.addBreadcrumb({
            category: 'urql-middleware',
            message: 'response with exception:' + serializeError(error),
            level: 'info',
          });

          captureException(error, {
            extra: {
              errorClient: 'urql',
            },
          });
        }
      },
    }),
    cacheExchange,
    fetchExchange,
  ],
  fetchOptions: () => {
    return {
      credentials: 'include',
      mode: 'cors',
      headers: {
        authorization: `Bearer ${getToken()}`,
      },
    };
  },
});

function serializeError(error: Error) {
  try {
    return JSON.stringify(error);
  } catch {
    return error.message;
  }
}
