import queryString from 'qs';

import { ClaimedCoupon, CouponDiscountType } from '@/api-binding/coupon';

function getExperimentOverideByDiscountType(discountType: CouponDiscountType): 'A' | 'B' {
  if (discountType === 'COUPON_TYPE_AMOUNT_OFF') {
    return 'B';
  }
  return 'A';
}

export function routeUpsellByCoupon(coupon: ClaimedCoupon, productName: string) {
  const couponId = coupon.code;
  const experimentOverride = getExperimentOverideByDiscountType(coupon.discountType);
  const { purchase_origin, trace_id } = queryString.parse(window.location.search, { ignoreQueryPrefix: true }) as {
    purchase_origin: string;
    trace_id: string;
  };
  return `/unlock/${productName}?${queryString.stringify({ experimentOverride, couponId, purchase_origin, trace_id })}`;
}
