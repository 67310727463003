import * as React from 'react';

function SvgAppIconBilling(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <g fill="currentColor">
        <path d="M9 12H3v1h6v-1z" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 2a2 2 0 012-2h16a2 2 0 012 2v12a2 2 0 01-2 2H2a2 2 0 01-2-2V2zm2-1h16a1 1 0 011 1v2H1V2a1 1 0 011-1zM1 5v2h18V5H1zm0 9V8h18v6a1 1 0 01-1 1H2a1 1 0 01-1-1z"
        />
      </g>
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgAppIconBilling);
export default ForwardRef;
