import * as React from 'react';

function SvgTrash(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.07 1.71L3.64 3H0v1h16V3h-3.64l-.43-1.29A2.5 2.5 0 009.558 0H6.441A2.5 2.5 0 004.07 1.71zM6.44 1a1.5 1.5 0 00-1.423 1.026L4.693 3h6.613l-.325-.974A1.5 1.5 0 009.558 1H6.441z"
        fill="currentColor"
      />
      <path d="M7 6H6v7h1V6zM9 6h1v7H9V6z" fill="currentColor" />
      <path
        d="M2.337 13.726L1.634 6h1.004l.695 7.636A1.5 1.5 0 004.826 15h6.348a1.5 1.5 0 001.493-1.364L13.361 6h1.005l-.703 7.726A2.5 2.5 0 0111.173 16H4.827a2.5 2.5 0 01-2.49-2.274z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgTrash);
export default ForwardRef;
