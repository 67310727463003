import * as React from 'react';

function SvgCircleIncomingPhone(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <rect width={32} height={32} rx={16} fill="#152231" />
      <path
        d="M22.4 18.8c-1 0-1.96-.16-2.856-.455a.816.816 0 00-.816.192l-1.76 1.76c-2.264-1.152-4.12-3-5.272-5.272l1.76-1.768a.768.768 0 00.2-.8A9.088 9.088 0 0113.2 9.6c0-.44-.36-.8-.8-.8H9.6c-.44 0-.8.36-.8.8 0 7.512 6.088 13.6 13.6 13.6.44 0 .8-.36.8-.8v-2.8c0-.44-.36-.8-.8-.8zm-.8-2.8h1.6A7.2 7.2 0 0016 8.8v1.6c3.096 0 5.6 2.505 5.6 5.6zm-3.2 0H20c0-2.207-1.792-4-4-4v1.6c1.328 0 2.4 1.073 2.4 2.4z"
        fill="#fff"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgCircleIncomingPhone);
export default ForwardRef;
