import { APIError } from './types';

/** Check if a specific error is related to not being authenticated */
export function errorIsAuth(error: APIError): boolean {
  return error.status === 401;
}

/** Check if a HTTP status code is retryable / should be retried */
export function httpStatusRetryable(status: number): boolean {
  // Don't retry any 4xx status codes - we only care about 5xx.
  if (status < 500) {
    return false;
  }

  // 501: Not Implemented
  // 505: HTTP version not supported
  // 507: Insufficient Storage (WebDAV)
  // 511: Network Authentication Required
  if (status === 501 || status === 505 || status === 507 || status === 511) {
    return false;
  }

  return true;
}

/**
 * Constructs the URL to be used as the API_URL for the app.
 * This is mainly used for apps hosted on multiple domains (tryotter.com, cloudkitchens.com).
 * Currently ensures that we use the same host that the app is running under,
 * defaulting to cloudkitchens if we're on localhost.
 *
 * @param subdomain the API subdomain. Example: 'api' or 'api-staging'
 */
export function buildAPIURL(subdomain: string, urlOverride?: string): string {
  if (urlOverride) {
    return urlOverride;
  }
  const { hostname } = window.location;
  if (hostname === 'localhost' || hostname === '192.168.99.100' || /\.diff-preview\./.test(hostname)) {
    return `https://${subdomain}.cloudkitchens.com`;
  }
  // replace first subdomain with prefix
  return `https://${hostname.replace(/^[^.]*/, subdomain)}`;
}
