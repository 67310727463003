import { isUnderFallbackMode } from '@/constant';

export function getApiBase() {
  const endpoint = import.meta.env.VITE_API_ENDPOINT;
  const fallbackEndpoint = import.meta.env.VITE_API_FALLBACK_ENDPOINT;

  if (typeof endpoint === 'undefined') {
    throw new Error('API_ENDPOINT is not defined');
  }

  if (endpoint.startsWith('http')) {
    if (isUnderFallbackMode()) {
      return fallbackEndpoint;
    }
    return endpoint;
  } else {
    return new URL(endpoint, window.location.origin).toString();
  }
}
