import * as React from 'react';

function SvgAppIconInventory(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5 9H9v7h7V9h-2.5v2.5h-2V9zM2.5 9H0v7h7V9H4.5v2.5h-2V9zM7 0H4.5v7h7V0H9v2.5H7V0z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgAppIconInventory);
export default ForwardRef;
