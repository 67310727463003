import { getUserId } from '@/hooks/use-user-id';
import { Money, moneyToNumber } from '@css/currency';
import { useQuery } from '@tanstack/react-query';
import { gql } from 'graphql-request';
import { urqlClient } from '../../http-clients';
import { EnrichedFlatValueCoupon } from '../types';
import { Tag } from '@/features/coupon/hooks/use-redeemable-coupons';

const fetchCouponsQuery = gql`
  query fetchCoupons($userId: ID!) {
    lutrahubCoupons(input: { merchantId: $userId, pagination: { first: 200 } }) {
      coupons {
        code
        title
        status
        expiryTime
        amountOff {
          currencyCode
          units
          nanos
        }
        plans {
          name
          productName
          distributorName
          localizedPlanName
          localizedPlanShortName(locale: "zh_CN")
        }
        redemptionLimit
        redeemedTimes
        percentage
        discountType
        tags {
          key
          value
        }
      }
    }
  }
`;

export type CouponFromApi = {
  amountOff: Money;
  code: string;
  plans: Array<{
    name: string;
    localizedPlanShortName: string;
    localizedPlanName: string;
    productName: string;
    distributorName?: string;
  }>;
  status: 'REDEEMABLE' | 'UNSPECIFIED' | 'REDEEMED' | 'EXPIRED';
  title: string;
  expiryTime: string;
  redemptionLimit: number;
  redeemedTimes: number;
  percentage?: number;
  tags?: Tag[];
  discountType?: CouponDiscountType;
};

export enum CouponDiscountType {
  CouponTypeAmountOff = 'COUPON_TYPE_AMOUNT_OFF',
  CouponTypeFreeTrial = 'COUPON_TYPE_FREE_TRIAL',
  CouponTypePercentOff = 'COUPON_TYPE_PERCENT_OFF',
  CouponTypePeriodExtension = 'COUPON_TYPE_PERIOD_EXTENSION',
  CouponTypeUnknown = 'COUPON_TYPE_UNKNOWN',
}

/***
 * useGetCoupons
 * @deprecated: use useCouponsNg instead
 */
export function useGetCoupons() {
  return useQuery(
    ['coupons', 'source'],
    async function () {
      const userId = await getUserId();
      const res = await urqlClient
        .query<{
          lutrahubCoupons: {
            coupons: CouponFromApi[];
          };
        }>(fetchCouponsQuery, { userId })
        .toPromise();

      return res.data?.lutrahubCoupons.coupons ?? [];
    },
    {
      staleTime: 60 * 1000,
      select: (coupons): EnrichedFlatValueCoupon[] => {
        return coupons
          .filter((coupon) => !!coupon.plans[0] && (moneyToNumber(coupon.amountOff) > 0 || coupon.percentage))
          .map(convertToEnrichedFlatValueCoupon);
      },
      refetchOnMount: true,
    }
  );
}

export function convertToEnrichedFlatValueCoupon(coupon: CouponFromApi): EnrichedFlatValueCoupon {
  return {
    __typename: 'FlatValueCoupon',
    couponInfo: {
      code: coupon.code,
      title: coupon.title,
      expiryTime: coupon.expiryTime,
    },
    value: coupon.amountOff,
    applicablePlans: coupon.plans.map((plan) => plan.name),
    distributorPlans: coupon.plans.filter((o) => !!o.distributorName),
    status: coupon.status,
    descriptionFromPlan: coupon.plans[0].localizedPlanName,
    productName: coupon.plans[0].productName,
    remainingRedemptions: Math.max(0, coupon.redemptionLimit - coupon.redeemedTimes),
    percentage: coupon.percentage,
    plans: coupon.plans,
    discountType: coupon.discountType,
    tags: coupon.tags,
  };
}
