import * as React from 'react';

function SvgCullinanCalendar(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 3H5v1.5H3V17h14V4.5h-2V3h-2v1.5H7V3zM4.5 7.5h11v8h-11v-8zM14 10h-4v4h4v-4z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgCullinanCalendar);
export default ForwardRef;
