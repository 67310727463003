import * as React from 'react';

function SvgCompetitionInsightsPromptStars(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 16 16"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      ref={svgRef}
      {...props}
    >
      <path d="M9.6 3.2L8 7.2 4 8.8l4 1.6 1.6 4 1.6-4 4-1.6-4-1.6-1.6-4zM2.8 5.2l.8 2 .8-2 2-.8-2-.8-.8-2-.8 2-2 .8 2 .8zM4.2 12l-.6-1.6L3 12l-1.4.4L3 13l.6 1.4.6-1.4 1.4-.6-1.4-.4z" />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgCompetitionInsightsPromptStars);
export default ForwardRef;
