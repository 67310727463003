import styled, { DefaultTheme } from 'styled-components';

import { TextVariant, TextColorVariant } from '../../../theme/constants';
import { ellipsis, lineHeight, textColor, typography, textDecoration } from './typography';

export interface TextProps {
  /**
   * The text variant to be used. @default 'paragraphRegular'
   */
  variant?: TextVariant;

  /**
   * Optional data test
   */
  dataTest?: string;

  /**
   * The color of the text field @default 'primary'
   */
  color?: TextColorVariant;

  /**
   * This keyword value will display an ellipsis ('…', U+2026 HORIZONTAL ELLIPSIS) to represent clipped text. The ellipsis is displayed inside the content area, decreasing the amount of text displayed. @default true
   */
  ellipsis?: boolean;

  /**
   * Include title
   */
  includeTitle?: boolean;

  lineHeight?: keyof DefaultTheme['lineHeights'];

  /**
   * Optional element title. The title global attribute contains text representing advisory information related to the element it belongs to. (https://developer.mozilla.org/en-US/docs/Web/HTML/Global_attributes/title)
   */
  title?: string;

  /**
   * Optional decoration for the text (e.g. line-through, overline, underline)
   */
  textDecoration?: keyof DefaultTheme['textDecorations'];
}

export const Text = styled.span.attrs<TextProps>((props) => {
  let title: string | undefined;
  const shouldAddTitle = props.includeTitle || props.title;

  if (shouldAddTitle) {
    const maybeTitleFromChildren = typeof props.children === 'string' ? props.children : undefined;
    title = props.title || maybeTitleFromChildren;
  }

  return {
    'data-testid': props.dataTest || 'op-text',
    title,
  };
})<TextProps>`
  ${(props) => typography(props.variant ?? 'paragraphRegular')}
  ${(props) => (props.ellipsis ? ellipsis() : null)}
  ${(props) => textColor(props.color || 'primary')}
  ${(props) => (props.lineHeight ? lineHeight(props.lineHeight) : null)}
  ${(props) => (props.textDecoration ? textDecoration(props.textDecoration) : null)}
`;
