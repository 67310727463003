import * as React from 'react';

function SvgBankHighlight(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <g clipPath="url(#bank-highlight_svg__clip0)">
        <path
          d="M10.565 30.788h4.205V11.405h-4.205v19.383zM2.577 30.788h4.205V11.405H2.577v19.383zM26.583 30.788h4.205V11.405h-4.205v19.383zM18.594 30.788h4.205V11.405h-4.205v19.383z"
          fill="#FFE4DE"
        />
        <path d="M0 33.4l.76-5.527h31.88l.76 5.527H0zM32.995 13.585v-2.963H.435v2.963h32.56z" fill="#FF5530" />
        <path d="M.4 10.613L16.7 0 33 10.613H.4z" fill="#FFE4DE" />
        <path
          d="M6.768 13.576H2.563v2.483h4.205v-2.483zM14.778 13.576h-4.205v2.483h4.205v-2.483zM22.747 13.576h-4.205v2.483h4.205v-2.483zM30.757 13.576h-4.205v2.483h4.205v-2.483z"
          fill="#000"
        />
      </g>
      <defs>
        <clipPath id="bank-highlight_svg__clip0">
          <path fill="#fff" d="M0 0h33.4v33.4H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgBankHighlight);
export default ForwardRef;
