import { AriaLabelingProps } from '@react-types/shared';
import React, { ReactElement, ReactNode } from 'react';
import { useLabel } from 'react-aria';
import styled, { DefaultTheme } from 'styled-components';

import { FilledQuestionMark } from '@css/op-icons';

import { TextColorVariant } from '../../../theme';
import { Label } from '../../atoms/Label';
import { Text } from '../../atoms/Text';
import { Intent } from '../../types';
import { SpacingVariant } from '../../types/SpacingVariant';
import { Popover } from '../Popover';

type LabelSpacingVariant = Extract<SpacingVariant, 'regular' | 'loose'>;

export interface FormItemProps {
  /**
   * Optional help text that gives a user additional context about the
   * field. It's always visible under the field to be entered.
   * If a field has an error the error text will replace the help text.
   */
  helpText?: string;
  /**
   * Optional label help text is rendered as a popover next to the label and is shown on hover.
   */
  labelHelpText?: ReactNode;
  /**
   * Optional intent of the form field, can be used to highlight errors.
   */
  intent?: Intent;
  /**
   * The label text for the form field.
   */
  label?: any;

  /**
   * Optional spacing between the label and the contents of the form. Can be loose or regular.
   * A loose spacing will be used by default if an item has a labelHelpText.
   */
  labelSpacing?: LabelSpacingVariant;

  /**
   * Optional outer spacing. Deprecated in favor for setting parent grid layout.
   */
  spacing?: SpacingVariant;

  children?: (props: { fieldProps: Pick<AriaLabelingProps, 'aria-label' | 'aria-labelledby'> }) => ReactElement;

  /**
   * Optional test attribute
   */
  dataTest?: string;

  /**
   * Whether to decrease margins around popover icon to make total height match form items without
   * help text.
   */
  narrowPopoverIcon?: boolean;

  /**
   * Whether this FormItem is a required field.
   */
  required?: boolean;
}

const Spacing: Record<SpacingVariant, keyof DefaultTheme['space']> = {
  none: '$0',
  tight: '$3',
  regular: '$6',
  loose: '$10',
};

const LabelSpacing: Record<LabelSpacingVariant, string> = {
  regular: '4px',
  loose: '6px',
};

const FormItemLabel = styled(Label)<{ $required?: boolean }>`
  display: flex;
  height: ${(props) => props.theme.lineHeights.normal};
  &:after {
    display: ${(props) => (props.$required === true ? null : 'none')};
    content: '*';
    margin-left: 2px;
    color: ${(props) => props.theme.colors.red};
  }
`;

const FormItemHelpText = styled(Text)`
  display: block;
  font-size: ${(props) => props.theme.fontSizes.micro};
  margin: 4px 0 0 1px;
`;

const FormItemWrapper = styled.div<{ spacing: SpacingVariant }>`
  width: 100%;
  margin-bottom: ${(props) => props.theme.space[Spacing[props.spacing]]};
`;

const PopoverWrapper = styled.div<{ dataTest?: string; narrowPopoverIcon?: boolean }>`
  display: flex;
  margin-left: ${(props) => props.theme.space.$1};
  ${(props) => !!props.narrowPopoverIcon && 'margin-top: -4px; margin-bottom: -4px;'}
  height: ${(props) => props.theme.space.$4};
  width: ${(props) => props.theme.space.$4};
`;

const LabelWrapper = styled.div<Pick<FormItemProps, 'labelSpacing'>>`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 0 ${(props) => LabelSpacing[props.labelSpacing || 'regular']} 1px;
`;

const getTextColorVariant = (intent: Intent | undefined): TextColorVariant => {
  if (intent === 'error') {
    return 'error';
  } else if (intent === 'warning') {
    return 'warning';
  }
  return 'secondary';
};

export const FormItem: React.FC<FormItemProps> = (props: FormItemProps) => {
  const {
    label,
    dataTest = 'op-formItem',
    spacing = 'regular',
    labelSpacing,
    intent,
    helpText,
    labelHelpText,
    children,
    narrowPopoverIcon,
    required,
  } = props;
  const { labelProps: ariaLabelProps, fieldProps } = useLabel({ label, labelElementType: 'label' });
  const { ...ariaLabelPropsSubset } = ariaLabelProps;
  const labelSpacingOrDefault = labelSpacing || (labelHelpText ? 'loose' : 'regular');

  return (
    <FormItemWrapper data-testid={dataTest} spacing={spacing} data-intent={intent}>
      {label && (
        <LabelWrapper labelSpacing={labelSpacingOrDefault}>
          {typeof label === 'function' ? (
            label({ ...ariaLabelProps, 'data-testid': `${dataTest}-label` })
          ) : (
            <FormItemLabel {...ariaLabelPropsSubset} data-testid={`${dataTest}-label`} $required={required}>
              {label}
            </FormItemLabel>
          )}
          {labelHelpText && (
            <PopoverWrapper narrowPopoverIcon={narrowPopoverIcon}>
              <Popover
                dataTest={`${dataTest}-labelPopover`}
                triggerMode="hover"
                hasContentPadding
                content={<Text variant="microMedium">{labelHelpText}</Text>}
                interactive="hoverable"
                placement="top"
              >
                <FilledQuestionMark />
              </Popover>
            </PopoverWrapper>
          )}
        </LabelWrapper>
      )}
      {children?.({ fieldProps })}
      {helpText && (
        <FormItemHelpText color={getTextColorVariant(intent)} variant="smallRegular" dataTest={`${dataTest}-helpText`}>
          {helpText}
        </FormItemHelpText>
      )}
    </FormItemWrapper>
  );
};
