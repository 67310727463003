import { debounce } from 'lodash-es';
import React, { useCallback, useEffect, useState } from 'react';
import { ThemeProvider as StyledComponentsThemeProvider } from 'styled-components';

import { BreakpointVariant, Orientation } from '../../components/types';
import { useMatchedBreakpoints } from '../../hooks/useMatchedBreakpoints';
import { OrderedBreakpoints, useOrderedBreakpoints } from '../../hooks/useOrderedBreakpoints';
import { OpTheme } from '../../theme';
import { getOrientation } from '../utilities';

export interface ThemeProviderProps {
  /**
   * @default false
   */
  disableContentInset?: boolean;
  theme: OpTheme;
  children?: React.ReactNode;
}

const DELAY = 250;

interface ResponsiveState {
  // Currently active breakpoint
  breakpoint?: BreakpointVariant;
  // Current screen dimensions
  dimensions?: { width: number; height: number };
  // Screen orientation
  orientation?: Orientation;
}

// Distance the content view is inset from the edge of the screen.
// Useful when needing to place elements beneath navigation bars, etc.

export enum InsetNamespace {
  adminBanner = 'adminBanner',
  appBanner = 'appBanner',
  primaryNav = 'primaryNav',
  pageActions = 'pageActions',
}

export const insetNamespaceIndexes: Record<InsetNamespace, number> = {
  adminBanner: 0,
  appBanner: 1,
  primaryNav: 2,
  pageActions: 3,
};

export interface ContentInset {
  top: number;
  right: number;
  bottom: number;
  left: number;
}
export interface ContentInsets {
  top: [number, number, number, number];
  right: [number, number, number, number];
  bottom: [number, number, number, number];
  left: [number, number, number, number];
}

export const defaultContentInset: ContentInsets = {
  top: [0, 0, 0, 0],
  right: [0, 0, 0, 0],
  bottom: [0, 0, 0, 0],
  left: [0, 0, 0, 0],
};

type UIContextType = ResponsiveState & {
  // All the app breakpoints, ordered from smallest to largest
  orderedBreakpoints: OrderedBreakpoints;
  contentInset: ContentInsets;
  setContentInset: React.Dispatch<React.SetStateAction<ContentInsets>>;
};

export const UIContext = React.createContext<UIContextType>({
  contentInset: defaultContentInset,
  orderedBreakpoints: [],
  setContentInset: () => 0,
});

const EMPTY_INSET: ContentInsets = {
  top: [0, 0, 0, 0],
  right: [0, 0, 0, 0],
  bottom: [0, 0, 0, 0],
  left: [0, 0, 0, 0],
};

export const ThemeProvider: React.FC<ThemeProviderProps> = ({ children, theme, disableContentInset = false }) => {
  const orderedBreakpoints = useOrderedBreakpoints(theme.breakpoints);
  const breakpoint = useMatchedBreakpoints(orderedBreakpoints);
  const [uiState, setUiState] = useState<ResponsiveState>({});
  const [contentInset, setContentInset] = useState<ContentInsets>(defaultContentInset);

  /**
   * Some components will actually need the screen dimensions (for example, modals) and
   * we don't want every instance having to measure and bind listeners.
   */
  const getDimensions = useCallback(() => {
    const { innerWidth: width, innerHeight: height } = window;
    const orientation = getOrientation({ width, height });
    setUiState({ dimensions: { width, height }, orientation });
  }, []);

  useEffect(() => {
    getDimensions();
    const debouncedGetDimensions = debounce(getDimensions, DELAY);
    window.addEventListener('resize', debouncedGetDimensions);

    return () => window.removeEventListener('resize', debouncedGetDimensions);
  }, [getDimensions]);

  return (
    <StyledComponentsThemeProvider theme={theme}>
      <UIContext.Provider
        value={{
          ...uiState,
          breakpoint,
          orderedBreakpoints,
          contentInset: disableContentInset ? EMPTY_INSET : contentInset,
          setContentInset,
        }}
      >
        {children}
      </UIContext.Provider>
    </StyledComponentsThemeProvider>
  );
};
