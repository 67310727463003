import { createContext, useContext, useEffect } from 'react';

interface PageProps {
  name: string;
  children?: React.ReactElement | React.ReactElement[];
}

const pageContext = createContext<string>('WEB');

const { Provider } = pageContext;

export function Page({ name, children }: PageProps) {
  useEffect(
    function () {
      // TODO: change to `trackEvent`
      console.log('page_loaded', {
        pathname: location.pathname,
      });
    },
    [name]
  );
  return (
    <Provider data-testid="page" value={name}>
      {children}
    </Provider>
  );
}

export function usePageName() {
  const name = useContext(pageContext);

  if (!name) {
    throw new Error('usePage must be used within a Page');
  }

  return name;
}
