import orderprepScreenshot from './orderprep.svg';
import printsegmentScreenshot from './printsegment.webp';
import vasbundleOrderScreenshot from './vasbundle-order.webp';
import vasbundleOperateScreenshot from './vasbundle-operate.webp';
import { StyledSubTitle } from './ui';

interface FeatureMeta {
  name: string;
  byline: string | React.ReactNode;
  screenshot: string;
  width: number;
  height: number;
  purchasePlan: string;
  buttonText?: string;
}

export const features: Record<string, FeatureMeta> = {
  printsegment: {
    name: '菜品排序',
    byline: '灵活设置小票菜品分区，自定义排序',
    screenshot: printsegmentScreenshot,
    width: 1029,
    height: 1383,
    purchasePlan: 'print.segment.recurring.p1m.store.chn',
    buttonText: '立即激活',
  },
  orderprep: {
    name: '外卖监控',
    byline: '实时获取多平台门店各项指标',
    screenshot: orderprepScreenshot,
    width: 343,
    height: 461,
    purchasePlan: 'order.prep.recurring.p1m.store.chn',
  },
  vasbundleorder: {
    name: '接单大师',
    byline: (
      <p>
        优惠券抵扣38.99元（39-38.99=0.01元）
        <br />
        ￥0.01 畅享7天 （限首次购买） <s>原价 ￥39</s>
        <br />
        <StyledSubTitle variant="paragraphRegular">外卖接单必备组合，5大功能助力高效接单备餐。</StyledSubTitle>
      </p>
    ),
    screenshot: vasbundleOrderScreenshot,
    width: 1029,
    height: 1383,
    purchasePlan: 'vas.bundle.order.recurring.p1m.store.chn',
    buttonText: '确认协议并开通',
  },

  vasbundleoperate: {
    name: '运营大师',
    byline: (
      <p>
        优惠券抵扣38.99元（39-38.99=0.01元）
        <br />
        ￥0.01 畅享7天 （限首次购买） <s>原价 ￥39</s>
        <br />
        外卖运营爆单工具，5大功能助力外卖运营增收提效。
      </p>
    ),
    screenshot: vasbundleOperateScreenshot,
    width: 1029,
    height: 1383,
    purchasePlan: 'vas.bundle.operate.recurring.p1m.store.chn',
    buttonText: '确认协议并开通',
  },
} as const;

export type FeaturesMapping = typeof features;

export function getFeatureBySlug(slug: string) {
  if (isValidSlug(slug)) {
    return features[slug];
  }
  return null;
}

function isValidSlug(slug: string): slug is keyof FeaturesMapping {
  return slug in features;
}
