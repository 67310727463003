import { AmountError, PaymentError, ServerInternalError } from '@/errors';
import { createLogger } from '@/logger';
import { captureException } from '@sentry/browser';

const logger = createLogger('payment-error');

export const capturePaymentException = (error: PaymentError | AmountError) => {
  const sentryEventId = captureException(error, {
    level: 'fatal',
    extra: {
      url: window.location.href,
      context: error.context,
      cause: error.cause,
    },
  });

  logger.error({ sentryEventId }, error.message);

  return sentryEventId;
};

export const captureServerInternalError = (error: ServerInternalError) => {
  const sentryEventId = captureException(error, {
    level: 'error',
    extra: {
      url: window.location.href,
      traceId: error.traceId,
    },
  });

  logger.error({ sentryEventId }, error.message);

  return sentryEventId;
};
