import { HttpClient as HttpClientIntegration } from '@sentry/integrations';
import * as Sentry from '@sentry/react';
import { getUserId } from './hooks/use-user-id';
import { isInChinaOtterApp } from './utils/userAgent';

async function launchSentry() {
  if (isInChinaOtterApp()) {
    Sentry.init({
      dsn: 'https://d682eec6f15746a9916631ca9106d40e@o175931.ingest.sentry.io/4504677097340929',
      release: `otterapp-assets@${__OTTERAPP_ASSETS_VERSION__ ?? 'development'}`,
      integrations: [
        new Sentry.BrowserTracing(),
        new HttpClientIntegration({
          failedRequestTargets: [/^https:\/\/api(?:-staging)?\.(?:csschina\.com|baitime\.cn)\/.*/],
        }),
      ],
      sampleRate: 1,
      environment: import.meta.env.MODE,
      enabled: import.meta.env.MODE === 'production' && window.location.hostname !== 'localhost',
      sendDefaultPii: true,
    });
    const userId = await getUserId();
    Sentry.setUser({ id: userId });
  }
}

void launchSentry();
