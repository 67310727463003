import * as React from 'react';

function SvgAppIconMenus(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path d="M8 5H3v1h5V5zM12 5h5v1h-5V5zM8 9H3v1h5V9zM12 9h5v1h-5V9z" fill="currentColor" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 .388C0 .174.174 0 .388 0h3.456c2.09 0 4.167.337 6.15.998a.018.018 0 00.012 0A19.45 19.45 0 0116.156 0h3.456c.214 0 .388.174.388.388v15.224a.388.388 0 01-.388.388h-3.456c-2.09 0-4.167.337-6.15.998h-.012A19.45 19.45 0 003.844 16H.388A.388.388 0 010 15.612V.388zM16.156 1H19v14h-2.844c-2.089 0-4.165.32-6.156.949A20.447 20.447 0 003.844 15H1V1h2.844c1.983 0 3.953.32 5.834.947.209.07.435.07.644 0A18.45 18.45 0 0116.156 1z"
        fill="currentColor"
      />
      <path d="M20 20v-1H0v1h20z" fill="currentColor" />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgAppIconMenus);
export default ForwardRef;
