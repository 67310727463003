import { ProductSlug } from '@/product-registry';
import { button } from '@/cva/button';
import { typography } from '@/cva/typography';
import { cx } from 'class-variance-authority';
import { createQuery } from 'react-query-kit';
import fire from './fire.png';
import { spawnHttpClient } from '@/http-clients';

const httpClient = spawnHttpClient();

export const useUpsellStats = createQuery({
  primaryKey: 'upsell-stats',
  queryFn: async function ({ queryKey: [, { productName }] }) {
    const res = await httpClient.get<{
      paidOrderSummary: Array<{
        productName: string;
        paidOrderNumber: number;
      }>;
    }>({
      path: '/lutrahub/ops/queryPaidOrderSummaryByProduct',
      method: 'POST',
      requestParams: {
        data: {
          productName: [productName],
          startTime: new Date('2023-03-01T08:00:00Z').toISOString(),
          endTime: new Date().toISOString(),
        },
      },
    });

    return res;
  },
});

export function FireButton({ productName, action = '下单' }: { productName: ProductSlug; action?: string }) {
  const statsQuery = useUpsellStats({ variables: { productName } });

  return (
    <div className="relative flex mx-auto">
      <img src={fire} alt="" className="h-[48px] -top-[12px] absolute" />
      <button className={button({ size: 'large', intent: 'fire' })}>
        <span className="shrink-0">累计</span>
        <span className={cx(typography({ variant: 'emphasis' }), 'shrink')}>
          {statsQuery.data?.paidOrderSummary[0].paidOrderNumber ?? '--'}
        </span>
        <span className="shrink-0">个用户</span>
        <span className={cx(typography({ variant: 'emphasis' }), 'shrink-0')}>已{action}</span>
      </button>
    </div>
  );
}
