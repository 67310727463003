import * as React from 'react';

function SvgInventory(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <rect width={24} height={24} rx={8} fill="#EF5F3E" />
      <path d="M16.16 4.505a3.385 3.385 0 110 6.77 3.385 3.385 0 010-6.77z" fill="#fff" />
      <rect
        x={19.546}
        y={12.778}
        width={6.77}
        height={6.77}
        rx={3.385}
        transform="rotate(90 19.546 12.778)"
        fill="#fff"
      />
      <rect
        x={11.271}
        y={4.505}
        width={6.77}
        height={6.77}
        rx={3.385}
        transform="rotate(90 11.271 4.505)"
        fill="#fff"
      />
      <rect
        x={11.271}
        y={12.778}
        width={6.77}
        height={6.77}
        rx={3.385}
        transform="rotate(90 11.271 12.778)"
        fill="#fff"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgInventory);
export default ForwardRef;
