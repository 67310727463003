import * as React from 'react';

function SvgTableOrder(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 38 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M31.036 31.03H6.964v-4.012h24.072v4.012zm0-8.024H6.964v-4.012h24.072v4.012zm0-8.024H6.964V10.97h24.072v4.012zM.946 41.06l3.01-3.01 3.008 3.01 3.01-3.01 3.008 3.01 3.009-3.01L19 41.06l3.009-3.01 3.009 3.01 3.009-3.01 3.009 3.01 3.008-3.01 3.01 3.01V.94l-3.01 3.01L31.037.94l-3.01 3.01L25.019.94l-3.01 3.01L19 .94l-3.01 3.01L12.983.94 9.973 3.95 6.964.94 3.955 3.95.946.94v40.12z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgTableOrder);
export default ForwardRef;
