import queryString from 'query-string';

/***
 * 通过 ?displayPlans=1 来决定是否在某些页面显示 plan selector ，比如 vasbundleorder 的续约页面。
 */
export function shouldDisplayPlans(): boolean {
  const qs = queryString.parse(window.location.search);
  if (String(qs.displayPlans) === '1') {
    return true;
  }

  return false;
}
