import { getLinkForOrderFeed, getLinkForHomepage } from './app-binding/deeplink';
import { BAAS_HOSTNAME, ECD_HOSTNAME } from './constant';
import { OfoType } from './hooks/use-ofo-status';
import { buildOfoAuthCallbackUrl } from './iap/buildOfoAuthCallbackUrl';
import { buildAdvancedTemplateCallbackURL } from './pages/PrinterTemplate/buildAdvancedTemplateCallbackURL';
import { getPlatform, cmpVersions, getVersion } from './utils/cmpVersions';

export interface TProductContext {
  authorizedOfos: Partial<Record<OfoType, boolean>>;
  storeId?: string;
}

/**
 * this is because otter android before 3.7.2 does not recognize 'otter://reports'
 * if the payment page(or any page) tries to visit this deep link, android app will CRASH
 *
 * this is the function that prevent android before 3.7.2 from visiting otter://reports
 */
export function preventAndroidFromCrashByUnknownDeepLink(url: string | undefined): string | undefined {
  const platform = getPlatform();

  if (url?.startsWith('otter://reports') && platform === 'ANDROID') {
    if (cmpVersions(getVersion(), '3.7.2') > 0) {
      return url;
    } else {
      return '';
    }
  }

  return url;
}

export type TProduct =
  | 'vasbundleorder'
  | 'vasbundleoperate'
  | 'vasbundleoprotection'
  | 'orderprep'
  | 'BAAS'
  | 'storemonitor'
  | 'smart_promotion'
  | 'qrco'
  | 'ordercashier'
  | 'xcd'
  | 'xcdRenew'
  | 'printsegment'
  | 'reviewmgmt'
  | 'printtemplate'
  | 'printer-4g'
  | 'ordermissing'
  | 'ofoapi'
  | 'ofoapibundle'
  | 'sdrmarketingbundle'
  | 'advance-template-branded-dinner'
  | 'advance-template-tea'
  | 'advance-template-spicy-hot-pot';

interface TProductDefinition {
  readonly slug: TProduct;
  readonly trackingProductName: string;
  readonly title: string;
  defaultCallback?: string | ((context: TProductContext) => string);
}

export const products: Record<TProduct, TProductDefinition> = {
  vasbundleoprotection: {
    slug: 'vasbundleoprotection',
    trackingProductName: 'BUNDLE_PROTECTION',
    title: '外卖守护宝',
    defaultCallback: 'otter://general?scenario=CLOSE_WEB_APPLICATION',
  },
  ofoapi: {
    slug: 'ofoapi',
    trackingProductName: 'OFO_API',
    title: 'OFO API',
    defaultCallback: 'otter://general?scenario=CLOSE_WEB_APPLICATION',
  },
  ofoapibundle: {
    slug: 'ofoapibundle',
    trackingProductName: 'OFO_API_BUNDLE',
    title: 'OFO API BUNDLE',
    defaultCallback: 'otter://general?scenario=CLOSE_WEB_APPLICATION',
  },
  vasbundleorder: {
    slug: 'vasbundleorder',
    trackingProductName: 'BUNDLE_ORDER',
    title: '接单大师',
    defaultCallback: function (context: TProductContext) {
      const { authorizedOfos } = context;

      if (authorizedOfos['eleme'] || authorizedOfos['meituan']) {
        return `${window.location.origin}/bundle/vasbundleorder/setting`;
      } else {
        return buildOfoAuthCallbackUrl('vasbundleorder');
      }
    },
  },
  vasbundleoperate: {
    slug: 'vasbundleoperate',
    trackingProductName: 'BUNDLE_OPERATE',
    title: '运营大师',
    defaultCallback: function (context: TProductContext) {
      const { storeId } = context;
      let url = `https://${BAAS_HOSTNAME}/mobile/vasbundleoperate?fromPurchase`;
      if (storeId) {
        url = `https://${BAAS_HOSTNAME}/mobile/vasbundleoperate?fromPurchase&storeId=${storeId}`;
      }
      return `otter://webview?url=${encodeURIComponent(url)}`;
    },
  },
  orderprep: {
    slug: 'orderprep',
    trackingProductName: 'ONE_CLICK_BATCHPREP',
    title: '一键出餐',
    defaultCallback: buildOfoAuthCallbackUrl('orderprep'),
  },
  BAAS: {
    slug: 'BAAS',
    trackingProductName: 'STOREMONITOR',
    title: '外卖监控',
    defaultCallback: 'otter://reports?path=/mobile/smart_monitoring/abnormal_analysis%3FskipOnboardingPage',
  },
  storemonitor: {
    slug: 'storemonitor',
    trackingProductName: 'STOREMONITOR',
    title: '外卖监控',
    defaultCallback: 'otter://reports?path=/mobile/smart_monitoring/abnormal_analysis%3FskipOnboardingPage',
  },
  smart_promotion: {
    slug: 'smart_promotion',
    trackingProductName: 'SMART_PROMOTION',
    title: '',
  },
  qrco: {
    slug: 'qrco',
    trackingProductName: 'QRCO',
    title: '扫码点餐 ',
    defaultCallback: 'otter://general?scenario=CLOSE_WEB_APPLICATION',
  },
  ordercashier: {
    slug: 'ordercashier',
    trackingProductName: 'ORDERCASHIER',
    title: '收银点餐系统',
    defaultCallback: 'otter://general?scenario=CLOSE_WEB_APPLICATION',
  },
  xcd: {
    slug: 'xcd',
    trackingProductName: 'XCD',
    title: '发券宝',
    defaultCallback: function (context: TProductContext) {
      const { authorizedOfos } = context;
      if (authorizedOfos['eleme'] || authorizedOfos['meituan']) {
        const couponSettingUrl = `https://${ECD_HOSTNAME}/xcd/onboard/couponSettings?storeId=${context.storeId}`;
        return couponSettingUrl;
      } else {
        const ofoAuthUrl = `https://${ECD_HOSTNAME}/xcd/onboard/ofo?storeId=${context.storeId}`;
        return ofoAuthUrl;
      }
    },
  },
  xcdRenew: {
    slug: 'xcdRenew',
    trackingProductName: 'xcdRenew',
    title: '发券宝',
    defaultCallback: 'otter://general?scenario=CLOSE_WEB_APPLICATION',
  },
  printsegment: {
    slug: 'printsegment',
    trackingProductName: 'PRINTER_SECTION_TICKET',
    title: '菜品打印排序',
    defaultCallback: 'otter://printer?operation=print_segment',
  },
  reviewmgmt: {
    slug: 'reviewmgmt',
    trackingProductName: 'COMMENT_HANDLING',
    title: '评价回复',
    defaultCallback: 'otter://reports?path=/mobile/review_management%3FskipOnboardingPage',
  },
  printtemplate: {
    slug: 'printtemplate',
    trackingProductName: 'TBD',
    title: '小票高级定制',
  },
  'printer-4g': {
    slug: 'printer-4g',
    trackingProductName: 'PRINTER_SIM',
    title: '云打印机4G数据',
    defaultCallback: getLinkForHomepage(),
  },
  ordermissing: {
    slug: 'ordermissing',
    trackingProductName: 'ORDER_MISSING',
    title: '漏单保护',
    defaultCallback: buildOfoAuthCallbackUrl('ordermissing'),
  },
  sdrmarketingbundle: {
    slug: 'sdrmarketingbundle',
    trackingProductName: 'SDR_MARKETING_BUNDLE',
    title: '水獭掌柜组合包',
    defaultCallback: 'otter://general?scenario=CLOSE_WEB_APPLICATION',
  },
  'advance-template-branded-dinner': {
    slug: 'advance-template-branded-dinner',
    trackingProductName: 'ADVANCE_TEMPLATE_BRANDED_DINNER',
    title: '品牌正餐',
    defaultCallback: buildAdvancedTemplateCallbackURL('advance-template-branded-dinner'),
  },
  'advance-template-tea': {
    slug: 'advance-template-tea',
    trackingProductName: 'ADVANCE_TEMPLATE_TEA',
    title: '连锁茶饮',
    defaultCallback: buildAdvancedTemplateCallbackURL('advance-template-tea'),
  },
  'advance-template-spicy-hot-pot': {
    slug: 'advance-template-spicy-hot-pot',
    trackingProductName: 'ADVANCE_TEMPLATE_SPICY_HOT_POT',
    title: '麻辣烫',
    defaultCallback: buildAdvancedTemplateCallbackURL('advance-template-spicy-hot-pot'),
  },
} as const;

type Products = typeof products;

export type ProductSlug = keyof Products;
export type TrackingName = Products[ProductSlug]['trackingProductName'];

function isValidSlug(slug: string): slug is ProductSlug {
  return slug in products;
}

export function getProduct(slug: string): Products[ProductSlug] | undefined {
  if (isValidSlug(slug)) {
    return products[slug];
  }
  return undefined;
}

export function getTrackingName(slug: string): TrackingName | string {
  return getProduct(slug)?.trackingProductName ?? `unknown product: ${slug}`;
}

export const getCallbackByProduct = (context: TProductContext) => (product: TProduct) => {
  const { defaultCallback } = products[product] ?? {};

  const DEFAULT_CALLBACK = getLinkForOrderFeed();

  const result =
    preventAndroidFromCrashByUnknownDeepLink(
      typeof defaultCallback === 'function' ? defaultCallback(context) : defaultCallback
    ) ?? DEFAULT_CALLBACK;

  return result;
};
