import * as React from 'react';

function SvgOtterLogo(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 38 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M14.175 0h-2.488v2.4h-1.46v2.456h1.46v6.975h2.488V4.856h1.473V2.4h-1.473V0zM20.331 0h-2.488v2.4h-1.459v2.456h1.46v6.975h2.487V4.856h1.473V2.4h-1.473V0zM4.92 2.217C2.181 2.217 0 4.405 0 7.1a4.91 4.91 0 004.92 4.9c2.751 0 4.933-2.217 4.933-4.9.014-2.696-2.182-4.884-4.934-4.884zm0 7.454c-1.404 0-2.558-1.2-2.558-2.57 0-1.355 1.154-2.54 2.557-2.54 1.432 0 2.571 1.185 2.571 2.54.014 1.37-1.14 2.57-2.57 2.57zM26.974 2.217c-2.946 0-4.808 2.259-4.808 4.899 0 2.752 2.154 4.884 4.933 4.884 1.584 0 3.071-.72 3.905-1.92l-1.626-1.327c-.542.72-1.445 1.03-2.279 1.03-1.39 0-2.307-.818-2.57-1.947h7.073c.041-.24.055-.523.055-.777-.014-2.612-1.945-4.842-4.683-4.842zm-2.376 3.91c.347-1.016 1.25-1.694 2.362-1.694 1.153 0 2.126.734 2.265 1.694h-4.627zM36.91 2.357c-2.487 0-4.516 2.062-4.516 4.589v4.87h2.501V6.96c0-1.13.903-2.047 2.015-2.047h.334V2.372h-.334v-.015z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgOtterLogo);
export default ForwardRef;
