import * as React from 'react';

function SvgCullinanExportPdf(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 20 20"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      ref={svgRef}
      {...props}
    >
      <path d="M3.628 13.412a1.127 1.127 0 00-.053.058A2.089 2.089 0 005.083 17a2.073 2.073 0 001.481-.605l.035-.032.03-.036c.528-.63 1.117-1.695 1.621-2.64 1.066-.417 2.14-.819 3.177-1.09 1.006.712 2.338 1.171 3.49 1.171A2.089 2.089 0 0017 11.686a2.089 2.089 0 00-2.082-2.082c-.864 0-2.008.26-2.945.548a9.79 9.79 0 01-1.483-1.95l.073-.223c.135-.404.273-.82.386-1.213.16-.558.29-1.141.29-1.684A2.089 2.089 0 009.157 3a2.089 2.089 0 00-2.082 2.082c0 .594.162 1.217.377 1.806.185.508.422 1.024.664 1.516-.388 1.204-.814 2.431-1.332 3.492l-.411.157c-.41.157-.812.31-1.171.463-.555.235-1.09.5-1.515.842l-.031.025-.028.03zm6.091-8.33a.556.556 0 00-.562-.562.556.556 0 00-.562.562c0 .442.287 1.221.637 2.033.261-.834.487-1.61.487-2.033zm1.137 6.1a11.275 11.275 0 01-1.458-1.854c-.293.895-.604 1.803-.992 2.679a26.715 26.715 0 012.45-.826zm4.624.504a.556.556 0 00-.562-.562c-.526 0-1.427.206-2.253.44.72.398 1.62.684 2.253.684a.556.556 0 00.562-.562zM5.469 15.335c.275-.332.667-.99 1.062-1.695-.815.326-1.532.632-1.861.887a.604.604 0 00-.15.39c0 .316.247.563.562.563a.535.535 0 00.387-.145z" />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgCullinanExportPdf);
export default ForwardRef;
