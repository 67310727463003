import * as React from 'react';

function SvgSparkle(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M11 2L9 7 4 9l5 2 2 5 2-5 5-2-5-2-2-5zM2.5 4.5l1 2.5 1-2.5 2.5-1-2.5-1-1-2.5-1 2.5-2.5 1 2.5 1zM4.25 13l-.75-2-.75 2-1.75.5 1.75.75L3.5 16l.75-1.75L6 13.5 4.25 13z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgSparkle);
export default ForwardRef;
