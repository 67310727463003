import * as React from 'react';

function SvgCold(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 11v3c1.21.91 2 2.37 2 4 0 2.76-2.24 5-5 5s-5-2.24-5-5c0-1.63.79-3.09 2-4v-2H6a1 1 0 110-2h3V4c0-1.66 1.34-3 3-3s3 1.34 3 3v3h3a1 1 0 110 2h-3v2zm-2-3V4c0-.55-.45-1-1-1s-1 .45-1 1v5l2-1z"
        fill="#707070"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgCold);
export default ForwardRef;
