import * as React from 'react';

function SvgStore(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 28 28"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path d="M23.333 4.667H4.667V7h18.666V4.667zM24.5 16.332V14l-1.167-5.834H4.667L3.5 14v2.333h1.167v7h11.666v-7H21v7h2.333v-7H24.5zM14 21H7v-4.667h7V21z" />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgStore);
export default ForwardRef;
