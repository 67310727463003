import * as React from 'react';

function SvgCullinanNavExpand(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path d="M2 16V4h2v12H2zM11.172 9H6v2h5.172l-2.536 2.536 1.414 1.414L15 10l-4.95-4.95-1.414 1.415L11.172 9z" />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgCullinanNavExpand);
export default ForwardRef;
