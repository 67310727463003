import LoadingOverlay from '@/common/components/LoadingOverlay/LoadingOverlay';
import useTitle from '@/hooks/useTitle';
import { Suspense, useEffect } from 'react';
import banner from './assets/store-close-monitor-banner.png';
import { cva } from 'class-variance-authority';
import { OfoIcon } from '@/app-binding/ofo/ofo-icon';
import badge_green from './assets/status-badge-green.svg';
import badge_red from './assets/status-badge-red.svg';
import badge_gray from './assets/status-badge-gray.svg';
import { ofoName } from '@/app-binding/ofo/ofo-name';
import thumbup from './assets/thumbup.png';
import {
  useCompleteNotificationMutation,
  useStoreMonitorDashboard,
  useStoreMonitorHistory,
  useStoreMonitorOfoStatus,
} from './useStoreCloseMonitor';
import { secondsToHours } from './utils';
import moment from 'moment';
import { StoreStatusChange } from './types';
import { trackEvent } from '@/mixpanel';

export function StoreCloseMonitorPage() {
  useTitle('置休监控');
  return (
    <Suspense fallback={<LoadingOverlay />}>
      <div className="pb-safe">
        <StoreCloseMonitor />
      </div>
    </Suspense>
  );
}

function StoreCloseMonitor() {
  const useCompleteNotification = useCompleteNotificationMutation({
    onSuccess() {
      console.log('complete notification success');
    },
  });
  useEffect(() => {
    void useCompleteNotification.mutateAsync();
    trackEvent('page_loaded', {
      page_name: 'STORE_CLOSE_MONITOR_PAGE',
    });
  }, []);
  return (
    <div className="flex flex-col">
      <img src={banner} />
      <div className={sectionTitle()}>店铺运行情况</div>
      <StoreOperationStatusSection />
      <div className={sectionTitle()}>历史置休记录</div>
      <HistorySection />
      <Footer />
    </div>
  );
}

function StoreOperationStatusSection() {
  const useOfoStatusQuery = useStoreMonitorOfoStatus({ suspense: true });
  const ofoChanges = useOfoStatusQuery.data ?? [];
  return (
    <div className="flex flex-col justify-center rounded-2xl border border-slate-200 mx-4 ">
      {ofoChanges.map((change, index) => {
        return <OfoStatusCellContent key={index} storeStatusChange={change} />;
      })}
    </div>
  );
}

function OfoStatusCellContent({ storeStatusChange }: { storeStatusChange: StoreStatusChange }) {
  const { ofoSlug, storeOpen, actionSource, reason, createdAt } = storeStatusChange;
  const titleSuffix = storeOpen ? '运行中' : storeStatusChange.actionSource === 'OFO' ? '置休中' : '闭店中';
  const badge = storeOpen ? badge_green : storeStatusChange.actionSource === 'OFO' ? badge_red : badge_gray;
  return (
    <div className="flex p-5 gap-3 items-center border-b border-slate-200 last-of-type:border-b-0">
      <OfoIcon ofo={ofoSlug} />
      <div className="flex flex-col flex-grow">
        <p className={cellTitle()}>
          {ofoName(ofoSlug)}
          {titleSuffix}
        </p>
        {!storeOpen && actionSource === 'OFO' && reason ? (
          <p className="text-red-600 text-[14px] leading-5">
            {moment(createdAt).format('HH:mm')}
            &nbsp;
            {reason}
          </p>
        ) : null}
      </div>
      <img src={badge} />
    </div>
  );
}

function HistorySection() {
  return (
    <div className="flex flex-col gap-3">
      <Dashboard />
      <HistoryList />
    </div>
  );
}

const dashboardItemContainer = cva('flex flex-col justify-start gap-1 flex-grow');
const dashBoardItemTitle = cva('text-[14px] leading-[20px] text-[#525252]');
const dashBoardItemTimesText = cva('text-[16px] leading-[18px] text-[#525252]');
const dashBoardItemTimesNumber = cva('text-[18px] leading-[22px] font-semibold text-[#000] mr-1');

function Dashboard() {
  const dashboardQuery = useStoreMonitorDashboard({ suspense: true });
  const summaries = dashboardQuery.data ?? [];
  const totalTimes = summaries.reduce((acc, cur) => acc + cur.closeCount, 0);
  const totalHours = secondsToHours(summaries.reduce((acc, cur) => acc + cur.closeTimeInSecond, 0));
  return (
    <div className="flex mx-4 rounded-2xl border border-slate-200 p-4 gap-3">
      <div className={dashboardItemContainer()}>
        <p className={dashBoardItemTitle()}>累计置休</p>
        <p className={dashBoardItemTimesText()}>
          <span className={dashBoardItemTimesNumber()}>{totalTimes}</span>次
        </p>
      </div>
      <div className="border-l border-slate-200"></div>
      <div className={dashboardItemContainer()}>
        <p className={dashBoardItemTitle()}>累计总时长</p>
        <p className={dashBoardItemTimesText()}>
          <span className={dashBoardItemTimesNumber()}>{totalHours}</span>小时
        </p>
      </div>
    </div>
  );
}

function HistoryList() {
  const useHistoryQuery = useStoreMonitorHistory({ suspense: true });
  const res = useHistoryQuery.data ?? [];
  const histories = res.filter((item) => !item.storeOpen);
  if (histories.length === 0) {
    return null;
  }
  return (
    <div className="flex flex-col justify-center rounded-2xl border border-slate-200 mx-4 ">
      {histories.map((history, index) => {
        return <HistoryCellContent key={index} change={history} />;
      })}
    </div>
  );
}

function HistoryCellContent({ change }: { change: StoreStatusChange }) {
  const { ofoSlug, reason, createdAt: date } = change;
  return (
    <div className="flex p-5 gap-3 items-center border-b border-slate-200 last-of-type:border-b-0">
      <OfoIcon ofo={ofoSlug} />
      <div className="flex flex-col gap-0 flex-grow">
        <p className={cellTitle()}>{moment(date).format('YYYY/MM/DD')}</p>
        <p>原因：{reason}</p>
      </div>
      <p className="self-start">{moment(date).format('HH:mm')}</p>
    </div>
  );
}

function Footer() {
  return (
    <div className="flex justify-start items-start p-4 my-3 mx-4 bg-neutral-100  rounded-2xl gap-3">
      <img src={thumbup} />
      <p className="text-[12px] leading-[14px] flex-grow">
        开通水獭掌柜<span className="font-semibold">店铺置休监控</span>
        功能，一旦店铺被平台处罚置休，第一时间获得提醒，有效减少订单流失和营业损失
      </p>
    </div>
  );
}

const sectionTitle = cva(
  'mx-[16px] mt-[32px] mb-[16px] font-sans text-[20px] font-semibold leading-[24px] tracking-[0.4px]'
);

const cellTitle = cva('font-semibold text-[16px] leading-[20px] flex-grow');
