import { ROARR, Roarr } from 'roarr';
import { createLogWriter } from '@roarr/browser-log-writer';
import { aliyunTransport } from './transports';

ROARR.write = (message: string) => {
  aliyunTransport(message);
  createLogWriter()(message);
};

type CustomContext = Record<string, string | number>;

export function createLoggerFactory(application: string, appLevelContext?: CustomContext) {
  return function createLogger(namespace: string, customContext?: CustomContext) {
    return Roarr.child({
      ...appLevelContext,
      ...customContext,
      application,
      namespace,
      path: window.location.pathname,
    });
  };
}
