import React from 'react';
import styled from 'styled-components';

import { color, Overlay } from '@css/op-components';

interface MobOverlayDialogProps {
  onClose: () => void;
}

export const MobOverlayDialog: React.FC<MobOverlayDialogProps> = ({ onClose, children }) => {
  return (
    <Overlay>
      <WrapperDiv>
        <NonContentDiv onClick={onClose} />
        <ContentDiv>{children}</ContentDiv>
      </WrapperDiv>
    </Overlay>
  );
};

const WrapperDiv = styled.div`
  position: absolute;
  width: 100vw;
  height: 100vh;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
`;

const NonContentDiv = styled.div`
  min-height: 1px;
  width: 100%;
  flex: auto;
`;

const ContentDiv = styled.div`
  width: 100%;
  ${color.bg('white')}
  font-family: "PingFang SC";
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
`;
