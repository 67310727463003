import * as React from 'react';

function SvgMailbox(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 13 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M.5 5.45v5.8c0 .414.336.75.75.75h10.5a.75.75 0 00.75-.75v-5.8a.75.75 0 00-.099-.373L9.716.377A.75.75 0 009.065 0h-5.13a.75.75 0 00-.65.378l-2.686 4.7a.75.75 0 00-.099.371zm10.875 5.425h-9.75V6.562h2.063v2.625h5.624V6.563h2.063v4.312zm-.064-5.438H8.188v2.625H4.812V5.438H1.69l2.464-4.313h4.694l2.464 4.313z"
        fill="#112232"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgMailbox);
export default ForwardRef;
