import React, { useState, useEffect } from 'react';

import './LoadingOverlay.scss';
import spinner from './spinner.png';

const LoadingOverlay: React.FC<{ delay?: number }> = ({ delay = 0 }) => {
  const [renderContent, setRenderContent] = useState(false);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setRenderContent(true);
    }, delay);

    return () => clearTimeout(timeoutId);
  }, [delay]);

  return renderContent ? (
    <div className="loading-overlay">
      <div className="loading-overlay-body">
        <div className="spinner">
          <img src={spinner} alt="Spinner" />
        </div>
      </div>
    </div>
  ) : null;
};

export default LoadingOverlay;
