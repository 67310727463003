import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
body {
  font-family: 'PingFang SC';
  background-color: #fff;
  box-sizing: border-box;
  margin: 0;
}
`;

export { GlobalStyle };
