import templatePreviewDinner from './assets/template-preview-dinner.png';
import templatePreviewSpicyHotpot from './assets/template-preview-spicy-hotpot.png';
import templatePreviewTea from './assets/template-preview-tea.png';

export const templateNameToProductNameMap: Record<string, string> = {
  品牌正餐: 'advance-template-branded-dinner',
  连锁茶饮: 'advance-template-tea',
  麻辣烫: 'advance-template-spicy-hot-pot',
};

export const templateNameToFeatureMap: Record<string, string> = {
  品牌正餐: 'advance.template.branded_dinner',
  连锁茶饮: 'advance.template.tea',
  麻辣烫: 'advance.template.spicy_hot_pot',
};

export const templatePreviewMockImageMap: Record<string, string> = {
  品牌正餐: templatePreviewDinner,
  麻辣烫: templatePreviewSpicyHotpot,
  连锁茶饮: templatePreviewTea,
};
