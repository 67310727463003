import * as React from 'react';

function SvgAppIconDashboard(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <g fill="currentColor">
        <path d="M15 7.707l-5 5-2-2-3.646 3.647-.708-.707L8 9.293l2 2L14.293 7H12.5V6H16v3.5h-1V7.707z" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2 0a2 2 0 00-2 2v16a2 2 0 002 2h16a2 2 0 002-2V2a2 2 0 00-2-2H2zm16 1H2a1 1 0 00-1 1v16a1 1 0 001 1h16a1 1 0 001-1V2a1 1 0 00-1-1z"
        />
      </g>
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgAppIconDashboard);
export default ForwardRef;
