import * as React from 'react';

function SvgCullinanUser(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M7 7c1.934 0 3.5-1.566 3.5-3.5S8.934 0 7 0a3.499 3.499 0 00-3.5 3.5C3.5 5.434 5.066 7 7 7zm0 1.75c-2.336 0-7 1.172-7 3.5V14h14v-1.75c0-2.328-4.664-3.5-7-3.5z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgCullinanUser);
export default ForwardRef;
