import * as React from 'react';

function SvgCoupon(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path d="M19.41 9.41L10.58.58C10.21.21 9.7 0 9.17 0H2C.9 0 0 .9 0 2v7.17c0 .53.21 1.04.59 1.41l8.83 8.83c.78.78 2.05.78 2.83 0l7.17-7.17c.78-.78.78-2.04-.01-2.83zM4.5 6C3.67 6 3 5.33 3 4.5S3.67 3 4.5 3 6 3.67 6 4.5 5.33 6 4.5 6z" />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgCoupon);
export default ForwardRef;
