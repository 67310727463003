import * as React from 'react';

function SvgMenus(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <rect width={24} height={24} rx={6} fill="#FD8947" />
      <path d="M16.538 6.256a1.522 1.522 0 013.044 0v11.57a1.522 1.522 0 11-3.044 0V6.256z" fill="#fff" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.026 6.38c-.278.672-.278 1.523-.278 3.226v4.871c0 1.703 0 2.554.278 3.225a3.654 3.654 0 001.977 1.977c.672.279 1.523.279 3.225.279 1.703 0 2.554 0 3.226-.279a3.654 3.654 0 001.977-1.977c.278-.671.278-1.523.278-3.225V9.606c0-1.703 0-2.554-.278-3.225a3.654 3.654 0 00-1.977-1.978c-.672-.278-1.523-.278-3.226-.278-1.702 0-2.553 0-3.225.278a3.654 3.654 0 00-1.977 1.978zm5.203.79a2.436 2.436 0 100 4.871 2.436 2.436 0 000-4.871z"
        fill="#fff"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgMenus);
export default ForwardRef;
