export function cmpVersions(a: string, b: string): number {
  let i, diff;
  const regExStrip0 = /(\.0+)+$/;
  const segmentsA = a.replace(regExStrip0, '').split('.');
  const segmentsB = b.replace(regExStrip0, '').split('.');
  const l = Math.min(segmentsA.length, segmentsB.length);

  for (i = 0; i < l; i++) {
    diff = parseInt(segmentsA[i], 10) - parseInt(segmentsB[i], 10);
    if (diff) {
      return diff;
    }
  }
  return segmentsA.length - segmentsB.length;
}

export type Platform = 'IOS' | 'ANDROID' | 'MINI_PROGRAM' | 'UNKNOWN';

export function getPlatform(): Platform {
  const ua = window.navigator.userAgent;
  if (!ua) return 'UNKNOWN';

  if (ua.toLowerCase().includes('ios')) {
    return 'IOS';
  }

  if (ua.toLowerCase().includes('android')) {
    return 'ANDROID';
  }

  if (ua.includes('miniProgram')) {
    return 'MINI_PROGRAM';
  }

  return 'UNKNOWN';
}

export function getVersion() {
  const ua = window.navigator.userAgent;

  const matched =
    ua
      .match(/\bOtterApp.*/)?.[0]
      ?.replace(/\s/g, '')
      ?.split('/') ?? [];
  const version = matched.length >= 3 ? matched[2] : '0.0.0';

  return version;
}

interface VersionSpec {
  iOS: `${number}.${number}.${number}`;
  android: `${number}.${number}.${number}`;
  defaultForH5?: boolean;
}

export function isVersionAfter({ iOS, android }: VersionSpec) {
  const version = getVersion();
  const platform = getPlatform();

  if (platform === 'IOS') {
    return cmpVersions(version, iOS) >= 0;
  }

  if (platform === 'ANDROID') {
    return cmpVersions(version, android) >= 0;
  }

  return true;
}

export function isVersionBefore({ iOS, android, defaultForH5 }: VersionSpec) {
  const version = getVersion();
  const platform = getPlatform();

  if (platform === 'IOS') {
    return cmpVersions(version, iOS) < 0;
  }

  if (platform === 'ANDROID') {
    return cmpVersions(version, android) < 0;
  }

  return defaultForH5 ?? true;
}
