import * as React from 'react';

function SvgCullinanCheckOutlined(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path d="M9.563 12.678l3.963-3.964-.928-.928-3.036 3.036L7.84 9.098l-.928.928 2.652 2.652z" fill="currentColor" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17 10a7 7 0 11-14 0 7 7 0 0114 0zm-1.313 0a5.688 5.688 0 11-11.375 0 5.688 5.688 0 0111.375 0z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgCullinanCheckOutlined);
export default ForwardRef;
