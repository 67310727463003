import { useParams } from 'react-router';

import LoadingOverlay from '@/common/components/LoadingOverlay/LoadingOverlay';
import { createLogger } from '@/logger';
import { Suspense, useEffect } from 'react';

import { createQuery } from 'react-query-kit';
import { routeExecutor } from './routes';

const logger = createLogger('landing page');

const useLandingRoute = createQuery<string, { productName: string }>({
  primaryKey: 'landing-route',
  queryFn: async function ({ queryKey: [, { productName }] }) {
    logger.info({ productName }, 'landing route params');
    return await routeExecutor(productName);
  },
});

export function SplashLandingPage() {
  return (
    <Suspense fallback={<LoadingOverlay />}>
      <LandingContent />
    </Suspense>
  );
}

function LandingContent() {
  const { productName } = useParams<{ productName: string }>();

  const routeQuery = useLandingRoute({ variables: { productName }, suspense: true });

  useEffect(
    function () {
      if (routeQuery.isSuccess) {
        window.location.replace(routeQuery.data);
      } else {
        window.location.replace(`/iap/${productName}`);
      }
    },
    [routeQuery.data]
  );

  return <LoadingOverlay />;
}
