import ElemeIcon from '@/common/svgs/ElemeIcon.svg';
import MeituanIcon from '@/common/svgs/MeituanIcon.svg';
import { OfoType } from '@/hooks/use-ofo-status';

interface TOfoSpec {
  brandName: string;
  icon: React.ElementType;
}

export const ofos: Record<OfoType, TOfoSpec> = {
  /***
   * 美团接单授权
   */
  meituan: {
    brandName: '美团',
    icon: MeituanIcon,
  },

  /***
   * 美团非接单授权
   */
  'meituan-admin': {
    brandName: '美团',
    icon: MeituanIcon,
  },

  /***
   * 饿了么授权
   */
  eleme: {
    brandName: '饿了么',
    icon: ElemeIcon,
  },
} as const;

function isValidOfo(ofo: string): ofo is OfoType {
  return ofo in ofos;
}

export function getOfoBrandName(ofo: string): string | undefined {
  if (isValidOfo(ofo)) {
    return ofos[ofo].brandName;
  }

  return undefined;
}
