import { fetchVasStoreId } from '@/hooks/use-vas-store-id';
import { spawnHttpClient, urqlClient } from '@/http-clients';
import { createMutation, createQuery } from 'react-query-kit';
import {
  StoreStatusChange,
  StoreStatusChangeResponse,
  StoreStatusChangeSummariesResponse,
  StoreStatusChangeSummary,
} from './types';
import { gql } from 'graphql-request';
import { getUserId } from '@/hooks/use-user-id';

function fetchStoreMonitorHistory(storeId: string): Promise<StoreStatusChangeResponse> {
  return spawnHttpClient().get<StoreStatusChangeResponse>({
    path: `/stores/store_status_history?store_id=${storeId}&ofo=all`,
    method: 'GET',
    requestParams: {},
  });
}

export const useStoreMonitorHistory = createQuery<StoreStatusChange[], void, Error>({
  primaryKey: 'storeMonitorHistory',
  queryFn: async function () {
    const storeId = await fetchVasStoreId();
    if (!storeId) {
      throw Error('store id not found');
    }
    const res = await fetchStoreMonitorHistory(storeId);
    return res.storeStatusChanges ?? [];
  },
});

function fetchStoreMonitorDashboard(storeId: string): Promise<StoreStatusChangeSummariesResponse> {
  return spawnHttpClient().get<StoreStatusChangeSummariesResponse>({
    path: `/stores/store_status_summary?store_id=${storeId}&ofo=all`,
    method: 'GET',
    requestParams: {},
  });
}

export const useStoreMonitorDashboard = createQuery<StoreStatusChangeSummary[], void, Error>({
  primaryKey: 'storeMonitorDashboard',
  queryFn: async function () {
    const storeId = await fetchVasStoreId();
    if (!storeId) {
      throw Error('store id not found');
    }
    const res = await fetchStoreMonitorDashboard(storeId);
    return res.storeStatusChangeSummaries;
  },
});

function fetchOfoStatus(storeId: string): Promise<StoreStatusChangeResponse> {
  return spawnHttpClient().get({
    path: `/stores/store_status?store_id=${storeId}&ofo=all`,
    method: 'GET',
    requestParams: {},
  });
}
export const useStoreMonitorOfoStatus = createQuery<StoreStatusChange[], void, Error>({
  primaryKey: 'storeMonitorOfoStatus',
  queryFn: async function () {
    const storeId = await fetchVasStoreId();
    if (!storeId) {
      throw Error('store id not found');
    }
    const res = await fetchOfoStatus(storeId);
    return res.storeStatusChanges;
  },
});

const completeNotificationMutation = gql`
  mutation completeNotification($userId: String!, $event: String!) {
    completeNotification(input: { entity: { entityId: $userId, entityType: USER }, event: $event }) {
      success
      data
    }
  }
`;

export const useCompleteNotificationMutation = createMutation<
  void,
  { event: 'lutrahub_ofo_controlled_store_closure' } | void,
  Error
>({
  mutationFn: async function ({ event } = { event: 'lutrahub_ofo_controlled_store_closure' }) {
    const userId = await getUserId();
    if (!userId) {
      throw Error('user id not found');
    }
    const res = await urqlClient
      .mutation<{
        completeNotification: {
          success: boolean;
          data: string;
        };
      }>(completeNotificationMutation, { userId, event })
      .toPromise();
    const success = res.data?.completeNotification.success ?? false;
    if (!success) {
      throw new Error('complete notification failed');
    }
    return void 0;
  },
});
