import React, { ReactElement, useContext, useEffect } from 'react';

import { moneyToNumber, subtractFromMoney } from '@css/currency';
import { isAppVersionAllowCouponFeature } from '../../utils/isAppVersionAllowCouponFeature';
import { IapContext } from '../IapContext';
import { OriginalPrice } from '../ui';
import type { DisplayPlan } from '../types';
import { getCurrentDuration, getUnitDuration } from '../utils';
import { useCoupons } from '@/features/coupon/hooks/use-redeemable-coupons';
import { Plans, PlanItem, ParaphText, EmphasisText, ErrorMessage, CouponBanner } from './ui';
import { usePlanSelector } from '@/store/plan-slice';
import { useProduct } from '../use-product';
import { override } from '@/features/payment/override';
import { createLogger } from '@/logger';

const logger = createLogger('iap:plan-selector-ng');

export function PlanSelector({
  productName,
  plansFilterFn = () => true,
}: {
  productName: string;
  plansFilterFn?: (plan: DisplayPlan) => boolean;
}) {
  const couponQuery = useCoupons('REDEEMABLE');
  const productQuery = useProduct(productName);

  const { selectedCoupon, selectedPlan, selectPlan: selectPlanLegacy } = useContext(IapContext);

  const currentPlan = usePlanSelector((state) => state.currentPlan);
  const selectPlan = usePlanSelector((state) => state.selectPlan);

  const selectedPlanHasCoupon = !!selectedCoupon && selectedCoupon.applicablePlans.includes(selectedPlan?.name ?? '');
  const selectedPlanDiscountValue = moneyToNumber(selectedCoupon?.value);

  const init = usePlanSelector((state) => state.init);

  useEffect(
    function () {
      if (couponQuery.isSuccess && productQuery.isSuccess) {
        init(productQuery.data.filter(plansFilterFn), couponQuery.data);
      }
    },
    [couponQuery.isSuccess, productQuery.isSuccess]
  );

  if (productQuery.isSuccess) {
    const plans = productQuery.data.filter(plansFilterFn);
    return (
      <>
        <Plans data-testid="plan-selector-ng">
          {plans.map((plan) => {
            const isSelectedPlan = plan.name === selectedPlan?.name;
            let appliedCouponDiscount = moneyToNumber(plan.bestValueCoupon?.value);

            if (isSelectedPlan && selectedPlanHasCoupon) {
              appliedCouponDiscount = selectedPlanDiscountValue;
            }

            const displayPrice = appliedCouponDiscount
              ? Math.max(moneyToNumber(subtractFromMoney(plan.displayPrice, appliedCouponDiscount)), 0)
              : moneyToNumber(plan.displayPrice);

            const unitDuration = getUnitDuration(plan.duration);

            return (
              <PlanItem
                selected={plan.name === currentPlan}
                data-plan-name={plan.name}
                data-selected={plan.name === currentPlan}
                key={plan.name}
                onClick={() => {
                  logger.debug({ selectedPlan: plan.name }, 'select plan now');

                  selectPlan(plan.name);
                  selectPlanLegacy(plan.name);
                }}
              >
                <PlanCouponBanner plan={plan} />
                <PlanTitle plan={plan} />

                <EmphasisText style={{ marginTop: 10, fontWeight: 900 }}>
                  <span style={{ fontSize: 14, lineHeight: '32px', marginRight: 2 }}>¥</span>
                  {displayPrice}
                </EmphasisText>

                <PlanNote
                  planName={plan.name}
                  fallback={
                    <>
                      {moneyToNumber(plan.basePrice) ? (
                        <OriginalPrice strikeThrough={moneyToNumber(plan.basePrice) > 0}>
                          {moneyToNumber(plan.basePrice) > displayPrice ? (
                            <>
                              {[`￥${moneyToNumber(plan.basePrice).toFixed(2)}`, unitDuration.unit]
                                .filter(Boolean)
                                .join(' / ')}
                            </>
                          ) : null}
                        </OriginalPrice>
                      ) : (
                        <OriginalPrice>
                          {unitDuration.value > 1
                            ? [
                                `￥${(moneyToNumber(plan.unitPrice) / unitDuration.value).toFixed(2)}`,
                                unitDuration.unit,
                              ]
                                .filter(Boolean)
                                .join(' / ')
                            : null}
                        </OriginalPrice>
                      )}
                    </>
                  }
                />
              </PlanItem>
            );
          })}
        </Plans>
        <UpgradePrompt
          selectedPlanDiscountValue={selectedPlanDiscountValue}
          selectedPlanHasCoupon={selectedPlanHasCoupon}
        />
      </>
    );
  }
  return null;
}

function PlanTitle({ plan }: { plan: DisplayPlan }) {
  const localOverride = override[plan.name]?.title;

  const periodText = localOverride ?? plan.localizedPlanShortName ?? `${plan.duration.months}个月`;
  return <ParaphText style={{ marginTop: 32, color: '#000', fontWeight: 600 }}>{periodText}</ParaphText>;
}

function PlanNote({ planName, fallback }: { planName: string; fallback: ReactElement }) {
  const localOverride = override[planName]?.note;

  if (typeof localOverride !== 'undefined') {
    return <span className="text-xs text-center text-neutral-400">{localOverride}</span>;
  }

  return fallback;
}

function UpgradePrompt({
  selectedPlanHasCoupon,
  selectedPlanDiscountValue,
}: {
  selectedPlanHasCoupon: boolean;
  selectedPlanDiscountValue: number;
}) {
  const isCouponSupported = isAppVersionAllowCouponFeature();

  if (selectedPlanHasCoupon && selectedPlanDiscountValue && !isCouponSupported) {
    return (
      <ErrorMessage>
        <svg
          style={{ height: 20, width: 20, marginRight: 10, flexShrink: 0 }}
          width="20"
          height="20"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.99992 1.33331C4.31992 1.33331 1.33325 4.31998 1.33325 7.99998C1.33325 11.68 4.31992 14.6666 7.99992 14.6666C11.6799 14.6666 14.6666 11.68 14.6666 7.99998C14.6666 4.31998 11.6799 1.33331 7.99992 1.33331ZM8.66658 11.3333H7.33325V9.99998H8.66658V11.3333ZM8.66658 8.66665H7.33325V4.66665H8.66658V8.66665Z"
            fill="#C4232B"
          />
        </svg>
        <div>有可用优惠券，请更新水獭掌柜App后使用</div>
      </ErrorMessage>
    );
  }

  return null;
}

function PlanCouponBanner({ plan }: { plan: DisplayPlan }) {
  const localOverride = override[plan.name]?.banner;
  if (localOverride) {
    return <CouponBanner>{localOverride}</CouponBanner>;
  }

  if (plan.banner) {
    return <CouponBanner>{plan.banner}</CouponBanner>;
  }

  if (getCurrentDuration(plan) === 30) {
    return <CouponBanner>先试试</CouponBanner>;
  }

  return null;
}
