import React, { useState } from 'react';
import { AutoRenewableTipText } from './ui';
import type { DisplayPlan } from './types';
import { usePlanSelector } from '@/store/plan-slice';
import { usePlan } from './use-product';
import { useParams } from 'react-router';
import { BottomSheet } from '@/common/components/bottom-sheet';
import { RecommendTable } from '@/pages/product/recommend-table';
import { button } from '@/cva/button';
import { LaunchPaymentButton } from '@/next/components/launch-payment-button';
import { planNameIsOfoApiBundle } from '@/features/plan/utils';
import { EnsureInViewport } from '@/components/ensure-in-viewport';

export function PlanNote({ plan }: { plan?: DisplayPlan }) {
  if (!plan) return null;
  return (
    <>
      {plan.description ? (
        <AutoRenewableTipText>· {plan.description}</AutoRenewableTipText>
      ) : plan?.autoRenewable ? (
        <AutoRenewableTipText>
          {plan?.firstDuration ? (
            <>
              · {plan?.firstDuration.days}天后每个月
              <b>自动续费 {plan.unitPriceNumber} 元</b>，可随时取消
            </>
          ) : (
            <>
              · 每个月<b>自动续费 {plan.unitPriceNumber} 元</b>，可随时取消
            </>
          )}
        </AutoRenewableTipText>
      ) : null}
    </>
  );
}

export function PlanNoteNg() {
  const currentPlan = usePlanSelector((state) => state.currentPlan);
  const { productName } = useParams<{ productName: string }>();
  const planQuery = usePlan(productName, currentPlan);

  const [showBottomSheet, setShowBottomSheet] = useState(false);
  const noteOverride: Record<PlanName, React.ReactNode> = {
    'qrco.advanced.rent.0d.store.chn.v0': (
      <>
        <AutoRenewableTipText>
          <>· 扫码点餐高级版和1台psp588一体机</>
        </AutoRenewableTipText>
        <AutoRenewableTipText>
          <>· 若开通后6个月内，达到目标流水金额达到即可返现499元</>
        </AutoRenewableTipText>
      </>
    ),
  };
  if (planQuery.isSuccess) {
    if (noteOverride[currentPlan ?? '']) {
      return <>{noteOverride[currentPlan ?? '']}</>;
    }
    return (
      <>
        {!planNameIsOfoApiBundle(currentPlan ?? '') ? (
          <AutoRenewableTipText>
            {planQuery.data.description ? (
              <>· {planQuery.data.description}</>
            ) : planQuery.data?.autoRenewable ? (
              <>
                {planQuery.data?.firstDuration ? (
                  <>
                    · {planQuery.data?.firstDuration.days}天后每个月
                    <b>自动续费 {planQuery.data.unitPriceNumber} 元</b>，可随时取消
                  </>
                ) : (
                  <>
                    · 每个月<b>自动续费 {planQuery.data.unitPriceNumber} 元</b>，可随时取消
                  </>
                )}
              </>
            ) : null}
            <br />
          </AutoRenewableTipText>
        ) : null}

        {productName === 'ofoapi' ? (
          <>
            {currentPlan && planNameIsOfoApiBundle(currentPlan) ? (
              <>
                <AutoRenewableTipText style={{ paddingTop: 0 }}>
                  · 之后每月{planQuery.data.unitPriceNumber}元，可随时取消{' '}
                  <span onClick={() => setShowBottomSheet(true)} className="text-primary pl-2 text-underline">
                    查看组合包详情
                  </span>
                </AutoRenewableTipText>
                <BottomSheet
                  isOpen={showBottomSheet}
                  handleClose={() => setShowBottomSheet(false)}
                  title={
                    <h4 className="text-xl mb-4 font-semibold text-left">
                      {planQuery.data?.localizedPlanShortName}说明
                    </h4>
                  }
                >
                  <div className="pb-safe">
                    <SheetContentForOfoAPIBundle planName={currentPlan} />
                    <LaunchPaymentButton planName={currentPlan}>
                      <span>立即订阅</span>
                    </LaunchPaymentButton>
                  </div>
                </BottomSheet>
              </>
            ) : (
              <>
                <AutoRenewableTipText style={{ paddingTop: 0 }}>
                  · 请基于您的实际情况选择方案{' '}
                  <span onClick={() => setShowBottomSheet(true)} className="text-primary pl-2 text-underline">
                    查看参考方案
                  </span>
                </AutoRenewableTipText>
                <BottomSheet
                  isOpen={showBottomSheet}
                  handleClose={() => setShowBottomSheet(false)}
                  title={<h4 className="text-xl mb-4 font-semibold text-left">单量包购买参考</h4>}
                >
                  <EnsureInViewport name="ofoapi-intro-popup">
                    <div className="pb-safe">
                      <RecommendTable />
                      <button
                        className={button({ intent: 'primary', size: 'full' })}
                        onClick={() => {
                          setShowBottomSheet(false);
                        }}
                      >
                        好的
                      </button>
                    </div>
                  </EnsureInViewport>
                </BottomSheet>
              </>
            )}
          </>
        ) : null}
      </>
    );
  }
  return null;
}

interface SheetProps {
  title: string;
  descriptions: string[];
}
type PlanName = string;
const sheetOverride: Record<PlanName, SheetProps> = {
  'vas.ofoapibundle.xcd.recurring.p1m.facility.chn.v0': {
    title: '聚合发圈宝组合包权益说明:',
    descriptions: [
      '聚合发圈宝组合包订阅成功后，聚合发圈宝和不限量外卖接单服务有效期即时生效；',
      '原接单服务的在期用户直接在原剩余有效期时长上叠加，组合包续费期间，优先使用组合包内的不限量接单服务权益；新订阅用户有效期则自开通日起算。',
    ],
  },

  'vas.ofoapibundle.order.recurring.p1m.facility.chn.v0': {
    title: '接单大师组合包权益说明:',
    descriptions: [
      '接单大师组合包订阅成功后，接单大师和不限量外卖接单服务有效期即时生效；',
      '原接单服务的在期用户直接在原剩余有效期时长上叠加，组合包续费期间，优先使用组合包内的不限量接单服务权益；新订阅用户有效期则自开通日起算。',
    ],
  },

  'vas.ofoapibundle.operate.recurring.p1m.facility.chn.v0': {
    title: '运营大师组合包权益说明:',
    descriptions: [
      '运营大师组合包订阅成功后，运营大师和不限量外卖接单服务有效期即时生效；',
      '原接单服务的在期用户直接在原剩余有效期时长上叠加，组合包续费期间，优先使用组合包内的不限量接单服务权益；新订阅用户有效期则自开通日起算。',
    ],
  },
};

export function SheetContentForOfoAPIBundle({ planName }: { planName: string }) {
  const { title, descriptions } = sheetOverride[planName];
  return (
    <div className="border-0 w-full h-full  mb-[36px] ">
      <div className="text-black font-normal text-[16px] leading-5.5">
        {title}
        <ul className="list-disc pl-5">
          {descriptions.map((description) => (
            <li key={description}>{description}</li>
          ))}
        </ul>
      </div>
    </div>
  );
}
