import * as React from 'react';

function SvgAppIconReports(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <g fill="currentColor">
        <path d="M6 10h8V9H6v1zM12 14H6v-1h6v1z" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3 2h2.914l.243-.133a1 1 0 00.482-.602l.154-.54A1 1 0 017.754 0h4.492a1 1 0 01.961.725l.154.54a1 1 0 00.482.602l.243.133H17a2 2 0 012 2v14a2 2 0 01-2 2H3a2 2 0 01-2-2V4a2 2 0 012-2zm12 1.093A.988.988 0 0014.996 3H17a1 1 0 011 1v14a1 1 0 01-1 1H3a1 1 0 01-1-1V4a1 1 0 011-1h2.004A.999.999 0 015 3.093V4a1 1 0 001 1h8a1 1 0 001-1v-.907zM12.246 1H7.754l-.154.54a2 2 0 01-.963 1.205L6 3.093V4h8v-.907l-.637-.348a2 2 0 01-.963-1.206L12.246 1z"
        />
      </g>
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgAppIconReports);
export default ForwardRef;
