import * as React from 'react';

function SvgExclamationTriangleFilled(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 3.428l8 13H2l8-13zm.64 5.063H9.36v3.5h1.28v-3.5zm-1.28 4.31h1.28v1.28H9.36v-1.28z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgExclamationTriangleFilled);
export default ForwardRef;
