import { convertTagsToDictionary } from '@/api-binding/coupon';
import { isUpsellCoupon } from '@/features/coupon/hooks/use-redeemable-coupons';
import { trackEvent } from '@/mixpanel';
import queryString from 'qs';
import { SuccessEventType } from '../../PrinterTemplate/types';
import { claimSuccessCouponMutation } from '../../PrinterTemplate/useClaimCoupon';
import type { LandingRule } from './types';
import { routeUpsellByCoupon } from './helper';

type ProductSuccessEventMap = Record<string, SuccessEventType>;

export function buildClaimCouponRule(productName: string): LandingRule {
  const { utm_source } = queryString.parse(window.location.search, { ignoreQueryPrefix: true }) as {
    utm_source: string;
  };

  const ClaimSuccessEventMap: Record<string, ProductSuccessEventMap> = {
    splash: {
      vasbundleorder: SuccessEventType.splashScreen_bundleOrder,
      vasbundleprotection: SuccessEventType.splashScreen_bundleProtection,
    },
    wechat: {
      vasbundleorder: SuccessEventType.claim_only_bundle_order,
      vasbundleprotection: SuccessEventType.claim_only_protection,
    },
    advanced_template: {
      vasbundleorder: SuccessEventType.AdvancedTemplate,
    },
  };

  const eventType = ClaimSuccessEventMap[utm_source]?.[productName];
  return {
    checker: function () {
      return typeof eventType === 'undefined';
    },
    otherwise: async function () {
      try {
        const claimedCouponResponse = await claimSuccessCouponMutation({ successEventType: eventType });
        const claimedCoupon = claimedCouponResponse?.claimedCoupons[0];
        const couponCode = claimedCoupon?.code;
        const trackingTags = claimedCouponResponse?.couponTags.find(
          (couponTag) => couponTag.couponId === claimedCoupon?.id
        )?.tags;
        const trackingParams = convertTagsToDictionary(trackingTags ?? []);
        if (typeof claimedCoupon !== 'undefined' && isUpsellCoupon(claimedCoupon) && typeof couponCode === 'string') {
          trackEvent('upselling_ads_requested', {
            ...trackingParams,
            result: 'success',
            from_page: 'ASSETS_LANDING_PAGE',
            product_name: productName,
          });
          window.location.replace(routeUpsellByCoupon(claimedCoupon, productName));
        }
      } catch {
        trackEvent('upselling_ads_requested', {
          result: 'failure',
          from_page: 'ASSETS_LANDING_PAGE',
          product_name: productName,
        });
        const { purchase_origin, trace_id } = queryString.parse(window.location.search, {
          ignoreQueryPrefix: true,
        }) as {
          purchase_origin: string;
          trace_id: string;
        };
        const nextHop = `/iap/${productName}?${queryString.stringify({ purchase_origin, trace_id })}`;
        window.location.replace(nextHop);
      }
    },
  };
}
