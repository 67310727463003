import * as React from 'react';

function SvgAccount(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 28 28"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path d="M3.5 5.833v16.334A2.333 2.333 0 005.833 24.5h16.334a2.34 2.34 0 002.333-2.333V5.833A2.34 2.34 0 0022.167 3.5H5.833A2.333 2.333 0 003.5 5.833zm14 4.667c0 1.937-1.563 3.5-3.5 3.5a3.495 3.495 0 01-3.5-3.5C10.5 8.563 12.063 7 14 7s3.5 1.563 3.5 3.5zM7 19.833c0-2.333 4.667-3.616 7-3.616s7 1.283 7 3.616V21H7v-1.167z" />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgAccount);
export default ForwardRef;
