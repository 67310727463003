import * as React from 'react';

function SvgCullinanCaretUp(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path d="M14 12l-4-4-4 4h8z" fill="currentColor" />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgCullinanCaretUp);
export default ForwardRef;
