import { cva } from 'class-variance-authority';

export const typography = cva('font-sans subpixel-antialiased	', {
  variants: {
    variant: {
      emphasis: 'font-semibold text-xl',
      popupBanner: 'w-full h-auto object-cover mb-6',
      popupTitle: 'text-black text-center text-[22px] font-medium mb-3',
      popupDescription: 'text-center text-gray-500 text-lg mb-5 w-full break-words',
      popupSecondaryButton: 'w-full text-black font-medium p-2 rounded-[8px] text-center leading-6 text-lg h-12',
    },
  },
});

export const body = cva('font-sans subpixel-antialiased', {
  variants: {
    size: {
      large: 'text-lg leading-6',
      medium: 'text-base leading-5',
      small: 'text-sm leading-4',
    },
    weight: {
      regular: 'font-normal',
      bold: 'font-bold',
    },
  },
  compoundVariants: [
    {
      size: 'large',
      weight: 'bold',
      className: 'tracking-tight',
    },
  ],
  defaultVariants: {
    weight: 'regular',
    size: 'medium',
  },
});

export const title = cva('font-sans subpixel-antialiased', {
  variants: {
    size: {
      large: 'text-3xl leading-9',
      medium: 'text-2xl leading-7',
      small: 'text-xl leading-6',
      xsmall: 'text-lg leading-5',
    },
  },
});

export const label = cva('font-sans subpixel-antialiased', {
  variants: {
    size: {
      large: 'text-lg leading-6',
      medium: 'text-base leading-6',
      small: 'text-sm leading-5',
    },
    weight: {
      regular: 'font-normal',
      bold: 'font-semibold',
    },
  },
  compoundVariants: [
    {
      size: 'large',
      weight: 'bold',
      className: 'tracking-tight',
    },
  ],
  defaultVariants: {
    weight: 'regular',
    size: 'medium',
  },
});
