import cx from 'classnames';
import React, { useState, useEffect, useLayoutEffect } from 'react';
import { useParams } from 'react-router-dom';

import { pages } from '../../iap/pages';
import { trackEvent } from '../../mixpanel';
import { Button } from '../ui';
import intro1 from './images/1.jpg';
import intro2 from './images/2.jpg';
import intro3 from './images/3.jpg';
import intro4 from './images/4.jpg';
import intro5 from './images/5.jpg';
import './style.scss';

const productConfigMapping = {
  orderprep: {
    hint: '关注水獭掌柜公众号，第一时间获得出餐提醒',
    nexthop: 'otter://orderfeed?fetchResource=true',
  },
} as const;

function Mask({ open }: { open: boolean }) {
  if (open) {
    return <div className="mask"></div>;
  }
  return null;
}

export default function WechatIntroPage() {
  const [showToolTip, setShowToolTip] = useState(() => false);

  const [maskOpen, setMaskOpen] = useState(false);

  const { productName } = useParams<{ productName: string }>();

  const productTitle =
    pages.find((page) => {
      return page.planProductName === productName;
    })?.title ?? '';

  interface Step {
    id: string;
    title: string;
    image: string;
  }

  const steps: Array<Step> = [
    {
      id: '1',
      title: '打开微信搜索',
      image: intro1,
    },

    {
      id: '2',
      title: '找到公众号入口',
      image: intro2,
    },
    {
      id: '3',
      title: '搜索水獭掌柜并关注',
      image: intro3,
    },
    {
      id: '4',
      title: '点击页面底部菜单栏中的“运营工具”，选择“运营中台绑定”',
      image: intro4,
    },
    {
      id: '5',
      title: '输入账号和密码（水獭掌柜 App 用户请输入注册 App 的手机号和密码），点击“绑定”',
      image: intro5,
    },
  ];

  useLayoutEffect(() => {
    trackEvent('wechat_intro_page_view', {
      productName,
    });
  }, [productName]);

  return (
    <div className="page">
      <Mask open={maskOpen} />
      <div className="container">
        <Modal
          bottom
          onClose={() => {
            setShowToolTip(false);
            setMaskOpen(false);
          }}
          onOpen={() => {
            setMaskOpen(true);
          }}
          open={showToolTip}
          title="为什么要关注公众号？"
        >
          {productConfigMapping[productName as keyof typeof productConfigMapping]?.hint ??
            '关注水獭掌柜公众号，第一时间获得微信通知'}
        </Modal>

        <header className="header">
          <div className="header__content">
            <h2 className="header__content__label">{productTitle}</h2>
            <h1 className="header__content__title">
              关注水獭掌柜公众号
              <br />
              第一时间获得微信通知
            </h1>

            <Button
              tooltip
              onClick={() => {
                setShowToolTip(true);
                trackEvent('wechat_intro_show_hint', {
                  productName,
                });
              }}
            >
              <svg width={23} height={23} fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M17.25 10.542v1.917h3.833v-1.917H17.25ZM15.333 16.876c.92.68 2.118 1.582 3.067 2.29.383-.507.767-1.025 1.15-1.532-.949-.71-2.147-1.61-3.067-2.3-.383.517-.766 1.035-1.15 1.542ZM19.55 5.367c-.383-.508-.767-1.026-1.15-1.534-.949.71-2.147 1.61-3.067 2.3.384.508.767 1.026 1.15 1.534.92-.69 2.118-1.581 3.067-2.3ZM3.833 8.625a1.922 1.922 0 0 0-1.916 1.917v1.917c0 1.054.862 1.916 1.916 1.916h.959v3.834h1.916v-3.834h.959l4.791 2.875V5.75L7.667 8.625H3.833Zm4.82 1.639 1.889-1.13v4.733l-1.888-1.13-.46-.278h-4.36v-1.917h4.36l.46-.278ZM14.854 11.5a4.293 4.293 0 0 0-1.437-3.21V14.7a4.257 4.257 0 0 0 1.437-3.2Z"
                  fill="#fff"
                />
              </svg>
              为什么要关注公众号？
            </Button>
          </div>
        </header>

        <div className="steps-container">
          {steps.map((step) => (
            <div key={step.id} className="steps-container__step">
              <p className="steps-container__step__title">
                <span className="steps-container__step__label">{step.id}</span>
                {step.title}
              </p>
              <img className="steps-container__image" alt="" src={step.image} />
            </div>
          ))}
        </div>

        <footer className="bottom-footer">
          <Button
            fill
            primary
            onClick={() => {
              const nexthop = productConfigMapping[productName as keyof typeof productConfigMapping].nexthop;

              if (nexthop) {
                window.location.href = nexthop;
              }
            }}
          >
            完成
          </Button>
        </footer>
      </div>
    </div>
  );
}

function Modal({
  bottom,
  title,
  children,
  open,
  onClose,
  CustomHeader,
  style,
  onOpen,
}: {
  bottom?: boolean;
  open: boolean;
  title?: string;
  children?: React.ReactNode;
  onClose: () => void;
  CustomHeader?: React.ElementType;
  style?: React.CSSProperties;
  onOpen?: () => void;
}) {
  useEffect(() => {
    if (open) {
      onOpen?.();
    } else {
      onClose?.();
    }
  }, [open]);

  if (open) {
    return (
      <div
        style={style}
        className={cx([
          'modal',
          {
            'modal--bottom': bottom,
          },
        ])}
      >
        <header className="modal__header">
          {CustomHeader ? (
            <CustomHeader />
          ) : (
            <>
              <h3 className="modal__header__title">{title}</h3>
              <span onClick={() => onClose()} className="modal__header__close">
                &times;
              </span>
            </>
          )}
        </header>
        <div className="modal__content">{children}</div>
      </div>
    );
  }
  return null;
}
