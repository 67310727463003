import * as React from 'react';

function SvgUpdate(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M9.167 6.667v4.166l3.541 2.1.642-1.066-2.933-1.742V6.667h-1.25zM17.5 8.333V2.5l-2.2 2.2A7.447 7.447 0 0010 2.5a7.5 7.5 0 107.5 7.5h-1.667A5.84 5.84 0 0110 15.833 5.84 5.84 0 014.167 10 5.84 5.84 0 0110 4.167c1.608 0 3.067.658 4.125 1.708l-2.458 2.458H17.5z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgUpdate);
export default ForwardRef;
