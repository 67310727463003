import * as React from 'react';

function SvgCommentIcon(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 9 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.317 8.849a4.3 4.3 0 10-3.093.366v2.284l3.093-2.65z"
        fill="#112232"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgCommentIcon);
export default ForwardRef;
