import * as React from 'react';

function SvgCullinanLiveMonitoring(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 20 20"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      ref={svgRef}
      {...props}
    >
      <path d="M13 10a3 3 0 11-6 0 3 3 0 016 0zM6.82 4.911A5.996 5.996 0 004 10a5.996 5.996 0 002.82 5.089l-1.06 1.696A7.995 7.995 0 012 10c0-2.86 1.502-5.37 3.76-6.785l1.06 1.696zM18 10c0 2.86-1.502 5.37-3.76 6.785l-1.06-1.696A5.996 5.996 0 0016 10a5.996 5.996 0 00-2.82-5.089l1.06-1.696A7.995 7.995 0 0118 10z" />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgCullinanLiveMonitoring);
export default ForwardRef;
