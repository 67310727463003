import * as React from 'react';

function SvgCullinanBilling(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path d="M16 2H4v16l2-2 2 2 2-2 2 2 2-2 2 2V2zm-4.037 10.09c-.32.382-.76.624-1.303.727V14H9.392v-1.183c-1.14-.215-1.82-.979-1.847-2.087l1.57-.196c.01.699.346 1.062.958 1.062.483 0 .768-.26.768-.643 0-.335-.12-.465-.716-.642l-.5-.15c-.69-.214-1.174-.484-1.441-.81-.268-.326-.406-.736-.406-1.24 0-.465.147-.875.432-1.229.293-.354.69-.587 1.182-.69V5h1.268v1.193c.966.214 1.579.913 1.656 1.844l-1.544.196c-.069-.568-.31-.83-.768-.83-.474 0-.742.224-.742.57 0 .297.121.446.63.595l.613.168c1.38.4 1.95 1.006 1.95 2.012 0 .513-.164.96-.492 1.342z" />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgCullinanBilling);
export default ForwardRef;
