import React, { InputHTMLAttributes, ReactNode, useMemo } from 'react';

import { useClientRect } from '@css/op-hooks';

import { Box } from '../../layouts/Box';
import { StyledInput, StyledInputAffixWrapper, StyledInputWrapper } from './styles';
import { InputIntent, InputSize, InputType, InputVariant } from './types';

// Input
// https://www.figma.com/file/QDorFoJrLM53TvfUZiOKcB/Otter-Design-Language-System?node-id=1743%3A5528
export interface InputProps
  extends Pick<
    InputHTMLAttributes<HTMLInputElement>,
    | 'aria-label'
    | 'aria-labelledby'
    | 'autoFocus'
    | 'defaultValue'
    | 'disabled'
    | 'id'
    | 'inputMode'
    | 'max'
    | 'maxLength'
    | 'min'
    | 'minLength'
    | 'name'
    | 'onBlur'
    | 'onChange'
    | 'onFocus'
    | 'onKeyDown'
    | 'onKeyPress'
    | 'onKeyUp'
    | 'pattern'
    | 'placeholder'
    | 'required'
    | 'role'
    | 'step'
    | 'spellCheck'
    | 'value'
    | 'height'
    | 'tabIndex'
  > {
  /**
   * Optional element auto-complete
   */
  autoComplete?: boolean | string;

  /**
   * Optional data test id attribute used for automated testing
   * @default op-input
   */
  dataTest?: string;

  /**
   * Optional input intent
   * @default default
   */
  intent?: InputIntent;

  /**
   * Optional prefix react node
   */
  prefix?: ReactNode;

  /**
   * Optional element input type.
   * @default text
   */
  type?: InputType;

  /**
   * Optional variant
   * @default medium
   */
  size?: InputSize;

  /**
   * Optional suffix react node
   */
  suffix?: ReactNode;

  /**
   * Optional input variant.
   * @default form
   */
  variant?: InputVariant;

  /**
   * Whether to disable number increment/decrement buttons in Webkit/Firefox
   */
  disableNumberArrows?: boolean;
}

export const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      autoComplete,
      dataTest = 'op-input',
      disabled = false,
      intent = 'default',
      prefix,
      size = 'medium',
      suffix,
      variant = 'form',
      disableNumberArrows = false,
      ...props
    },
    ref
  ) => {
    const [prefixElementDimensions, prefixElementRef] = useClientRect<HTMLDivElement>();
    const [suffixElementDimensions, suffixElementRef] = useClientRect<HTMLDivElement>();

    const style: React.CSSProperties = useMemo(() => {
      return {
        paddingLeft: prefixElementDimensions?.width,
        paddingRight: suffixElementDimensions?.width,
      };
    }, [prefixElementDimensions?.width, suffixElementDimensions?.width]);

    return (
      <StyledInputWrapper>
        {!!prefix && (
          <StyledInputAffixWrapper ref={prefixElementRef} left={0}>
            <Box display="flex" marginLeft={variant === 'minimal' ? 0 : '$3'} marginRight="$2">
              {prefix}
            </Box>
          </StyledInputAffixWrapper>
        )}
        <StyledInput
          {...props}
          ref={ref}
          autoComplete={autoComplete === true ? 'on' : autoComplete === false ? 'off' : autoComplete}
          data-testid={dataTest}
          disabled={disabled}
          style={style}
          $intent={intent}
          $size={size}
          $variant={variant}
          $disableNumberArrows={disableNumberArrows}
        />
        {!!suffix && (
          <StyledInputAffixWrapper ref={suffixElementRef} right={0}>
            <Box display="flex" marginLeft="$2" marginRight={variant === 'minimal' ? 0 : '$2'}>
              {suffix}
            </Box>
          </StyledInputAffixWrapper>
        )}
      </StyledInputWrapper>
    );
  }
);
