import { createQuery } from 'react-query-kit';
import { useQuery, useQueryClient, UseQueryOptions } from '@tanstack/react-query';

import { moneyToNumber } from '@css/currency';

import { gql } from 'graphql-request';
import { spawnGraphqlClient, urqlClient } from '../http-clients';
import { trackAction } from '../mixpanel';
import {
  AvailableCoupon,
  FlatValueCoupon,
  PercentageValueCoupon,
  plansByProductResponse,
  DisplayPlan,
  Plan,
  Money,
  EnrichedFlatValueCoupon,
} from './types';
import { useParams } from 'react-router';
import { useFacilityId } from '@/hooks/use-facility-id';
import { queryClient } from '@/query-client';
import { fetchVasStoreId } from '@/hooks/use-vas-store-id';
import { planIsTypeOfPlan } from '@/features/plan/utils';

const planFragment = gql`
  fragment PlanFragment on Plan {
    name
    scope
    countryCode
    features
    distributorName
    paymentProviders(scenario: APP_2_APP) {
      scsAccountId
      scsAccountType
      paymentMethods {
        preference
        paymentMethodType
      }
    }
    duration {
      months
      days
    }
    firstDuration {
      months
      days
    }
    localizedPlanName(locale: "zh_CN")
    localizedPlanShortName(locale: "zh_CN")
    localizedPromotionText(locale: "zh_CN")
    availableCoupons {
      ... on FlatValueCoupon {
        __typename
        couponInfo {
          code
          expiryTime
          title
        }
        value {
          units
          nanos
          currencyCode
        }
      }
      ... on PercentageValueCoupon {
        __typename
        couponInfo {
          code
          expiryTime
          title
        }
        value {
          stringValue
        }
      }
    }
    lineItemPricingSpec {
      pricingSpecs {
        id
        feeSpec {
          feeType
          staticFeeUnitPrice {
            units
            nanos
            currencyCode
          }
        }
      }
    }
    autoRenewable
    rank
    unitPrice {
      units
      nanos
      currencyCode
    }
    basePrice {
      units
      nanos
      currencyCode
    }
    firstPurchaseUnitPrice {
      units
      nanos
      currencyCode
    }
    description
    banner
  }
`;

const productQuery = gql`
  ${planFragment}
  query fetchProductInfo($productName: String!, $distributorName: String, $facilityId: String, $storeId: ID!) {
    plansByProduct(
      input: {
        facilityId: $facilityId
        productName: $productName
        distributorName: $distributorName
        planAssociatedEntity: { entityId: $storeId, entityType: STORE }
      }
    ) {
      plans {
        ...PlanFragment
      }
      recommendedPlanName
    }
  }
`;

const planQuery = gql`
  ${planFragment}
  query fetchPlan($planName: String!) {
    planByName(input: { name: $planName }) {
      plan {
        ...PlanFragment
      }
    }
  }
`;

export function isFlatValueCoupon(coupon: AvailableCoupon): coupon is FlatValueCoupon {
  return coupon.__typename === 'FlatValueCoupon';
}

export function isPercentageCoupon(coupon: AvailableCoupon): coupon is PercentageValueCoupon {
  return coupon.__typename === 'PercentageValueCoupon';
}

export const useProductNg = createQuery<
  unknown,
  {
    productName: string;
    facilityId: string;
    distributorName?: string;
  },
  Error
>({
  primaryKey: 'use-product-ng',
  queryFn: async function ({ queryKey: [, { productName, facilityId, distributorName }] }) {
    const res = await urqlClient
      .query(productQuery, {
        productName,
        facilityId,
        distributorName,
      })
      .toPromise();

    const plans = res.data.plansByProduct.plans;

    plans.forEach((plan) => {
      queryClient.setQueryData(['use-plan-ng', plan.name], plan);
    });

    return res;
  },
});

async function fetchProductData(
  productName: string,
  facilityId: string | undefined,
  distributorName: string | undefined,
  storeId: string | undefined
) {
  const res = await spawnGraphqlClient().request(productQuery, {
    productName,
    facilityId,
    distributorName,
    storeId,
  });
  return res;
}

export function useProduct(
  productName: string,
  options?: UseQueryOptions<plansByProductResponse, Error, Array<DisplayPlan>, Array<string>>
) {
  const queryClient = useQueryClient();
  const { distributorName } = useParams<{ distributorName?: string }>();
  const { data: facilityId } = useFacilityId();

  return useQuery<plansByProductResponse, Error, Array<DisplayPlan>, Array<string>>(
    ['fetch-product', productName, 'default'],
    async function () {
      const start = Date.now();
      const storeId = await fetchVasStoreId();
      if (productName === 'ofoapi') {
        const [ofoapiRes, ofoapiBundleRes] = await Promise.all([
          fetchProductData('ofoapi', facilityId, distributorName, storeId),
          fetchProductData('ofoapibundle', facilityId, distributorName, storeId),
        ]);

        // Combining the plans
        const elapse = Date.now() - start;
        trackAction('IAP: Loading plan data', { time: elapse, productName: 'ofoapi' });
        trackAction('IAP: Loading plan data', { time: elapse, productName: 'ofoapibundle' });
        return {
          plansByProduct: {
            plans: [...ofoapiBundleRes.plansByProduct.plans, ...ofoapiRes.plansByProduct.plans],
          },
          recommendedPlanName: ofoapiRes.plansByProduct.recommendedPlanName,
        };
      } else {
        const res = await fetchProductData(productName, facilityId, distributorName, storeId);
        const elapse = Date.now() - start;
        trackAction('IAP: Loading plan data', { time: elapse, productName });
        return res;
      }
    },
    {
      enabled: Boolean(facilityId),
      select: (data) => {
        const recommendedPlanName = data.plansByProduct.recommendedPlanName;
        const plans = (data.plansByProduct?.plans ?? ([] as Array<Plan>)).map((plan) => {
          const zeroMoney = {
            currencyCode: 'CNY',
            units: 0,
            nanos: 0,
          };
          let displayPriceMoney: Money =
            (plan.autoRenewable
              ? plan.firstPurchaseUnitPrice
              : plan.lineItemPricingSpec?.pricingSpecs[0]?.feeSpec.staticFeeUnitPrice) ?? zeroMoney;
          const periodInMonth = plan.duration.months;
          let firstPurchaseUnitPriceMoney = plan.firstPurchaseUnitPrice;

          if (plan.autoRenewable && moneyToNumber(firstPurchaseUnitPriceMoney) === 0) {
            // firstPurchaseUnitPriceNumber will be 0 when user has already purchased it
            // we need to use price from lineItemPricingSpec in this case
            displayPriceMoney = firstPurchaseUnitPriceMoney =
              plan.lineItemPricingSpec?.pricingSpecs[0]?.feeSpec.staticFeeUnitPrice;
          }

          const allFlatValueCoupon: EnrichedFlatValueCoupon[] = (plan.availableCoupons || [])
            .map((coupon): EnrichedFlatValueCoupon | undefined => {
              if (isFlatValueCoupon(coupon)) {
                return {
                  ...coupon,
                  applicablePlans: [plan.name],
                  distributorPlans: [],
                  status: 'REDEEMABLE',
                  descriptionFromPlan: plan.localizedPlanShortName,
                };
              } else if (coupon) {
                return {
                  couponInfo: coupon.couponInfo,
                  value: {
                    currencyCode: '',
                    units: 0,
                    nanos: 0,
                  },
                  applicablePlans: [plan.name],
                  distributorPlans: [],
                  status: 'REDEEMABLE',
                  descriptionFromPlan: plan.localizedPlanShortName,
                  percentage: Number(coupon.value.stringValue),
                };
              } else {
                return undefined;
              }
            })
            .filter((coupon): coupon is EnrichedFlatValueCoupon => typeof coupon !== 'undefined');

          const unitPriceNumber = plan.unitPrice && moneyToNumber(plan.unitPrice);
          const basePriceNumber = plan.basePrice && moneyToNumber(plan.basePrice);

          const bestValueCoupon: EnrichedFlatValueCoupon | undefined = allFlatValueCoupon.sort((couponA, couponB) => {
            const originPrice = moneyToNumber(displayPriceMoney);
            const finalPriceA = couponA.percentage
              ? originPrice * couponA.percentage
              : originPrice - moneyToNumber(couponA.value);
            const finalPriceB = couponB.percentage
              ? originPrice * couponB.percentage
              : originPrice - moneyToNumber(couponB.value);
            return finalPriceA - finalPriceB;
          })[0];

          return {
            ...plan,
            displayPrice: displayPriceMoney,
            average: moneyToNumber(displayPriceMoney) / periodInMonth,
            rank: plan.rank,
            firstPurchaseUnitPriceNumber: moneyToNumber(firstPurchaseUnitPriceMoney),
            unitPriceNumber,
            basePriceNumber,
            allFlatValueCoupon,
            bestValueCoupon,
            banner: plan.banner || getLocalBannerOverride(plan),
            isRecommended: plan.name === recommendedPlanName,
          };
        });

        return plans.sort((a, b) => a.rank - b.rank);
      },
      refetchOnMount: true,
      ...options,
      onSuccess: function (data) {
        options?.onSuccess?.(data);

        for (const plan of data) {
          queryClient.setQueryData(['fetch-plan', plan.name], plan);
        }
      },
    }
  );
}

export function usePlan(productName: string, planName?: string) {
  const { refetch } = useProduct(productName);

  return useQuery(
    ['fetch-plan', planName],
    async function () {
      const { data } = await refetch();
      const plan = data?.find((plan) => planIsTypeOfPlan(plan, planName ?? ''));
      if (data && !plan) {
        throw new Error(`Plan not found: ${String(planName)}`);
      }
      return plan;
    },
    {
      enabled: Boolean(planName) && Boolean(productName),
      staleTime: 30 * 1000,
    }
  );
}

export const usePlanNg = createQuery({
  primaryKey: 'use-plan-ng',
  queryFn: async function ({ queryKey: [, { planName }] }) {
    const fromCache = await queryClient.getQueryData(['use-plan-ng', planName]);
    if (fromCache) {
      return fromCache;
    }

    const res = await urqlClient.query(planQuery, { planName }).toPromise();

    return res.data.planByName.plan;
  },
});

function getLocalBannerOverride(plan: Plan): string | undefined {
  const overrides: Record<string, string> = {
    // staging:
    'ordercashier.sw.0d.store.chn.v0': '超实惠',
    'ordercashier.sw.hw.0d.store.chn.v0': '组合套装',

    // production:
    'ordercashier.sw.store.chn.v0': '超实惠',
    'ordercashier.sw.hw.store.chn.v0': '组合套装',
  };
  return overrides[plan.name];
}
