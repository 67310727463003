import * as React from 'react';

function SvgWrench(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M15.67 10.749a4.54 4.54 0 01-4.982.97L5.408 17 3 14.593l5.281-5.281a4.54 4.54 0 015.953-5.953l-2.977 2.976 2.408 2.408 2.976-2.977a4.54 4.54 0 01-.97 4.983z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgWrench);
export default ForwardRef;
