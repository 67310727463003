import * as React from 'react';

function SvgCullinanContainedErrorFilled(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 17a7 7 0 100-14 7 7 0 000 14zm2.058-9.942l.884.884L10.884 10l2.058 2.058-.884.884L10 10.884l-2.058 2.058-.884-.884L9.116 10 7.058 7.942l.884-.884L10 9.116l2.058-2.058z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgCullinanContainedErrorFilled);
export default ForwardRef;
