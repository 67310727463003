import * as React from 'react';

function SvgCircleCable(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <circle cx={16} cy={16} r={16} fill="#E6E9ED" />
      <path
        d="M22.667 10.167v-.834a.836.836 0 00-.834-.833h-1.666a.836.836 0 00-.834.833v.834H18.5V13.5c0 .458.375.833.834.833h.833v5.834c0 .916-.75 1.666-1.667 1.666-.916 0-1.666-.75-1.666-1.666v-8.334a3.332 3.332 0 10-6.667 0v5.834h-.834a.836.836 0 00-.833.833v3.333h.833v.834c0 .458.375.833.834.833h1.667a.836.836 0 00.833-.833v-.834h.833V18.5a.836.836 0 00-.833-.833h-.833v-5.834c0-.916.75-1.666 1.666-1.666.917 0 1.667.75 1.667 1.666v8.334a3.332 3.332 0 106.667 0v-5.834h.833a.836.836 0 00.833-.833v-3.333h-.833z"
        fill="#3C5266"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgCircleCable);
export default ForwardRef;
