import React from 'react';
import styled from 'styled-components';

export const Footer = styled.footer`
  display: flex;
  flex-direction: column;
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: white;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  padding: 12px 16px;
  padding-bottom: calc(16px + env(safe-area-inset-bottom));
  box-sizing: border-box;
  max-width: inherit;
  box-shadow: 0 -2px 8px rgba(0, 0, 0, 0.3);
`;

export const ButtonContainer = styled.div`
  display: flex;
  gap: 10px;
  justify-content: space-between;
  height: 44px;
`;

export const Tip = styled.div`
  font-family: 'PingFang SC';

  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  /* identical to box height, or 114% */

  display: flex;
  align-items: center;
  letter-spacing: -0.006em;

  /* Static / Text / Strong */

  color: #000000;

  background-color: #ce6d5033;
  padding: 12px 16px;
  border-radius: 8px;
  margin: 16px;
  display: inline-flex;
  gap: 8px;
  align-items: center;
  & > svg {
    flex-shrink: 0;
  }
`;

export const PlanSelectorPlaceHolder = styled.div`
  width: 100%;
  padding: 0 16px;
  box-sizing: border-box;
  overflow-x: auto;
`;

export const Page = styled.div`
  overflow-x: hidden;
  min-height: 100vh;
  min-height: fill-available;
  padding-bottom: 120px;
  max-width: inherit;
`;

export const Highlight = styled.div`
  position: relative;
  min-height: 100px;
  background-color: white;
  border-radius: 16px;
  padding: 20px 16px;
  margin: 16px;
  box-shadow: 0px 0px 2px 0px #00000012, 0px 1px 2px 0px #0000001a, 0px 1px 1px 0px #0000001a;
`;

const H1 = styled.h1`
  font-size: 28px;
  line-height: 1.428571;
  color: white;
  font-weight: normal;
  & > strong {
    font-weight: bold;
  }
`;

export const H2 = styled.h2`
  font-size: 18px;
  line-height: 1.444444;
`;

export const H3 = styled.h3`
  font-size: 16px;
  line-height: 1.4;
  margin: 0;
  padding: 0;
`;

export const SectionTitle = styled.div`
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.02em;
`;

const Description = styled.p`
  margin: 0;
  padding: 0;
  font-size: 14px;
  line-height: 1.4;
  color: #525252;
  margin-bottom: 8px;
`;

const HighLightLayout = styled.div`
  display: flex;
  align-items: flex-start;
  margin: 8px 0;
  gap: 12px;
`;

const HighLightIcon = styled.img`
  width: 48px;
  height: 48px;
  flex-shrink: 0;
`;

export const HighlightTitle = styled.h2<{ color?: string }>`
  font-weight: bold;
  font-size: 18px;
  line-height: 1.111111;
  padding-left: 8px;
  border-left: 4px solid ${(props) => props.color ?? 'initial'};
`;

export function HighlightPoint({ icon, title, content }: { icon: string; title: string; content?: string }) {
  return (
    <HighLightLayout>
      <HighLightIcon width="48" height="48" src={icon} role="presentation" />
      <div>
        <H3>{title}</H3>
        <Description>{content ? content : null}</Description>
      </div>
    </HighLightLayout>
  );
}

const HeroWrapper = styled.div<{ background: string }>`
  background-color: ${(props) => props.background ?? 'initial'};
  min-height: 200px;
  overflow: auto;
  position: relative;
`;

export function Hero({ background, children }: { background: string; children?: React.ReactNode }) {
  return <HeroWrapper background={background}>{children}</HeroWrapper>;
}

const HeroContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 80px;
  & > * {
    flex-grow: 1;
  }
  & > ${H1} {
    margin-left: 16px;
  }
`;

export function HeroContent({ title, image }: { title: React.ReactNode; image: string }) {
  return (
    <HeroContentWrapper>
      <H1>{title}</H1>
      <img src={image} role="presentation" />
    </HeroContentWrapper>
  );
}

export const Button = styled.a<{ primary?: boolean; disabled?: boolean }>`
  display: block;
  height: 100%;
  width: 100%;
  background: ${(props) => (props.primary ? (props.disabled ? 'rgba(206, 109, 80, 0.2)' : '#ce6d50') : 'white')};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  color: ${(props) => (props.primary ? (props.disabled ? 'rgba(206, 109, 80, 0.2)' : '#fff') : '#000')};
  border: 1px solid rgba(0, 0, 0, 0.16);
  font-size: 16px;
  line-height: 20px;
  text-decoration: none;
  &.loading {
    opacity: 0.4;
  }
`;

export const Byline = styled.span`
  font-size: 10px;
  line-height: 1.166667;
  color: #8f8f8f;
`;

export const BylineStrikeThrough = styled.span`
  font-size: 10px;
  line-height: 1.166667;
  color: #8f8f8f;
  text-decoration: line-through;
`;

export const OriginalPrice = styled(Byline)<{ strikeThrough?: boolean }>`
  text-decoration: ${(props) => (props.strikeThrough ? 'line-through' : 'initial')};
`;

export const BannerImage = styled.img`
  width: 100%;
`;

export const DetailImage = styled.img`
  width: 100%;
  border-radius: 16px 16px 0px 0px;
  display: block;
`;

export const DetailImageWithoutBorder = styled.img`
  width: 100%;
  display: block;
`;

export const AutoRenewableTipText = styled.span`
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  display: flex;
  align-items: center;

  color: #525252;
  padding: 12px 16px 0px;
`;

export const UserAgreementText = styled.div`
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  text-align: center;

  /* Static/Text//Standard */

  color: #525252;
  padding-bottom: 16px;
`;

export const UnbindHint = styled.div`
  padding: 16px 8px 8px;
  text-align: center;
  font-weight: 500;
  font-size: 16px;
`;

export const StyledPlanLoading = styled.div`
  display: flex;
  flex-direction: row;
  overflow: hidden;
  justify-content: center;
`;
const HeaderDiv = styled.div`
  /* background: linear-gradient(132.37deg, #232323 3.79%, #4d4d4d 82.32%); */
  padding: 16px 0px;
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  /* color: #ffffff; */
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;
function LeftArrow() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.5922 3.225L13.1089 1.75L4.86719 10L13.1172 18.25L14.5922 16.775L7.81719 10L14.5922 3.225Z"
        fill="black"
      />
    </svg>
  );
}
export function Nav() {
  return (
    <HeaderDiv>
      <Header>
        <span
          style={{ marginLeft: '20px', zIndex: 100 }}
          onClick={() => {
            window.history.back();
          }}
        >
          <LeftArrow />
        </span>
      </Header>
    </HeaderDiv>
  );
}
