import * as React from 'react';

function SvgCullinanArrowLeft(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path d="M3.5 9.996l5.306 5.3 1.414-1.413-2.891-2.888h9.17V8.997H7.33l2.891-2.889-1.414-1.412-5.306 5.3z" />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgCullinanArrowLeft);
export default ForwardRef;
