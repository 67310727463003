export interface SharedPrintLayout {
  ticketType: string;
  destinationFormat: string;
  ptsTemplateId: string;
  locale: string;
  variables: Variable[];
}

export interface VariablesByTemplateID {
  ptsTemplateId: string;
  variables: Variable[];
}
export interface Variable {
  name: string;
  value: Value;
}

export interface Value {
  sizeOptionValue?: SizeOptionValue;
  booleanValue?: boolean;
  stringValue?: string;
}

export interface TicketTemplate {
  templateId: string;
  templateName: string;
  revisionNumber: number;
  templateType: string;
  contentType: string;
  createdAt: Date;
  updatedAt: Date;
  updatedBy: string;
  inactive: boolean;
  dataModel: TemplateDataModel;
  isAdvanced: boolean;
}

export interface PurpleConfigVariableGroup {
  name: string;
  variableDefinitions: PurpleVariableDefinition[];
  description: string;
}

export interface PurpleVariableDefinition {
  name: string;
  type: VariableDefinitionType;
  defaultValue: PurpleDefaultValue;
  description: string;
  deprecated: boolean;
  userModifiable: boolean;
  descriptionDetails: string;
}

export interface PurpleDefaultValue {
  booleanValue?: boolean;
  stringValue?: string;
}

export enum VariableDefinitionType {
  DataTypeBoolean = 'DATA_TYPE_BOOLEAN',
  DataTypeSizeOption = 'DATA_TYPE_SIZE_OPTION',
  DataTypeString = 'DATA_TYPE_STRING',
}

export interface TemplateDataModel {
  configVariableGroups: FluffyConfigVariableGroup[];
}

export interface FluffyConfigVariableGroup {
  name: string;
  variableDefinitions: FluffyVariableDefinition[];
  description: string;
}

export interface FluffyVariableDefinition {
  name: string;
  type: VariableDefinitionType;
  defaultValue: FluffyDefaultValue;
  description: string;
  deprecated: boolean;
  userModifiable: boolean;
  descriptionDetails: string;
}

export interface FluffyDefaultValue {
  sizeOptionValue?: SizeOptionValue;
  booleanValue?: boolean;
  stringValue?: string;
}

export enum SizeOptionValue {
  SizeOptionLarge = 'SIZE_OPTION_LARGE',
  SizeOptionSmall = 'SIZE_OPTION_SMALL',
  SizeOptionStandard = 'SIZE_OPTION_STANDARD',
}

export type TemplateListResponse = {
  templates: TicketTemplate[];
  advancedTemplates: TicketTemplate[];
};

export type GetPrintLayoutResponse = {
  sharedPrintLayout: SharedPrintLayout;
  variablesByTemplateId: VariablesByTemplateID[];
};

export type UpdatePrintLayoutInput = {
  storeIds: string[];
  printLayout: SharedPrintLayout;
};

export interface PrintQueueMapping {
  queueId: string;
}

export type PrintQueueResponse = {
  printQueueMappings: PrintQueueMapping[];
};

export interface StoresOfFacilityResponse {
  stores: Store[];
}

export interface Store {
  storeId: string;
  config: Config;
}
export interface Config {
  printReceiptPrices: boolean;
  newOrderPopups: boolean;
  usingPos: boolean;
  posName: string;
  printTemplateId: string;
  printModifierGroupName: boolean;
  orderReadySignalConfig: OrderReadySignalConfig;
  menuConfig: MenuConfig;
  printConfig: PrintConfig;
  isActive: boolean;
}

export interface MenuConfig {
  generateZendeskIssues: boolean;
}

export interface OrderReadySignalConfig {
  stationOrdersReady: boolean;
  locationInHandoff: boolean;
  enableStoreHandoff: boolean;
}

export interface PrintConfig {
  templateId: string;
  printModifierGroupName: boolean;
  printOnlyCustomerTicketOnVanillaOrders: boolean;
  printCustomerTicketToStationId: string;
  printPickupEta: boolean;
  printReceiptPrices: boolean;
  printCanceledTicket: boolean;
  printLayouts: PrintLayout[];
}

export interface PrintLayout {
  ptsTemplateId: string;
  ticketType: string;
  destinationFormat: string;
  locale: string;
  variables: Variable[];
}

export interface Contact {
  phone: string;
  email: string;
  homePage: string;
}
export enum SuccessEventType {
  BindPrinter = 'BIND_PRINTER_EVENT',
  OnboardOfo = 'ONBOARD_OFO_EVENT',
  PrintOrder = 'PRINT_ORDER_EVENT',
  MarkOrderReady = 'MARK_ORDER_READY_EVENT',
  SplashCouponVasbundleOperate = 'SPLASH_COUPON_VASBUNDLEOPERATE',
  SplashCouponXCD = 'SPLASH_COUPON_XCD',
  AdvancedTemplate = 'ADVANCED_TEMPLATE_EVENT',
  splashScreen_bundleOrder = 'SPLASH_SCREEN_BUNDLE_ORDER_EVENT',
  splashScreen_bundleProtection = 'SPLASH_SCREEN_BUNDLE_PROTECTION_EVENT',
  claim_only_bundle_order = 'CLAIM_ONLY_BUNDLE_ORDER_EVENT',
  claim_only_bundle_operate = 'CLAIM_ONLY_BUNDLE_OPERATE_EVENT',
  claim_only_xcd = 'CLAIM_ONLY_XCD_EVENT',
  claim_only_protection = 'CLAIM_ONLY_BUNDLE_PROTECTION_EVENT',
}
export interface PrintPreviewResponse {
  preview: Preview[];
  errors: any[];
  errorCorrelationId: string;
  previewImages: PreviewImage[];
}

export interface Preview {
  base64PngImage: string;
}

export interface PreviewImage {
  preview: Preview[];
  destinationFormat: string;
}
