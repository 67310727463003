import * as React from 'react';

function SvgThumbsDown(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 29 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.038 0h3.839c.53 0 .96.428.96.955V18.15c0 .527-.43.955-.96.955h-3.838a.957.957 0 01-.96-.955V.955c0-.527.43-.955.96-.955zM2.229 17.744a3.956 3.956 0 01-1.4-1.146 3.93 3.93 0 01-.69-3.44l2.327-8.691a6.016 6.016 0 012.158-3.221A6.063 6.063 0 018.305 0h8.742c.993 0 1.965.292 2.79.842l1.32 1.024v16.001a21.57 21.57 0 00-5.037 4.097l-4.736 5.24A2.43 2.43 0 019.651 28a2.547 2.547 0 01-1.771-.703 4.438 4.438 0 01-.839-5.147l2.013-4h-5.06a3.975 3.975 0 01-1.767-.405z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgThumbsDown);
export default ForwardRef;
