import * as React from 'react';

function SvgCullinanContainedCheckFilled(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 17a7 7 0 100-14 7 7 0 000 14zm3.527-8.286l-3.964 3.964-2.652-2.652.928-.928 1.724 1.724 3.035-3.036.929.928z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgCullinanContainedCheckFilled);
export default ForwardRef;
