import { Platform, getPlatform, isVersionAfter } from '@/utils/cmpVersions';
import { default as qs } from 'query-string';

export type TDeepLink = `otter://${string}`;
interface TSupplyChainParams {
  type: 'supplychain';
  username: `gh_${string}`;
  path: string;
}

interface TYuanyuanParams {
  type: 'yuanyuan';
}

interface TOfficialAccountRedirectParams {
  type: 'official_account_redirect';
  otterId: string;
  source: string;
  traceId: string;
}

type TMiniAppParams = TSupplyChainParams | TYuanyuanParams | TOfficialAccountRedirectParams;

function isSupplyChainParams(params: TMiniAppParams): params is TSupplyChainParams {
  return params.type === 'supplychain';
}

function isYuanyuanParams(params: TMiniAppParams): params is TYuanyuanParams {
  return params.type === 'yuanyuan';
}

function isOfficialAccountRedirectParams(params: TMiniAppParams): params is TOfficialAccountRedirectParams {
  return params.type === 'official_account_redirect';
}

export function getLinkForMiniApp(params: TMiniAppParams): TDeepLink {
  // TODO: 最后实际传给客户端的参数，需要校验 username 是 `gh_` 开头。

  if (isOfficialAccountRedirectParams(params)) {
    const { otterId, source, traceId } = params;
    const search = qs
      .stringify({
        username: 'gh_a46dfcd05946',
        path: qs.stringifyUrl({
          url: 'pages/otter/aboutus',
          query: {
            otterId,
            source,
            traceId,
          },
        }),
      })
      .toString();

    return `otter://open_wechat_mini_app?${search}`;
  }

  if (isSupplyChainParams(params)) {
    const { username, path } = params;
    const search = qs
      .stringify({
        username,
        path,
      })
      .toString();

    return `otter://open_wechat_mini_app?${search}`;
  }

  if (isYuanyuanParams(params)) {
    return `otter://open_wechat_mini_app?${qs.stringify({ campaign: '001-yuanyuan' })}`;
  }

  throw new Error('invalid params for open_wechat_mini_app deeplink', params);
}

export type TPrintTemplateAction = 'edit_logo' | 'edit_line_spacing' | 'edit_seller_note';

export function getLinkForPrintTemplate(
  action: TPrintTemplateAction,
  fallback: TDeepLink = 'otter://orderfeed'
): TDeepLink {
  if (
    isVersionAfter({
      iOS: '3.9.1',
      android: '4.0.3',
    })
  ) {
    return `otter://active_print_template/custom_page/${action}?${qs.stringify({ action })}`;
  }

  return fallback;
}

/***
 * 获取 native app 中的 order feed
 * operation 为 android 特有参数，当 operation 为 'orderpre' 时，会重新拉取订单
 */
export function getLinkForOrderFeed(facilityId?: string) {
  const platform = getPlatform();

  function getBindingByPlatform(platform: Platform) {
    if (platform === 'ANDROID') {
      return {
        facilityId,
        operation: 'orderprep',
      };
    }

    return { facilityId, fetchResource: true };
  }

  return `otter://orderfeed?${qs.stringify(getBindingByPlatform(platform))}`;
}

export function getLinkForHomepage() {
  return 'otter://open_home_page';
}

const STAFF_CUSTOM_SERVICE_LINK_MAP: Record<string, string> = {
  xcd: 'https://www.soboten.com/chat/h5/v2/index.html?sysnum=d186971f59f44eb8b4a16c1addc602cc&channelid=5',
  orderbooster: 'https://www.soboten.com/chat/h5/v2/index.html?sysnum=d186971f59f44eb8b4a16c1addc602cc&channelid=5',
};
export function getCustomServiceLink(productName: string) {
  return STAFF_CUSTOM_SERVICE_LINK_MAP[productName] ?? `otter://support/customer_service?sceneId=iap-${productName}`;
}

export function getOrderMissingLink({ facilityId, entitledDays }: { facilityId: string; entitledDays: number }) {
  return `otter://ordermissquery?facilityId=${facilityId}&entitledDays=${entitledDays}`;
}

/**
 *
 * @returns 访问打印机 native view 的 deeplink
 */
export function getPairPrinterLink() {
  return `otter://printer`;
}

type AccountType = 'meituan' | 'meituan-admin' | 'eleme';

export function getOfoOnboardLink({
  accountType,
  storeId,
  source_tag,
  product,
  callbackurl,
}: {
  accountType: AccountType;
  storeId: string;
  source_tag: string;
  product: string;
  callbackurl?: string;
}): TDeepLink {
  type OnboardLinkParams = {
    storeId: string;
    account_type: AccountType;
    ofotype: 'meituan' | 'eleme';
    source_tag: string;
    callbackurl?: string;
    product: string;
  };

  const params = {
    storeId,
    account_type: accountType,
    ofotype: accountType.startsWith('meituan') ? 'meituan' : 'eleme',
    source_tag,
    callbackurl,
    product,
  } satisfies OnboardLinkParams;

  return `otter://onboard?${qs.stringify(params)}`;
}

export function getWebviewLink(url: string): TDeepLink {
  return `otter://webview?${qs.stringify({
    url: encodeURIComponent(url),
  })}`;
}

export function getDeviceSettingLink(): TDeepLink {
  return `otter://device_settings?action=open`;
}

export function getTemplatePreviewDeepLink({
  queueId,
  templateId,
}: {
  queueId: string;
  templateId: string;
}): TDeepLink {
  return `otter://print/template_preview?${qs.stringify({
    print_queue_id: queueId,
    template_id: templateId,
  })}`;
}
