import * as React from 'react';

function SvgMenuFront(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path d="M41 2H8v44h33V2z" fill="#fff" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 6H6V5h4v1zm0 6H6v-1h4v1zm-4 6h4v-1H6v1zm4 6H6v-1h4v1zm-4 6h4v-1H6v1zm4 6H6v-1h4v1zm-4 6h4v-1H6v1z"
        fill="#1F2B3D"
      />
      <path
        d="M15.324 26.534c0 .955.808 1.763 1.763 1.763h.808c.588 0 1.175.367 1.47.881.293.514.88.882 1.468.882h10.064c.66 0 1.175-.367 1.469-.882.294-.514.808-.881 1.47-.881h.807c.955 0 1.763-.808 1.763-1.763H15.324z"
        fill="#B5B5B5"
      />
      <path d="M34.936 26.534c-1.248-3.82-4.848-6.61-9.035-6.61-4.26 0-7.786 2.79-9.035 6.61h18.07z" fill="#DBDBDB" />
      <path
        d="M25.828 19.996c.661 0 1.249-.587 1.249-1.248 0-.735-.588-1.25-1.25-1.25-.66 0-1.248.588-1.248 1.25 0 .66.588 1.248 1.249 1.248z"
        fill="#1F2B3D"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgMenuFront);
export default ForwardRef;
