import { useEffect, useLayoutEffect, useState } from 'react';
import { Route, Switch, useParams } from 'react-router-dom';
import { Agreements } from '../../common/components/agreements';
import { useExitConfirm } from '@/app-binding/web-view-controls';
import { useUpsellStats } from '@/features/upsell/components/fire-button';
import { LaunchPaymentButton } from '@/next/components/launch-payment-button';
import useSearchParams from '../../hooks/useSearchParams';
import { trackEvent } from '../../mixpanel';
import { ProductSlug, getTrackingName } from '../../product-registry';
import VasBundleOperateUpsellPage from '../upsell/vas-bundle-operate';
import VasBundleOrderUpsellPage from '../upsell/vas-bundle-order';
import VasBundleProtectionUpsellPage from '../upsell/vas-bundle-protection';
import { ButtonContent, PrintTestAndKnowMoreButton } from './components';
import { getFeatureBySlug } from './features';
import { ButtonContainer, StyledContainer, StyledImage, StyledTopInfo, Text } from './ui';
import { cssTracking } from '@/tracking/css-tracking';
import { XCDUpsellPage } from '../upsell/xcd';

export default function FeatureUnlockedPage() {
  const { couponId } = useSearchParams();
  const { productName } = useParams<{ productName: ProductSlug }>();
  const feature = getFeatureBySlug(productName);

  const [exitConfirmContent, setExitConfirmContent] = useState<string>('确认放弃限时特权吗？');

  const statsQuery = useUpsellStats({
    variables: {
      productName,
    },
  });

  useEffect(
    function () {
      if (statsQuery.data) {
        const count = statsQuery.data?.paidOrderSummary[0].paidOrderNumber;

        if (count) {
          setExitConfirmContent(`累计已有${count}个用户开通此服务，确认放弃限时特权吗？`);
        }
      }
    },
    [statsQuery.data, productName]
  );

  useLayoutEffect(function () {
    try {
      const [perfResult] = performance.getEntriesByType('navigation');
      if (perfResult) {
        trackEvent('page_loaded', {
          page_name: 'UPSELLING_PAGE',
          load_status: 'success',
          load_latency: Math.max(perfResult.duration, 0),
        });

        cssTracking('page_loaded', {
          url: window.location.href,
        });
      }
    } catch (e) {
      console.error(e);

      trackEvent('page_loaded', {
        page_name: 'UPSELLING_PAGE',
        load_status: 'success',
      });
    }
  }, []);

  useExitConfirm({
    shouldEnable: () => true,
    title: '',
    content: exitConfirmContent,
    ok: '确认放弃',
    cancel: '我再想想',
  });

  useEffect(() => {
    trackEvent('upselling_landing_page_loaded', {
      page_name: 'UPSELLING_PAGE',
      // TODO: 需要在这里处理支持多个产品的逻辑
      product_list: [getTrackingName(productName)],
    });
  }, []);

  return (
    <Switch>
      <Route path="/unlock/:productName(vasbundleprotection)">
        <VasBundleProtectionUpsellPage />
      </Route>

      <Route path="/unlock/:productName(vasbundleorder)">
        <VasBundleOrderUpsellPage />
      </Route>

      <Route path="/unlock/:productName(vasbundleoperate)">
        <VasBundleOperateUpsellPage />
      </Route>

      <Route path="/unlock/:productName(xcd)">
        <XCDUpsellPage />
      </Route>

      <Route>
        {feature ? (
          <StyledContainer style={{ height: 'auto', minHeight: '100vh' }}>
            <StyledTopInfo>
              <Text variant="titleLargeSemiBold">
                <span style={{ color: '#E06E4D' }}>{feature.name}</span>
                服务特权已激活
              </Text>
              <br />
              {feature.byline}
            </StyledTopInfo>

            <StyledImage src={feature.screenshot} width={feature.width} height={feature.height} />

            <ButtonContainer>
              <Agreements
                files={['user-agreement', 'privacy-policy']}
                trackingContext={{
                  product_name: getTrackingName(productName),
                  page_name: 'UPSELLING_PAGE',
                }}
              />

              <LaunchPaymentButton planName={feature.purchasePlan} couponCode={couponId}>
                <ButtonContent />
              </LaunchPaymentButton>

              <PrintTestAndKnowMoreButton />
            </ButtonContainer>
          </StyledContainer>
        ) : null}
      </Route>
    </Switch>
  );
}
