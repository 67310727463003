import * as React from 'react';

function SvgAngledStarFilled(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M9.5 0l2.133 6.564h6.902l-5.584 4.057 2.133 6.565L9.5 13.129l-5.584 4.057 2.133-6.565L.465 6.564h6.902L9.5 0z"
        fill="#112232"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgAngledStarFilled);
export default ForwardRef;
