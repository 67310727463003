import * as React from 'react';

function SvgCheck(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg
      fill="currentcolor"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 8 8"
      width="1em"
      height="1em"
      ref={svgRef}
      {...props}
    >
      <path d="M7.328 2.203L3.15 6.4h-.003c-.053.053-.197.172-.363.172a.547.547 0 01-.365-.178l-1.75-1.75a.125.125 0 010-.178l.556-.557a.123.123 0 01.088-.037c.03 0 .062.012.087.037l1.388 1.388L6.6 1.457a.123.123 0 01.088-.038c.034 0 .065.012.087.037l.547.566c.056.053.056.131.006.181z" />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgCheck);
export default ForwardRef;
