import { isInOtterApp } from '@/app-binding/env';
import { createLogger } from '@/logger';
import { captureException } from '@sentry/browser';
const logger = createLogger('get-token');

export function getToken(): string {
  const token = localStorage.getItem('css.authtoken');
  if (typeof token !== 'string') {
    const errorMessage = 'http-client: token is undefined';
    const error = new Error(errorMessage);
    if (isInOtterApp()) {
      logger.error(errorMessage);
      captureException(error);
    }
    throw error;
  }
  return token;
}
