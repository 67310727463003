import LoadingOverlay from '@/common/components/LoadingOverlay/LoadingOverlay';
import { Page } from '@/components/page';
import useSearchParams from '@/hooks/useSearchParams';
import { spawnHttpClient } from '@/http-clients';
import { trackEvent } from '@/mixpanel';
import queryString from 'query-string';
import { useEffect } from 'react';
import { createQuery } from 'react-query-kit';

/***
 * 这里要求 variantMap 一定需要 `default` 的情况
 */
export type VariantMap = {
  default: () => JSX.Element | null;
  [variant: string]: () => JSX.Element | null;
};

interface ExperimentProps {
  name: string;
  variantMap: VariantMap;
}

export interface ExperimentResponse {
  experiment: string;
  variant: string;
}

export type ExperimentVariables = {
  name: string;
  override?: string;
} & ExperimentContext;

export type ExperimentContext = Partial<{ planName: string }>;

/***
 * useExperiment
 * @param name 实验的名字
 * @param override 实验的 override，如果有 override 那么直接返回对应的 override
 * @description 根据 name 和 override 来返回 experiment 的结果，如果 name 是空，那么直接返回 `{ experiment: '', variant: 'default' }`，如果有 override 那么直接返回对应的 override，再有其他的情况会去请求 api
 */
export const useExperiment = createQuery<ExperimentResponse, ExperimentVariables, Error>({
  primaryKey: 'experiment',
  staleTime: Infinity,
  queryFn: async ({ queryKey: [, variables] }) => {
    const { override, name } = variables;
    if (override) {
      return {
        experiment: name,
        variant: override,
      };
    }
    if (!name) {
      return {
        experiment: name,
        variant: 'default',
      };
    }
    const httpClient = spawnHttpClient();

    const res = await httpClient.get<ExperimentResponse>({
      path: queryString.stringifyUrl({
        url: '/lutrahub/abtest/experiment',
        query: { experiment: variables.name },
      }),
      method: 'GET',
      requestParams: {},
    });

    return {
      ...res,
      experiment: res.experiment ?? variables.name,
    };
  },
  retry: 0,
});

export function Experiment({ name, variantMap }: ExperimentProps) {
  const { experimentOverride } = useSearchParams();

  const experimentQuery = useExperiment({
    variables: { name, override: experimentOverride },
  });

  useEffect(
    function () {
      if (experimentQuery.data) {
        trackEvent('variant_loaded', {
          experimentName: experimentQuery.data.experiment,
          variantName: experimentQuery.data.variant,
        });
      }
    },
    [experimentQuery.data]
  );

  if (experimentQuery.isFetching && Boolean(name)) {
    return <LoadingOverlay />;
  }

  const variant = experimentQuery.data?.variant ?? 'default';

  const Component = variantMap[variant];
  if (Component) {
    return (
      <Page name={variant}>
        <Component />
      </Page>
    );
  }

  return null;
}
