import * as React from 'react';

function SvgCullinanSearch(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 3a6 6 0 103.477 10.89L16 17.415 17.414 16l-3.523-3.524A6 6 0 009 3zM5 9a4 4 0 118 0 4 4 0 01-8 0z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgCullinanSearch);
export default ForwardRef;
