import * as React from 'react';

function SvgUser(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 12 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.735 7.127a3.086 3.086 0 100-6.172 3.086 3.086 0 000 6.172zM.49 11.763C1.57 9.56 3.515 8.091 5.734 8.091c2.22 0 4.164 1.47 5.245 3.672.286.584-.099 1.244-.735 1.378a21.889 21.889 0 01-9.019 0C.59 13.007.204 12.347.49 11.763z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgUser);
export default ForwardRef;
