import * as React from 'react';

function SvgArrow(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      ref={svgRef}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.78 2.97a.75.75 0 10-1.06 1.06l3.22 3.22H.75a.75.75 0 000 1.5h12.19l-3.22 3.22a.75.75 0 101.06 1.06L15.81 8l-5.03-5.03z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgArrow);
export default ForwardRef;
