import { useEntitlement } from '@/hooks/use-entitlement';
import { queryClient } from '@/query-client';
import type { LandingRule } from './types';

async function isEntitledFor(feature: string, entityType: 'FACILITY' | 'STORE' = 'FACILITY') {
  const entitled = await queryClient.fetchQuery({
    queryKey: useEntitlement.getKey({ feature, entityType }),
    queryFn: useEntitlement.queryFn,
  });
  return entitled;
}

export function buildEntitlementRule(
  productName: string,
  feature: string,
  entityType: 'STORE' | 'FACILITY'
): LandingRule {
  return {
    checker: async function () {
      const isEntitled = await isEntitledFor(feature, entityType);
      return isEntitled;
    },
    otherwise: function () {
      window.location.replace(`/iap/${productName}`);
    },
  };
}
