import * as React from 'react';

function SvgLocation(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <ellipse cx={16} cy={22} rx={7} ry={3} fill="#B9B9B9" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.36 21.724c1.207-1.368 5.025-5.893 5.025-8.379C21.385 10.393 18.974 8 16 8c-2.974 0-5.385 2.393-5.385 5.345 0 2.486 3.818 7.011 5.024 8.379a.479.479 0 00.722 0zM16 15.636a2.3 2.3 0 002.308-2.29A2.3 2.3 0 0016 11.053a2.3 2.3 0 00-2.308 2.291A2.3 2.3 0 0016 15.635z"
        fill="#fff"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgLocation);
export default ForwardRef;
