import { css } from 'styled-components';

import { Color, StyledBorderUtil } from './types';

export const border: StyledBorderUtil = (width = '1px', color: Color = 'ashDark') => ({ theme }) =>
  css`
    border: ${width} solid ${theme.colors[color]};
  `;

border.top = (width = '1px', color: Color = 'ashDark') => ({ theme }) =>
  css`
    border-top: ${width} solid ${theme.colors[color]};
  `;
border.left = (width = '1px', color: Color = 'ashDark') => ({ theme }) =>
  css`
    border-left: ${width} solid ${theme.colors[color]};
  `;
border.bottom = (width = '1px', color: Color = 'ashDark') => ({ theme }) =>
  css`
    border-bottom: ${width} solid ${theme.colors[color]};
  `;
border.right = (width = '1px', color: Color = 'ashDark') => ({ theme }) =>
  css`
    border-right: ${width} solid ${theme.colors[color]};
  `;
