import * as React from 'react';

function SvgRoundError(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <circle cx={14} cy={14} r={12} fill="#B41313" />
      <path d="M9.5 18.5l9-9M9.5 9.5l9 9" stroke="#fff" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M14 24C8.477 24 4 19.523 4 14H0c0 7.732 6.268 14 14 14v-4zm10-10c0 5.523-4.477 10-10 10v4c7.732 0 14-6.268 14-14h-4zM14 4c5.523 0 10 4.477 10 10h4c0-7.732-6.268-14-14-14v4zm0-4C6.268 0 0 6.268 0 14h4C4 8.477 8.477 4 14 4V0z"
        fill="#F9F9F9"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgRoundError);
export default ForwardRef;
