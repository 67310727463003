import React from 'react';
import { FC } from 'react';

export const RightArrowIcon: FC<React.SVGProps<SVGSVGElement>> = ({ fill = '#000' }: { fill?: string }) => {
  return (
    <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.7 0 .3 1.41 4.88 6 .3 10.59 1.7 12l6-6-6-6Z" fill={fill} />
    </svg>
  );
};
