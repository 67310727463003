import React from 'react';
import { useLocation, Switch, Route, Redirect } from 'react-router-dom';

import PrepTimeConfig from './001-preptime-config';
import WechatIntroPage from './003-wechat-intro';

export function AppModuleIndex() {
  const location = useLocation();

  return (
    <Switch>
      <Route path="/am/preptime-config">
        <PrepTimeConfig />
      </Route>
      <Route
        path="/am/privileges-list"
        render={() => <Redirect to={{ ...location, pathname: '/upsell/privileges-list' }} />}
      />
      <Route path="/am/wechat-intro/:productName">
        <WechatIntroPage />
      </Route>
    </Switch>
  );
}
